import { fromJS } from 'immutable';

const initialState = fromJS({
  hasFetchedPromos: false,
  promos: []
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_PROMOS':
      return state.set('promos', fromJS(action.promos));
    case 'SET_HAS_FETCHED_PROMOS':
      return state.set('hasFetchedPromos', action.hasFetchedPromos);
    default:
      return state;
  }
}
