import { getQueryParamsAsObject } from './url';
import { getBrowserLocale } from './locale';
import constants from './constants';

export const getClientId = () => {
  const clientId = window?.POINTS_CONFIG?.clientId;
  if (clientId) {
    return clientId;
  }
  console.error('No clientId defined!');
  return '';
};

export const getLocale = () => {
  const params = getQueryParamsAsObject();
  const locale = params.locale || getBrowserLocale() || constants.defaultLocale;

  return locale;
};

export const getEnvironment = () => {
/* TODO: Switch this back to check for process.env.ENV and update to what we did in DLWUI-254
   after we stop deploying via the wallet repository */

  const subdomain = window.location.host.split('.')[0];
  const isDevEnv = constants.devEnvironments.find(env => subdomain.includes(env));

  return isDevEnv ? constants.devEnvKey : constants.prodEnvKey;
};

export const isProdEnvironment = () => getEnvironment() === 'prod';

export default {
  isProdEnvironment
};
