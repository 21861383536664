import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import constants from '../utils/constants';
import { handleKeyPressEscape } from './utils/accessibility-helpers';

class AppModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    const { showModal } = props;

    this.state = {
      isShowing: showModal,
      isShowingModal: showModal,
      onKeyUp: handleKeyPressEscape(() => this.setState({ isShowingModal: false }))
    };
  }

  UNSAFE_componentWillMount() {
    const { onKeyUp } = this.props;

    if (onKeyUp) {
      this.setState({ onKeyUp });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { updateIsShowing, showModal } = this.props;
    const hasInternallyChangedDisplay = nextState.isShowingModal !== this.state.isShowingModal &&
                                        nextState.isShowingModal !== showModal;
    const hasExternallyChangedDisplay = showModal !== nextProps.showModal;

    if (hasExternallyChangedDisplay) {
      const isShowing = nextProps.showModal;
      this.handleOpenOrClose(isShowing);
    }

    if (hasInternallyChangedDisplay) {
      this.delayAnimation(() => {
        if (updateIsShowing) {
          updateIsShowing(nextState.isShowingModal);
        }
      });
    }
  }

  close = () => {
    this.setState({ isShowingModal: false });
  };

  delayAnimation = fn => {
    setTimeout(fn, constants.baseAnimationLength);
  };

  firstElement = null;

  handleOpenOrClose = isShowing => {
    if (isShowing) {
      this.setState({ isShowing });
      this.delayAnimation(() => {
        this.setState({ isShowingModal: isShowing });
        this.props.setIsShowingModal(true);
      });
    } else {
      this.setState({ isShowingModal: isShowing });
      this.delayAnimation(() => {
        this.setState({ isShowing });
        this.props.setIsShowingModal(false);
      });
    }
  };

  render() {
    const { isShowing, isShowingModal, onKeyUp } = this.state;

    if (!isShowing) {
      return null;
    }

    return (
      <div
        role='dialog'
        className={classnames('modal', { 'modal--showing': isShowingModal })}
        onKeyUp={onKeyUp}
      >
        <div className='modal__content'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

AppModal.propTypes = {
  updateIsShowing: PropTypes.func,
  showModal: PropTypes.bool,
  setIsShowingModal: PropTypes.func,
  onKeyUp: PropTypes.func
};

export default AppModal;
