import Promos from '../api/promos';

export function setPromos(promos) {
  return {
    type: 'SET_PROMOS',
    promos
  };
}

export function setHasFetchedPromos(hasFetchedPromos) {
  return {
    type: 'SET_HAS_FETCHED_PROMOS',
    hasFetchedPromos
  };
}

export function fetchPromos(orderType) {
  const params = {};
  if (orderType !== '') {
    params.orderType = orderType;
  }
  return dispatch => {
    dispatch(setHasFetchedPromos(false));
    return Promos.fetchPromos(params).then(data => {
      dispatch(setPromos(data));
      dispatch(setHasFetchedPromos(true));
    });
  };
}
