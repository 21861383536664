import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getMembershipFromState, getLpFromState } from './utils/state-helpers';

import { setModalContentKey } from '../action-creators/app';

import ModalHeader from './ModalHeader';
import ExchangeRules from './ExchangeRules';

export const ExchangeRulesModal = ({ formatter, fromLp, selectedOffer, fromMembership, setModalContentKey }) => (
  <div>
    <ModalHeader
      header='exchangeConfigure.exchangeRulesModalHeader'
      formatter={formatter}
      setModalContentKey={setModalContentKey}
    />
    <ExchangeRules
      classes='u-padding u-margin-vertical text-base-size-palm'
      fromLp={fromLp}
      formatter={formatter}
      selectedOffer={selectedOffer}
      fromMembershipId={fromMembership.get('id')}
    />
  </div>
);

ExchangeRulesModal.propTypes = {
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  fromLp: ImmutablePropTypes.map,
  selectedOffer: ImmutablePropTypes.map,
  fromMembership: ImmutablePropTypes.map,
  setModalContentKey: PropTypes.func
};

function mapStateToProps(state) {
  return {
    formatter: state.app.get('formatter'),
    fromLp: getLpFromState(state, 'from'),
    selectedOffer: state.exchange.get('selectedOffer'),
    fromMembership: getMembershipFromState(state, 'from')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setModalContentKey
  }, dispatch);
}

export const ExchangeRulesModalContainer = connect(mapStateToProps, mapDispatchToProps)(ExchangeRulesModal);
