import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

export const DynamicHero = ({ image, header, subheading, info, button, translationValues }) => {
  const hasButton = Object.keys(button).length > 0;
  const hasDynamicContent = header || subheading || info || hasButton;

  return (
    <div className='earnAndRedeem__hero__container'>
      <img
        alt='Hero Banner'
        src={image}
        className='earnAndRedeem__hero'
      />
      {hasDynamicContent ?
        <div className='earnAndRedeem__hero__contentContainer'>
          <div className='earnAndRedeem__hero__text'>
            {header ? <h2 className='earnAndRedeem__hero__header'><FormattedHTMLMessage id={header} values={translationValues} /></h2> : null}
            {subheading ? <h4 className='earnAndRedeem__hero__subheader'><FormattedHTMLMessage id={subheading} values={translationValues} /></h4> : null}
            {info ? <p className='earnAndRedeem__hero__info'><FormattedHTMLMessage id={info} values={translationValues} /></p> : null}
          </div>
          {hasButton && (
            <button onClick={button.onClick}>
              <FormattedHTMLMessage id={button.text} />
            </button>
          )}
        </div>
        : null}
    </div>
  );
};

DynamicHero.propTypes = {
  image: PropTypes.string.isRequired,
  header: PropTypes.string,
  subheading: PropTypes.string,
  info: PropTypes.string,
  translationValues: PropTypes.object,
  button: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string
  })
};

DynamicHero.defaultProps = {
  header: '',
  subheading: '',
  info: '',
  translationValues: {},
  button: {}
};
