import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import constants from '../utils/constants';
import { getLocalImage } from './utils/image-helpers';
import { useSelector } from 'react-redux';

export const TextAd = props => {
  const { path } = props;
  const partner = useSelector(state => state.app.getIn(['clientData', 'partnerDirName']));

  const getAppBadges = () => (
    <div className='earnAndRedeem__textAd__downloadAppContainer'>
      {Object.entries(constants.lyftAppBadgeUrls).map(([store, url]) => (
        <a href={url} target='_blank' rel='noopener noreferrer' key={`${store}-link`}>
          <img src={getLocalImage(partner, `${store}-badge.png`)} alt={`${store}-link`} />
        </a>
      ))}
    </div>
  );

  return (
    <div className='earnAndRedeem__textAd'>
      <div className='section'>
        { path === 'start' ? null :
          (
            <p className='earnAndRedeem__textAd__header'>
              <FormattedMessage id={`ad.${path}.promo`} />
            </p>
          )
        }
        <p className='earnAndRedeem__textAd__promo'>
          <FormattedMessage id={`ad.${path}.promo.info`} />
        </p>
        <p className='earnAndRedeem__textAd__description'>
          <FormattedMessage id={`ad.${path}.description`} />
        </p>
        { getAppBadges() }
      </div>
    </div>
  );
};

TextAd.propTypes = {
  path: PropTypes.string.isRequired
};
