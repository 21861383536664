import Redeem from '../api/redeem';
import { replace } from 'react-router-redux';
import { isValidOffer, containsAllOffers } from '../components/utils/offers-helpers';
import constants from '../utils/constants';

const { redemptionSuccess: successUrl, redemptionError: errorUrl } = constants.lyftRedirectUrls;

/*-------------------------------
 *
 * Sync Actions
 *
 -------------------------------*/

export const setRedeemOffers = offers => ({
  type: 'SET_REDEEM_OFFERS',
  offers
});

export const setSuccessfulRedeemTransaction = transaction => ({
  type: 'SET_SUCCESSFUL_REDEEM_TRANSACTION',
  transaction
});

export const setIsFetchingRedeemOffers = status => ({
  type: 'SET_IS_FETCHING_REDEEM_OFFERS',
  status
});

export const setIsRedeeming = status => ({
  type: 'SET_IS_REDEEMING',
  status
});

export const setRedemptionError = error => ({
  type: 'SET_REDEMPTION_ERROR',
  error
});

/*-------------------------------
 *
 * Async Actions
 *
 -------------------------------*/

export const fetchRedeemOffers = (redemptionDenominations, redemptionCountryDetails, fromMembership) => dispatch => {
  dispatch(setIsFetchingRedeemOffers(true));
  const availableOffers = {};
  const currencyOfLastCountry = redemptionCountryDetails[redemptionCountryDetails.length - 1].currency;
  redemptionCountryDetails.map(country => {
    const { categoryPrefix, currency, toLpId } = country;
    let fetchCount = 0;
    let countryOffers = [];
    redemptionDenominations.map(denomination => {
      const category = `${categoryPrefix}${denomination.credit}`;
      const req = {
        fromLpId: fromMembership.lpId,
        fromMembershipId: fromMembership.id,
        toLpId,
        category
      };
      Redeem.fetchRedeemOffers(req)
        .then(res => {
          fetchCount += 1;
          if (isValidOffer(res, category)) {
            countryOffers.push({ ...denomination, ...res.available[0] });
          }
          if (fetchCount === redemptionDenominations.length) {
            countryOffers = countryOffers.sort((a, b) => a.credit > b.credit ? 1 : -1);
            availableOffers[currency] = countryOffers;
          }
          if (containsAllOffers(availableOffers, currencyOfLastCountry, redemptionCountryDetails.length)) {
            dispatch(setRedeemOffers(availableOffers));
            dispatch(setIsFetchingRedeemOffers(false));
          }
        }).catch(err => {
          console.error(err);
          dispatch(replace('/error'));
          dispatch(setIsFetchingRedeemOffers(false));
        });
    });
  });
};

export const redeem = req => dispatch => {
  dispatch(setIsRedeeming(true));
  Redeem.redeem(req)
    .then(res => {
      if (res.status <= 299) {
        dispatch(setSuccessfulRedeemTransaction(req));
        dispatch(replace(successUrl));
        dispatch(setIsRedeeming(false));
      } else {
        dispatch(setRedemptionError(res.body.errors[0].code));
        dispatch(replace(errorUrl));
        dispatch(setIsRedeeming(false));
      }
    }).catch(() => {
      dispatch(replace(errorUrl));
      dispatch(setIsRedeeming(false));
    });
};
