import React from 'react';
import PropTypes from 'prop-types';
import { getTabbableElements } from '../utils/dom';
import constants from '../utils/constants';

class FocusTrap extends React.Component {
  static defaultProps = {
    isActive: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.containerEl) {
      return;
    }
    const componentWillActivate = !this.props.isActive && nextProps.isActive;
    const componentWillDeactivate = this.props.isActive && !nextProps.isActive;

    if (componentWillActivate) {
      this.previousElement = document.activeElement;
      this.previousElement.blur();

      setTimeout(() => {
        this.computeTabbableElements();

        if (this.elements && this.elements.length > 0) {
          this.elements[0].focus();
        }
      }, constants.baseAnimationLength);
    } else if (componentWillDeactivate) {
      setTimeout(() => {
        this.previousElement.focus();
      }, constants.baseAnimationLength);
    }
  }

  onEndFocus = () => {
    if (this.elements && this.elements.length > 0) {
      this.elements[0].focus();
    }
  };

  onStartFocus = () => {
    if (this.elements && this.elements.length > 0) {
      this.elements[this.elements.length - 1].focus();
    }
  };

  computeTabbableElements = () => {
    this.elements = getTabbableElements(this.containerEl).filter(el => !el.dataset.focusTrap);
  };

  containerEl = null;

  elements = null;
  endEl = null;

  startEl = null;

  withFocusOutEventListener = newElement => {
    if (this.containerEl) {
      return this.containerEl;
    }

    // This is a hack for restoring the focus on an element inside the focus trap.
    //
    // A use case for this is when there's a submit button which gets disabled when clicked while
    // an async request is being made. The button element will loose focus when being disabled
    // and by default the top-level html element gets the focus.
    //
    // With this event listener we look for focusable elements while the focus trap is active.
    //
    // Currently the children of focus trap should have at least one focusable element.
    //
    // We can change this so that the modal itself is a tabbable component, but that messes up
    // navigation inside the modal content my opinion. Another alternative would be to always
    // show the close button in every modal and add a feature to disable its click callback
    // instead of hiding it. Another possibility would be to have an explicit focusOnElement
    // action-creator which other components could use to directly assign the value of the active
    // element.
    newElement.addEventListener('focusout', () => {
      this.computeTabbableElements();
      if (this.props.isActive && this.elements && this.elements.length === 0) {
        const refreshInterval = setInterval(() => {
          if (!this.props.isActive) {
            clearInterval(refreshInterval);
          }
          this.computeTabbableElements();
          if (this.elements && this.elements.length > 0) {
            this.elements[0].focus();
            clearInterval(refreshInterval);
          }
        }, constants.baseAnimationLength);
      }
    });

    this.containerEl = newElement;
  };

  render() {
    return (
      <div ref={this.withFocusOutEventListener}>
        <span
          tabIndex='0'
          data-focus-trap
          onFocus={this.onStartFocus}
          ref={c => this.startEl = c}
        />
        {this.props.children}
        <span
          tabIndex='0'
          data-focus-trap
          onFocus={this.onEndFocus}
          ref={c => this.endEl = c}
        />
      </div>
    );
  }
}

FocusTrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isActive: PropTypes.bool
};

export default FocusTrap;
