import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const NoticeBox = ({ intl }) => {
  const headerMessageExists = Boolean(intl.messages['earn.linkAccount.noticeBox.header']);
  const descriptionMessageExists = Boolean(intl.messages['earn.linkAccount.noticeBox.description']);

  if (!headerMessageExists && !descriptionMessageExists) {
    return null;
  }

  return (
    <div className='noticeBox__parentContainer'>
      <div className='noticeBox__subContainer'>
        { headerMessageExists &&
          <div className='noticeBox__header'>
            <FormattedMessage id='earn.linkAccount.noticeBox.header' />
          </div>
        }
        { descriptionMessageExists &&
          <div className='noticeBox__description'>
            <FormattedHTMLMessage id='earn.linkAccount.noticeBox.description' />
          </div>
        }
      </div>
    </div>
  );
};

NoticeBox.propTypes = {
  intl: PropTypes.any.isRequired
};

export default injectIntl(NoticeBox);
