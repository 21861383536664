import React from 'react';
import PropTypes from 'prop-types';
import { BannerAd } from './BannerAd';
import { TextAd } from './TextAd';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

export const AdContainer = ({ bannerSrc }) => {
  const componentName = useSelector(state => state.app.getIn(['clientData', 'adComponent']));
  const pathname = window.location.pathname;
  const path = pathname.replaceAll('/', '.').replaceAll('-', '').slice(1);

  const renderAd = () => {
    switch (componentName) {
      case 'BannerAd':
        return <BannerAd path={path} bannerSrc={bannerSrc} />;
      case 'TextAd':
        return <TextAd path={path} />;
      default:
        return null;
    }
  };

  return (
    <>
      { path === 'start' && !!componentName ?
        (
          <h2 className='earnAndRedeem__textAd__title text-center'>
            <FormattedMessage id={`ad.${path}.promo`} />
          </h2>
        ) : null
      }
      {renderAd()}
    </>
  );
};

AdContainer.propTypes = {
  bannerSrc: PropTypes.string
};

AdContainer.defaultProps = {
  bannerSrc: ''
};
