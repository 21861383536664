import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class ActionLink extends React.Component {
  handleClick = e => {
    const { onClickAction } = this.props;
    e.preventDefault();
    if (!onClickAction) return;
    onClickAction();
  };

  render() {
    const { text, classes, isShowingSlide } = this.props;
    if (!text) return null;

    return (
      <div
        className={`${classes} u-bb flex flex-space-between cursor-pointer u-padding-bottom-small`}
        onClick={this.handleClick}
        aria-hidden={isShowingSlide}
      >
        <a
          href='#'
          className='link-no-deco text-fancy-field-size'
        >
          <FormattedMessage id={text} />
        </a>
        <i
          className='icon-chevron-right u-margin-right-small text-color-muted'
          aria-hidden
        />
      </div>
    );
  }
}

ActionLink.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.string,
  onClickAction: PropTypes.func.isRequired,
  isShowingSlide: PropTypes.bool
};

ActionLink.defaultProps = {
  classes: ''
};

export default ActionLink;
