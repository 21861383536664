import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { push } from 'react-router-redux';

import constants from '../utils/constants';
import { getQueryParamsAsObject, externalRedirect, reloadWithoutMv } from '../utils/url';
import { getLocalImage } from './utils/image-helpers';
import { validateEmail, validateRequiredField, compareValidation } from '../utils/validations';
import { Helmet } from 'react-helmet';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getClientId } from '../utils/application';
import FancyField from 'react-fancy-field';
import FancyButton from 'react-fancy-button';
import Slide from './Slide';
import { setIsShowingSlide } from '../action-creators/app';
import { fetchClientPromos, fetchEarnIntegration } from '../action-creators/earn';
import Spinner from './Spinner';
import Checkbox from './Checkbox';
import BoxGroup from './BoxGroup';
import ActionLink from './ActionLink';
import FullOfferTerms from './FullOfferTerms';
import FAQ from './FAQ';
import EarnTcs from './EarnTcs';
import Interstitial from './Interstitial';
import HeroOverlay from './HeroOverlay';
import { getValidationFromField, isFormValid } from './utils/react-component-helpers';
import { FaQuestionCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import FocusTrap from './FocusTrap';
import EarnPartnerIntegration from './EarnPartnerIntegration';


export class Earn extends React.Component {
  state = {
    accountNumber: '',
    email: '',
    confirmEmail: '',
    selectedMembership: Map(),
    isFastTrack: false,
    earnPromoSlideContent: Map(),
    triggerValidation: 0,
    allowEmailValidation: false,
    toggleSlide: 0,
    windowWidth: window.innerWidth,
    isTCCheckboxChecked: false,
    isFullOfferTermsChecked: false
  };

  confirmEmailFieldEl = null;
  emailFieldEl = null;
  heroBgEl = null;

  componentDidMount() {
    const {
      fetchClientPromos,
      locale,
      partnerLpId,
      clientData,
      location
    } = this.props;
    const hasAnonymousFlow = clientData.get('hasAnonymousFlow') || false;
    if (!hasAnonymousFlow && !location.pathname.includes('full-offer-terms') && !location.pathname.includes('points-account-terms')) externalRedirect('/error'); // Take out when DLW-705 is done
    const promoCopyParams = {
      orderType: constants.product.earn,
      orderSubtype: constants.orderSubType.milesEarnedPerDollarSpent,
      locale
    };
    if (partnerLpId) {
      promoCopyParams.lpId = partnerLpId;
    }
    fetchClientPromos(getClientId(), promoCopyParams);
    this.setAccountMembershipNumber();
    this.prepopulateEmail();

    window.addEventListener('resize', this.setWindowWidth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { memberships, user, isLoggedIn, location, push } = this.props;
    if (!memberships.size && nextProps.memberships.size) {
      this.setAccountMembershipNumber(nextProps);
    }
    if (!user.size && nextProps.user.size) {
      this.prepopulateEmail(nextProps);
    }
    if (!isLoggedIn && nextProps.isLoggedIn) {
      reloadWithoutMv(push, location);
    }
  }

  componentWillUnmount() {
    // When redirecting to Lyft for integrations, this elements are set to null and
    // cause an error
    if (this.emailField && this.emailFieldEl.fancyFieldEl) {
      this.emailFieldEl.fancyFieldEl.removeEventListener('paste', this.handlePaste);
    }
    if (this.confirmEmailFieldEl && this.confirmEmailFieldEl.fancyFieldEl) {
      this.confirmEmailFieldEl.fancyFieldEl.removeEventListener('paste', this.handlePaste);
    }
  }

  getCredentials = membership => membership.getIn(['credentials', 'identifyingFactors.memberId']);

  getFields = () => {
    const validateSameEmail = compareValidation(() => this.state.email, 'validations.invalidConfirmEmail');

    return [{
      name: 'email',
      validators: [validateEmail, validateRequiredField]
    }, {
      name: 'confirmEmail',
      validators: this.isEmailEditable() ? [validateEmail, validateRequiredField, validateSameEmail] : []
    }];
  };

  getHowItWorksContent = () => {
    const { clientData } = this.props;
    const types = clientData.get('landingPageFeatures') || List();
    return types.map(type => {
      const step = {
        icon: `icon-${type}`,
        header: `earn.howItWorks.${type}.header`,
        description: `earn.howItWorks.${type}.description`
      };
      if (type === 'shop') {
        step.onLinkClick = this.showRestrictionsModal;
        step.link = 'earn.howItWorks.shop.link';
      }
      return Map(step);
    });
  };

  setAccountMembershipNumber = (props = this.props) => {
    const { memberships, partnerLpId } = props;
    if (!memberships.size || this.state.selectedMembership.size) return;

    const selectedMembership = memberships.find(membership => membership.get('lpId') === partnerLpId);

    if (selectedMembership && selectedMembership.size) {
      this.setState({
        selectedMembership,
        accountNumber: this.getCredentials(selectedMembership)
      });
    }
  };

  setEarnPromoSlideContent = content => {
    const { setIsShowingSlide } = this.props;
    if (content) {
      this.setState({ toggleSlide: this.state.toggleSlide + 1 });
      // This happens every time we want to show the slide (we forced the slide to be hidden with the shouldHideOnLoad hack, so isShowingSlide is never updated)
      // To fix this, we correct the omited call to setIsShowingSlide
      setIsShowingSlide(true);
    }
    this.setState({ earnPromoSlideContent: Map(content) });
  };

  setWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  emailOnChange = newEmail => {
    this.setState({ email: newEmail });
  };

  handlePaste = e => {
    e.preventDefault();
  };

  isEmailEditable = () => {
    const { email, isFastTrack } = this.state;

    return !isFastTrack || !email.length;
  };

  prepopulateEmail = (props = this.props) => {
    const { user } = props;
    const { isFastTrack } = this.state;

    if (!this.emailFieldEl) return;

    if (!user.size || isFastTrack) {
      this.emailFieldEl.fancyFieldEl.focus();
      return;
    }

    this.setState({
      isFastTrack: true,
      allowEmailValidation: true,
      email: user.get('email')
    });
  };

  showFaqModal = () => {
    const { messages, promoData, clientData } = this.props;
    const showFAQByCategory = clientData.get('showFAQByCategory');
    this.setEarnPromoSlideContent({
      header: 'earn.faqs.header',
      description:
  <FAQ
    faqs={messages.get('earn.faqs.description').toJSON()}
    footnote={promoData ? `*${promoData.get('restrictions')}` : ''}
    showFAQByCategory={showFAQByCategory}
  />,
      disableHtmlFormatting: true
    });
  };

  showFullOfferTerms = () => {
    const { messages } = this.props;
    this.setEarnPromoSlideContent({
      header: 'earn.fullOfferTerms.slide.header',
      description: <FullOfferTerms terms={messages.get('earn.fullOfferTerms.description').toJSON()} />,
      disableHtmlFormatting: true
    });
  };

  showPointsTCsModal = () => {
    const { messages } = this.props;
    this.setEarnPromoSlideContent({
      header: 'earn.pointsTCs',
      description: <EarnTcs tcs={messages.get('earn.pointsTCsTerms')} />,
      disableHtmlFormatting: true
    });
  };

  showRestrictionsModal = () => {
    const { promoData } = this.props;
    if (!promoData || !promoData.size) return;

    this.setEarnPromoSlideContent({
      header: 'earn.howItWorks.shop.modal.header',
      description: promoData.get('restrictions'),
      disableHtmlFormatting: true
    });
  };

  submitEarn = e => {
    const {
      partnerLpId,
      fetchEarnIntegration,
      locale,
      promoData,
      googleTagManager
    } = this.props;
    const { email, isFastTrack } = this.state;

    e.preventDefault();

    fetchEarnIntegration({
      email,
      clientId: getClientId(),
      lpId: partnerLpId,
      orderType: constants.product.earn,
      orderSubtype: constants.orderSubType.milesEarnedPerDollarSpent,
      locale
    });
    googleTagManager.pushGtmData({
      winningOfferId: promoData.get('offerId'),
      isFastTrack,
      action: constants.ANALYTICS_COMPLETE_EARN_LANDING
    });
  };

  withPasteEventListeners = (fieldName, newElement) => {
    const currentElement = this[fieldName];
    if (currentElement) {
      return currentElement;
    }

    if (!newElement.fancyFieldEl) {
      return null;
    }

    newElement.fancyFieldEl.addEventListener('paste', this.handlePaste);
    return newElement;
  };

  renderAccountNumber = () => {
    const { allLps, partnerLpId, isShowingSlide } = this.props;
    const { accountNumber, selectedMembership } = this.state;
    const hasCredentials = !!this.getCredentials(selectedMembership);
    const partnerLp = allLps.find(lp => lp.get('id') === partnerLpId);

    if (!selectedMembership.size || !hasCredentials || !partnerLp) return;

    const lpSchema = partnerLp.get('schema');
    const memberIdField = lpSchema && lpSchema.find(field => field.get('name') === 'identifyingFactors.memberId');
    const label = memberIdField && memberIdField.get('label');

    return (
      <FancyField
        name='accountNumber'
        type='input'
        classes='fs-exclude u-margin-top-large'
        disabled
        label={label}
        placeholder={label}
        value={accountNumber}
        aria-hidden={isShowingSlide}
        onChange={accountNumber => this.setState({ accountNumber })}
      />
    );
  };

  renderFullTandC = isShowingSlide => (
    <div className='section section--compact text-left u-padding-top-small'>
      <ActionLink
        classes='earn__fullOfferTerms'
        onClickAction={this.showFullOfferTerms}
        text='earn.fullOfferTerms.header'
        isShowingSlide={isShowingSlide}
      />
    </div>
  );

  renderEarnPromoDetails = () => {
    const { isShowingSlide, clientData } = this.props;
    const showFullTandC = clientData.get('showFullTandC');
    return (
      <div className='earn__promo-form__subsection u-margin-top u-padding u-margin-top-large@md u-padding-large@md' aria-hidden={isShowingSlide}>
        <div className='section section--compact text-left u-bt u-padding-top-small'>
          <ActionLink
            classes='earn__faqs'
            onClickAction={this.showFaqModal}
            text='earn.faqs.header'
            isShowingSlide={isShowingSlide}
          />
        </div>
        { showFullTandC ? this.renderFullTandC(isShowingSlide) : null }
        { this.renderTCs() }
      </div>
    );
  };

  renderEarnPromoModal = () => {
    const { formatter, setIsShowingSlide, isShowingSlide } = this.props;
    const { earnPromoSlideContent, toggleSlide } = this.state;
    const disableHtmlFormatting = earnPromoSlideContent.get('disableHtmlFormatting');
    const description = earnPromoSlideContent.get('description');
    const header = earnPromoSlideContent.get('header');
    const queryParams = getQueryParamsAsObject();

    let content = description;
    if (!disableHtmlFormatting && description) {
      content = <FormattedHTMLMessage id={description} />;
    }

    return (
      <FocusTrap isActive={isShowingSlide}>
        <Slide
          handleClose={this.setEarnPromoSlideContent}
          formatter={formatter}
          header={header ? formatter.formatMessage({ id: header }) : null}
          setIsShowingSlide={setIsShowingSlide}
          toggleSlide={toggleSlide}
          shouldHideOnLoad
          disableClose={queryParams.close === 'disabled'}
        >
          <div className='section section--compact u-padding-small text-left'>
            { earnPromoSlideContent.size ? content : null }
          </div>
        </Slide>
      </FocusTrap>
    );
  };

  onBlurEmail = () => {
    this.setState({ allowEmailValidation: true });
  };

  renderEmail = () => {
    const { formatter, isShowingSlide } = this.props;
    const { email, triggerValidation, allowEmailValidation } = this.state;
    const { confirmEmail } = this.state;

    return (
      <div aria-hidden={isShowingSlide}>
        <FancyField
          name='email'
          type='input'
          classes='fs-exclude u-margin-top-large'
          ref={el => this.emailFieldEl = this.withPasteEventListeners('emailFieldEl', el)}
          label={formatter.formatMessage({ id: 'earn.emailLabel' })}
          placeholder={formatter.formatMessage({ id: 'earn.emailLabel' })}
          value={email}
          disabled={!this.isEmailEditable()}
          suppressError={allowEmailValidation}
          validator={allowEmailValidation ? getValidationFromField('email', this.getFields()) : null}
          triggerValidation={triggerValidation}
          onChange={this.emailOnChange}
          onBlur={this.onBlurEmail}
        />
        {!this.isEmailEditable() ? null : (
          <FancyField
            name='emailConfirmation'
            type='input'
            classes='fs-exclude u-margin-top-large'
            ref={el => this.confirmEmailFieldEl = this.withPasteEventListeners('confirmEmailFieldEl', el)}
            label={formatter.formatMessage({ id: 'earn.confirmEmailLabel' })}
            placeholder={formatter.formatMessage({ id: 'earn.confirmEmailLabel' })}
            value={confirmEmail}
            disabled={false}
            validator={getValidationFromField('confirmEmail', this.getFields())}
            triggerValidation={triggerValidation}
            onChange={confirmEmail => this.setState({ confirmEmail })}
          />
        )}
      </div>
    );
  };

  renderForm = () => {
    const { isSingleSigningOn, isShowingSlide } = this.props;
    if (isSingleSigningOn) {
      return (
        <div className='u-margin-top-huge'>
          <Spinner />
        </div>
      );
    }

    return (
      <div className='earn__promo-form text-center u-padding-small'>
        <div className='section section--compact'>
          <h2 className='earn__promo__form__header' aria-hidden={isShowingSlide}>
            <FormattedHTMLMessage id='earn.formHeader' />
          </h2>

          {this.renderAccountNumber()}

          {this.renderEmail()}

          {this.renderPointsTCs()}

          {this.renderSubmitButton()}
        </div>
      </div>
    );
  };

  renderHero = () => {
    const { clientData, promoData } = this.props;
    const partnerDirName = clientData.get('partnerDirName');
    const heroDesktopImage = clientData.get('heroDesktopImage');
    const heroMobileImage = clientData.get('heroMobileImage');

    if (promoData.isEmpty()) {
      return null;
    }

    const shouldDisplayMobileImage = this.state.windowWidth <= 750;
    const desktopHeroImage = promoData.get('heroImageDesktop') || getLocalImage(partnerDirName, heroDesktopImage);
    const mobileHeroImage = promoData.get('heroImageMobile') || getLocalImage(partnerDirName, heroMobileImage);
    const heroToRender = (shouldDisplayMobileImage && mobileHeroImage) ? mobileHeroImage : desktopHeroImage;
    const overlayContent = promoData.get('overlayContent');

    return (
      <div className='earn__hero-container'>
        <img
          alt='Earn banner'
          src={heroToRender}
          className='earn__hero'
          ref={el => this.heroBgEl = el}
        />
        {overlayContent ?
          <HeroOverlay
            overlayContent={overlayContent}
          />
          : null}
      </div>
    );
  };

  renderHowItWorks = () => {
    const { isShowingSlide } = this.props;
    return (
      <div className='earn__how-it-works section text-center' aria-hidden={isShowingSlide}>
        <h2 className='earn__promo__header'>
          <FormattedMessage id='earn.howItWorksHeader' />
        </h2>
        <BoxGroup content={this.getHowItWorksContent()} classes='earn__box-group u-margin-large' />
      </div>
    );
  };

  handleCheckboxChange = (name, value) => {
    this.setState({ [name]: !value });
  }

  renderLinkAccountsCheckbox = () => (
    <div className='earn__agreeToTandC flex flex-space-between'>
      <div className='checkbox__container flex flex-left text-left'>
        <Checkbox
          name='isTCCheckboxChecked'
          checked={this.state.isTCCheckboxChecked}
          onChange={this.handleCheckboxChange}

        />
        {this.renderAgreementStatement()}
      </div>
    </div>
  )

  renderFullOfferTermsCheckbox = () => (
    <div className='earn__agreeToTandC flex flex-space-between'>
      <div className='checkbox__container flex flex-left text-left'>
        <Checkbox
          name='isFullOfferTermsChecked'
          checked={this.state.isFullOfferTermsChecked}
          onChange={this.handleCheckboxChange}
        />
        {this.renderFullOfferTermsAgreementStatement()}
      </div>
    </div>
  )

  renderTooltipText = () => {
    const { messages } = this.props;
    const tooltipText = messages.get('earn.toolTip');
    return (
      <p className='earn__tooltipText'>
        <span dangerouslySetInnerHTML={{ __html: tooltipText }} />
      </p>
    );
  }

  renderAgreementStatement = () => {
    const { messages, clientData } = this.props;
    const firstStatement = messages.get('earn.agreementStatement.first');
    const secondStatement = messages.get('earn.agreementStatement.second');
    const showPrivacyPolicy = clientData.get('showPrivacyPolicy');

    return (
      <div>
        <span>
          <span  dangerouslySetInnerHTML={{ __html: firstStatement }} />
          {showPrivacyPolicy && <span dangerouslySetInnerHTML={{ __html: secondStatement }} />}
        </span>
        {showPrivacyPolicy && (
          <span>
            <FaQuestionCircle data-tip data-for='earn__pointsAgreementHelper' className='earn__helpIcon' />
            <ReactTooltip id='earn__pointsAgreementHelper' place='top' type='dark' effect='float'>
              {this.renderTooltipText()}
            </ReactTooltip>
          </span>
        )}
        {this.renderEarnPromoModal()}
      </div>
    );
  }


  renderFullOfferTermsAgreementStatement = () => {
    const { messages } = this.props;
    const statement = messages.get('earn.fullOfferTerms.agreementStatement');
    return (
      <div>
        <div id='earn__agreementStatementText' dangerouslySetInnerHTML={{ __html: statement }} />
      </div>
    );
  }

  renderPointsTCs = () => {
    const { clientData } = this.props;
    const showLinkAccCheckbox = clientData.get('showLinkAccCheckbox');
    const showFullTandC = clientData.get('showFullTandC');
    return (
      <div className='u-margin-top-large section'>
        {showLinkAccCheckbox ? this.renderLinkAccountsCheckbox() :  this.renderPointsTCsAsLink() }
        {showFullTandC ? this.renderFullOfferTermsCheckbox() :  null }
      </div>
    );
  }

  renderPointsTCsAsLink = () => {
    const { isShowingSlide } = this.props;
    return (
      <div className='u-margin-top-large'>
        <ActionLink
          classes='earn__points-tcs'
          onClickAction={this.showPointsTCsModal}
          text='earn.pointsTCs'
          isShowingSlide={isShowingSlide}
        />
        {this.renderEarnPromoModal()}
      </div>
    );
  };

  isFormValid = () => {
    const { clientData } = this.props;
    const showLinkAccCheckbox = clientData.get('showLinkAccCheckbox');
    const formVadlidity = showLinkAccCheckbox ? this.validateFormWithCheckbox() : this.validateFormWithoutCheckbox();
    return formVadlidity;
  }

  validateFormWithoutCheckbox = () => !isFormValid(this.getFields(), this.state)

  validateFormWithCheckbox = () => {
    const { clientData } = this.props;
    const showFullTandC = clientData.get('showFullTandC');
    const textValidation = !isFormValid(this.getFields(), this.state);
    const formValidation = showFullTandC ?
      !(this.state.isTCCheckboxChecked && this.state.isFullOfferTermsChecked && !textValidation) :
      !(this.state.isTCCheckboxChecked && !textValidation);
    return formValidation;
  }

  renderSubmitButton = () => {
    const { formatter, isTriggeringPartnerIntegration, isShowingSlide } = this.props;
    const { triggerValidation } = this.state;
    return (
      <FancyButton
        role='button'
        classes='earn__submit__button btn btn-primary u-1/1 u-margin-top-large'
        onClick={this.submitEarn}
        label={formatter.formatMessage({ id: 'earn.submitButton' })}
        disabled={this.isFormValid()}
        trigger={isTriggeringPartnerIntegration}
        autoFocus
        aria-hidden={isShowingSlide}
        onDisabledClick={() => this.setState({ triggerValidation: triggerValidation + 1 })}
      />
    );
  };

  renderTCs = () => {
    const { promoData } = this.props;
    // should avoid using dangerouslySetInnerHTML - but we can trust this source since its from the API
    return (
      <div className='section section--compact text-left u-bt u-padding-top-small'>
        <p
          className='u-margin-top text-size-x-small'
          dangerouslySetInnerHTML={{ __html: promoData.get('termsAndConditions') }}
        />
      </div>
    );
  };

  renderTitle = () => {
    const { promoData, isShowingSlide } = this.props;
    return (
      <div className='earn__heading section section--large u-padding-none@md u-margin-vertical-large text-center' aria-hidden={isShowingSlide}>
        <h1 className='earn__header'>
          {promoData.get('landingHeader')}
        </h1>
        <p className='earn__subheader'>
          {promoData.get('landingSubHeader')}
        </p>
        <p className='earn__special-info'>
          {promoData.get('landingBody')}
        </p>
      </div>
    );
  };

  render() {
    const { formatter, shouldShowEarnInterstitial, allLps, partnerLpId, children } = this.props;
    const partnerLp = allLps.find(lp => lp.get('id') === partnerLpId);

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        showPointsTCsModal: this.showPointsTCsModal,
        showFullOfferTerms: this.showFullOfferTerms
      }));

    return (
      <div className='earn'>
        <Helmet>
          <title>{formatter.formatMessage({ id: 'pageTitle.earn' })}</title>
          <meta
            name='description'
            content={formatter.formatMessage({ id: 'pageDescription.earn' })}
          />
          <meta
            name='keywords'
            content={formatter.formatMessage({ id: 'pageKeywords.earn' })}
          />
        </Helmet>
        {this.renderTitle()}
        {this.renderHero()}
        {this.renderHowItWorks()}
        {this.renderForm()}
        {this.renderEarnPromoDetails()}
        <EarnPartnerIntegration
          type={this.props.integration}
          isShowing={this.props.shouldExecutePartnerIntegration}
        />
        <Interstitial
          loadingMessage='earn.redirect'
          partnerLp={partnerLp}
          formatter={formatter}
          isShowing={shouldShowEarnInterstitial}
        />
        {childrenWithProps}
      </div>
    );
  }
}

Earn.propTypes = {
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  googleTagManager: PropTypes.object,
  locale: PropTypes.string,
  messages: ImmutablePropTypes.map,
  partnerLpId: PropTypes.string,
  isShowingSlide: PropTypes.bool,
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  promoData: ImmutablePropTypes.map,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  isTriggeringPartnerIntegration: PropTypes.bool,
  user: ImmutablePropTypes.map,
  isSingleSigningOn: PropTypes.bool,
  shouldShowEarnInterstitial: PropTypes.bool,
  fetchClientPromos: PropTypes.func,
  setIsShowingSlide: PropTypes.func,
  fetchEarnIntegration: PropTypes.func,
  integration: PropTypes.string,
  shouldExecutePartnerIntegration: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  push: PropTypes.func
};

function mapStateToProps(state) {
  return {
    clientData: state.app.get('clientData'),
    formatter: state.app.get('formatter'),
    googleTagManager: state.app.get('googleTagManager'),
    locale: state.app.get('locale'),
    messages: state.app.get('messages'),
    partnerLpId: state.app.get('partnerLpId'),
    isShowingSlide: state.app.get('isShowingSlide'),
    allLps: state.lps.get('allLps'),
    promoData: state.earn.get('promoData'),
    memberships: state.memberships.get('data'),
    isTriggeringPartnerIntegration: state.earn.get('isTriggeringPartnerIntegration'),
    user: state.user.get('data'),
    isSingleSigningOn: state.auth.get('isSingleSigningOn'),
    shouldShowEarnInterstitial: state.earn.get('shouldShowEarnInterstitial'),
    integration: state.earn.get('integration'),
    shouldExecutePartnerIntegration: state.earn.get('shouldExecutePartnerIntegration'),
    isLoggedIn: state.auth.get('isLoggedIn')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchClientPromos,
    setIsShowingSlide,
    fetchEarnIntegration,
    push
  }, dispatch);
}

export const EarnContainer = connect(mapStateToProps, mapDispatchToProps)(Earn);
