import { fromJS } from 'immutable';

const initialState = fromJS({
  error: '',
  isLoadingData: false,
  order: {},
  lp: {},
  membership: {},
  potentialMemberships: [],
  shouldSelectMembership: false,
  slideStatus: 0,
  isAttemptingRegistration: false,
  triggeredEarnMembershipSelection: false,
  triggeredPendingMembershipInstructions: false,
  triggeredMembershipRegistrationForm: false,
  triggeredMembershipRegistrationAttempt: false,
  triggeredMembershipRegistrationComplete: false
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_FIX_EARN_ORDER_ERROR':
      return state.set('error', action.error);
    case 'SET_FIX_EARN_ORDER_IS_LOADING_DATA':
      return state.set('isLoadingData', action.isLoadingData);
    case 'SET_FIX_EARN_ORDER_ORDER':
      return state.set('order', fromJS(action.order));
    case 'SET_FIX_EARN_ORDER_LP':
      return state.set('lp', fromJS(action.lp));
    case 'SET_FIX_EARN_ORDER_MEMBERSHIP':
      return state.set('membership', fromJS(action.membership));
    case 'SET_FIX_EARN_ORDER_POTENTIAL_MEMBERSHIPS':
      return state.set('potentialMemberships', fromJS(action.potentialMemberships));
    case 'SET_FIX_EARN_ORDER_SHOULD_SELECT_MEMBERSHIP':
      return state.set('shouldSelectMembership', action.shouldSelectMembership);
    case 'SET_FIX_EARN_ORDER_SLIDE_STATUS':
      return state.set('slideStatus', action.slideStatus);
    case 'SET_FIX_EARN_ORDER_IS_ATTEMPTING_REGISTRATION':
      return state.set('isAttemptingRegistration', action.isAttemptingRegistration);
    case 'SET_FIX_EARN_ORDER_TRIGGERED_EARN_MEMBERSHIP_SELECTION':
      return state.set('triggeredEarnMembershipSelection', action.triggeredEarnMembershipSelection);
    case 'SET_FIX_EARN_ORDER_TRIGGERED_PENDING_MEMBERSHIP_INSTRUCTIONS':
      return state.set('triggeredPendingMembershipInstructions', action.triggeredPendingMembershipInstructions);
    case 'SET_FIX_EARN_ORDER_TRIGGERED_MEMBERSHIP_REGISTRATION_FORM':
      return state.set('triggeredMembershipRegistrationForm', action.triggeredMembershipRegistrationForm);
    case 'SET_FIX_EARN_ORDER_TRIGGERED_MEMBERSHIP_REGISTRATION_ATTEMPT':
      return state.set('triggeredMembershipRegistrationAttempt', action.triggeredMembershipRegistrationAttempt);
    case 'SET_FIX_EARN_ORDER_TRIGGERED_MEMBERSHIP_REGISTRATION_COMPLETE':
      return state.set('triggeredMembershipRegistrationComplete', action.triggeredMembershipRegistrationComplete);
    default:
      return state;
  }
}
