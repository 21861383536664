import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Sidebar = ({ onClose, isOpen, children }) => {
  const close = onClose || function noop() {};
  return (
    <div className={classnames('sidebar', { sidebar__open: isOpen })}>
      <i className='sidebar__close icon-close' onClick={close} />
      <div className='sidebar__body'>
        {children}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default Sidebar;
