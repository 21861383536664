/**
@class User API
*/

import { addAuthTokenToHeaders } from './utils/httpUtils';

/**
`GET /v1/user` get all user's memberships
@method fetchUser
*/
export function fetchUser() {
  const options = addAuthTokenToHeaders();
  return fetch('/v1/user', options)
    .then(res => res.json())
    .then(payload => payload);
}

export default { fetchUser };
