import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import classnames from 'classnames';
import { isImmutable } from './utils/react-component-helpers';

class EarnTcs extends React.Component {
  static defaultProps = {
    tcs: List()
  };

  renderContent = content =>
    content.map((paragraph, key) => {
      if (typeof paragraph === 'string') {
        return (
          <div
            key={key}
            className='u-margin-bottom'
          >
            <div dangerouslySetInnerHTML={{ __html: paragraph }} />
          </div>
        );
      }
      if (isImmutable(paragraph) && paragraph.size) {
        return this.renderList(paragraph, key);
      }
    });

  renderList = (list, key, isNestedList) => (
    <ol key={key} type={isNestedList ? 'a' : '1'}>
      { list.map(this.renderListItem) }
    </ol>
  );

  renderNonNestedListItem = listItem => <div dangerouslySetInnerHTML={{ __html: listItem }} />;

  renderListItem = (listItem, key) => {
    const isNestedList = isImmutable(listItem) && listItem.size;
    const uniqKey = `${!isNestedList ? listItem.replace(/\W+/g, '') : ''}-${key}`;
    return (
      <li
        key={uniqKey}
        className={classnames('u-margin-bottom', { 'list--bare': isNestedList })}
      >
        { isNestedList ? this.renderList(listItem, uniqKey, true) : this.renderNonNestedListItem(listItem) }
      </li>
    );
  };

  renderSingleTc = (tc, key) => {
    const header = tc.get('header');
    const content = tc.get('content');
    return (
      <div key={key}>
        <h1>
          { header }
        </h1>
        <div>
          { this.renderContent(content) }
        </div>
      </div>
    );
  };

  render() {
    const { tcs } = this.props;
    return (
      <div className='earn__terms-and-conditions'>
        { tcs.map(this.renderSingleTc) }
      </div>
    );
  }
}

EarnTcs.propTypes = {
  tcs: ImmutablePropTypes.listOf(ImmutablePropTypes.map)
};

export default EarnTcs;
