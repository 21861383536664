import classnames from 'classnames';
import { fromJS, List } from 'immutable';
import { sha256 } from 'js-sha256';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedHTMLMessage, FormattedMessage, FormattedNumber, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { EndToEndTestWidget } from '../components/End2EndTestWidget';
import { fetchLocale } from '../action-creators/app';
import {
  fetchMembership,
  login,
  registerMembership,
  setWidgetParameters,
  showRegistrationErrors,
  submitRegistrationForm
} from '../action-creators/register-membership';
import constants from '../utils/constants';
import urlUtils from '../utils/url';
import { IntlContainer } from './Intl';
import Spinner from './Spinner';
import SSOWidget from './SSOWidget';
import { getLogoPath } from './utils/image-helpers';
import { shouldHideMemberData } from './utils/wps-helpers';

const widgetComponentsByLp =  {
  '1543': SSOWidget,
  '5506': SSOWidget, // Acts like Amex (1543), but used by our test wallets
  '0000': EndToEndTestWidget
};
export class WidgetCredentialCatcher extends Component {
  state = {
    WidgetComponent: null,
    attemptedRegistration: false,
    pageUrl: null,
    widgetErrors: List()
  };

  componentDidMount() {
    const {
      clientData,
      fetchLocale,
      setWidgetParameters,
      lpId,
      partnerMv,
      membershipId,
      params
    } = this.props;
    const locale = clientData.get('locale');
    fetchLocale(locale);

    if (!lpId || !partnerMv || !membershipId) {
      let membershipId;
      let partnerMv;
      let pageUrl;
      let urlParams;

      if (!params.id) {
        // in the case of amex, this block gets executed in a failure response
        const persistedParams = this.getCredentialCatcherParamsFromLocalStorage();
        membershipId = persistedParams.membershipId;
        partnerMv = persistedParams.partnerMv;
        const initialPageUrl = persistedParams.pageUrl;
        urlParams = `?${urlUtils.getQueryParams(initialPageUrl)}`;
        pageUrl = urlUtils.getLocationHref();
      } else {
        // this block gets executed when user hits:
        // https://homechef-staging.loyaltywallet.io/register-widget-membership/<MV>?lpId=1543
        // ( this includes amex success response )
        pageUrl = urlUtils.getLocationHref();
        membershipId = params.id;
        partnerMv = params.partnerMv ? window.atob(params.partnerMv) : '';
        urlParams = `?${urlUtils.getQueryParams(pageUrl)}`;
        if (partnerMv) {
          this.setCredentialCatcherParamsInLocalStorage(
            membershipId,
            partnerMv,
            pageUrl
          );
        }
      }

      this.setPageUrlUrlInState(pageUrl);
      const queryParams = urlUtils.getQueryParamsAsObject(urlParams);
      const lpIdParam = clientData.getIn(['credentialCatchers', 'params', 'lpId']);
      setWidgetParameters(membershipId, partnerMv, queryParams[lpIdParam]);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      lpId,
      partnerMv,
      membershipId,
      login,
      isLoggedIn,
      push,
      fetchMembership,
      isSingleSigningOn,
      errors,
      isAttemptingRegistration
    } = this.props;
    const lpIdSaved = !prevProps.lpId && lpId;
    const mvSaved = !prevProps.partnerMv && partnerMv;
    const membershipIdSaved = !prevProps.membershipId && membershipId;

    if (lpIdSaved && mvSaved && membershipIdSaved) {
      if (!isLoggedIn && !isSingleSigningOn && errors.isEmpty()) {
        login(partnerMv);
        // The pathname has to be hardcoded, can't rely on reloadPage fn
        // the location prop's pathname includes the memberId and mv in the URL
        // so it would not strip them from the URL as desired
        push('/register-widget-membership');
      }
    }

    if (!prevProps.isLoggedIn && isLoggedIn) {
      // partnerMV has just logged in, and condition ensures membership is fetched
      // only once
      fetchMembership(membershipId);
      const widgetComponent = this.getWidgetComponent(lpId);
      this.setState({
        WidgetComponent: widgetComponent
      });
    }


    if (prevProps.isAttemptingRegistration && !isAttemptingRegistration) {
      this.setState({
        attemptedRegistration: true
      });
    }
  }

  setPageUrlUrlInState(pageUrl) {
    this.setState({ pageUrl });
  }

  setCredentialCatcherParamsInLocalStorage = (membershipId, partnerMv, pageUrl) => {
    const params = { membershipId, partnerMv, pageUrl };
    window.localStorage.setItem(constants.memberAuthenticatorKey, JSON.stringify(params));
  };

  clearCredentialCatcherParamsInLocalStorage = () => {
    window.localStorage.removeItem(constants.memberAuthenticatorKey);
  };

  getCredentialCatcherParamsFromLocalStorage = () => {
    const params = window.localStorage.getItem(constants.memberAuthenticatorKey) || '{}';
    const result = JSON.parse(params);
    return result;
  };

  getWidgetComponent = lpId => widgetComponentsByLp[lpId];

  reloadPage = () => {
    const { location, push } = this.props;
    const current = location.pathname;
    push(current);
  };

  submitMembershipRegistration = walletId => {
    const {
      membership,
      registerMembership,
      submitRegistrationForm,
      showRegistrationErrors
    } = this.props;
    const hashedWalletId = sha256(walletId);
    const credentials = fromJS({
      'identifyingFactors.memberId': hashedWalletId,
      'authenticatingFactors.token': walletId
    });

    if (!membership.isEmpty()) {
      submitRegistrationForm();
      if (this.validateCredentials(credentials)) {
        const membershipWithCredentials = Object.assign({}, membership.toJSON(), { credentials });
        registerMembership(membershipWithCredentials);
      } else {
        showRegistrationErrors();
      }
    }
  };

  validateCredentials = credentials => {
    const { lp } = this.props;
    const lpSchema = lp.get('schema');

    const validatedFields = lpSchema.map(field => {
      const name = field.get('name');
      const err = this.validateField(credentials.get(name), name);
      // To make debugging easier when credentials validation against lp schema go wrong
      if (err === 'string') console.error(err);
      return typeof err !== 'string';
    });
    return !validatedFields.contains(false);
  };

  validateField = (value = '', name = '') => {
    const { lp } = this.props;
    const lpSchema = lp.get('schema');
    const field = lpSchema.find(field => field.get('name') === name);
    const required = field.get('required');
    const maxLength = field.get('maxLength');
    const minLength = field.get('minLength');

    if (required && !value.length) {
      return `The field ${name} is missing from the credentials`;
    }

    const shouldValidate = required || value.length;
    if (!shouldValidate) {
      return null;
    }
    const minLengthRequired = minLength && value.length < minLength;
    if (minLengthRequired) {
      return `Minimum size is ${minLength} for field ${name} in credentials`;
    }

    const maxLengthRequired = maxLength && value.length > maxLength;
    if (maxLengthRequired) {
      return `Maximum size is ${maxLength} for field ${name} in credentials`;
    }
    return true;
  };

  getWidgetPropsByLp = () => {
    const { lpId, clientData } = this.props;
    let props = {};

    switch (lpId) {
      case constants.lpsWithSSOWidget.amex:
      case constants.lpsWithSSOWidget.testChurros:
        props = {
          submitMembershipRegistration: this.submitMembershipRegistration,
          clientData,
          reloadPage: this.reloadPage,
          pageUrl: this.state.pageUrl,
          clearLocalStorage: this.clearCredentialCatcherParamsInLocalStorage,
          handleErrors: this.handleWidgetErrors
        };
        break;
      default:
        break;
    }

    return props;
  };

  handleWidgetErrors = errors => {
    const e = fromJS(errors);
    this.setState({ widgetErrors: e });
  };

  renderErrors = () => {
    const { errors } = this.props;
    const { widgetErrors } = this.state;
    return (
      <div className='register_membership__wrapper'>
        <h1 className='register_membership__heading'>
          <FormattedHTMLMessage id='credentialCatcher.error' />
        </h1>
        {
          errors.map((errId, i) => (
            <p key={`register_membership_error_${i}`}><FormattedHTMLMessage id={errId} /></p>
          ))
        }
        {
          widgetErrors.map(err => (
            <p
              key={err.get('code')}
              className='widget-error'
            >{err.get('code')} - {err.get('message')}
            </p>))
        }
      </div>
    );
  };

  renderLpLogo = () => {
    const { lp } = this.props;

    if (lp.isEmpty()) {
      return null;
    }

    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpCode = lp.getIn(['content', 'code']);

    return (
      <div className='register_membership__program_logo'>
        <img
          alt={`${lpCompanyName} logo`}
          src={getLogoPath(lpCode)}
        />
      </div>
    );
  };

  renderMembershipErrors = () => {
    const { membership, formatter } = this.props;
    const { widgetErrors } = this.state;

    const isPolling = membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    const membershipErrors = membership.get('memberDetailsErrors') || List();
    if (isPolling) {
      return null;
    }

    if (membershipErrors.isEmpty() && widgetErrors.isEmpty()) {
      return (
        <div>
          <FormattedHTMLMessage id='credentialCatcher.error' />
        </div>
      );
    }

    return (
      <div className='register_membership__message_error'>
        {
          membershipErrors.map(error => {
            const defaultMessage = formatter.formatMessage({ id: 'membershipRegistration.defaultError' });
            return (
              <div
                key={error}
                className='u-margin-vertical-none'
                aria-live='assertive'
                role='alert'
                aria-label={formatter.formatMessage({ id: `membershipRegistration.${error}`, defaultMessage })}
              >
                <FormattedMessage id={`membershipRegistration.${error}`} defaultMessage={defaultMessage} />
                <br />
                <FormattedMessage id='credentialCatcher.error.addon' />
              </div>
            );
          })
        }
      </div>
    );
  };

  getAccountIdLabel = membership => {
    const accountId = membership.getIn(['credentials', 'identifyingFactors.memberId']) || '';
    return accountId ? `#${accountId}` : '';
  };

  renderDummyCard = () => {
    const { membership, lp, clientData } = this.props;

    if (lp.isEmpty() || membership.isEmpty()) {
      return null;
    }

    const memberDetailsStatus = membership.get('memberDetailsStatus');
    const accountIdLabel = this.getAccountIdLabel(membership);
    const logoName = `${lp.get('name')} logo`;
    const lpCode = lp.getIn(['content', 'code']);
    const updatedAtLabel = moment(membership.get('updatedAt')).format('dddd, MMMM Do YYYY');
    const updatedPrefix = <FormattedMessage id='credentialCatcher.dummyCard.updated' />;
    const shouldHideBalance = shouldHideMemberData(clientData, lp.get('id'));

    const unregisteredCircle = function unregisteredMark(className) {
      const selectToRegisterAriaLabel = (<FormattedMessage
        id='membershipcard.registerAriaLabel'
        lpName={membership.get('lpName')}
      />);
      return (
        <div
          aria-label={selectToRegisterAriaLabel}
          role='button'
          className={classnames('circle', className)}
        >
          <div className='circle__content circle__content--smaller-font'>
            <FormattedMessage id='membershipcard.circleMessage' />
          </div>
        </div>
      );
    };

    const circleWithError = function errorMark(className) {
      const selectAriaLabel = (<FormattedMessage
        id='membershipcard.selectAriaLabel'
        lpName={membership.get('lpName')}
      />);
      return (
        <div
          aria-label={selectAriaLabel}
          role='button'
          className={classnames('circle', className)}
        >
          <div className='circle__content circle__content--smaller-font'>
            <FormattedMessage id='membershipcard.circleMessageError' />
          </div>
        </div>
      );
    };

    const circleWithMembershipBalance = function balanceMark(className) {
      const balance = membership.getIn(['memberDetails', 'balance']);
      const ariaLabel = (<FormattedMessage
        id='membershipcard.registeredProgram'
        balance={balance}
        lpName={membership.get('lpName')}
      />);
      const hiddenBalanceLabel = <FormattedMessage id='membershipcard.hiddenBalanceLabel' />;
      const balanceLabel = <FormattedNumber value={balance} />;
      const registeredProgramLabel = shouldHideBalance ? hiddenBalanceLabel : balanceLabel;

      return (
        <div
          aria-label={ariaLabel}
          role='button'
          className={classnames('circle circle--with-sub-content', className)}
        >
          <div className={classnames('circle__content', { 'text-size-big': shouldHideBalance })}>
            {registeredProgramLabel}
            {
              !shouldHideBalance && (
                <div className='circle__sub-content'>
                  {lp.getIn(['content', 'currencyNameShort'])}
                </div>
              )
            }
          </div>
        </div>
      );
    };

    return (
      <div className='register_membership__card u-padding'>
        <div className='membership-card u-margin-bottom membership-card--no-action'>
          <div className='membership-card-desk'>
            <div className='u-padding-small u-padding-horizontal'>
              <img
                className='membership-card__img u-1/1'
                alt={logoName}
                src={getLogoPath(lpCode)}
              />
            </div>
            <div className='u-margin-bottom u-margin-bottom'>
              {
                (() => {
                  switch (memberDetailsStatus) {
                    case constants.MEMBER_DETAILS_STATUS_UNREGISTERED:
                      return unregisteredCircle('circle--action');
                    case constants.MEMBER_DETAILS_STATUS_PENDING:
                      return circleWithMembershipBalance('circle--muted');
                    case constants.MEMBER_DETAILS_STATUS_SUCCESS:
                      return circleWithMembershipBalance('circle--info');
                    case constants.MEMBER_DETAILS_STATUS_ERROR:
                      return circleWithError('circle--error');
                  }
                })()
              }

              {membership.isEmpty() ? unregisteredCircle('circle--action') : null}
            </div>

            <div className='register_membership__card_separator'>&nbsp;</div>

            {!shouldHideBalance &&
            <div className='fs-exclude text-center text-size-small'> {accountIdLabel}</div>}
            <div className='text-center text-size-x-small'> {updatedPrefix} {updatedAtLabel}</div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  renderRegistrationSuccess(values) {
    return (
      <div>
        <h1 className='register_membership__heading'>
          <FormattedMessage id='credentialCatcher.link.heading' />
        </h1>
        <div className='register_membership__success_text'>
          <FormattedHTMLMessage id='credentialCatcher.link.success' values={values} />
        </div>
        {this.renderDummyCard()}
      </div>
    );
  }

  renderRegistrationErrors() {
    return (
      <div>
        {this.renderLpLogo()}
        {this.renderMembershipErrors()}
      </div>
    );
  }

  renderContent = () => {
    const { membership } = this.props;
    const alreadyRegistered = membership.get('isRegistered');

    const values = {
      lpName: membership.get('lpName')
    };

    return (
      <div className='register_membership__wrapper'>
        {alreadyRegistered &&
        this.renderRegistrationSuccess(values)
        }

        {!alreadyRegistered &&
        this.renderRegistrationErrors()
        }
      </div>
    );
  };

  render() {
    const {
      locale, messages, lpId, partnerMv, membershipId, isAttemptingRegistration, isSingleSigningOn,
      errors, membership, lp
    } = this.props;
    const { WidgetComponent, attemptedRegistration, widgetErrors } = this.state;

    if (!locale || !messages.size || !lpId || !partnerMv || !membershipId
      || isAttemptingRegistration || isSingleSigningOn || lp.isEmpty() || membership.isEmpty()) {
      return (
        <div className='u-padding-small'>
          <Spinner />
        </div>
      );
    }

    const widgetProps = this.getWidgetPropsByLp();

    return (
      <IntlProvider locale={locale} textComponent='span' messages={messages.toJSON()}>
        <IntlContainer>
          <div className='u-padding-small'>
            {errors.isEmpty() && !attemptedRegistration && WidgetComponent &&
              <WidgetComponent
                key={lpId}
                {...widgetProps}
              />
            }

            {errors.isEmpty() && attemptedRegistration &&
              this.renderContent()
            }

            {!errors.isEmpty() || !widgetErrors.isEmpty() &&
              this.renderErrors()
            }
          </div>
        </IntlContainer>
      </IntlProvider>
    );
  }
}

WidgetCredentialCatcher.propTypes = {
  clientData: ImmutablePropTypes.map.isRequired,
  errors: ImmutablePropTypes.list,
  fetchLocale: PropTypes.func.isRequired,
  fetchMembership: PropTypes.func.isRequired,
  isAttemptingRegistration: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isSingleSigningOn: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  location: PropTypes.object,
  login: PropTypes.func.isRequired,
  lp: ImmutablePropTypes.map,
  lpId: PropTypes.string,
  membership: ImmutablePropTypes.map,
  membershipId: PropTypes.string,
  messages: ImmutablePropTypes.map,
  params: PropTypes.object,
  partnerMv: PropTypes.string,
  push: PropTypes.func.isRequired,
  registerMembership: PropTypes.func.isRequired,
  setWidgetParameters: PropTypes.func.isRequired,
  showRegistrationErrors: PropTypes.func.isRequired,
  submitRegistrationForm: PropTypes.func.isRequired,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

const mapStateToProps = state => ({
  clientData: state.app.get('clientData'),
  formatter: state.app.get('formatter'),
  locale: state.app.get('locale'),
  messages: state.app.get('messages'),
  lpId: state.registerMembership.get('lpId'),
  lp: state.registerMembership.get('lp'),
  membershipId: state.registerMembership.get('membershipId'),
  membership: state.registerMembership.get('membership'),
  partnerMv: state.registerMembership.get('partnerMv'),
  isLoggedIn: state.auth.get('isLoggedIn'),
  errors: state.registerMembership.get('errors'),
  isAttemptingRegistration: state.registerMembership.get('isAttemptingRegistration'),
  isSingleSigningOn: state.auth.get('isSingleSigningOn')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchLocale,
  setWidgetParameters,
  login,
  fetchMembership,
  submitRegistrationForm,
  registerMembership,
  showRegistrationErrors,
  push
}, dispatch);

export const WidgetCredentialCatcherContainer = connect(mapStateToProps, mapDispatchToProps)(WidgetCredentialCatcher);
