import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';

import { FormattedMessage } from 'react-intl';
import FancyButton from './FancyButton';
import RadioButtonList from './RadioButtonList';
import { getLogoPath } from './utils/image-helpers';

class MembershipSelection extends React.Component {
  state = {
    selectedMembershipId: ''
  };

  getMembershipsAsOptions = lp => {
    const lpName = lp.get('name');
    const currencyNameShort = lp.getIn(['content', 'currencyNameShort']);

    const partnerMemberships = this.getPartnerMemberships();
    return partnerMemberships.map((membership, index) => {
      const memberId = membership.getIn(['credentials', 'identifyingFactors.memberId']);
      const balance = membership.getIn(['memberDetails', 'balance']);
      const option = fromJS({
        id: membership.get('id'),
        label: `${lpName} - ${memberId} (${balance} ${currencyNameShort})`,
        isDefault: index === 0
      });
      return option;
    });
  };

  getPartnerMemberships = () => {
    const { lp, memberships } = this.props;
    return memberships.filter(membership => membership.get('lpId') === lp.get('id'));
  };

  setSelectedMembershipId = selectedMembershipId => {
    this.setState({ selectedMembershipId });
  };

  submitSelection = () => {
    const { onSubmit } = this.props;
    const { selectedMembershipId } = this.state;
    onSubmit(selectedMembershipId);
  };

  render() {
    const { formatter, instructions, lp } = this.props;
    const lpName = lp.get('name');
    const lpCode = lp.getIn(['content', 'code']);
    const logoAriaLabel = formatter.formatMessage({ id: 'shared.partnerLogo' }, { lpName });

    return (
      <div className='u-padding-horizontal-tiny u-padding-horizontal@md u-padding-top-large u-margin-bottom'>
        <div className='o-layout o-layout--center'>
          <div className='o-layout__item u-1/2@lg u-2/3@md u-1/1'>
            <div className='o-layout o-layout--center'>
              <div className='o-layout__item u-1/2 u-margin-top-large'>
                <img alt={logoAriaLabel} className='u-1/1' src={getLogoPath(lpCode)} />
              </div>
            </div>
            <div className='text-center u-padding-vertical'>
              { instructions || <FormattedMessage id='membershipSelect.instructions' /> }
            </div>
            <form className='u-padding-tiny u-padding@md'>
              <RadioButtonList
                options={this.getMembershipsAsOptions(lp)}
                optionName='membership'
                onOptionSelection={this.setSelectedMembershipId}
              />
            </form>
            <FancyButton
              classes='btn btn-primary u-1/1'
              type='submit'
              label='membershipSelect.continueButton'
              onClick={this.submitSelection}
            />
          </div>
        </div>
      </div>
    );
  }
}

MembershipSelection.propTypes = {
  lp: ImmutablePropTypes.map.isRequired,
  instructions: PropTypes.string,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  onSubmit: PropTypes.func
};

export default MembershipSelection;
