import { fromJS } from 'immutable';

const initialState = fromJS({
  promoData: {},
  isTriggeringPartnerIntegration: false,
  promoRedirectUrl: '',
  shouldShowEarnInterstitial: false,
  membership: {},
  membershipLp: {},
  integration: '',
  shouldExecutePartnerIntegration: false,
  partnerAccountLinked: false,
  earnMembershipSelectionLpId: ''
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_PROMO_DATA':
      return state.set('promoData', fromJS(action.promoData));

    case 'SET_IS_TRIGGERING_PARTNER_INTEGRATION':
      return state.set('isTriggeringPartnerIntegration', action.isTriggeringPartnerIntegration);

    case 'SET_PROMO_REDIRECT_URL':
      return state.set('promoRedirectUrl', action.promoRedirectUrl);

    case 'SET_SHOULD_SHOW_EARN_INTERSTITIAL':
      return state.set('shouldShowEarnInterstitial', action.shouldShowEarnInterstitial);

    case 'SET_EARN_MEMBERSHIP':
      return state.set('membership', fromJS(action.membership));

    case 'SET_EARN_INTEGRATION':
      return state.set('integration', action.integration);

    case 'SET_EARN_SHOULD_EXECUTE_PARTNER_INTEGRATION':
      return state.set('shouldExecutePartnerIntegration', action.shouldExecute);

    case 'SET_EARN_MEMBERSHIP_LP':
      return state.set('membershipLp', fromJS(action.lp));

    case 'SET_PARTNER_ACCOUNT_LINKED':
      return state.set('partnerAccountLinked', action.partnerAccountLinked);

    case 'SET_EARN_MEMBERSHIP_SELECTION_LP_ID':
      return state.set('earnMembershipSelectionLpId', action.earnMembershipSelectionLpId);

    default:
      return state;
  }
}
