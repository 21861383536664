import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';

class RadioButtonList extends React.Component {
  state = {
    defaultValue: ''
  };

  UNSAFE_componentWillMount() {
    this.setDefaultValue();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { options } = this.props;
    const { defaultValue } = this.state;
    const willReceiveOptions = !options.size && nextProps.options.size;
    if (willReceiveOptions && !defaultValue) {
      this.setDefaultValue(nextProps);
    }
  }

  setDefaultValue = (props = this.props) => {
    const { options } = props;
    if (!options || !options.size) return;
    const { defaultValue: defaultValueAlreadySet } = this.state;
    const defaultOption = options.filter(option => option.get('isDefault')).last();
    const defaultValue = defaultOption ? defaultOption.get('id') : '';
    if (!defaultValueAlreadySet && defaultValue) {
      this.setState({ defaultValue });
      this.setOption(defaultValue);
    }
  };

  setOption = value => {
    const { onOptionSelection } = this.props;
    onOptionSelection(value);
  };

  spinnerEl = null;

  renderOption = option => {
    const { optionName } = this.props;
    const { defaultValue } = this.state;
    const optionId = option.get('id');
    const optionLabel = option.get('label');
    const optionHasFancyLabel = typeof option.get('hasFancyLabel') !== 'undefined' ? option.get('hasFancyLabel') : false;
    const optionDisabled = typeof option.get('disabled') !== 'undefined' ? option.get('disabled') : false;
    const inputTagId = `select-input-${optionId}`;
    const labelTagId = `select-label-${optionId}`;
    const labelElement = optionHasFancyLabel ? <FormattedMessage id={optionLabel} /> : optionLabel;
    return (
      <div
        key={`key-${optionId}`}
      >
        <input
          id={inputTagId}
          aria-labelledby={labelTagId}
          type='radio'
          name={optionName}
          value={optionId}
          className='radio-button__input text-success'
          defaultChecked={optionId === defaultValue}
          disabled={optionDisabled}
        />
        <i className='radio-button__icon' aria-hidden='true' />
        <label
          id={labelTagId}
          htmlFor={inputTagId}
          className='radio-button__label u-padding-left-large u-padding-vertical-small'
        >
          { labelElement }
        </label>
      </div>
    );
  };

  render() {
    const { options, useFieldsets = true } = this.props;

    if (!options || !options.size) {
      return null;
    } else if (useFieldsets) {
      return (
        <fieldset
          ref={el => this.fieldsetEl = el}
          className='radio-button__fieldset'
          onChange={event => this.setOption(event.target.value)}
        >
          { options.map(option => this.renderOption(option)) }
        </fieldset>
      );
    }
    return (
      <div
        ref={el => this.fieldsetEl = el}
        className='radio-button__div'
        onChange={event => this.setOption(event.target.value)}
      >
        { options.map(option => this.renderOption(option)) }
      </div>
    );
  }
}

RadioButtonList.propTypes = {
  optionName: PropTypes.string.isRequired,
  options: ImmutablePropTypes.list,
  onOptionSelection: PropTypes.func.isRequired,
  useFieldsets: PropTypes.bool
};

export default RadioButtonList;
