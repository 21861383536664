import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { scrollTo } from '../utils/dom';
import { FormattedMessage } from 'react-intl';
import constants from '../utils/constants';

const initialState = currentPage => ({
  currentPage,
  originalCurrentPage: currentPage,
  hasChangedInput: false,
  isValidInput: false
});

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const { offset, itemsPerPage } = props;
    const currentPage = offset / itemsPerPage + 1;
    this.state = initialState(currentPage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ currentPage: nextProps.offset / nextProps.itemsPerPage + 1 });
  }

  getNumPages = () => {
    const { totalCount, itemsPerPage } = this.props;
    return Math.ceil(totalCount / itemsPerPage);
  };

  setCurrentPage = e => {
    const { value } = e.target;
    value.replace(/\D/g, '');
    const currentPage = parseInt(value);
    if (!Number.isNaN(currentPage) && currentPage > 0 && currentPage <= this.getNumPages()) {
      this.setState({
        currentPage,
        hasChangedInput: true,
        isValidInput: true
      });
    } else {
      this.setState({
        currentPage: '',
        hasChangedInput: true,
        isValidInput: false
      });
    }
  };

  go = type => {
    const { offset, itemsPerPage, onSelect } = this.props;
    scrollTo();
    switch (type) {
      case 'next':
        return onSelect(offset + itemsPerPage);
      case 'prev':
        return onSelect(offset - itemsPerPage);
    }
  };

  goToPage = event => {
    const { itemsPerPage, onSelect } = this.props;
    const { currentPage } = this.state;

    if (!currentPage || event.keyCode !== constants.KEY_CODES.ENTER_KEY) {
      return;
    }

    const offset = (currentPage - 1) * itemsPerPage;
    onSelect(offset);
    scrollTo();
  };

  paginationBtnNextEl = null;

  paginationBtnPrevEl = null;
  paginationInputEl = null;
  paginationInputHelperEl = null;

  reset = () => {
    const { offset, itemsPerPage } = this.props;
    const currentPage = offset / itemsPerPage + 1;
    this.setState(initialState(currentPage));
  };

  renderPaginationHelper = () => {
    const { formatter } = this.props;
    const { isValidInput } = this.state;
    const inputInstructions = formatter.formatMessage({ id: 'pagination.inputInstructions' });
    const invalidPageError = formatter.formatMessage({ id: 'pagination.invalidPageError' });

    return (
      <div
        ref={el => this.paginationInputHelperEl = el}
        role='alert'
        className={classnames(
          'pagination__input-text-helper',
          { 'pagination__input-text-helper--error': !isValidInput }
        )}
      >
        {isValidInput ? inputInstructions : invalidPageError}
      </div>
    );
  };

  render() {
    const { offset, totalCount, itemsPerPage, device, formatter } = this.props;
    const { isValidInput, hasChangedInput, currentPage } = this.state;
    const isPalm = device === 'palm';
    const nextClasses = {
      'pagination__btn--next-palm': isPalm,
      'pagination__btn--next': !isPalm,
      'pagination__btn pagination__btn--disabled': this.props.offset === 0,
      'pagination__button--hidden': totalCount - offset <= itemsPerPage
    };
    const numPages = this.getNumPages();

    const ofTotalPages = formatter.formatMessage({ id: 'pagination.ofTotalPages' }, { numPages });
    const currentPageAriaLabel = formatter.formatMessage({ id: 'pagination.currentPage' }, { currentPage, numPages });
    const prevClasses = {
      'pagination__btn--prev-palm': isPalm,
      'pagination__btn--prev': !isPalm,
      'pagination__button--hidden': offset === 0
    };

    return (
      <div className='pagination'>

        <div className='pagination__section'>
          <button
            ref={el => this.paginationBtnPrevEl = el}
            className={classnames('pagination__btn', prevClasses)}
            onClick={() => { this.go('prev'); }}
          >
            <i className='pagination__arrow-left' /> <FormattedMessage id='pagination.previous' />
          </button>
        </div>

        <div className='pagination__section'>
          <div className='pagination__input-container'>
            <input
              ref={el => this.paginationInputEl = el}
              className={classnames(
                { 'pagination__input-palm': isPalm, pagination__input: !isPalm },
                { 'pagination__input--error': !isValidInput && hasChangedInput }
              )}
              type='text'
              aria-label={`Pagination input. ${currentPageAriaLabel}`}
              value={currentPage}
              onChange={this.setCurrentPage}
              onKeyUp={this.goToPage}
              onBlur={this.reset}
            />
            {this.state.hasChangedInput ? this.renderPaginationHelper() : null}
          </div>
        </div>

        <div className='pagination__section'>
          <div className='pagination__num-pages' aria-label={currentPageAriaLabel}>
            { ofTotalPages }
          </div>
        </div>

        <div className='pagination__section'>
          <button
            ref={el => this.paginationBtnNextEl = el}
            className={classnames('pagination__btn', nextClasses)}
            onClick={() => { this.go('next'); }}
          >
            <FormattedMessage id='pagination.next' /> <i className='pagination__arrow-right' />
          </button>
        </div>

      </div>
    );
  }
}

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  device: PropTypes.string.isRequired,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

export default Pagination;
