import React from 'react';

class EarnFullOfferTermsContainer extends React.Component {
    componentDidMount = () => {
      if (this.props.showFullOfferTerms) {
        this.props.showFullOfferTerms();
      }
    }

    render() {
      return null;
    }
}

export default EarnFullOfferTermsContainer;
