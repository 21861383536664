import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import FancyButton from 'react-fancy-button';
import constants from '../utils/constants';
import ModalHeader from './ModalHeader';

const DeleteMembershipModal = props => {
  const {
    formatter,
    toggleDeleteMembershipModal,
    membership,
    googleTagManager,
    deleteMembership,
    lp,
    setModalContentKey,
    clientData,
    isDeletingMembership,
    hasDeleteMembershipError
  } = props;
  const isRegistered = membership.get('isRegistered');
  const mode = isRegistered ? constants.EDIT_LINKED_MEMBERSHIP_MODE : constants.LINK_MEMBERSHIP_MODE;

  const triggerAnalyticsEvents = () => {
    googleTagManager.pushGtmData(Object.assign({
      action: constants.ANALYTICS_DELETE_LP,
      context: 'linkAccountForm',
      lpName: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      lpId: lp.get('id'),
      isRegistered
    }, lp));
    googleTagManager.pushGtmData({
      action: constants.ANALYTICS_DELETE_LP,
      [constants.ANALYTICS_DELETE_LP]: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      context: 'balanceTracker'
    });
  };

  const deleteMembershipHandler = e => {
    e.preventDefault();
    const clientLpId = clientData.get('clientLpId');
    if (membership.get('lpId') !== clientLpId) {
      triggerAnalyticsEvents();
      deleteMembership(membership);
    }
  };

  const renderErrorMsg = () => (
    <div>
      <div className='message message--error'>
        <span
          className='text-base-size-palm'
          aria-label={formatter.formatMessage({ id: 'exchangeConfigure.somethingWentWrong' })}
        >
          {formatter.formatMessage({ id: 'exchangeConfigure.somethingWentWrong' })}
        </span>
      </div>
      <div className='u-padding'>
        <div className='push-double--bottom text-center text-base-size-palm u-margin-bottom'>
          {formatter.formatMessage({ id: `deleteMembership.${mode}.modalErrorText` })}
        </div>
        <FancyButton
          classes='btn btn-primary u-1/1 btn-small-padding'
          type='submit'
          label={formatter.formatMessage({ id: 'deleteMembership.modalCloseBtn' })}
          onClick={() => toggleDeleteMembershipModal(false)}
        />
      </div>
    </div>
  );

  const renderDeletionReview = () => (
    <div className='u-padding'>
      <div className='push-double--bottom text-center text-base-size-palm u-margin-bottom'>
        {formatter.formatMessage({ id: `deleteMembership.${mode}.modalReviewText` })}
      </div>
      <FancyButton
        classes='btn btn-primary u-1/1 btn-small-padding'
        type='submit'
        label={formatter.formatMessage({ id: `deleteMembership.${mode}.modalDeleteBtn` })}
        trigger={isDeletingMembership}
        onClick={e => deleteMembershipHandler(e)}
      />
    </div>
  );

  return (
    <div className='modal__deleteMembership__overlay'>
      <div className='modal__content'>
        <ModalHeader
          header={!hasDeleteMembershipError ? 'deleteMembership.modalReviewHeader' : 'deleteMembership.modalErrorHeader'}
          hideCloseButton={isDeletingMembership}
          afterCloseClick={() => toggleDeleteMembershipModal(false)}
          formatter={formatter}
          setModalContentKey={setModalContentKey}
        />
        {!hasDeleteMembershipError ? renderDeletionReview() : renderErrorMsg()}
      </div>
    </div>
  );
};

DeleteMembershipModal.propTypes = {
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  isDeletingMembership: PropTypes.bool,
  toggleDeleteMembershipModal: PropTypes.func,
  membership: ImmutablePropTypes.map,
  googleTagManager: PropTypes.object,
  deleteMembership: PropTypes.func,
  lp: ImmutablePropTypes.map,
  setModalContentKey: PropTypes.func,
  clientData: ImmutablePropTypes.map,
  hasDeleteMembershipError: PropTypes.bool
};

export default DeleteMembershipModal;
