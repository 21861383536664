import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import RadioButtonList from './RadioButtonList';

const RedeemRecipientSection = props => {
  const {
    isSendingToSelf,
    isPhoneNumFormatCorrect,
    phoneNumber,
    getPhoneNumberOptions,
    setPhoneNumberOption,
    setPhoneNumber,
    checkNumFormat,
    renderNumFormatErrMsg,
    renderSendingToSelfCondition,
    renderSendingToOthersCondition,
    useFieldsets
  } = props;
  const options = fromJS(getPhoneNumberOptions());
  return (
    <div className='redeemForm__recipient'>
      <div className='redeemForm__recipientInfo'>
        <div className='redeemForm__recipientInfo__three'>
          <FormattedMessage id='redeem.offer.recipient.three' />
        </div>
        <div className='redeemForm__recipientInfo__info'>
          <FormattedMessage id='redeem.offer.recipient.title' />
        </div>
      </div>
      <div className='redeemForm__recipientOptions'>
        <RadioButtonList
          options={options}
          optionName='recipient'
          onOptionSelection={setPhoneNumberOption}
          useFieldsets={useFieldsets}
        />
      </div>
      <div id='redeemForm__recipient__number__title'>
        { isSendingToSelf ? <FormattedMessage id='redeem.offer.recipient.self.phoneTitle' />
          : <FormattedMessage id='redeem.offer.recipient.others.phoneTitle' />
        }
      </div>
      <input
        type='tel'
        className='fs-exclude redeemForm__recipient__phoneNumber'
        name='phoneNumber'
        placeholder='xxx-xxx-xxxx'
        value={phoneNumber}
        onChange={setPhoneNumber}
        onBlur={checkNumFormat}
      />
      { isPhoneNumFormatCorrect === false && renderNumFormatErrMsg() }
      { isSendingToSelf ? renderSendingToSelfCondition() : renderSendingToOthersCondition() }
    </div>
  );
};

RedeemRecipientSection.propTypes = {
  isSendingToSelf: PropTypes.bool.isRequired,
  isPhoneNumFormatCorrect: PropTypes.bool,
  phoneNumber: PropTypes.string,
  getPhoneNumberOptions: PropTypes.func.isRequired,
  setPhoneNumberOption: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  checkNumFormat: PropTypes.func.isRequired,
  renderNumFormatErrMsg: PropTypes.func.isRequired,
  renderSendingToSelfCondition: PropTypes.func.isRequired,
  renderSendingToOthersCondition: PropTypes.func.isRequired,
  useFieldsets: PropTypes.bool.isRequired
};

export default RedeemRecipientSection;
