import Orders from '../api/orders';
import { getTotalCountFromHeader } from '../api/utils/httpUtils';
import constants from '../utils/constants';

export function setOrders(orders) {
  return {
    type: 'SET_ORDERS',
    orders
  };
}

export function setSelectedOrder(selectedOrder) {
  return {
    type: 'SET_SELECTED_ORDER',
    selectedOrder
  };
}

export function setOrdersTotalCount(ordersTotalCount) {
  return {
    type: 'SET_ORDERS_TOTAL_COUNT',
    ordersTotalCount
  };
}

export function isFetchingOrders(isFetchingOrders) {
  return {
    type: 'SET_IS_FETCHING_ORDERS',
    isFetchingOrders
  };
}

export function setPendingEarnOrders(pendingEarnOrders) {
  return {
    type: 'SET_PENDING_EARN_ORDERS',
    pendingEarnOrders
  };
}

export function setPendingActivity(pendingActivity) {
  return {
    type: 'SET_PENDING_ACTIVITY',
    pendingActivity
  };
}

export function fetchOrders(params) {
  return dispatch => {
    params = params || {};
    params.limit = constants.resultsPerPage;
    dispatch(isFetchingOrders(true));
    return Orders.fetchOrders(params).then(data => {
      dispatch(setOrders(data.json.orders));
      dispatch(isFetchingOrders(false));
      dispatch(setOrdersTotalCount(getTotalCountFromHeader(data.res)));
    });
  };
}

export function fetchOrder(orderId) {
  return dispatch => Orders.fetchOrder(orderId).then(data => {
    dispatch(setSelectedOrder(data));
  });
}

export function fetchPendingActivity() {
  return dispatch => Orders.fetchOrders().then(data => {
    const { orders } = data.json;
    dispatch(setPendingActivity(orders));
  });
}

export function updateOrder(orderID, updatedData) {
  return dispatch => Orders.updateOrder(orderID, updatedData).then(() => {
    setTimeout(
      () => dispatch(fetchPendingActivity()),
      3000 // LCP might return stale results if we try to GET an order immediately after patching it
    );
  });
}

export function fetchPendingEarnOrders() {
  const params = {
    type: constants.orderType.earn,
    status: constants.pendingOrdersStatuses,
    limit: constants.resultsPerPage
  };

  return dispatch => Orders.fetchOrders(params).then(data => {
    const { orders } = data.json;
    dispatch(setPendingEarnOrders(orders));
  });
}
