import classnames from 'classnames';
import { List } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedHTMLMessage, FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import { fetchLocale } from '../action-creators/app';
import { fetchMembership, login, registerMembershipMv, registerMembershipPolling } from '../action-creators/register-membership';

import constants from '../utils/constants';
import { getQueryParamsAsObject } from '../utils/url';

import { IntlContainer } from './Intl';
import Spinner from './Spinner';
import { getLogoPath } from './utils/image-helpers';
import { shouldHideMemberData } from './utils/wps-helpers';

export class ExternalCredentialCatcher extends Component {
  constructor(props) {
    super(props);

    const queryParams = getQueryParamsAsObject();
    const lpMvParam = props.clientData.getIn(['credentialCatchers', 'params', 'lpMv']);

    this.state = {
      membershipId: props.params.id,
      lpMv: queryParams[lpMvParam],
      encodedPartnerMv: props.params.partnerMv
    };
  }

  componentDidMount() {
    const { clientData, fetchLocale, login, push } = this.props;
    const { membershipId, encodedPartnerMv } = this.state;

    const locale = clientData.get('locale');
    fetchLocale(locale);

    const partnerMv = encodedPartnerMv ? window.atob(encodedPartnerMv) : '';
    login(partnerMv);
    push(`/register-external-membership/${membershipId}`);
  }

  componentDidUpdate(prevProps) {
    const {
      isLoggedIn,
      registerMembershipMv,
      fetchMembership,
      membership,
      registerMembershipPolling
    } = this.props;

    const { membershipId, lpMv } = this.state;

    const gotLoggedIn = !prevProps.isLoggedIn && isLoggedIn;
    if (gotLoggedIn) {
      fetchMembership(membershipId);
    }

    const gotMembership = prevProps.membership.isEmpty() && !membership.isEmpty();

    if (gotMembership) {
      if (lpMv) {
        // Update the membership with the MV delegate in the lpMV query param.
        registerMembershipMv(membershipId, lpMv);
      } else if (membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING) {
        /*
          SSOGW will sometimes do the PUT call on the membership directly with Wallet-API (via multi-stage SSO process with PIE). SSOGW will redirect to BLW afterwards without lpMv.
          Poll for the membership if it is pending and BLW is not updating the membership (lack of lpMv).  This is so we have a correct membership state to show a succes/error screen.
          This is because authenticatingFactors can't be persisted if we go with the regular lpMv process.
        */
        registerMembershipPolling(membershipId);
      }
    }
  }

  getAccountIdLabel = membership => {
    const accountId = membership.getIn(['credentials', 'identifyingFactors.memberId']) || '';
    return !accountId ? '' : `#${accountId}`;
  };

  renderLpLogo = () => {
    const { lp } = this.props;

    if (lp.isEmpty()) {
      return null;
    }

    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpCode = lp.getIn(['content', 'code']);

    return (
      <div className='register_membership__program_logo'>
        <img
          alt={`${lpCompanyName} logo`}
          src={getLogoPath(lpCode)}
        />
      </div>
    );
  };

  renderMembershipErrors = () => {
    const { formatter, membership } = this.props;
    const membershipErrors = membership.get('memberDetailsErrors') || List();
    const closeWindowMsg = formatter.formatMessage({ id: 'credentialCatcher.error.addon' });

    if (membershipErrors.isEmpty()) {
      return (
        <div>
          <FormattedHTMLMessage id='credentialCatcher.error' />
        </div>
      );
    }

    return (
      <div className='register_membership__message_error'>
        {
          membershipErrors.map(error => {
            const defaultMessage = formatter.formatMessage({ id: 'membershipRegistration.defaultError' });
            return (
              <div
                key={error}
                className='u-margin-vertical-none'
                aria-live='assertive'
                role='alert'
                aria-label={formatter.formatMessage({ id: `membershipRegistration.${error}`, defaultMessage })}
              >
                <FormattedMessage id={`membershipRegistration.${error}`} defaultMessage={defaultMessage} />
                <br />
                {closeWindowMsg}
              </div>
            );
          })
        }
      </div>
    );
  };

  renderDummyCard = () => {
    const { membership, lp, formatter, clientData } = this.props;

    if (lp.isEmpty() || membership.isEmpty()) {
      return null;
    }

    const memberDetailsStatus = membership.get('memberDetailsStatus');
    const accountIdLabel = this.getAccountIdLabel(membership);
    const logoName = `${lp.get('name')} logo`;
    const lpCode = lp.getIn(['content', 'code']);
    const updatedAtLabel = moment(membership.get('updatedAt')).format('dddd, MMMM Do YYYY');
    const updatedPrefix = formatter.formatMessage({ id: 'credentialCatcher.dummyCard.updated' });

    const unregisteredCircle = function unregisteredMark(className) {
      const selectToRegisterAriaLabel = formatter.formatMessage({ id: 'membershipcard.registerAriaLabel' }, { lpName: membership.get('lpName') });
      return (
        <div
          aria-label={selectToRegisterAriaLabel}
          role='button'
          className={classnames('circle', className)}
        >
          <div className='circle__content circle__content--smaller-font'>
            <FormattedMessage id='membershipcard.circleMessage' />
          </div>
        </div>
      );
    };

    const circleWithError = function errorMark(className) {
      const selectAriaLabel = formatter.formatMessage({ id: 'membershipcard.selectAriaLabel' }, { lpName: membership.get('lpName') });
      return (
        <div
          aria-label={selectAriaLabel}
          role='button'
          className={classnames('circle', className)}
        >
          <div className='circle__content circle__content--smaller-font'>
            <FormattedMessage id='membershipcard.circleMessageError' />
          </div>
        </div>
      );
    };

    const circleWithMembershipBalance = function balanceMark(className) {
      const balance = membership.getIn(['memberDetails', 'balance']);
      const ariaLabel = formatter.formatMessage({ id: 'membershipcard.registeredProgram' }, {
        balance,
        lpName: membership.get('lpName')
      });
      const shouldHideBalance = shouldHideMemberData(clientData, lp.get('id'));
      const hiddenBalanceLabel = formatter.formatMessage({ id: 'membershipcard.hiddenBalanceLabel' });
      const balanceLabel = formatter.formatNumber(balance);
      const registeredProgramLabel = shouldHideBalance ? hiddenBalanceLabel : balanceLabel;
      return (
        <div
          aria-label={ariaLabel}
          role='button'
          className={classnames('circle circle--with-sub-content', className)}
        >
          <div className={classnames('circle__content', { 'text-size-big': shouldHideBalance })}>
            {registeredProgramLabel}
            {
              !shouldHideBalance && (
                <div className='circle__sub-content'>
                  {lp.getIn(['content', 'currencyNameShort'])}
                </div>
              )
            }
          </div>
        </div>
      );
    };

    return (
      <div className='register_membership__card u-padding'>
        <div className='membership-card u-margin-bottom membership-card--no-action'>
          <div className='membership-card-desk'>
            <div className='u-padding-small u-padding-horizontal'>
              <img
                className='membership-card__img u-1/1'
                alt={logoName}
                src={getLogoPath(lpCode)}
              />
            </div>
            <div className='u-margin-bottom u-margin-bottom'>
              {
                (() => {
                  switch (memberDetailsStatus) {
                    case constants.MEMBER_DETAILS_STATUS_UNREGISTERED:
                      return unregisteredCircle('circle--action');
                    case constants.MEMBER_DETAILS_STATUS_SUCCESS:
                      return circleWithMembershipBalance('circle--info');
                    case constants.MEMBER_DETAILS_STATUS_ERROR:
                      return circleWithError('circle--error');
                  }
                })()
              }

              {membership.isEmpty() ? unregisteredCircle('circle--action') : null}
            </div>

            <div className='register_membership__card_separator'>&nbsp;</div>

            <div className='fs-exclude text-center text-size-small'> {accountIdLabel}</div>
            <div className='text-center text-size-x-small'> {updatedPrefix} {updatedAtLabel}</div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  renderErrors = () => {
    const { errors } = this.props;
    return (
      <div className='register_membership__wrapper'>
        <h1 className='register_membership__heading'>
          <FormattedHTMLMessage id='credentialCatcher.error' />
        </h1>
        {
          errors.map((errId, i) => (
            <p key={`register_membership_error_${i}`}><FormattedHTMLMessage id={errId} /></p>
          ))
        }
      </div>
    );
  };

  renderContent = () => {
    const { membership } = this.props;
    const alreadyRegistered = membership.get('isRegistered');
    const values = {
      lpName: membership.get('lpName')
    };
    return (
      <div className='register_membership__wrapper'>
        <h1 className='register_membership__heading'>
          <FormattedMessage id='credentialCatcher.link.heading' />
        </h1>
        {
          alreadyRegistered ? (
            <div>
              <FormattedHTMLMessage id='credentialCatcher.link.success' values={values} />
              {this.renderDummyCard()}
            </div>
          ) : (
            <div>
              {this.renderLpLogo()}
              {this.renderMembershipErrors()}
            </div>
          )
        }
      </div>
    );
  };

  render() {
    const {
      formatter, locale, messages, isFetchingMembership, isSingleSigningOn, isAttemptingRegistration, errors, membership
    } = this.props;

    const membershipNeedsPolling = membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;

    if (!locale || !messages.size) {
      return null;
    } else if (isFetchingMembership || isSingleSigningOn || isAttemptingRegistration || membershipNeedsPolling) {
      const spinnerMsg = formatter && formatter.formatMessage({ id: 'credentialCatcher.loading' });
      return (
        <div className='register_membership__wrapper'>
          <h1 className='register_membership__heading'>{spinnerMsg}</h1>
          <Spinner />
        </div>
      );
    }

    return (
      <IntlProvider locale={locale} textComponent='span' messages={messages.toJSON()}>
        <IntlContainer>
          {errors.isEmpty() && formatter ? this.renderContent() : this.renderErrors()}
        </IntlContainer>
      </IntlProvider>
    );
  }
}

ExternalCredentialCatcher.propTypes = {
  clientData: ImmutablePropTypes.map.isRequired,
  errors: ImmutablePropTypes.list,
  fetchLocale: PropTypes.func.isRequired,
  fetchMembership: PropTypes.func.isRequired,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  isAttemptingRegistration: PropTypes.bool.isRequired,
  isFetchingMembership: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isSingleSigningOn: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  login: PropTypes.func.isRequired,
  lp: ImmutablePropTypes.map,
  membership: ImmutablePropTypes.map,
  messages: ImmutablePropTypes.map,
  params: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  registerMembershipMv: PropTypes.func.isRequired,
  registerMembershipPolling: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  clientData: state.app.get('clientData'),
  errors: state.registerMembership.get('errors'),
  formatter: state.app.get('formatter'),
  isAttemptingRegistration: state.registerMembership.get('isAttemptingRegistration'),
  isFetchingMembership: state.registerMembership.get('isFetchingMembership'),
  isLoggedIn: state.auth.get('isLoggedIn'),
  isSingleSigningOn: state.auth.get('isSingleSigningOn'),
  locale: state.app.get('locale'),
  lp: state.registerMembership.get('lp'),
  membership: state.registerMembership.get('membership'),
  messages: state.app.get('messages')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  registerMembershipMv,
  push,
  fetchLocale,
  fetchMembership,
  registerMembershipPolling
}, dispatch);

export const ExternalCredentialCatcherContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalCredentialCatcher);
