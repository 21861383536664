/**
 @module wallet-partner-label application
 */

/**-----------------------------
 *     Libs
 * -----------------------------*/
import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import { getPartnerPostUrl, setHttpUtilsStore } from './api/utils/httpUtils';
import { isProdEnvironment } from './utils/application';
import { StyledEngineProvider } from '@mui/material';
// AppContainer is a necessary wrapper component for HMR
import { AppContainer } from 'react-hot-loader';
import Root from './Root';

// Polyfill for native fetch api
// https://github.com/github/fetch
import 'whatwg-fetch';

// Polyfill for native Intl support
// https://github.com/andyearnshaw/Intl.js
import 'intl';
import 'intl/locale-data/jsonp/en';

import { debounce } from './utils/timing';

/**-----------------------------
 *     Middleware
 * -----------------------------*/

import { thunk } from 'redux-thunk';
import logger from './middleware/logger';

/**-----------------------------
 *     Action Creators
 * -----------------------------*/
import { fetchClientData, setClientSpecificData, setDevice } from './action-creators/app';

/**-----------------------------
 *     Reducers
 * -----------------------------*/

import { rootReducer } from './reducers';

/**-----------------------------
 *     Create Store & History
 * -----------------------------*/

const routingMiddleware = routerMiddleware(browserHistory);

let middlewares;
let composeEnhancers;

if (isProdEnvironment()) {
  middlewares = applyMiddleware(thunk, routingMiddleware);
  composeEnhancers = compose;
} else {
  middlewares = applyMiddleware(thunk, routingMiddleware, logger);
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(rootReducer, composeEnhancers(middlewares));

store.dispatch(setClientSpecificData(window.POINTS_CONFIG));

syncHistoryWithStore(browserHistory, store);

/**-----------------------------
 *     Add Store to utils
 * ----------------------------*/
setHttpUtilsStore(store);

/**-----------------------------
 *     Initialize & Render
 * ----------------------------*/

const render = RootContainer => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <RootContainer store={store} />
        </StyledEngineProvider>
      </Provider>
    </AppContainer>,
    document.getElementById('app')
  );
};

store.dispatch(fetchClientData()).then(clientData => {
  render(Root);
  const { isIframed, shouldShowTopNav } = clientData;
  initIframePostMessagging(isIframed, shouldShowTopNav);
});

/**-----------------------------
 *     iframe Post Messaging
 * ----------------------------*/
function initIframePostMessagging(isIframed, shouldShowTopNav) {
  const parentUrl = getPartnerPostUrl();
  const iframeManager = store.getState().app.get('iframeManager');
  const el = document.getElementById('points-loyalty-wallet-app');
  const headerEl = shouldShowTopNav ? document.getElementById('points-loyalty-wallet-app-header') : null;
  iframeManager.init(el, isIframed, parentUrl, headerEl);
}

/**-----------------------------
 *     Google Analytics Setup
 * ----------------------------*/

const googleTagManager = store.getState().app.get('googleTagManager');
googleTagManager.setStore(store);

/**-----------------------------
 *     Breakpoint Mgmt
 * ----------------------------*/
const breakpoints = {
  palm: 'screen and (max-width: 525px)',
  lap: 'screen and (min-width: 526px) and (max-width: 768px)',
  desk: 'screen and (min-width: 769px)'
};

function checkBreakpoint() {
  for (const device in breakpoints) {
    const breakpoint = breakpoints[device];
    if (window.matchMedia(breakpoint).matches) {
      const curDevice = store.getState().app.get('device');
      if (device !== curDevice) {
        store.dispatch(setDevice(device));
      }
    }
  }
}

checkBreakpoint();
window.addEventListener('resize', debounce(checkBreakpoint, 150));
