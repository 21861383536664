import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import moment from 'moment';
import constants from '../utils/constants';
import { FormattedMessage, FormattedHTMLMessage, FormattedNumber } from 'react-intl';
import { formatNumber } from '../utils/string';

import { fetchOrders } from '../action-creators/orders';
import Drawer from './Drawer';
import Pagination from './Pagination';
import Spinner from './Spinner';
import { Helmet } from 'react-helmet';

export class Activity extends React.Component {
  state = {
    offset: 0
  };

  UNSAFE_componentWillMount() {
    this.fetchPageOfOrders(this.state.offset);
  }

  getAriaLabelForActivity = (order, lp, direction) => {
    const orderStatus = order.get('status');
    const amount = order.getIn([direction, 'amount']);
    const createdAtString = moment(order.get('createdAt')).format('MMMM D YYYY');
    const lpCurrency = lp.getIn(['content', 'currencyNameLong']);
    const action = direction === 'to' ? 'Earned' : 'Spent';
    const lpName = lp.get('name');
    const type = order.get('type');

    return this.props.formatter.formatMessage({ id: 'activity.ariaLabelForActivity' }, {
      type, lpName, createdAtString, action, amount, lpCurrency, orderStatus
    });
  };

  activitiesRowsEl = null;
  activitiesTableEl = null;
  creditedAtDateEl = null;

  fetchPageOfOrders = offset => {
    this.props.fetchOrders({ offset });
    this.setState({ offset });
  };

  isOrderTypeEarn = order => order.get('type') === constants.orderType.earn;

  noTransactionsEl = null;
  spinnerEl = null;

  renderActivitiesTable = () => {
    const { orders } = this.props;

    if (!orders.size) {
      return (
        <div
          className='text-center text-color-muted u-padding'
          ref={el => this.noTransactionsEl = el}
        >
          <FormattedMessage id='activity.noTransactionsFound' />
        </div>
      );
    }

    return (
      <div ref={el => this.activitiesTableEl = el} role='list'>
        <div
          className='o-layout o-layout--flush activities__header u-padding-small text-weight-6 text-uppercase'
          aria-hidden='true'
        >
          <div className='o-layout__item u-2/12 hide show-ib@md'>
            <FormattedMessage id='activity.activityTableHeader.date' />
          </div>
          <div className='o-layout__item u-5/12@md u-2/3'>
            <FormattedMessage id='activity.activityTableHeader.description' />
          </div>
          <div className='o-layout__item u-1/12 hide show-ib@md'>
            <FormattedMessage id='activity.activityTableHeader.type' />
          </div>
          <div className='o-layout__item u-2/12 hide show-ib@md'>
            <FormattedMessage id='activity.activityTableHeader.status' />
          </div>
          <div className='o-layout__item u-2/12@md u-1/3'>
            <FormattedMessage id='activity.activityTableHeader.amount' />
          </div>
        </div>
        <div ref={el => this.activitiesRowsEl = el}>
          {
            orders.map(order => {
              switch (order.get('type')) {
                case constants.orderType.exchange: {
                  return ([
                    this.renderActivityRow(order, 'to'),
                    this.renderActivityRow(order, 'from')
                  ]);
                } case constants.orderType.earn: {
                  return this.renderActivityRow(order, 'to');
                } case constants.orderType.redeemDebit: {
                  return this.renderActivityRow(order, 'from');
                }
              }
            })
          }
        </div>
        { this.renderPagination() }
      </div>
    );
  };

  renderActivityRow = (order, direction) => {
    const { wps } = this.props;
    const wp = wps.find(wp => wp.get('id') === order.get('wpId'));
    const createdAtDate = moment(order.get('createdAt')).format(constants.dateFormatActivity);
    const id = `${order.get('orderId')}-${direction}-addtional-info`;

    return (
      <Drawer
        key={id}
        isOpen={false}
        header={this.renderActivityRowDrawerHeader(order, direction)}
      >
        <div className='o-layout o-layout--flush activities__additional-info'>
          <div className='o-layout__item u-2/12 hide show-ib@md' />
          <div className='o-layout__item u-8/12@md u-margin-right-small u-1/1 u-padding-left-large u-padding-vertical-small'>
            <div className='text-size-big'>
              <FormattedMessage id='activity.summaryHeader' />
            </div>
            <ul className='activity-summary-text u-margin-top-none'>
              <li className='text-capitalize'>
                <FormattedHTMLMessage
                  id='activity.activityRowConfirmationNumber'
                  values={{
                    type: order.get('type').toLowerCase(),
                    confirmationNumber: order.get('confirmationNumber')
                  }}
                />
              </li>
              { this.renderOrderProduct(order) }
              { this.renderOrderAmountsInfo(order) }
              <li>
                <FormattedHTMLMessage
                  id='activity.confirmationEmail'
                  values={{
                    confirmationEmail: order.get('confirmationEmail')
                  }}
                />
              </li>
              { this.renderPointsToBeCreditedAt(order) }
              <li>
                <FormattedMessage
                  id='activity.confirmationActivityDate'
                  values={{
                    createdAtDate,
                    wpName: wp.get('name')
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      </Drawer>
    );
  };

  renderActivityRowDrawerHeader = (order, direction) => {
    const { lps } = this.props;
    const lpId = order.getIn([direction, 'lpId']);
    const lp = lps.find(lp => lp.get('id') === lpId);
    const type = order.get('type');
    const iconClass = classnames({
      'icon-exchange': type === constants.orderType.exchange,
      'icon-chart': type === constants.orderType.earn
    });
    const statusClass = classnames('o-layout__item', 'u-2/12', 'text-capitalize', 'hide show-ib@md', {
      'activity-pending-status': order.get('status') === constants.orderStatus.pending
    });

    return (
      <div
        key={`${order.get('orderId')}-${direction}`}
        aria-label={this.getAriaLabelForActivity(order, lp, direction)}
        role='button'
        className='o-layout o-layout--flush u-padding-top u-padding-bottom u-padding-horizontal-small u-bt'
      >
        <div className='o-layout__item u-2/12 hide show-ib@md'>
          { moment(order.get('createdAt')).format(constants.dateFormatActivity) }
        </div>
        <div className='o-layout__item u-5/12 hide show-ib@md'>
          <div className='text-size-big'>
            { lp.get('name') }
          </div>
          <div className='text-color-muted text-size-small text-capitalize'>
            <FormattedHTMLMessage
              id='activity.orderConfirmationNumber'
              values={{
                type: type.toLowerCase(),
                confirmationNumber: order.get('confirmationNumber')
              }}
            />
          </div>
        </div>
        <div className='o-layout__item u-1/12 hide show-ib@md'>
          <i className={iconClass} />
        </div>
        <div className={statusClass}>
          <FormattedMessage id={`activity.orderStatus.${order.get('status').toLowerCase()}`} />
        </div>
        { this.renderPalmActivityInfo(lp, order) }
        <div className='o-layout__item u-2/12@md u-1/3 text-base-size-palm'>
          { `${direction === 'to' ? '+ ' : '- '} ` } <FormattedNumber value={order.getIn([direction, 'amount'])} />
        </div>
      </div>
    );
  };

  renderLoadingSpinner = () => (
    <div className='u-padding-vertical-huge'>
      <Spinner ref={el => this.spinnerEl = el} />
    </div>
  );

  renderOrderAmountsInfo = order => {
    const { lps } = this.props;

    switch (order.get('type')) {
      case constants.orderType.exchange: {
        const toLp = lps.find(lp => lp.get('id') === order.getIn(['to', 'lpId']));
        const toCompany = toLp.get('name');
        const toLpCurrency = toLp.getIn(['content', 'currencyNameLong']);
        const toAmount = order.getIn(['to', 'amount']);
        const toMemberId = order.getIn(['to', 'memberId']);
        const fromLp = lps.find(lp => lp.get('id') === order.getIn(['from', 'lpId']));
        const fromCompany = fromLp.get('name');
        const fromLpCurrency = fromLp.getIn(['content', 'currencyNameLong']);
        const fromAmount = order.getIn(['from', 'amount']);
        const fromMemberId = order.getIn(['from', 'memberId']);
        return (
          <li>
            <FormattedHTMLMessage
              id='activity.orderAmountsExchanged'
              values={{
                fromAmount: formatNumber(fromAmount),
                toAmount: formatNumber(toAmount),
                fromMemberId,
                toMemberId,
                fromCompany,
                toCompany,
                fromLpCurrency,
                toLpCurrency
              }}
            />
          </li>
        );
      }
      case constants.orderType.earn: {
        const toLp = lps.find(lp => lp.get('id') === order.getIn(['to', 'lpId']));
        const toLpCurrency = toLp.getIn(['content', 'currencyNameLong']);
        const toAmount = order.getIn(['to', 'amount']);
        return (
          <li>
            <FormattedMessage
              id='activity.orderAmountsEarned'
              values={{
                toAmount: formatNumber(toAmount), toLpCurrency
              }}
            />
          </li>
        );
      }
      case constants.orderType.redeemDebit: {
        const fromLp = lps.find(lp => lp.get('id') === order.getIn(['from', 'lpId']));
        const fromLpCurrency = fromLp.getIn(['content', 'currencyNameLong']);
        const fromAmount = order.getIn(['from', 'amount']);
        return (
          <li>
            <FormattedMessage
              id='activity.orderAmountsRedeemed'
              values={{
                fromAmount: formatNumber(fromAmount), fromLpCurrency
              }}
            />
          </li>
        );
      }
    }
  };

  renderOrderProduct = order => {
    const sku = order.get('sku');
    const productDescription = order.get('productDescription');
    if (!productDescription && !sku) {
      return null;
    }
    return (
      <li>
        <FormattedMessage
          id='activity.orderProductNotes'
          values={{
            productDescription,
            sku
          }}
        />
      </li>
    );
  };

  renderPagination = () => (
    <div className='text-center'>
      <Pagination
        totalCount={Number(this.props.ordersTotalCount)}
        offset={this.state.offset}
        device={this.props.device}
        itemsPerPage={constants.resultsPerPage}
        onSelect={this.fetchPageOfOrders}
        formatter={this.props.formatter}
      />
    </div>
  );

  renderPalmActivityInfo = (lp, order) => {
    const statusClass = classnames({
      'activity-pending-status': order.get('status') === constants.orderStatus.pending
    });
    return (
      <div className='o-layout__item u-2/3 hide@md text-base-size-palm'>
        <div>
          { lp.get('name') }
        </div>
        <div className={`text-size-normal ${statusClass}`}>
          { `Status: ${order.get('status').toLowerCase()}` }
        </div>
        <div className='text-size-normal'>
          { moment(order.get('createdAt')).format(constants.dateFormatActivity) }
        </div>
      </div>
    );
  };

  renderPointsToBeCreditedAt = order => {
    const creditedAtDate = moment(order.get('toBeCreditedAt')).format(constants.dateFormatActivity);
    const orderStatus = order.get('status');
    const isFailed = orderStatus === constants.orderStatus.failed;

    if (!this.isOrderTypeEarn(order) || isFailed) return null;

    const isComplete = orderStatus === constants.orderStatus.complete;
    const translationId = isComplete ? 'activity.pointsCreditedDate' : 'activity.pointsToBeCreditedDate';

    return (
      <li ref={el => this.creditedAtDateEl = el}>
        <FormattedMessage
          id={translationId}
          values={{
            creditedAtDate
          }}
        />
      </li>
    );
  };

  render() {
    const { formatter } = this.props;
    return (
      <div className='activity-component u-padding-small u-padding@md'>
        <Helmet>
          <title>{formatter.formatMessage({ id: 'pageTitle.activity' })}</title>
          <meta
            name='description'
            content={formatter.formatMessage({ id: 'pageTitle.activity' })}
          />
        </Helmet>
        <div className='section'>
          <div className='text-center'>
            <h1 className='u-margin-top-none'>
              <FormattedMessage id='activity.header' />
            </h1>
          </div>
          {this.props.isFetchingOrders ? this.renderLoadingSpinner() : this.renderActivitiesTable()}
        </div>
      </div>
    );
  }
}

Activity.propTypes = {
  orders: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  ordersTotalCount: PropTypes.number,
  isFetchingOrders: PropTypes.bool,
  device: PropTypes.string,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  lps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  wps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  fetchOrders: PropTypes.func
};

function mapStateToProps(state) {
  return {
    orders: state.orders.get('orders'),
    ordersTotalCount: state.orders.get('ordersTotalCount'),
    isFetchingOrders: state.orders.get('isFetchingOrders'),
    device: state.app.get('device'),
    formatter: state.app.get('formatter'),
    lps: state.lps.get('allLps'),
    wps: state.wps.get('allWps')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchOrders
  }, dispatch);
}

export const ActivityContainer = connect(mapStateToProps, mapDispatchToProps)(Activity);
