import { push } from 'react-router-redux';
import Settings from '../api/settings';
import { request } from '../api/utils/httpUtils';
import { getClientId, getLocale } from '../utils/application';

/**
 @class App action creator
 */

/*-------------------------------
 *
 * Sync Actions
 *
 -------------------------------*/

export function setAppError(appError) {
  return {
    type: 'SET_APP_ERROR',
    appError
  };
}

export function setClientSpecificData(data) {
  return {
    type: 'SET_CLIENT_DATA',
    data
  };
}

export function setDevice(device) {
  return {
    type: 'SET_DEVICE',
    device
  };
}

export function setPageMessage(pageMessage) {
  return {
    type: 'SET_PAGE_MESSAGE',
    pageMessage
  };
}

export function setProductType(productType) {
  return {
    type: 'SET_PRODUCT_TYPE',
    productType
  };
}

// NOTE: if this method is dispatched, it should also fetch
// a new locale based off of partnerLpId
export function setPartnerLpId(partnerLpId) {
  return {
    type: 'SET_PARTNER_LP_ID',
    partnerLpId
  };
}

/**
 UI boolean to indicate if certain components should be shown
 @method setShowCreateAccount
 @param {Boolean} showCreateAccount
 */
export function setShowCreateAccount(showCreateAccount) {
  return {
    type: 'SET_SHOW_CREATE_ACCOUNT',
    showCreateAccount
  };
}

/**
 UI boolean to indicate if the registration slide in WalletLanding should be closeable
 @method setRequireRegistration
 @param {Boolean} requireRegistration
 */
export function setRequireRegistration(requireRegistration) {
  return {
    type: 'SET_REQUIRE_REGISTRATION',
    requireRegistration
  };
}

/**
 UI boolean to indicate if certain components should be shown
 @method setShowMembershipRegistration
 @param {Boolean} showMembershipRegistration
 */
export function setShowMembershipRegistration(showMembershipRegistration) {
  return {
    type: 'SET_SHOW_MEMBERSHIP_REGISTRATION',
    showMembershipRegistration
  };
}

export function setIsShowingSlide(isShowingSlide) {
  return {
    type: 'SET_IS_SHOWING_SLIDE',
    isShowingSlide
  };
}

export function setIsShowingModal(isShowingModal) {
  return {
    type: 'SET_IS_SHOWING_MODAL',
    isShowingModal
  };
}

export function setLocale(locale) {
  return {
    type: 'SET_LOCALE',
    locale
  };
}

/**
 @method setMessages
 @param {Object} messages JSON Object of translations for application
 */
export function setMessages(messages) {
  return {
    type: 'SET_MESSAGES',
    messages
  };
}

export function setIntlFormatter(formatter) {
  return {
    type: 'SET_INTL_FORMATTER',
    formatter
  };
}

// setModalContentKey is used to set a value for identifying the modal content
// the App component should render. It expects a string in:
// ../utils/constants.js [ in the modals object ]
export function setModalContentKey(modalContentKey) {
  return {
    type: 'SET_MODAL_CONTENT_KEY',
    modalContentKey
  };
}

/**
 `GET /static/${partnerDirName}/i18n/${locale}.json`
 request and dispatches actions to set locale and messages for translations
 @method fetchLocale
 @param {String} locale language for app to be translated into
 */
export const fetchLocale = locale => (dispatch, getState) => {
  const partnerDirName = getState().app.getIn(['clientData', 'partnerDirName']);
  dispatch(setLocale(locale));
  return request(`/static/${partnerDirName}/i18n/${locale}.json`, { disableErrors: false })
    .then(response => {
      dispatch(setMessages(response.json));
    });
};

export function displayError(error) {
  return dispatch => {
    dispatch(setAppError(error));
    dispatch(push('/error'));
  };
}

export function fetchClientData() {
  return (dispatch, getStore) => new Promise(resolve => {
    if (window.location.pathname.includes('/maintenance')) {
      // if at maintenance page do not make any API requests
      return resolve({});
    }
    Settings.fetchClientData(getClientId(), getLocale()).then(data => {
      const wpSettings = Object.assign(getStore().app.get('clientData').toJS(), data);
      const mergedSettings = Object.assign(window.POINTS_CONFIG, wpSettings);
      dispatch(setClientSpecificData(mergedSettings));
      resolve(mergedSettings);
    }).catch(resolve);
  });
}

