import { addAuthTokenToHeaders, serializeQueryParams } from './utils/httpUtils';

export const fetchRedeemOffers = opts => {
  const options = addAuthTokenToHeaders();
  const query = {
    orderType: 'giftCard',
    ...opts
  };

  const url = `/v1/offer-sets?${serializeQueryParams(query)}`;
  return fetch(url, options)
    .then(res => res.json());
};

export const redeem = query => {
  const options = addAuthTokenToHeaders({
    method: 'post',
    body: JSON.stringify(query)
  });

  return fetch('/v1/orders/gift-card', options)
    .then(res =>  res.json().then(data => ({ status: res.status, body: data })));
};
export default { fetchRedeemOffers, redeem };
