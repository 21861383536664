import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';

import FancyButton from 'react-fancy-button';
import RadioButtonList from './RadioButtonList';

export default class MembershipSelectionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMembershipId: ''
    };
  }

  setSelectedMembershipId = selectedMembershipId => {
    this.setState({ selectedMembershipId });
  };

  getMembershipsAsOptions = lp => {
    const lpName = lp.get('name');
    const currencyNameShort = lp.getIn(['content', 'currencyNameShort']);

    const partnerMemberships = this.getPartnerMemberships();
    return partnerMemberships.map((membership, index) => {
      const memberId = membership.getIn(['credentials', 'identifyingFactors.memberId']);
      const balance = membership.getIn(['memberDetails', 'balance']);
      const option = fromJS({
        id: membership.get('id'),
        label: `${lpName} - ${memberId} (${balance} ${currencyNameShort})`,
        isDefault: index === 0
      });
      return option;
    });
  };

  getPartnerMemberships = () => {
    const { lp, memberships } = this.props;
    return memberships.filter(membership => membership.get('lpId') === lp.get('id'));
  };

  submitSelection = () => {
    const { onSubmit } = this.props;
    const { selectedMembershipId } = this.state;
    onSubmit(selectedMembershipId);
  };

  render() {
    const { lp, trigger, formatter } = this.props;
    return (
      <div>
        <form className='u-padding-top'>
          <RadioButtonList
            options={this.getMembershipsAsOptions(lp)}
            optionName='membership'
            onOptionSelection={this.setSelectedMembershipId}
          />
        </form>
        <div className='fix_earn_order__button'>
          <FancyButton
            classes='btn btn-primary fix_earn_order__btn'
            label={formatter.formatMessage({ id: 'fixEarnOrder.membershipSelection.actionButton' })}
            trigger={trigger}
            onClick={this.submitSelection}
          />
        </div>
      </div>
    );
  }
}

MembershipSelectionForm.propTypes = {
  lp: ImmutablePropTypes.map,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  onSubmit: PropTypes.func,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};
