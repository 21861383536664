import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { lyftRedirect } from '../action-creators/earn';

export class _EarnLyftIntegration extends React.Component {
  componentDidMount() {
    const { lyftRedirect } = this.props;
    lyftRedirect();
  }

  UNSAFE_componentWillReceiveProps() {
    const { lyftRedirect } = this.props;
    lyftRedirect();
  }

  render() {
    return null;
  }
}

_EarnLyftIntegration.propTypes = {
  lyftRedirect: PropTypes.func
};

function mapStateToProps(state) {
  return {
    promoRedirectUrl: state.earn.get('promoRedirectUrl')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    lyftRedirect
  }, dispatch);
}

export const EarnLyftIntegration = connect(
  mapStateToProps,
  mapDispatchToProps
)(_EarnLyftIntegration);
