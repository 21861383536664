/**
@class User Action Creator
*/

import User from '../api/user';
import { fetchMemberships } from './memberships';

/*-------------------------------
 *
 * Sync Actions
 *
 -------------------------------*/

/**
UI boolean to indicate if API request is complete
@method isFetchingUser
@param {Boolean} isFetchingUser
*/
export function isFetchingUser(isFetchingUser) {
  return {
    type: 'IS_FETCHING_USER',
    isFetchingUser
  };
}

/**
sets user on application state
@method setUser
@param {Map} user
*/
export function setUser(user) {
  return {
    type: 'SET_USER',
    user
  };
}

/**
sets email while linking account to send verification code to
@method setLinkAccountEmail
@param {String} email
*/
export function setLinkAccountEmail(email) {
  return {
    type: 'SET_LINK_ACCOUNT_EMAIL',
    email
  };
}

/*-------------------------------
 *
 * Async Actions
 *
 -------------------------------*/

/**
`GET /v1/user` gets user and sets on application state
@method fetchUser
@param {boolean} shouldRefreshMemberships
@param {boolean} shouldPollMemberships
*/
export function fetchUser(shouldRefreshMemberships, shouldPollMemberships) {
  return dispatch => {
    dispatch(isFetchingUser(true));
    return User.fetchUser().then(user => {
      dispatch(fetchMemberships(shouldRefreshMemberships, shouldPollMemberships));
      dispatch(setUser(user));
      dispatch(isFetchingUser(false));
    });
  };
}

