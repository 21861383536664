import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';

class BoxGroup extends React.Component {
  renderItem = item => (
    <div
      className='box-group__item u-padding u-1/1'
      key={`${item.get('icon')}-${item.get('header') ? item.get('header') : item.get('description')}`}
    >
      <div className='box-group__item__content'>
        <div className='box-group__item__content__heading'>
          <i
            className={`box-group__item__icon ${item.get('icon')}`}
            aria-hidden
          />
          { this.renderHeader(item) }
        </div>
        <p className='box-group__item__description'>
          <FormattedMessage id={item.get('description')} />
        </p>
        { this.renderLink(item) }
        { this.renderButton(item) }
      </div>
    </div>
  );

  renderButton = item => {
    if (!item.get('button') || !item.get('onButtonClick')) return null;
    const buttonHandler = item.get('onButtonClick');

    return (
      <button onClick={e => buttonHandler(e, item.get('button'))} className='btn btn-primary box-group__item__button'>
        <FormattedMessage id={item.get('button')} />
      </button>
    );
  };

  renderHeader = item => {
    if (!item.get('header')) return null;

    return (
      <div className='box-group__item__header text-size-big text-weight-7'>
        <FormattedMessage id={item.get('header')} />
      </div>
    );
  };

  renderLink = item => {
    if (!item.get('link') || !item.get('onLinkClick')) return null;

    return (
      <a
        onClick={item.get('onLinkClick')}
        className='text-size-small'
        href='#'
      >
        <FormattedMessage id={item.get('link')} />
      </a>
    );
  };
  render() {
    const { content, classes = '' } = this.props;
    if (!content) return null;

    return (
      <div className={`box-group ${classes} u-margin-vertical`}>
        <div className='section text-center'>
          <div className='flex flex-wrap-palm flex-justify-center'>
            { content.map(this.renderItem) }
          </div>
        </div>
      </div>
    );
  }
}

BoxGroup.propTypes = {
  content: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  classes: PropTypes.string
};

export default BoxGroup;
