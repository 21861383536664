
export const selectUserMemberValidation = function (state) {
  const clientLpId = state.app.getIn(['clientData', 'clientLpId']);
  const clientLps = state
    .memberships
    .get('data')
    .filter(membership => membership.get('lpId') === clientLpId && membership.get('isRegistered'));

  const mv = clientLps.getIn([0, 'memberDetails', 'links', 'memberValidation', 'href']);
  return mv;
};

export const selectMembershipByPartnerLpId = state => {
  const partnerLpId = state.app.get('partnerLpId');
  const membership = state
    .memberships
    .get('data')
    ?.find(membership => membership.get('lpId') === partnerLpId);
  return membership;
};
