import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage, IntlProvider } from 'react-intl';
import PageBanner from 'react-page-banner';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push, replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { selectLpById } from '../selectors/lps';
import {
  fetchLocale,
  setIsShowingModal,
  setModalContentKey,
  setPageMessage,
  setPartnerLpId,
  setProductType,
  setRequireRegistration
} from './../action-creators/app';
import { logOut, onLogin, postSSO, setLoginErrors } from './../action-creators/auth';
import { fetchAllEligibleLps, fetchLps, fetchPublicLps, setRemainingLps } from './../action-creators/lps';
import { fetchPendingEarnOrders } from './../action-creators/orders';

import { fetchUser } from './../action-creators/user';
import { fetchWps } from './../action-creators/wps';
import { getClientId, isProdEnvironment } from './../utils/application';
import constants from './../utils/constants';
import { isValidSession } from './../utils/jwt';
import { externalRedirect, getQueryParamsAsObject } from './../utils/url';
import AppModal from './AppModal';
import { ExchangeConfirmationModalContainer } from './ExchangeConfirmationModal';
import { ExchangeDisabledModalContainer } from './ExchangeDisabledModal';
import { ExchangeFromSelectModalContainer } from './ExchangeFromSelectModal';
import { ExchangeRulesModalContainer } from './ExchangeRulesModal';
import { ExchangeToSelectModalContainer } from './ExchangeToSelectModal';

import FocusTrap from './FocusTrap';
import HamburgerMenu from './HamburgerMenu';

import Sidebar from './Sidebar';
import Spinner from './Spinner';
import { getTabIndex, handleKeyPressEnter, shouldBeHiddenFromVoiceOver } from './utils/accessibility-helpers';
import { getLogoPath } from './utils/image-helpers';
import { isFeatureEnabled, isImmutable } from './utils/react-component-helpers';
import { VerificationCodeSentModalContainer } from './VerificationCodeSentModal';

export class App extends React.Component {
  state = {
    isSidebarOpen: false,
    refreshMemberships: true,
    pageBannerClose: 0,
    pageBannerOpen: 0,
    isHamburgerMenuOpen: true,
    screenWidth: null,
    lpsHaveBeenLoaded: false
  };

  UNSAFE_componentWillMount() {
    const queryParams = getQueryParamsAsObject();
    const {
      onLogin,
      postSSO,
      push,
      setLoginErrors,
      location,
      logOut,
      clientData
    } = this.props;
    const isMaintenance = location.pathname === '/maintenance';
    const anonymousUserRedirectUrl = clientData.get('anonymousUserRedirectUrl') || '/';

    this.setScreenWidth();
    window.addEventListener('resize', this.setScreenWidth);

    // must setProductType to fetchLocale at maintenance
    this.setPartnerLpId();
    this.setProductType();

    if (isMaintenance) {
      return;
    } else if (this.isLogoutPath(location)) {
      logOut();
      push(anonymousUserRedirectUrl);
    } else if (queryParams.mv) {
      postSSO(queryParams);
    } else if (isValidSession()) {
      onLogin(true);
    } else if (this.shouldRedirectToRoot()) {
      setLoginErrors(['noMv']);
      push('/');
    }

    const isEarn = location.pathname.includes(`/${constants.product.earn}`);
    // Don't refresh memberships when user is on Earn or still has a valid token
    // we must also use location since we do not yet have productType set
    if (isValidSession() || isEarn) {
      this.setState({ refreshMemberships: false });
    }
  }

  componentDidMount() {
    // On some browsers (Safari, Firefox), after a redirect, when the user clicks the back button
    // the browser's cache prevents the components lifecycle to run.
    const {
      googleTagManager,
      location
    } = this.props;
    googleTagManager.triggerPageView(location.pathname || '/', 'wallet.home');

    window.onpageshow = function reload(event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageMessage } = nextProps;
    const shouldShowPageBanner = !pageMessage.equals(this.props.pageMessage);
    if (pageMessage.size && shouldShowPageBanner) {
      this.setState({ pageBannerOpen: this.state.pageBannerOpen + 1 });
    } else if (!pageMessage.size && this.props.pageMessage.size) {
      this.setState({ pageBannerClose: this.state.pageBannerClose + 1 });
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setScreenWidth);
  };

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      clientData,
      fetchAllEligibleLps,
      fetchPendingEarnOrders,
      isLoggedIn,
      loginErrors,
      push,
      replace,
      setRemainingLps,
      setRequireRegistration,
      user,
      fetchPublicLps,
      location,
      logOut,
      wps,
      memberships
    } = this.props;

    const {
      product: {
        buy,
        redeem
      },
      publicLandingPageRegex
    } = constants;

    const isPublicLandingPage = publicLandingPageRegex.test(location.pathname);
    const isMaintenance = location.pathname.includes('/maintenance');
    const isBuy = location.pathname.includes(`/${buy}`);
    const isRedeem = location.pathname.includes(`/${redeem}`);
    const isTsAndCs = location.pathname.includes('/terms-and-conditions');
    const shouldFetchPublicLps = isPublicLandingPage || isBuy || isRedeem || isTsAndCs;
    const anonymousUserRedirectUrl = clientData.get('anonymousUserRedirectUrl') || '/';

    this.setProductType();

    if (!this.isLogoutPath(location) && this.isLogoutPath(nextProps.location)) {
      logOut();
      push(anonymousUserRedirectUrl);
    }

    if (isMaintenance) {
      return;
    }

    if (nextProps.loginErrors.size && !loginErrors.size) {
      return push('/error');
    }

    if (!isLoggedIn) {
      if (nextProps.isLoggedIn) {
        this.fetchDataAndRedirect(this.state.refreshMemberships);
      } else if (shouldFetchPublicLps && !nextProps.allLps.size && !nextProps.isFetchingLps) {
        fetchPublicLps(clientData.get('preferredLps'), getClientId());
      }
    }

    if ((nextProps.allLps.size && !nextProps.remainingLps.size) || (nextProps.memberships !== memberships)) {
      setRemainingLps(nextProps.memberships);
    }

    const willHaveUserAndWps = nextProps.user.size && nextProps.wps.size;
    const doesNotHaveUserOrWps = !user.size || !wps.size;
    const enabledFeatures = nextProps.clientData.get('enabledFeatures');

    if (nextProps.wps.size && !wps.size && isFeatureEnabled(constants.product.exchange, enabledFeatures)) {
      fetchAllEligibleLps(clientData.get('clientLpId'));
    }

    // When we are at the earnLandingPage we will not need to create account
    // or fetch pending orders since it is a serparate product.
    // I realize this next line is confusing, but the isEarnEnabled flag
    // is a different product altogether and is if Earn orders went through
    // our system (the BLW API)
    // *isEarnLandingPage & isEarnEnabled are mutually exclusive products*
    if (!this.isEarnLandingStyled() && willHaveUserAndWps && doesNotHaveUserOrWps) {
      const role = nextProps.user.get('role');
      if (isFeatureEnabled(constants.product.earn, enabledFeatures)) {
        if (role === constants.USER_ROLE_USER) {
          fetchPendingEarnOrders();
        } else if (role === constants.USER_ROLE_GUEST && nextProps.user.get('isRegistered')) {
          setRequireRegistration(true);
          replace('/wallet/create-account');
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { allLps } = this.props;

    if (!prevState.lpsHaveBeenLoaded && allLps.size !== prevProps.allLps.size) {
      this.setState({ lpsHaveBeenLoaded: true });
    }
  }
  setScreenWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  setPartnerLpId = () => {
    const {
      setPartnerLpId,
      fetchLocale,
      clientData
    } = this.props;
    const locale = clientData.get('locale');
    const partnerLpId = clientData.get('defaultLandingPageLpId');
    if (partnerLpId) {
      setPartnerLpId(partnerLpId);
    }
    fetchLocale(locale);
  };

  setProductType = () => {
    const {
      setProductType,
      productType,
      location
    } = this.props;
    const { pathname } = location;
    const {
      buy,
      earn,
      redeem
    } = constants.product;

    if (pathname.includes(`/${buy}`) && productType !== buy) {
      setProductType(buy);
    } else if ((pathname.includes(`/${earn}`) || pathname.includes(`/${redeem}`)) && productType !== earn) {
      setProductType(earn);
    }
  };

  isLogoutPath = location => {
    const { logOutPathRegex } = constants;
    return logOutPathRegex.test(location.pathname);
  };

  areLpsRequiredToRender = () => {
    const isMaintenance = window.location.pathname.includes('/maintenance');
    const isError = window.location.pathname.includes('/error');
    return !(isMaintenance || isError);
  };

  /**
   calls fetchMemberships and redirects to `/wallet`
   @method fetchDataAndRedirect
   @param {Boolean} shouldRefresh to send refresh param with membership request
   */
  fetchDataAndRedirect = shouldRefresh => {
    const {
      clientData,
      fetchUser,
      fetchLps,
      fetchWps,
      googleTagManager,
      location,
      replace,
      productType
    } = this.props;
    fetchUser(shouldRefresh, true);
    fetchLps(clientData.get('preferredLps'));
    if (productType !== constants.product.buy) {
      fetchWps();
    }
    if (location.pathname === '/') {
      replace('/wallet');
      googleTagManager.triggerPageView('/wallet', 'wallet.home');
    }
  };

  headerEl = null;
  headerLogoEl = null;

  isEarnLandingStyled = () => {
    const {
      location,
      productType,
      partnerLpId
    } = this.props;
    const isEarn = productType === constants.product.earn;
    const isErrorPage = location.pathname.includes('/error') && partnerLpId && !!partnerLpId.length;
    const isStartPage = location.pathname.includes('/start') || location.pathname.includes('/hiltonhonorslyft');
    const isRedeem = location.pathname.includes('/redeem-flow');
    return isEarn || isErrorPage || isStartPage || isRedeem;
  };

  pageBannerEl = null;
  pageEl = null;

  shouldRedirectToRoot = () => {
    const { location: { pathname } } = this.props;
    return pathname !== '/error'
      && pathname !== '/maintenance'
      && !pathname.includes('/terms-and-conditions')
      && !pathname.includes(`/${constants.product.earn}`);
  };

  toggleSidebar = () => {
    this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
  };

  trackTopNavClick = action => {
    this.props.googleTagManager.pushGtmData({
      action,
      context: 'topNav'
    });
  };

  renderLogo = () => {
    const {
      clientData,
      formatter
    } = this.props;

    if (!formatter) return;

    if (this.isEarnLandingStyled()) {
      // for earn landing page - does not have links and user details
      return this.renderLogoImages();
    }
    return (
      <a
        href={clientData.get('headerLogoLink')}
        className='header__logo__container'
      >
        {this.renderLogoImages()}
      </a>
    );
  };

  renderLogoImages = () => {
    const {
      formatter,
      clientData
    } = this.props;
    if (!formatter) return;
    const wpCode = clientData.get('wpCode');
    const wpName = clientData.get('name');

    if (clientData.get('shouldShowNavWithUserDetails')) {
      return (
        <div className='header__userDetailsNav__logosContainer'>
          {this.renderEarnLogoLp()}
          {this.renderEarnLogoLp() ? <div className='vl' /> : null}
          <img
            alt={formatter.formatMessage({ id: 'shared.partnerLogo' }, { lpName: wpName })}
            height={constants.headerLogoHeight}
            ref={el => this.headerLogoEl = el}
            src={getLogoPath(wpCode)}
            id='header__secondLogo'
          />
        </div>
      );
    }
    return (
      <div className='header__logo__container'>
        {this.renderEarnLogoLp()}
        <img
          alt={formatter.formatMessage({ id: 'shared.partnerLogo' }, { lpName: wpName })}
          height={constants.headerLogoHeight}
          ref={el => this.headerLogoEl = el}
          src={getLogoPath(wpCode)}
        />
      </div>
    );
  };

  renderUserDetailsBtn = () => {
    const { formatter, location, clientData } = this.props;
    const features = clientData.get('landingPageFeatures') || List();
    const includesEarnAndRedeem = features.includes('earn-2') && features.includes('redeem');
    let btnMsg;
    let btnRedirectionUrl;
    if (location.pathname.includes('/earn') && includesEarnAndRedeem) {
      btnMsg = formatter.formatMessage({ id: 'nav.userDetails.btn.redeem' });
      btnRedirectionUrl = constants.lyftRedirectUrls.redeemPage;
    } else if (location.pathname.includes('/redeem') && includesEarnAndRedeem) {
      btnMsg = formatter.formatMessage({ id: 'nav.userDetails.btn.earn' });
      btnRedirectionUrl = constants.lyftRedirectUrls.earnPage;
    } else {
      return null;
    }
    return (
      <button id='header__userDetailsNav__button' onClick={() => externalRedirect(btnRedirectionUrl)}>
        {btnMsg}
      </button>
    );
  };

  renderUserDetails = () => {
    const {
      clientData,
      user,
      memberships,
      formatter,
      getLpById
    } = this.props;
    const {
      isHamburgerMenuOpen,
      screenWidth
    } = this.state;

    if (!user.size || !memberships.size || !formatter) return null;

    const defaultLandingPageLpId = clientData.get('defaultLandingPageLpId');
    const firstName = user.get('firstName') || 'User';
    const defaultMembership = memberships.find(membership => membership.get('lpId') === defaultLandingPageLpId);
    const defaultLp = getLpById(defaultLandingPageLpId);
    const currencyName = defaultLp.getIn(['content', 'currencyNameLong']) || defaultLp.get(['content', 'currencyName']);
    const signOutMsg = formatter.formatMessage({ id: 'nav.userDetails.signOut' });
    const signOutRedirectionURL = constants.lyftRedirectUrls.signOut;
    let balance = defaultMembership.getIn(['memberDetails', 'balance'], 0);
    balance = balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (screenWidth <= 595) {
      return (
        <HamburgerMenu
          isOpen={isHamburgerMenuOpen}
          onStateChange={() => this.setState({ isHamburgerMenuOpen: !isHamburgerMenuOpen })}
          firstName={firstName}
          currencyName={currencyName}
          balance={balance}
          signOutMsg={signOutMsg}
          signOutRedirectionURL={signOutRedirectionURL}
          renderUserDetailsBtn={this.renderUserDetailsBtn}
          renderLogoImages={this.renderLogoImages}
        />
      );
    }
    return (
      <div className='header__userDetailsNav__userDetailsContainer'>
        <p id='header__user-details__name' className='fs-exclude'>{firstName}</p>
        <div className='vl' />
        <div className='header__userDetailsNav__balanceAndCurrencyContainer'>
          <p id='header__userDetailsNav__balance'>{balance}</p>
          <p id='header__userDetailsNav__currencyName'>{currencyName}</p>
        </div>
        <div className='vl' />
        <a
          href='#'
          onClick={() => externalRedirect(signOutRedirectionURL)}
          id='header__userDetailsNav__signOut'
        >
          {signOutMsg}
        </a>
        {this.renderUserDetailsBtn()}
      </div>
    );
  };

  renderModal = () => {
    const {
      modalContentKey,
      setModalContentKey
    } = this.props;

    function updateIsShowing(isShowing) {
      if (!isShowing) {
        setModalContentKey(null);
      }
    }

    const isShowingModal = !!modalContentKey && !!modalContentKey.length;

    let modalContent;

    const { modals } = constants;

    switch (modalContentKey) {
      case modals.exchangeDisabled:
        modalContent = <ExchangeDisabledModalContainer />;
        break;
      case modals.exchangeRules:
        modalContent = <ExchangeRulesModalContainer />;
        break;
      case modals.exchangeConfirmation:
        modalContent = <ExchangeConfirmationModalContainer />;
        break;
      case modals.exchangeFromSelect:
        modalContent = <ExchangeFromSelectModalContainer />;
        break;
      case modals.exchangeToSelect:
        modalContent = <ExchangeToSelectModalContainer />;
        break;
      case modals.createAccountVerificationCode:
        modalContent = <VerificationCodeSentModalContainer />;
        break;
    }

    return (
      <FocusTrap isActive={!!modalContentKey}>
        <AppModal
          updateIsShowing={updateIsShowing}
          showModal={isShowingModal}
          isShowingModal={isShowingModal}
          setIsShowingModal={this.props.setIsShowingModal}
        >
          {modalContent}
        </AppModal>
      </FocusTrap>
    );
  };

  renderNavLinks = () => {
    const {
      device,
      location,
      user,
      isShowingSlide,
      clientData
    } = this.props;
    const { pathname } = location;
    const linkClass = 'header__home-link';
    const displayLinksPalm = device === 'palm' && !clientData.get('disableHamburgerMenu');

    const walletClasses = classnames(
      'wallet-link', linkClass,
      { 'header__home-link--active': pathname === '/wallet' }
    );
    const exchangeClasses = classnames(
      'exchange-link', linkClass,
      { 'header__home-link--active': pathname === '/exchange/configure' }
    );
    const activityClasses = classnames(
      'activity-link', linkClass,
      { 'header__home-link--active': pathname === '/activity' }
    );
    const termsAndConditionsClasses = classnames(
      'termsAndConditions-link', linkClass,
      { 'header__home-link--active': pathname === '/terms-and-conditions' }
    );

    const isUserRole = user.get('role') === constants.USER_ROLE_USER;
    const tabIndexValue = getTabIndex(isShowingSlide);
    const ariaHiddenValue = shouldBeHiddenFromVoiceOver(tabIndexValue);
    const enabledFeatures = clientData.get('enabledFeatures');
    const isEarnLandingStyled = this.isEarnLandingStyled();

    if (isEarnLandingStyled) return;

    return (
      <ul
        className={classnames({
          'text-base-size-palm list-bare u-padding-horizontal u-margin-top-large': displayLinksPalm,
          'o-list-inline': !displayLinksPalm
        })}
        id='header__content--ul'
        role='menubar'
        onClick={this.toggleSidebar}
      >
        {
          isFeatureEnabled(constants.product.balanceTracker, enabledFeatures) ? (
            <li className={classnames(
              { 'u-bb': displayLinksPalm },
              {
                'header__content__top-nav-item': !displayLinksPalm
              }
            )}
            >
              <span onClick={() => {
                this.trackTopNavClick(constants.ANALYTICS_GOTO_BALANCE_TRACKER);
              }}
              >
                <Link
                  className={walletClasses}
                  tabIndex={tabIndexValue}
                  aria-hidden={ariaHiddenValue}
                  to='/wallet'
                >
                  <FormattedMessage id='topNavigation.wallet' />
                </Link>
              </span>
            </li>
          ) : null
        }
        {
          isFeatureEnabled(constants.product.exchange, enabledFeatures) ? (
            <li
              className={classnames({
                'u-bb': displayLinksPalm,
                'header__content__top-nav-item': !displayLinksPalm
              })}
              onClick={() => {
                this.trackTopNavClick(constants.ANALYTICS_GOTO_EXCHANGE);
              }}
            >
              <Link
                className={exchangeClasses}
                tabIndex={tabIndexValue}
                aria-hidden={ariaHiddenValue}
                to='/exchange/configure'
              >
                <FormattedMessage id='topNavigation.exchange' />
              </Link>
            </li>
          ) : null
        }
        {
          isFeatureEnabled(constants.product.activity, enabledFeatures) && isUserRole ? (
            <li
              className={classnames({
                'u-bb': displayLinksPalm,
                'header__content__top-nav-item': !displayLinksPalm
              })}
              onClick={() => {
                this.trackTopNavClick(constants.ANALYTICS_GOTO_ACTIVITY);
              }}
            >
              <Link
                className={activityClasses}
                aria-hidden={ariaHiddenValue}
                tabIndex={tabIndexValue}
                to='/activity'
              >
                <FormattedMessage id='topNavigation.activity' />
              </Link>
            </li>
          ) : null
        }
        {isFeatureEnabled(constants.featureFlag.navigationTermsAndConditions, enabledFeatures) &&
          <li
            className={classnames({
              'u-bb': displayLinksPalm,
              'header__content__top-nav-item': !displayLinksPalm
            })}
          >
            <Link
              className={termsAndConditionsClasses}
              aria-hidden={ariaHiddenValue}
              tabIndex={tabIndexValue}
              to='/terms-and-conditions'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FormattedMessage id='topNavigation.termsAndConditions' />
            </Link>
          </li>
        }
      </ul>
    );
  };

  renderPageBanner = () => {
    const {
      pageMessage,
      isShowingSlide,
      formatter,
      setPageMessage,
      clientData
    } = this.props;
    const {
      pageBannerClose,
      pageBannerOpen
    } = this.state;
    const pageBannerDuration = clientData.get('pageBannerDuration') ? clientData.get('pageBannerDuration') : 10000;
    const message = pageMessage.get('message');
    const afterClose = pageMessage.get('afterClose');
    if (!formatter) return;

    return (<PageBanner
      ref={el => this.pageBannerEl = el}
      closeIconClass='icon-close'
      ariaLiveMessage='assertive'
      roleMessage='alert'
      ariaLabelCloseIcon={formatter.formatMessage({ id: 'pageBanner.ariaLabelCloseIcon' })}
      duration={pageBannerDuration}
      triggerOpen={pageBannerOpen}
      triggerClose={pageBannerClose}
      type={pageMessage.get('type')}
      message={isImmutable(message) ? message.toJS() : message}
      tabIndexCloseIcon={() => getTabIndex(isShowingSlide)}
      onKeyUpCloseIcon={handleKeyPressEnter(() => this.setState({ pageBannerClose: pageBannerClose + 1 }))}
      afterClose={() => {
        setPageMessage();
        if (afterClose) afterClose();
      }}
    />);
  };

  renderWalletBody = () => {
    const {
      allLps,
      children,
      messages
    } = this.props;
    const lpsMissing = this.areLpsRequiredToRender() && !allLps.size;

    if (!messages.size || lpsMissing) {
      return (
        <Spinner />
      );
    }

    return children;
  };

  canShowHeader = () => {
    const {
      clientData,
      location: { pathname }
    } = this.props;
    return clientData.get('shouldShowTopNav') && !pathname.includes('terms-and-conditions');
  };

  renderHeaderWithImages = () => {
    const { clientData } = this.props;
    const shouldShowTopNavImages = clientData.get('shouldShowTopNavImages');
    const shouldShowTopNavArt = clientData.get('shouldShowTopNavArt');
    const wpName = clientData.get('wpName');
    const wpCode = clientData.get('wpCode');

    return (
      <div className='u-1/1'>
        {
          shouldShowTopNavArt ?
            <div className='header__content__top-nav-image absolute'>
              <img
                alt={`${wpName} logo`}
                height='80px'
                src={getLogoPath(wpCode)}
              />
            </div> : null
        }
        <div className='header__content flex  flex-align-center flex-space-between'>
          <div className='header__content__logo'>
            {shouldShowTopNavImages ? this.renderLogo() : null}
          </div>
          <div>
            {this.renderNavLinks()}
          </div>
          <div />
        </div>
      </div>
    );
  };

  renderHeaderPalm = () => (
    <div>
      <div className='o-layout'>
        <div className='o-layout__item u-1/8'>
          <i className='header__menu-icon icon-menu' onClick={this.toggleSidebar} />
        </div>
      </div>
      <Sidebar isOpen={this.state.isSidebarOpen} onClose={this.toggleSidebar}>
        {this.renderNavLinks()}
      </Sidebar>
    </div>
  );

  renderHeader = () => {
    const {
      clientData,
      device
    } = this.props;
    const isPalm = device === 'palm';
    const isEarnLandingStyled = this.isEarnLandingStyled();
    let body = this.renderHeaderPalm();

    if (clientData.get('shouldShowNavWithUserDetails')) {
      return (
        <div className='header__userDetailsNav__parentContainer'>
          <div className='header__userDetailsNav__subContainer' role='menubar'>
            {this.renderLogoImages()}
            {this.renderUserDetails()}
          </div>
        </div>
      );
    } else if (isEarnLandingStyled || !isPalm || clientData.get('disableHamburgerMenu')) {
      body = this.renderHeaderWithImages();
      return (
        <div
          ref={el => this.headerEl = el}
          className='header'
          id='points-loyalty-wallet-app-header'
        >
          {body}
        </div>
      );
    }
  };

  renderEarnLogoLp = () => {
    const {
      partnerLpId,
      formatter,
      getLpById
    } = this.props;
    const isEarnLandingStyled = this.isEarnLandingStyled();

    if (!isEarnLandingStyled || !partnerLpId) return;

    const partnerLp = getLpById(partnerLpId);
    if (!partnerLp) return;

    const partnerLpCode = partnerLp.getIn(['content', 'code']);

    return (
      <img
        alt={formatter.formatMessage(
          { id: 'shared.partnerLogo' },
          { lpName: `${partnerLp.getIn(['content', 'companyName'])} ${partnerLp.get('name')}` }
        )}
        height={constants.headerLogoHeight}
        src={getLogoPath(partnerLpCode)}
        id='header__firstLogo'
      />
    );
  };

  render() {
    const {
      clientData,
      locale,
      messages,
      isShowingModal
    } = this.props;
    const isEarnLandingStyled = this.isEarnLandingStyled();
    const tabIndexValue = getTabIndex(isShowingModal);
    const ariaHiddenValue = shouldBeHiddenFromVoiceOver(tabIndexValue);

    return (
      <IntlProvider locale={locale} textComponent='span' messages={messages.toJSON()}>
        <div>
          {this.renderModal()}
          <div
            className={classnames('page sticky-footer', { earn: isEarnLandingStyled })}
            ref={el => this.pageEl = el}
            aria-hidden={ariaHiddenValue}
            tabIndex={tabIndexValue}
          >
            <div className='sticky-footer__body'>

              {this.canShowHeader() && this.renderHeader()}

              { /* this id is used in index.js to tell the iframemanager when to emit a height change event  */}
              <div id='points-loyalty-wallet-app' className='body'>
                {this.renderPageBanner()}
                {this.renderWalletBody()}
              </div>
              {clientData.get('isIframed') || clientData.get('noPointsLogoFooter') ? null : <div className='sticky-footer__push' /> }
            </div>

            {
              clientData.get('isIframed') || clientData.get('noPointsLogoFooter') ? null : (
                <div className='footer sticky-footer__footer'>
                  <div className='text-center u-padding-top-large'>
                    <img
                      alt='Points logo'
                      className='footer__logo'
                      src={getLogoPath('points_powered_by_points')}
                    />
                    {
                      !isProdEnvironment() && (
                        <div className='ib u-margin-left text-size-small text-color-muted'>
                          {clientData.get('hash')}
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  messages: ImmutablePropTypes.map,
  googleTagManager: PropTypes.object,
  clientData: ImmutablePropTypes.map,
  device: PropTypes.string,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  locale: PropTypes.string,
  isShowingSlide: PropTypes.bool,
  isShowingModal: PropTypes.bool,
  pageMessage: ImmutablePropTypes.map,
  isLoggedIn: PropTypes.bool,
  loginErrors: ImmutablePropTypes.list,
  logOut: PropTypes.func,
  user: ImmutablePropTypes.map,
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  isFetchingLps: PropTypes.bool,
  wps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  remainingLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  memberships: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.listOf(ImmutablePropTypes.map)
  ]),
  productType: PropTypes.string,
  partnerLpId: PropTypes.string,
  modalContentKey: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchLps: PropTypes.func,
  fetchWps: PropTypes.func,
  onLogin: PropTypes.func,
  postSSO: PropTypes.func,
  fetchLocale: PropTypes.func,
  fetchPendingEarnOrders: PropTypes.func,
  setLoginErrors: PropTypes.func,
  setRemainingLps: PropTypes.func,
  setRequireRegistration: PropTypes.func,
  fetchAllEligibleLps: PropTypes.func,
  setPageMessage: PropTypes.func,
  setProductType: PropTypes.func,
  setPartnerLpId: PropTypes.func,
  fetchPublicLps: PropTypes.func,
  getLpById: PropTypes.func,
  setModalContentKey: PropTypes.func,
  setIsShowingModal: PropTypes.func
};

function mapStateToProps(state) {
  return {
    allLps: state.lps.get('allLps'),
    clientData: state.app.get('clientData'),
    device: state.app.get('device'),
    formatter: state.app.get('formatter'),
    getLpById: selectLpById(state),
    googleTagManager: state.app.get('googleTagManager'),
    isFetchingLps: state.lps.get('isFetchingLps'),
    isLoggedIn: state.auth.get('isLoggedIn'),
    isShowingModal: state.app.get('isShowingModal'),
    isShowingSlide: state.app.get('isShowingSlide'),
    locale: state.app.get('locale'),
    loginErrors: state.auth.get('loginErrors'),
    memberships: state.memberships.get('data'),
    messages: state.app.get('messages'),
    modalContentKey: state.app.get('modalContentKey'),
    pageMessage: state.app.get('pageMessage'),
    partnerLpId: state.app.get('partnerLpId'),
    productType: state.app.get('productType'),
    remainingLps: state.lps.get('remainingLps'),
    user: state.user.get('data'),
    wps: state.wps.get('allWps')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    push,
    replace,
    fetchUser,
    fetchLps,
    fetchWps,
    logOut,
    onLogin,
    postSSO,
    fetchLocale,
    fetchPendingEarnOrders,
    setLoginErrors,
    setRemainingLps,
    setRequireRegistration,
    fetchAllEligibleLps,
    setPageMessage,
    setProductType,
    setPartnerLpId,
    fetchPublicLps,
    setModalContentKey,
    setIsShowingModal
  }, dispatch);
}

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
