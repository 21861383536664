/**
@class Wps Action Creator
*/
import Wps from '../api/wps';

/*-------------------------------
 *
 * Sync Actions
 *
 -------------------------------*/

export function setWps(allWps, wpId) {
  return {
    type: 'SET_ALL_WPS',
    allWps,
    wpId
  };
}


/*-------------------------------
 *
 * Async Actions
 *
 -------------------------------*/

export function fetchWps() {
  return (dispatch, getState) => {
    const wpId = getState().app.getIn(['clientData', 'wpId']);
    return Wps.fetchWps()
      .then(wps => {
        dispatch(setWps(wps.walletPartners, wpId));
      });
  };
}
