import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getQueryParamsAsObject } from '../utils/url';

import { linkLyftAccount } from '../action-creators/earn';

export const EarnLyftIntegrationRedirect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = getQueryParamsAsObject();
    const code = queryParams.code;
    const [selectedLpId] = queryParams.state ? queryParams.state.split('|') : ['', 'en-US'];

    dispatch(linkLyftAccount(code, selectedLpId));
  }, [dispatch]);

  return null;
};
