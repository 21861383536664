import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const ExchangeSummary = ({ amount, lp, membership }) => (
  <div className='exchange-summary'>
    <span className='text-weight-8 u-padding-left-small text-header-3'>
      <FormattedMessage id='exchange.summaryHeader' />
    </span>
    <ul className='list-block list-block--small'>
      <li className='text-color-muted u-bb'>
        <div className='o-layout o-layout--small'>
          <div className='u-padding-left-none o-layout__item u-9/12'>
            <FormattedMessage id='exchange.summaryCurrentBalance' values={{ lpName: lp.getIn(['content', 'lpName']) }} />
          </div>
          <div className='o-layout__item u-3/12'>
            <FormattedNumber value={membership.getIn(['memberDetails', 'balance'])} />
          </div>
        </div>
      </li>
      <li>
        <div className='o-layout o-layout--small'>
          <div className='u-padding-left-none o-layout__item u-9/12'>
            <FormattedMessage id='exchange.summaryNewBalance' values={{ lpName: lp.getIn(['content', 'lpName']) }} />
          </div>
          <div className='o-layout__item u-3/12'>
            <FormattedNumber value={membership.getIn(['memberDetails', 'balance']) + amount} />
          </div>
        </div>
      </li>
    </ul>
  </div>
);

ExchangeSummary.propTypes = {
  amount: PropTypes.number.isRequired,
  lp: ImmutablePropTypes.map.isRequired,
  membership: ImmutablePropTypes.map.isRequired
};

export default ExchangeSummary;
