import classnames from 'classnames';
import { List, Map } from 'immutable';

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { FormattedMessage } from 'react-intl';
import constants from '../utils/constants';
import FancyButton from './FancyButton';

import { JoinNowLink } from './JoinNowLink';
import { TextInput } from './TextInput';
import { getLogoPath } from './utils/image-helpers';

class MembershipRegistration extends React.Component {
  registerMembershipBtnEl = null;
  removeLpLinkEl = null;

  constructor(props, context) {
    super(props, context);
    const credentials = this.props.membership.get('credentials');
    const updatedCredentials = credentials.set('identifyingFactors.memberId', '');

    this.state = {
      fadeIn: false,
      credentials: updatedCredentials.toJSON(),
      isRegisteringAccount: false,
      triggerValidation: 0,
      registrationFadeOutTimer: setTimeout(() => {
        if (props.fadeIn) {
          this.setState({ fadeIn: true });
        }
      })
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      closeMembershipRegistration,
      hasUpdatedMemberships,
      hasDeleteMembershipError,
      membership: oldMembership
    } = this.props;
    const updatedMembership = nextProps.membership;
    const isRegistered = updatedMembership?.get('isRegistered');
    const wasRegistered = oldMembership?.get('isRegistered');
    const updatedMembershipErrors = updatedMembership.get('memberDetailsErrors', List());

    const shouldCloseMembershipRegistration =
      (isRegistered && !hasUpdatedMemberships && nextProps.hasUpdatedMemberships && !hasDeleteMembershipError) ||
      (!updatedMembership.size &&
        !oldMembership.size &&
        nextProps.hasInitializedMemberships &&
        nextProps.hasUpdatedMemberships &&
        !hasDeleteMembershipError);

    if (shouldCloseMembershipRegistration) {
      this.setState({ isRegisteringAccount: false });
      closeMembershipRegistration();
    }

    if (!wasRegistered && !nextProps.isUpdatingMemberships && updatedMembershipErrors.size) {
      this.setState({ isRegisteringAccount: false });
    }
  }

  componentWillUnmount() {
    const { registrationFadeOutTimer } = this.state;
    const { toggleDeleteMembershipModal } = this.props;

    if (registrationFadeOutTimer) {
      clearTimeout(registrationFadeOutTimer);
    }

    if (toggleDeleteMembershipModal) {
      toggleDeleteMembershipModal(false);
    }
  }

  isValidCredentials = () => {
    const lp = this.getLp();
    const { credentials } = this.state;
    const schema = lp.get('schema');
    const validatedFields = schema.map(field => {
      const name = field.get('name');
      const isValid = this.validateField(credentials[name], name);
      return typeof isValid !== 'string';
    });
    const isValid = !validatedFields.contains(false);
    return isValid;
  };

  getLp = () => {
    const {
      allLps,
      membership
    } = this.props;
    return allLps.find(lp => lp.get('id') === membership.get('lpId')) || Map();
  };

  setCredsVal = (value, name) => {
    const { credentials } = this.state;
    credentials[name] = value;
    this.setState({ credentials });
  };

  _triggerValidation = () => {
    const { triggerValidation } = this.state;
    this.setState({ triggerValidation: triggerValidation + 1 });
  };

  submitRegistrationForm = e => {
    e.preventDefault();
    const {
      registerMembership,
      membership
    } = this.props;
    this._triggerValidation();
    if (this.isValidCredentials()) {
      const { credentials } = this.state;
      const membershipWithCreds = Object.assign({}, membership.toJSON(), { credentials });
      this.setState({ isRegisteringAccount: true });

      registerMembership(membershipWithCreds);
    }
  };

  validateField = (value = '', name = '') => {
    const lp = this.getLp();
    const field = lp.get('schema')
      .find(field => field.get('name') === name);
    const required = field.get('required');
    const maxLength = field.get('maxLength');
    const minLength = field.get('minLength');

    if (required && !value.length) {
      return 'this field is required';
    }

    const shouldValidate = required || value.length;
    if (!shouldValidate) {
      return null;
    }
    const minLengthRequired = minLength && value.length < minLength;
    if (minLengthRequired) {
      return `Minimum size is ${minLength}`;
    }

    const maxLengthRequired = maxLength && value.length > maxLength;
    if (maxLengthRequired) {
      return `Maximum size is ${maxLength}`;
    }
  };

  getValueForField = name => {
    const { credentials } = this.state;
    const value = credentials[name] || '';

    return value;
  };

  renderSchema = (schema, membership) => {
    if (schema.isEmpty() || membership.isEmpty()) {
      return null;
    }

    const schemaFields = schema.map(field => {
      const name = field.get('name');
      const label = field.get('label');
      const fieldValue = this.getValueForField(name);
      const placeHolder = this.getPlaceHolder(name, label);
      const isMemberId = name === 'identifyingFactors.memberId';
      const shouldHideLabel = isMemberId && !fieldValue && !placeHolder || !fieldValue && !isMemberId;

      return (
        <TextInput
          key={name}
          name={name}
          type={field.get('type')}
          triggerValidation={this.state.triggerValidation}
          label={label}
          placeholder={placeHolder}
          validator={this.validateField}
          value={fieldValue}
          onChange={this.setCredsVal}
          hideLabel={shouldHideLabel}
        />
      );
    });

    return schemaFields;
  };

  getPlaceHolder = (fieldName, label) => {
    let result = label;
    if (fieldName === 'identifyingFactors.memberId') {
      result = this.props.membership.getIn(['credentials', 'identifyingFactors.memberId']);
    }

    return result;
  };

  renderRegistrationContent = (
    membership,
    formatter,
    lp,
    isPolling,
    membershipErrors,
    schema,
    isRegistered,
    isClientLpId,
    toggleDeleteMembershipModal
  ) => {
    const mode = isRegistered ? constants.EDIT_LINKED_MEMBERSHIP_MODE : constants.LINK_MEMBERSHIP_MODE;
    const signUpUrl = lp.getIn(['content', 'websiteUrl']);

    return (
      <div className={classnames(
        'membership-registration transition--base u-padding-horizontal-tiny u-padding-horizontal@md u-padding-top-large u-margin-bottom',
        {
          'opacity-0': this.props.fadeIn,
          'opacity-10': this.state.fadeIn,
          'membership-registration__opacity': this.state.isRegisteringAccount
        }
      )}
      >
        <div className='o-layout o-layout--center'>
          <div className='o-layout__item u-2/3@md u-1/1'>
            {/* http://stackoverflow.com/questions/15738259/disabling-chrome-autofill */}
            <form onSubmit={this.submitRegistrationForm} autoComplete='new-password'>
              <div className='o-layout o-layout--center'>
                <div className='o-layout__item u-1/2'>
                  <img
                    alt={`${lp.get('name')} logo`}
                    className='u-1/1'
                    src={getLogoPath(lp.getIn(['content', 'code']))}
                  />
                </div>
              </div>
              <h2 className='u-padding-vertical rule'>
                <span className='slide__header__span'>
                  {formatter.formatMessage({ id: `membershipRegistration.${mode}.registerHeader` })}
                </span>
              </h2>
              <div className='text-center text-size-normal'>
                <FormattedMessage id={`membershipRegistration.${mode}.instructionsSubHeader`} />
              </div>
              <div className='card'>
                {
                  membershipErrors.size && !isPolling ? (
                    <div className='message message--error'>
                      {
                        membershipErrors.map(error => {
                          const defaultMessage = formatter.formatMessage({ id: 'membershipRegistration.defaultError' });
                          return (
                            <div
                              key={error}
                              className='u-margin-vertical-none'
                              aria-live='assertive'
                              role='alert'
                              aria-label={formatter.formatMessage({ id: `membershipRegistration.${error}`, defaultMessage })}
                            >
                              <FormattedMessage id={`membershipRegistration.${error}`} defaultMessage={defaultMessage} />
                            </div>
                          );
                        })
                      }
                    </div>
                  ) : null
                }
                <div className='u-padding-tiny u-padding@md'>
                  {this.renderSchema(schema, membership)}
                </div>
              </div>
            </form>
            <div className='u-padding-horizontal'>
              <FancyButton
                ref={el => this.registerMembershipBtnEl = el}
                classes='btn btn-primary u-1/1 btn-large-padding'
                type='submit'
                label={`membershipRegistration.${mode}.registerButton`}
                trigger={this.state.isRegisteringAccount}
                onClick={this.submitRegistrationForm}
              />

              {signUpUrl &&
                <JoinNowLink signupUrl={signUpUrl} />
              }

              {!isClientLpId && toggleDeleteMembershipModal && isRegistered &&
                <div
                  className='membership-registration__delete_program u-margin-top text-center text-size-normal'
                  onClick={e => {
                    e.stopPropagation();
                    toggleDeleteMembershipModal(true);
                  }}
                  ref={el => this.removeLpLinkEl = el}
                >
                  <a href='#'>
                    <FormattedMessage
                      id={`membershipcard.${mode}.deleteLinkLabel`}
                    />
                  </a>
                </div>
              }
              <div className='text-center  u-margin-top-large u-padding-vertical'>
                <img alt='Points logo' className='u-1/4' src={getLogoPath('points_powered_by_points')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  isDevMode = () => process.env.ENV === 'development';

  render() {
    const {
      clientData,
      formatter,
      membership,
      toggleDeleteMembershipModal
    } = this.props;

    const lp = this.getLp();
    const isPolling = membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    const membershipErrors = membership.get('memberDetailsErrors', List());
    const schema = lp.get('schema') || List();
    const isRegistered = membership.get('isRegistered', false);

    const isClientLpId = membership.get('lpId') === clientData.get('clientLpId') || false;
    let workingMembership = membership;

    if (this.isDevMode()) {
      const creds = membership.get('credentials')
        .set('identifyingFactors.memberId', lp.get('id'));
      workingMembership = membership.set('credentials', creds);
    }
    return this.renderRegistrationContent(
      workingMembership,
      formatter,
      lp,
      isPolling,
      membershipErrors,
      schema,
      isRegistered,
      isClientLpId,
      toggleDeleteMembershipModal
    );
  }
}

MembershipRegistration.propTypes = {
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  clientData: ImmutablePropTypes.map,
  closeMembershipRegistration: PropTypes.func.isRequired,
  fadeIn: PropTypes.bool,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  hasDeleteMembershipError: PropTypes.bool,
  hasInitializedMemberships: PropTypes.bool.isRequired,
  hasUpdatedMemberships: PropTypes.bool.isRequired,
  isUpdatingMemberships: PropTypes.bool.isRequired,
  membership: ImmutablePropTypes.map,
  registerMembership: PropTypes.func.isRequired,
  toggleDeleteMembershipModal: PropTypes.func
};

MembershipRegistration.defaultProps = {
  fadeIn: false,
  hasDeleteMembershipError: false,
  toggleDeleteMembershipModal: null,
  membership: Map()
};

export default MembershipRegistration;
