/**
@class Wps API
*/
import { request } from './utils/httpUtils';

/**
`GET /v1/wps`
@method fetchWps
*/
export function fetchWps() {
  return request('/v1/wps')
    .then(data => data.json);
}

export default {
  fetchWps
};
