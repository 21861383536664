import { Map, List, fromJS } from 'immutable';

const initialState = fromJS({
  fromAmount: 0,
  fromMembershipId: '',
  toAmount: 0,
  toMembershipId: '',
  amountIndex: 0,
  amounts: [],
  exchangeContext: null,
  offerSet: {},
  selectedOffer: {},
  isFetchingOfferSet: false,
  isSubmittingExchange: false,
  order: {},
  exchangeError: {},
  selectedExchange: Map(),
  shouldResetExchange: false
});

let amounts;
let amountIndex;
let selectedAmount;
let selectedOffer;
let fromAmount;
let toAmount;

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_EXCHANGE_CONTEXT':
      return state.set('exchangeContext', action.context);

    case 'SET_EXCHANGE_FROM_MEMBERSHIP':
      return state.set('fromMembershipId', action.membershipId || '');

    case 'SET_EXCHANGE_TO_MEMBERSHIP':
      return state.set('toMembershipId', action.membershipId || '');

    case 'SET_SELECTED_OFFER':
      selectedOffer = action.selectedOffer;
      amounts = selectedOffer.get('amounts');
      amountIndex = Math.floor(amounts.size / 2);
      selectedAmount = amounts.get(amountIndex);
      fromAmount = selectedAmount.get('fromAmount');
      toAmount = selectedAmount.get('toAmount');
      return state.merge({ fromAmount, toAmount, amounts, amountIndex, selectedOffer });

    case 'SET_SELECTED_AMOUNT':
      amounts = state.get('amounts');
      amountIndex = action.amountIndex;
      selectedAmount = amounts.get(amountIndex);
      fromAmount = selectedAmount.get('fromAmount');
      toAmount = selectedAmount.get('toAmount');
      return state.merge({ fromAmount, toAmount, amountIndex });

    case 'SET_OFFER_SET':
      return state.set('offerSet', fromJS(action.offerSet));

    case 'RESET_AMOUNTS':
      return state.merge({ fromAmount: 0, toAmount: 0, amountIndex: 0, amounts: List() });

    case 'RESET_EXCHANGE':
      return initialState;

    case 'RESET_OFFER_SET':
      return state.merge({ offerSet: Map(), selectedOffer: Map() });

    case 'SET_ORDER':
      return state.set('order', fromJS(action.order));

    case 'SET_EXCHANGE_ERROR':
      return state.set('exchangeError', fromJS(action.error));

    case 'RESET_EXCHANGE_ERROR':
      return state.set('exchangeError', Map());

    case 'IS_FETCHING_OFFER_SET':
      return state.set('isFetchingOfferSet', action.isFetchingOfferSet);

    case 'IS_SUBMITTING_EXCHANGE':
      return state.set('isSubmittingExchange', action.isSubmittingExchange);

    case 'SET_SELECTED_EXCHANGE':
      return state.set('selectedExchange', action.selectedExchange);

    case 'SET_SHOULD_RESET_EXCHANGE':
      return state.set('shouldResetExchange', action.shouldResetExchange);

    default:
      return state;
  }
}
