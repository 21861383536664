import { fromJS, Map } from 'immutable';
import urlUtils from '../utils/url';

import constants from '../../src/utils/constants';

import Auth from '../api/auth';
import Lps from '../api/lps';
import Memberships from '../api/memberships';
import Orders from '../api/orders';
import { isValidSession } from '../utils/jwt';
import { setIsShowingSlide } from './app';

import { onLogin, setIsSingleSigningOn } from './auth';

export function setError(error) {
  return {
    type: 'SET_FIX_EARN_ORDER_ERROR',
    error
  };
}

export function setIsLoadingData(isLoadingData) {
  return {
    type: 'SET_FIX_EARN_ORDER_IS_LOADING_DATA',
    isLoadingData
  };
}

export function setOrder(order) {
  return {
    type: 'SET_FIX_EARN_ORDER_ORDER',
    order
  };
}

export function setLp(lp) {
  return {
    type: 'SET_FIX_EARN_ORDER_LP',
    lp
  };
}

export function setMembership(membership) {
  return {
    type: 'SET_FIX_EARN_ORDER_MEMBERSHIP',
    membership
  };
}

export function setPotentialMemberships(potentialMemberships) {
  return {
    type: 'SET_FIX_EARN_ORDER_POTENTIAL_MEMBERSHIPS',
    potentialMemberships
  };
}

export function setShouldSelectMembership(shouldSelectMembership) {
  return {
    type: 'SET_FIX_EARN_ORDER_SHOULD_SELECT_MEMBERSHIP',
    shouldSelectMembership
  };
}

export function setSlideStatus(slideStatus) {
  return {
    type: 'SET_FIX_EARN_ORDER_SLIDE_STATUS',
    slideStatus
  };
}

export function setIsAttemptingRegistration(isAttemptingRegistration) {
  return {
    type: 'SET_FIX_EARN_ORDER_IS_ATTEMPTING_REGISTRATION',
    isAttemptingRegistration
  };
}

export function activateSlide() {
  return dispatch => {
    dispatch(setSlideStatus(1));
    dispatch(setIsShowingSlide(true));
  };
}

export function deactivateSlide() {
  return dispatch => {
    dispatch(setSlideStatus(0));
    dispatch(setIsShowingSlide(false));
  };
}

export function makeRegistrationAttempt() {
  return dispatch => {
    dispatch(setIsAttemptingRegistration(true));
  };
}

export function login(mv, verifyEmailToken, clientId) {
  return dispatch => {
    if (mv) {
      dispatch(setIsSingleSigningOn(true));
      return Auth.postSSO(clientId, mv, verifyEmailToken)
        .then(() => {
          dispatch(onLogin(true));
          dispatch(setIsSingleSigningOn(false));
        }, () => {
          // error
          dispatch(setError(constants.fixEarnOrderErrors.invalidSession));
          dispatch(setIsSingleSigningOn(false));
        });
    } else if (isValidSession()) {
      dispatch(onLogin(true));
    } else {
      // error
      dispatch(setError(constants.fixEarnOrderErrors.invalidSession));
    }
  };
}

export function fetchWpLpConfig(order) {
  const wpId = order.wpId;
  const lpId = order.to.lpId;
  const membershipId = order.to.membershipId;

  return dispatch => Memberships.fetchOneWpLpConfig(wpId, lpId)
    .then(res => {
      const enabledConfig = res.config.credentialCatcher.enabled;
      if (enabledConfig === 'inline') {
        dispatch(fetchInitialMemberships(order));
      } else {
        dispatch(redirectUserToRegistrationPage(membershipId));
      }
    })
    .catch(() => {
      dispatch(fetchInitialMemberships(order));
    });
}

// Check the lp of the order is permissioned and, if so, dispatch the next action creator to get the wp-lp config
export function checkLpIsPermissioned(order) {
  const lpId = order.to.lpId;
  return dispatch => Lps.fetchLps()
    .then(lps => {
      const lp = lps.find(lp => lp.id === lpId);
      if (lp) {
        dispatch(setLp(lp));
        dispatch(fetchWpLpConfig(order));
      } else {
        dispatch(setError(constants.fixEarnOrderErrors.lpNotFound));
        dispatch(setIsLoadingData(false));
      }
    })
    .catch(() => {
      dispatch(setError(constants.fixEarnOrderErrors.lpNotFound));
      dispatch(setIsLoadingData(false));
    });
}

// Get the specified order, then dispatch the next action creator to check the lp of the order
export function loadData(orderId) {
  return dispatch => {
    if (orderId) {
      dispatch(setIsLoadingData(true));
      dispatch(setError(''));
      return Orders.fetchOrder(orderId)
        .then(order => {
          dispatch(setOrder(order));
          dispatch(checkLpIsPermissioned(order));
        })
        .catch(() => {
          dispatch(setError(constants.fixEarnOrderErrors.orderNotFound));
          dispatch(setIsLoadingData(false));
        });
    }
    dispatch(setError(constants.fixEarnOrderErrors.requireOrderId));
  };
}

export function setTriggeredEarnMembershipSelection(triggeredEarnMembershipSelection) {
  return {
    type: 'SET_FIX_EARN_ORDER_TRIGGERED_EARN_MEMBERSHIP_SELECTION',
    triggeredEarnMembershipSelection
  };
}

export function setTriggeredPendingMembershipInstructions(triggeredPendingMembershipInstructions) {
  return {
    type: 'SET_FIX_EARN_ORDER_TRIGGERED_PENDING_MEMBERSHIP_INSTRUCTIONS',
    triggeredPendingMembershipInstructions
  };
}

export function setTriggeredMembershipRegistrationForm(triggeredMembershipRegistrationForm) {
  return {
    type: 'SET_FIX_EARN_ORDER_TRIGGERED_MEMBERSHIP_REGISTRATION_FORM',
    triggeredMembershipRegistrationForm
  };
}

export function setTriggeredMembershipRegistrationAttempt(triggeredMembershipRegistrationAttempt) {
  return {
    type: 'SET_FIX_EARN_ORDER_TRIGGERED_MEMBERSHIP_REGISTRATION_ATTEMPT',
    triggeredMembershipRegistrationAttempt
  };
}

export function setTriggeredMembershipRegistrationComplete(triggeredMembershipRegistrationComplete) {
  return {
    type: 'SET_FIX_EARN_ORDER_TRIGGERED_MEMBERSHIP_REGISTRATION_COMPLETE',
    triggeredMembershipRegistrationComplete
  };
}

export function triggerAnalyticsEarnMembershipSelection() {
  return (dispatch, getState) => {
    const gtm = getState().app.get('googleTagManager');
    const lp = getState().fixEarnOrder.get('lp');
    const alreadyTriggered = getState().fixEarnOrder.get('triggeredEarnMembershipSelection');

    if (alreadyTriggered) {
      return;
    }

    gtm.pushGtmData({
      action: 'earnMembershipSelection',
      context: 'fixEarnOrder',
      lpName: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      lpId: lp.get('id'),
      event: 'NewEarnFlowVirtualPageview',
      virtualPageURL: '/fix-earn-order/membership/arbitrary',
      virtualPageTitle: 'fix-earn-order.membership.arbitrary'
    });

    dispatch(setTriggeredEarnMembershipSelection(true));
  };
}

export function triggerAnalyticsPendingMembershipInstructions() {
  return (dispatch, getState) => {
    const gtm = getState().app.get('googleTagManager');
    const lp = getState().fixEarnOrder.get('lp');
    const alreadyTriggered = getState().fixEarnOrder.get('triggeredPendingMembershipInstructions');

    if (alreadyTriggered) {
      return;
    }

    gtm.pushGtmData({
      action: 'pendingMembershipInstructions',
      context: 'fixEarnOrder',
      lpName: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      lpId: lp.get('id'),
      event: 'NewEarnFlowVirtualPageview',
      virtualPageURL: '/fix-earn-order/membership/pending',
      virtualPageTitle: 'fix-earn-order.membership.pending'
    });

    dispatch(setTriggeredPendingMembershipInstructions(true));
  };
}

export function triggerAnalyticsMembershipRegistrationForm() {
  return (dispatch, getState) => {
    const gtm = getState().app.get('googleTagManager');
    const lp = getState().fixEarnOrder.get('lp');
    const alreadyTriggered = getState().fixEarnOrder.get('triggeredMembershipRegistrationForm');

    if (alreadyTriggered) {
      return;
    }

    gtm.pushGtmData({
      action: 'membershipRegistrationForm',
      context: 'fixEarnOrder',
      lpName: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      lpId: lp.get('id'),
      event: 'NewEarnFlowVirtualPageview',
      virtualPageURL: '/fix-earn-order/membership/show-form',
      virtualPageTitle: 'fix-earn-order.membership.show-form'
    });

    dispatch(setTriggeredMembershipRegistrationForm(true));
  };
}

function triggerAnalyticsMembershipRegistrationAttempt() {
  return (dispatch, getState) => {
    const gtm = getState().app.get('googleTagManager');
    const lp = getState().fixEarnOrder.get('lp');
    const alreadyTriggered = getState().fixEarnOrder.get('triggeredMembershipRegistrationAttempt');

    if (alreadyTriggered) {
      return;
    }

    gtm.pushGtmData({
      action: 'membershipRegistrationAttempt',
      context: 'fixEarnOrder',
      lpName: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      lpId: lp.get('id'),
      event: 'NewEarnFlowVirtualPageview',
      virtualPageURL: '/fix-earn-order/membership/register-attempt',
      virtualPageTitle: 'fix-earn-order.membership.register-attempt'
    });

    dispatch(setTriggeredMembershipRegistrationAttempt(true));
  };
}

function triggerAnalyticsMembershipRegistrationComplete() {
  return (dispatch, getState) => {
    const gtm = getState().app.get('googleTagManager');
    const lp = getState().fixEarnOrder.get('lp');
    const alreadyTriggered = getState().fixEarnOrder.get('triggeredMembershipRegistrationComplete');

    if (alreadyTriggered) {
      return;
    }

    gtm.pushGtmData({
      action: 'membershipRegistrationComplete',
      context: 'fixEarnOrder',
      lpName: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      lpId: lp.get('id'),
      event: 'NewEarnFlowVirtualPageview',
      virtualPageURL: '/fix-earn-order/membership/registered',
      virtualPageTitle: 'fix-earn-order.membership.registered'
    });

    dispatch(setTriggeredMembershipRegistrationComplete(true));
  };
}

export function triggerAnalyticsForgotInfo(lp) {
  return (dispatch, getState) => {
    const gtm = getState().app.get('googleTagManager');
    const lpId = lp.get('id');
    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpName = lp.get('name');
    const lpURL = lp.getIn(['content', 'websiteUrl']);

    gtm.pushGtmData({
      action: 'forgotInfo',
      context: 'fixEarnOrder',
      lpName: `${lpCompanyName} ${lpName}`,
      lpId,
      event: 'NewEarnFlowVirtualPageview',
      virtualPageURL: '/fix-earn-order/membership/forgot-info',
      virtualPageTitle: 'fix-earn-order.membership.forgot-info',
      lpRedirectURL: lpURL
    });

    window.open(lpURL, '_blank');
  };
}

export function fetchInitialMemberships(order) {
  const lpId = order.to.lpId;
  const membershipId = order.to.membershipId;
  const membershipPickedArbitrarily = order.to.pickedArbitrarily;
  return dispatch => Memberships.fetchMemberships(false)
    .then(memberships => {
      const potentialMemberships = memberships.filter(membership => membership.lpId === lpId);
      const selectedMembership = potentialMemberships.find(membership => membership.id === membershipId);
      const containsPendingMemberships = potentialMemberships.some(membership => (
        membership.memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_PENDING
      ));

      if (containsPendingMemberships) {
        setTimeout(() => {
          dispatch(fetchInitialMemberships(order));
        }, constants.POLLING_DELAY);
      } else {
        if (membershipPickedArbitrarily || !selectedMembership) {
          dispatch(setPotentialMemberships(potentialMemberships));
          dispatch(setShouldSelectMembership(true));
          dispatch(triggerAnalyticsEarnMembershipSelection());
        } else {
          dispatch(setPotentialMemberships(potentialMemberships));
          dispatch(setMembership(selectedMembership));
          dispatch(setShouldSelectMembership(false));
          if (selectedMembership.isRegistered) {
            dispatch(triggerAnalyticsMembershipRegistrationComplete());
          } else {
            dispatch(triggerAnalyticsPendingMembershipInstructions());
          }
        }
        dispatch(setIsLoadingData(false));
      }
    }, () => {
      // error
      dispatch(setError(constants.fixEarnOrderErrors.membershipNotFound));
      dispatch(setIsLoadingData(false));
    });
}

export function selectMembership(membershipId) {
  return (dispatch, getState) => {
    dispatch(setIsLoadingData(true));
    dispatch(setError(''));
    const order = getState().fixEarnOrder.get('order');
    const orderId = order.get('orderId');

    if (!orderId) {
      dispatch(setError(constants.fixEarnOrderErrors.requireOrderId));
      return;
    }

    const updatedOrderData = Map({
      toMembershipId: membershipId
    });

    return Orders.updateOrder(orderId, updatedOrderData, false)
      .then(() => {
        dispatch(loadData(orderId));
      }, () => {
        dispatch(setError(constants.fixEarnOrderErrors.orderUpdateIssue));
        dispatch(setIsLoadingData(false));
      });
  };
}

export function finishRegistration(membership) {
  return dispatch => {
    dispatch(setMembership(membership));
    dispatch(setIsAttemptingRegistration(false));
    if (membership.isRegistered) {
      dispatch(triggerAnalyticsMembershipRegistrationComplete());
      dispatch(deactivateSlide());
    }
  };
}

export function pollRegisteredMembership(membership) {
  return dispatch => Memberships.fetchMembership(membership.id, false)
    .then(membership => {
      if (membership.memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_PENDING) {
        setTimeout(() => {
          dispatch(pollRegisteredMembership(membership));
        }, constants.POLLING_DELAY);
      } else {
        dispatch(finishRegistration(membership));
      }
    })
    .catch(() => {
      dispatch(setError(constants.fixEarnOrderErrors.membershipNotFound));
      dispatch(setMembership(membership));
      dispatch(setIsAttemptingRegistration(false));
    });
}

export function deleteAndRegisterMembership(duplicatedMembership, desiredMembership) {
  const updatedParams = {
    lpId: duplicatedMembership.get('lpId'),
    credentials: duplicatedMembership.get('credentials'),
    id: desiredMembership.get('id')
  };
  return dispatch => Memberships.deleteMembership(duplicatedMembership.get('id'))
    .then(() => {
      dispatch(registerMembership(updatedParams));
    })
    .catch(() => {
      dispatch(setError(constants.fixEarnOrderErrors.genericIssue));
      dispatch(setIsAttemptingRegistration(false));
    });
}

export function registerMembership(unregisteredMembership) {
  return (dispatch, getState) => {
    dispatch(triggerAnalyticsMembershipRegistrationAttempt());
    return Memberships.registerMembership(unregisteredMembership)
      .then(registeredMembership => {
        if (registeredMembership.memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_PENDING) {
          dispatch(pollRegisteredMembership(registeredMembership));
        } else {
          dispatch(finishRegistration(registeredMembership));
        }
      }, res => {
        const { errorCodes } = constants;
        const shouldMergeMemberships = fromJS(res.errors).filter(item => item.get('code') === errorCodes.duplicateMemberId);

        if (shouldMergeMemberships && shouldMergeMemberships.size) {
          const duplicatedMembership = fromJS(unregisteredMembership);
          const potentialMemberships = getState().fixEarnOrder.get('potentialMemberships');
          const desiredMembership = potentialMemberships.find(membership => (
            membership.get('isRegistered') === true &&
            membership.getIn(['credentials', 'memberId']) === duplicatedMembership.getIn(['credentials', 'memberId'])));

          if (!desiredMembership || desiredMembership.isEmpty()) {
            dispatch(setError(constants.fixEarnOrderErrors.genericIssue));
            dispatch(setIsAttemptingRegistration(false));
            return;
          }
          dispatch(deleteAndRegisterMembership(duplicatedMembership, desiredMembership));
        } else {
          dispatch(setError(constants.fixEarnOrderErrors.genericIssue));
          dispatch(setIsAttemptingRegistration(false));
        }
      });
  };
}

export function redirectUserToRegistrationPage(membershipId) {
  return dispatch => Memberships.generateCredentialCatcherURL(membershipId)
    .then(credentialCatcher => {
      const url = credentialCatcher.register.href;
      urlUtils.externalRedirect(url);
    })
    .catch(() => {
      dispatch(setError(constants.fixEarnOrderErrors.registrationUrlNotFound));
      dispatch(setIsLoadingData(false));
    });
}

