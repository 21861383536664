/**
@class Exchange API
*/
import { addAuthTokenToHeaders, serializeQueryParams, request, getPartnerSessionId } from './utils/httpUtils';

/**
`GET /v1/offer-sets?includeAnonymousPricing=true&orderType=exchange`
fetch offer set for exchange
@method fetchOfferSet
@param {Object} opts
*/
export function fetchOfferSet(opts) {
  const options = addAuthTokenToHeaders();
  const query = {
    includeAnonymousPricing: true,
    orderType: 'exchange',
    ...opts
  };

  const url = `/v1/offer-sets?${serializeQueryParams(query)}`;

  return fetch(url, options)
    .then(res => res.json());
}

/**
`POST /v1/orders/exchange`
@method submitExchange
 @param {string} fraudUniqueId
 @param {Object} exchange
*/
export function submitExchange(fraudUniqueId, exchange) {
  Object.assign(exchange, { fraudUniqueId, isBlw: true });
  const partnerSessionId = getPartnerSessionId();
  if (partnerSessionId) {
    Object.assign(exchange, { partnerSessionId });
  }
  const options = addAuthTokenToHeaders({
    method: 'post',
    body: JSON.stringify(exchange)
  });

  return request('/v1/orders/exchange', options)
    .then(data => data.json);
}

export default { fetchOfferSet, submitExchange };
