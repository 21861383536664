import { StatusCodes } from 'http-status-codes';

/**
@class Auth API
*/
import { setAuthToken, request, setPartnerPostUrl, setPartnerSessionId, addAuthTokenToHeaders } from './utils/httpUtils';

export function postSSO(clientId, token, verifyEmailToken) {
  const options = {
    method: 'post',
    body: JSON.stringify({ token, clientId, verifyEmailToken })
  };

  return request('/auth/blw/sso', options)
    .then(payload => {
      const { partnerPostUrl, partnerSessionId, accessToken } = payload.json;
      setPartnerPostUrl(partnerPostUrl);
      setPartnerSessionId(partnerSessionId);
      setAuthToken(accessToken);
      return payload;
    });
}

/**
Calls endpoint `POST /v1/blw/users` to create a user, or submit a
verification code associated with user. On success will set accessToken.
@method createAccount
@param {string} email account associated with mv
@param {string} [verificationCode] a six digit code that user receives in email or sms message
@return {Object} accessToken and resetToken
*/
export function createAccount(email, passcode) {
  const options = {
    method: 'post',
    body: JSON.stringify({ email, passcode })
  };
  return request('/v1/blw/users', options)
    .then(payload => setAuthToken(payload.json.accessToken));
}

/**
Calls endpoint `DELETE /v1/user/tokens/:token` to invalidate
the accessToken.
@method invalidateAccessToken
@param {string} token
 */
export function invalidateAccessToken(token) {
  const authOptions = addAuthTokenToHeaders();
  const options = {
    method: 'delete',
    ...authOptions
  };
  return fetch(`/v1/user/tokens/${token}`, options)
    .then(response => {
      if (response.status >= StatusCodes.OK && response.status < StatusCodes.MULTIPLE_CHOICES) {
        return response.status;
      }
      throw new Error(`Status: ${response.status}`);
    });
}

/**
Calls enpoint `POST /v1/blw/send-code` to send verification code to user at email
address.
@method sendCode
@param {string} email to send verification code to.
*/
export function sendCode(email) {
  const authOptions = addAuthTokenToHeaders();
  const options = {
    method: 'post',
    body: JSON.stringify({ email }),
    ...authOptions
  };
  return fetch('/v1/blw/send-code', options);
}

export default { postSSO, createAccount, sendCode, invalidateAccessToken };
