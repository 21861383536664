import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getTabIndex } from './utils/accessibility-helpers';

class ModalHeader extends React.Component {
  closeModal = () => {
    const { setModalContentKey, afterCloseClick } = this.props;

    setModalContentKey(null);
    if (afterCloseClick) {
      afterCloseClick();
    }
  };
  render() {
    const { header, hideCloseButton, formatter } = this.props;

    const modalName = header ? `'${formatter.formatMessage({ id: header })}'` : '';
    const closeButtonAriaLabel = formatter.formatMessage({
      id: 'accessibility.pressEscapeToCloseModal'
    }, {
      modalName
    });

    const closeButton = !hideCloseButton ? (
      <div className='modal__close'>
        <i
          className='modal__close-icon icon-close'
          role='note'
          aria-label={closeButtonAriaLabel}
          tabIndex={getTabIndex(false, true)}
          onClick={this.closeModal}
        />
      </div>
    ) : null;
    return (
      <div className='modal__header u-padding-small'>
        { closeButton }
        <h2 className='u-margin-vertical-none text-header-3'>
          <FormattedMessage id={header} />
        </h2>
      </div>
    );
  }
}

ModalHeader.propTypes = {
  header: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  setModalContentKey: PropTypes.func,
  afterCloseClick: PropTypes.func
};

export default ModalHeader;
