import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { bindActionCreators } from 'redux';
import { push, replace } from 'react-router-redux';
import classnames from 'classnames';

import constants from '../utils/constants';
import { handleKeyPressEnter, getTabIndex } from './utils/accessibility-helpers';
import { getMembershipFromState, getLpFromState } from './utils/state-helpers';
import { capitalize } from '../utils/string';
import { shouldHideMemberData } from './utils/wps-helpers';
import { setIsShowingSlide, setPageMessage, setModalContentKey } from '../action-creators/app';
import {
  fetchEligibleLps,
  setEligibleLps,
  resetEligibleFromLps,
  resetEligibleToLps
} from '../action-creators/lps';

import {
  addSelectMembership,
  setSelectedAmount,
  setExchangeFromMembershipId,
  setExchangeToMembershipId,
  fetchOfferSet,
  setSelectedOffer,
  resetAmounts,
  resetExchange,
  setSelectedExchange,
  setShouldResetExchange
} from '../action-creators/exchange';
import { fetchPromos } from '../action-creators/promos';

import { registerMembership, fetchMemberships, postNewMembership, setHasFetchedFinalOfferSet, deleteMembership } from '../action-creators/memberships';
import { createAccount, linkAccount } from '../action-creators/auth';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ExchangeSelectBoxComponent from './ExchangeSelectBox';
import ExchangeRules from './ExchangeRules';
import ExchangeSlider from './ExchangeSlider';
import ExchangeSummary from './ExchangeSummary';
import PromoDetails from './PromoDetails';
import Slide from './Slide';
import FancyButton from 'react-fancy-button';
import { Helmet } from 'react-helmet';

import FocusTrap from './FocusTrap';

const initialState = () => ({
  isShowingCreateAccountSuccess: false,
  isShowingPromoSlide: false,
  selectedExchange: {},
  closeSlide: 0,
  newlyAddedProgram: Map(),
  hasCompletedSetup: false
});

export class ExchangeConfigure extends React.Component {
  state = initialState();

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
    this.configureLps();
  }

  componentDidMount() {
    const { user, push, clientData, fetchPromos, hasFetchedPromos } = this.props;
    const clientLpId = clientData.get('clientLpId');

    // Require a non-guest user for exchange
    const role = user.get('role');
    if (role === constants.USER_ROLE_GUEST) {
      push('/exchange/configure/create-account');
    }

    if (!hasFetchedPromos) {
      fetchPromos(constants.product.exchange);
    }

    if (!clientLpId) {
      this.fetchExchangeEligibleLps('from');
      this.fetchExchangeEligibleLps('to');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hasCreatedAccount, offerSet, resetExchange, setPageMessage, setShouldResetExchange } = this.props;
    this.automaticallySetMemberships(nextProps);
    this.sendAnalyticsEvents(nextProps);

    const offerSetHasChanged = !offerSet.equals(nextProps.offerSet);
    const bothMembershipsAreSelected = nextProps.fromMembership.size && nextProps.toMembership.size;
    if (offerSetHasChanged && bothMembershipsAreSelected) {
      this.setOffersetsPageMessage(nextProps);
    }

    if (!hasCreatedAccount && nextProps.hasCreatedAccount) {
      this.setState({
        isShowingCreateAccountSuccess: true
      });

      // The CreateAccount component used to be followed by slider membership registrations so the success handlers
      // within the component don't close the slider. Closing the slider here because the CreateAccount component is
      // still used in other places.
      this.closeSlide();
    }

    if (!this.props.shouldResetExchange && nextProps.shouldResetExchange) {
      resetExchange(); // Reset exchange-related variables in store
      setPageMessage(); // Reset global page banner
      setShouldResetExchange(false);
    }

    const fromMembershipChanged = !this.props.fromMembership.equals(nextProps.fromMembership);
    const toMembershipChanged = !this.props.toMembership.equals(nextProps.toMembership);
    if (fromMembershipChanged || toMembershipChanged) {
      this.fetchExchangeEligibleLps('from', nextProps);
      this.fetchExchangeEligibleLps('to', nextProps);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const justAddedProgram = !this.state.newlyAddedProgram.size && nextState.newlyAddedProgram.size;
    if (justAddedProgram) {
      this.automaticallySetMemberships(nextProps);
    }
  }

  componentWillUnmount() {
    const { resetExchange } = this.props;
    resetExchange();
  }

  componentDidUpdate(nextProps) {
    this.configureLps();
    const { amounts, resetAmounts, fromMembership, toMembership, offerSet } = this.props;
    const areBothMembershipsSelected = fromMembership.size && toMembership.size;

    if (areBothMembershipsSelected) {
      const fromMembershipChanged = !fromMembership.equals(nextProps.fromMembership);
      const toMembershipChanged = !toMembership.equals(nextProps.toMembership);
      const membershipSelectionChanged = fromMembershipChanged || toMembershipChanged;
      const amountsAreNotSet = offerSet.size && amounts.isEmpty();

      if (membershipSelectionChanged) {
        resetAmounts();
        this.fetchOfferSets();
      }
      if (amountsAreNotSet) {
        this.setAmounts();
      }
    }
  }

  getDefaultFromMembershipId = () => {
    const { allEligibleFromLpIds, location, clientData } = this.props;
    const clientDefaultFromLpId = clientData.get('clientLpId');
    const {
      fromLpId: fromLpIdParam,
      fromMembershipId: fromMembershipIdParam
    } = location.query;

    const defaultMembershipIdByClient = this.getMembershipByLpId(clientDefaultFromLpId).get('id');
    const fromMembershipByIdParam = this.getMembershipByLpId(fromMembershipIdParam);
    const isValidFromMembershipIdParam = fromMembershipIdParam && allEligibleFromLpIds.includes(fromMembershipByIdParam.get('lpId'));
    const isValidFromLpIdParam = fromLpIdParam && allEligibleFromLpIds.includes(fromLpIdParam);
    const membershipIdByLpId = isValidFromLpIdParam ? this.getMembershipByLpId(fromLpIdParam).get('id') : '';

    // We have multiple possible sources to pick a membership, we check them in the following order/priority:
    // 1. Pick based on client's default lpId
    // 2. Pick based on URL fromMembershipId parameter
    // 3. Pick based on URL fromLpId parameter

    return defaultMembershipIdByClient
        || (isValidFromMembershipIdParam && fromMembershipByIdParam.get('id'))
        || (isValidFromLpIdParam && membershipIdByLpId)
        || '';
  };

  getDefaultToMembershipId = () => {
    const { addSelectMembership, clientData, location } = this.props;
    const clientDefaultFromLpId = clientData.get('clientLpId');
    const {
      toLpId: toLpIdParam,
      toMembershipId: toMembershipIdParam
    } = location.query;
    const validToLpIdParam = toLpIdParam && toLpIdParam !== clientDefaultFromLpId;
    const membershipIdByLpId = validToLpIdParam ? this.getMembershipByLpId(toLpIdParam).get('id') : '';
    if (validToLpIdParam && !membershipIdByLpId) {
      addSelectMembership(toLpIdParam);
    }

    // We have multiple possible sources to pick a membership, we check them in the following order/priority:
    // 1. Pick based on URL toMembershipId parameter
    // 2. Pick based on URL toLpId parameter

    return toMembershipIdParam
        || membershipIdByLpId
        || '';
  };

  getLpById = lpId => {
    const { allLps } = this.props;
    return allLps.find(lp => lp.get('id') === lpId);
  };

  getMembershipByLpId = lpId => {
    const { memberships } = this.props;
    const matchingMemberships = memberships.filter(membership => membership.get('lpId') === lpId);
    if (!matchingMemberships.size) {
      return Map();
    }
    // give preference to registered memberships
    return matchingMemberships.find(membership => membership.get('isRegistered')) || matchingMemberships.first();
  };

  setAmounts = () => {
    // TODO why do we need this function? Shouldn't the amounts be set automatically along the offer-set?
    // Either refactor or add a comment to explain why
    const { offerSet, setSelectedOffer, fromMembership, toMembership } = this.props;

    if (offerSet.get('errors')) {
      return;
    }
    const included = offerSet.get('included') || List();
    const offers = offerSet.get('available').concat(included);
    const selectedOffer = offers.find(offer => {
      const isFromLp = offer.get('fromLpId') === fromMembership.get('lpId');
      const isToLp = offer.get('toLpId') === toMembership.get('lpId');
      return isFromLp && isToLp;
    });
    if (!selectedOffer) {
      console.error('no offer was found');
      console.log('offerSet: ', offerSet.toJSON());
      console.log('fromMembership: ', fromMembership.toJSON());
      console.log('toMembership: ', toMembership.toJSON());
      return;
    }
    setSelectedOffer(selectedOffer);
  };

  setMembership = (direction, membershipId) => {
    if (membershipId) {
      if (direction === 'from') {
        this.props.setExchangeToMembershipId('');
      }
      this.props[`setExchange${capitalize(direction)}MembershipId`](membershipId);
    }
  };

  setOffersetsPageMessage = props => {
    const { fromLp, user, setPageMessage, formatter } = props;
    const { isShowingCreateAccountSuccess } = this.state;
    const hasUserRole = user.get('role') === constants.USER_ROLE_USER;
    const hasOfferSetError = this.hasOfferSetError(props);
    const shouldShowLpRegistrationWarning = this.isAnySelectedMembershipUnregistered() && hasUserRole;

    if (hasOfferSetError) {
      const currency = fromLp.getIn(['content', 'currencyNameLong']);
      setPageMessage({
        type: 'error',
        message: formatter.formatMessage({ id: 'EXCHANGE_INSUFFICIENT_POINTS' }, { currency })
      });
    } else if (isShowingCreateAccountSuccess) {
      setPageMessage({
        type: 'success',
        message: formatter.formatMessage({ id: 'createAccount.successfulAccountCreationMessage' })
      });
    } else if (shouldShowLpRegistrationWarning) {
      setPageMessage({
        type: 'warning',
        message: formatter.formatMessage({ id: 'exchange.lpRegistrationWarning' })
      });
    }
  };

  /**
   * The initial state of a brand new exchange
   * check if the client is also an LP and sets it as the fromLp
   * we also check fromLp and toLp params and set them in the store
   *
   * * @method setupInitialExchange
   */
  setupInitialExchange = () => {
    const { replace: replacePath } = this.props;
    if (this.state.hasCompletedSetup) {
      return;
    }
    this.setState({ hasCompletedSetup: true });

    // set initial 'from' membership
    const fromMembershipId = this.getDefaultFromMembershipId();
    this.setMembership('from', fromMembershipId);

    // set initial 'to' membership
    const toMembershipId = this.getDefaultToMembershipId();
    this.setMembership('to', toMembershipId);

    replacePath('/exchange/configure');
  };

  areSuccessfulMembershipsSelected = () => {
    const { fromMembership, toMembership } = this.props;
    const fromMembershipIsSuccess = fromMembership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_SUCCESS;
    const toMembershipIsSuccess = toMembership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_SUCCESS;
    return fromMembershipIsSuccess && toMembershipIsSuccess;
  };

  autoSetMembershipByLpId = (direction, lpId, oppositeDirectionLpId) => {
    const { memberships, postNewMembership } = this.props;
    const existingMembership = memberships.find(membership => membership.get('lpId') === lpId);
    const isAlreadySelected = oppositeDirectionLpId === lpId;
    if (isAlreadySelected) {
      return;
    }

    if (existingMembership) {
      this.setMembership(direction, existingMembership.get('id'));
    } else {
      postNewMembership(lpId, memberships);
      this.setState({
        newlyAddedProgram: Map({
          lpId,
          direction
        })
      });
    }
  };

  automaticallySetMemberships = ({ fromMembership, toMembership } = this.props) => {
    // If only one program can be used for a given exchange direction, set it automatically.
    // Related: https://trello.com/c/PJ8LkjGm/3-alaska-feedback-from-alaska-default-to-and-from-programs-for-exchange-if-there-are-only-two-lps-permissioned
    const { newlyAddedProgram } = this.state;
    const { allEligibleFromLpIds, allEligibleToLpIds, hasUpdatedMemberships } = this.props;

    if (hasUpdatedMemberships) {
      const shouldAutoSetFromMembership = allEligibleFromLpIds.size === 1 && !fromMembership.get('id');
      const shouldAutoSetToMembership = allEligibleToLpIds.size === 1 && !toMembership.get('id');
      if (shouldAutoSetFromMembership) {
        this.autoSetMembershipByLpId('from', allEligibleFromLpIds.first(), toMembership.get('lpId'));
      } else if (shouldAutoSetToMembership) {
        this.autoSetMembershipByLpId('to', allEligibleToLpIds.first(), fromMembership.get('lpId'));
      }

      if (newlyAddedProgram.size) {
        const lpId = newlyAddedProgram.get('lpId');
        const direction = newlyAddedProgram.get('direction');
        this.setMembership(direction, this.getMembershipByLpId(lpId).get('id'));
        this.setState({ newlyAddedProgram: Map() });
      }
    }
  };

  closeSlide = () => {
    const { closeSlide } = this.state;
    this.setState({ closeSlide: closeSlide + 1 });
  };

  /**
   * Configure Lps is responsible for setting up the initial exchange and
   * fetching offerSets when a fromLp or toLp is selected.
   *
   * @method configureLps
   */

  configureLps = () => {
    const {
      fromMembership,
      toMembership,
      isFetchingOfferSet,
      setHasFetchedFinalOfferSet,
      hasFetchedFinalOfferSet
    } = this.props;
    if (isFetchingOfferSet) {
      return;
    }

    if (!fromMembership.size && !toMembership.size) {
      return this.setupInitialExchange();
    }

    if (this.areSuccessfulMembershipsSelected() && !hasFetchedFinalOfferSet) {
      setHasFetchedFinalOfferSet(true);
    }
  };

  exchangeRulesLinkEl = null;
  exchangeRulesTextEl = null;

  fetchExchangeEligibleLps = (direction, props = this.props) => {
    // The logic for this particular function is quite convoluted. We're counting on other parts
    // of the code to automatically choose memberships depending on the clientLpId and also we are
    // assuming that the user will determine the exchange based on the *from* membership.
    const { fromMembership, toMembership, fetchEligibleLps, clientData } = props;

    const params = { direction };
    const clientLpId = clientData.get('clientLpId');

    // Add to params what we already know about the current exchange setup
    if (!fromMembership.isEmpty()) {
      params.fromLpId = fromMembership.get('lpId');
    }
    if (!toMembership.isEmpty()) {
      if (clientLpId) {
        params.toLpId = clientLpId;
      } else {
        params.toLpId = toMembership.get('lpId');
      }
    }

    // Since we're trying to get the program list for 'direction', delete the assumption
    // of a specific lpId/membershipId for that direction
    delete params[`${direction}LpId`];
    fetchEligibleLps(params);
  };

  getSelectedCountryCode = allowedCountriesArr => {
    const { fromMembership, push } = this.props;
    const fromMembershipCountryCode = fromMembership.getIn(['credentials', 'identifyingFactors.countryCode']);

    if (fromMembershipCountryCode && allowedCountriesArr.includes(fromMembershipCountryCode)) {
      return fromMembershipCountryCode;
    }
    console.log(`Error: Could not determine country code of membership from the lpId ${fromMembership.get('lpId')} with the id ${fromMembership.get('id')}`);
    return push('/error');
  }

  fetchOfferSets = () => {
    const { fetchOfferSet, fromMembership, toMembership, clientData } = this.props;
    const params = {};
    const allowedCountriesArr = clientData.toJSON().exchangeCountryCodes;

    if (fromMembership.size) {
      params.fromMembershipId = fromMembership.get('id');
      params.fromLpId = fromMembership.get('lpId');
    }
    if (toMembership.size) {
      params.toMembershipId = toMembership.get('id');
      params.toLpId = toMembership.get('lpId');
    }
    if (allowedCountriesArr && allowedCountriesArr.length > 0) {
      params.category = this.getSelectedCountryCode(allowedCountriesArr);
    }
    fetchOfferSet(params);
  };

  hasOfferSetError = (props = this.props) => {
    const { offerSet } = props;
    const hasAvailableOffers = offerSet && offerSet.get('available') && offerSet.get('available').size;
    const hasIncludedOffers = offerSet && offerSet.get('included') && offerSet.get('included').size;
    const offerSetErrors = offerSet.get('errors');
    const hasOfferSetError = offerSetErrors && offerSetErrors.size;
    const hasEmptyOfferSet = offerSet && offerSet.size && !hasAvailableOffers && !hasIncludedOffers;
    return hasOfferSetError || hasEmptyOfferSet;
  };

  isAnySelectedMembershipUnregistered = () => {
    const { fromMembership, toMembership } = this.props;
    const fromIsUnregistered = !fromMembership.get('isRegistered');
    const toIsUnregistered = !toMembership.get('isRegistered');
    return fromIsUnregistered || toIsUnregistered;
  };

  isValidExchange = () => {
    const hasSelectedOffer = !!this.props.selectedOffer.size;
    const userIsGuest = this.props.user.get('role') === constants.USER_ROLE_GUEST;
    const hasFromAmount = this.props.fromAmount > 0;
    const isValid = userIsGuest || this.areSuccessfulMembershipsSelected() && hasSelectedOffer && hasFromAmount;

    return isValid;
  };

  openExchangeConfirmationModal = () => {
    const { setModalContentKey } = this.props;
    setModalContentKey(constants.modals.exchangeConfirmation);
  };

  // Open modal callbacks

  openExchangeDisabledModal = () => {
    const { setModalContentKey } = this.props;
    setModalContentKey(constants.modals.exchangeDisabled);
  };

  openExchangeRulesModal = () => {
    const { setModalContentKey, selectedOffer } = this.props;
    if (!selectedOffer.size) {
      return;
    }
    setModalContentKey(constants.modals.exchangeRules);
  };

  resetExchangeComponent = () => {
    this.props.setShouldResetExchange(true);
  };

  selectExchange = () => {
    const {
      user,
      push,
      iframeManager,
      googleTagManager,
      selectedOffer,
      fromAmount,
      toAmount,
      fromMembership,
      toMembership,
      setSelectedExchange,
      toLp,
      fromLp
    } = this.props;

    if (user.get('role') === constants.USER_ROLE_GUEST) {
      this.triggerAnalyticsEvent({
        action: constants.ANALYTICS_GOTO_SIGNUP
      });
      return push('/exchange/configure/create-account');
    }

    if (!this.isValidExchange()) {
      return;
    }
    iframeManager.scrollParentFrameToTop();

    googleTagManager.triggerPageView('exchange/configure/confirm', 'exchange.configure.confirm', {
      action: constants.ANALYTICS_SHOW_EXCHANGE_CONFIRM,
      context: 'exchange'
    });

    const fromMembershipId = fromMembership.get('id');
    const fromOfferId = selectedOffer.getIn(['from', 'offerId']);
    const toMembershipId = toMembership.get('id');
    const toOfferId = selectedOffer.getIn(['to', 'offerId']);

    setSelectedExchange({
      orderType: 'exchange',
      fromAmount,
      fromMembershipId,
      fromOfferId,
      toAmount,
      toMembershipId,
      toMembership,
      fromMembership,
      toLp,
      fromLp,
      toOfferId
    });

    this.openExchangeConfirmationModal();
  };

  selectExchangeEl = null;

  sendAnalyticsEvents = nextProps => {
    const { order, exchangeError } = this.props;
    if (!exchangeError.size && nextProps.exchangeError.size) {
      this.triggerAnalyticsEvent({
        action: constants.ANALYTICS_COMPLETE_EXCHANGE,
        status: 'error',
        errorCode: nextProps.exchangeError.get('errors').first().get('code')
      });
    }
    if (!order.size && nextProps.order.size) {
      this.triggerAnalyticsEvent({
        action: constants.ANALYTICS_COMPLETE_EXCHANGE,
        status: 'success'
      });
    }
  };

  slideEl = null;

  toggleIsShowingAccountCreateSuccess = () => {
    this.props.fetchMemberships(true, true);
    this.setState({
      isShowingCreateAccountSuccess: !this.state.isShowingCreateAccountSuccess
    });
  };

  triggerAnalyticsEvent = data => {
    this.props.googleTagManager.pushGtmData(Object.assign({
      context: 'exchange'
    }, data));
  };

  shouldShowSelectProgramsMessage = () => {
    const { fromMembership, toMembership } = this.props;
    const areBothMembershipsSelected = fromMembership.size && toMembership.size;
    return !areBothMembershipsSelected;
  }

  renderCreateAccountSlide = () => {
    const { formatter, isShowingSlide } = this.props;
    return (
      <div className='outer-container__hide-scrollbar'>
        <FocusTrap isActive={isShowingSlide}>
          <Slide
            ref={el => this.slideEl = el}
            closeSlide={this.state.closeSlide}
            setIsShowingSlide={this.props.setIsShowingSlide}
            handleClose={() => this.props.push('/wallet')}
            formatter={formatter}
          >
            { this.props.children }
          </Slide>
        </FocusTrap>
      </div>
    );
  };

  // Both boxes need to be aligned, regardless of what's on top or on bottom
  // The two scenarios with tricky alignment are when the two headers have different heights or when a loader/spinner
  // appears under one of them.
  renderExchangeBoxes = () => (
    <div className='o-layout'>
      <div className='o-layout__item u-1/2 valign-bottom'>
        {this.renderExchangeSelectHeader('from')}
      </div>
      <div className='o-layout__item u-1/2 valign-bottom'>
        {this.renderExchangeSelectHeader('to')}
      </div>
      <div className='o-layout__item u-1/2'>
        {this.renderExchangeSelectBox('from')}
      </div>
      <div className='o-layout__item u-1/2'>
        {this.renderExchangeSelectBox('to')}
      </div>
    </div>
  );

  renderExchangeButton = () => {
    const { isShowingSlide, formatter } = this.props;
    const makeExchangeButtonLabel = formatter.formatMessage({ id: 'exchange.makeExchangeButton' });

    return (
      <FancyButton
        ref={el => this.selectExchangeEl = el}
        role='button'
        classes='btn btn-primary u-1/1 u-margin-bottom-small u-margin-bottom-huge@md btn-small-padding'
        onClick={this.selectExchange}
        ariaLabel={makeExchangeButtonLabel}
        tabIndex={getTabIndex(isShowingSlide)}
        onDisabledClick={this.openExchangeDisabledModal}
        label={makeExchangeButtonLabel}
        disabled={!this.isValidExchange()}
      />
    );
  };

  // direction should be either 'from' or 'to'
  renderExchangeSelectBox = direction => {
    const membership = this.props[`${direction}Membership`];
    const lp = this.props[`${direction}Lp`];

    return (
      <div>
        <ExchangeSelectBoxComponent
          direction={direction}
          getMembershipIdFromLpId={lpId => this.getMembershipByLpId(lpId).get('id')}
          setMembership={this.setMembership}
          isFetchingEligibleLps={this.props[`isFetching${capitalize(direction)}Lps`]}
          eligibleLps={this.props[`eligible${capitalize(direction)}Lps`]}
          membership={membership}
          lp={lp}
          {...this.props}
        />
      </div>
    );
  };

  renderExchangeSelectHeader = direction => {
    const { isShowingSlide } = this.props;
    const modalHeader = direction === 'from' ? 'exchangeSelect.exchangeFromModalHeader' : 'exchangeSelect.exchangeToModalHeader';
    return (
      <span className='exchange-configure-directions text-center text-weight-8 text-header-3' aria-hidden={isShowingSlide}>
        <FormattedMessage id={modalHeader} />
      </span>
    );
  };

  renderSelectProgramsMessage = () => (
    <div className='u-padding-vertical-small text-center text-base-size-palm exchange-configure-amounts__description'>
      <span >
        <FormattedMessage
          id='exchange.selectProgramFirst'
        />
      </span>
    </div>
  );

  renderExchangeSummary = () => {
    const { toAmount, toLp, toMembership, clientData } = this.props;
    if (!this.areSuccessfulMembershipsSelected()) {
      return null;
    }
    if (shouldHideMemberData(clientData, toLp.get('id'))) {
      return null;
    }
    return (
      <ExchangeSummary
        amount={toAmount}
        lp={toLp}
        membership={toMembership}
      />
    );
  };

  renderIframedHeading = () => {
    const { isShowingSlide } = this.props;
    return (
      <h1 className='text-center u-margin-none thin-header' aria-hidden={isShowingSlide}>
        <FormattedHTMLMessage id='exchange.welcome' />
      </h1>
    );
  };


  getPromo = () => {
    const { promos, selectedOffer } = this.props;
    return (promos.size && selectedOffer.size && promos.find(promo => promo.get('offerId') === selectedOffer.get('toOfferId'))) || Map();
  };

  renderPromoHeader = promo => {
    const { isShowingSlide } = this.props;
    return (
      <div className='exchange-configure__promo u-padding-vertical'>
        <div className='exchange-configure__promo--content'>
          <div className='exchange-configure__promo--header'>
            { promo.get('exchangeHeader') }
          </div>
          <div>
            { promo.get('restrictions') }
            <a
              className='u-margin-left-tiny'
              onClick={this.showPromoSlide}
              tabIndex={getTabIndex(isShowingSlide)}
              aria-hidden={isShowingSlide}
            >
              See full details
            </a>
          </div>
        </div>
      </div>
    );
  };

  renderPromoSlide = () => {
    const { formatter, isShowingSlide } = this.props;
    const promo = this.getPromo();
    return (
      <FocusTrap isActive={isShowingSlide}>
        <Slide
          handleClose={this.hidePromoSlide}
          closeSlide={this.state.closeSlide}
          setIsShowingSlide={this.props.setIsShowingSlide}
          formatter={formatter}
        >
          <PromoDetails
            closePromoDetails={this.hidePromoSlide}
            promo={promo}
            {...this.props}
          />
        </Slide>
      </FocusTrap>
    );
  };

  showPromoSlide = () => {
    const { googleTagManager } = this.props;
    this.setState({ isShowingPromoSlide: true });
    googleTagManager.triggerPageView('/exchange/promo', 'exchange.promo');
  };

  hidePromoSlide = () => {
    this.setState({ isShowingPromoSlide: false });
  };

  renderMainExchangeUI = () => {
    const {
      fromMembership,
      isShowingSlide,
      location,
      selectedOffer,
      clientData
    } = this.props;
    const { isShowingPromoSlide } = this.state;
    const exchangeModalClasses = classnames(['link-decoration', { 'text-color-muted': !selectedOffer.size }]);
    const isCreatingAccount = location.pathname.split('/').includes('create-account');
    const showExchangeRulesLink = clientData.get('shouldShowExchangeRulesLink') && selectedOffer && selectedOffer.size;
    const promo = this.getPromo();
    const elementsToHideClass = classnames({ 'hostedExchangeElement--isShowing': !isShowingSlide, 'hostedExchangeElement--notShowing': isShowingSlide });
    // These elements need to be hidden when the slider is up because when the page is rendered in an iframe and the user agent is an iPhone, these elements tend to behave strangely.

    if (isCreatingAccount) {
      return this.renderCreateAccountSlide();
    }

    return (
      <div>
        { isShowingPromoSlide ? this.renderPromoSlide() : null }
        <div className='u-margin-top@md u-margin-top-none u-margin-bottom-small text-center' aria-hidden={isShowingSlide}>
          {
            showExchangeRulesLink ? (
              <a
                ref={el => this.exchangeRulesLinkEl = el}
                href='#'
                className={exchangeModalClasses}
                tabIndex={getTabIndex(isShowingSlide)}
                onClick={this.openExchangeRulesModal}
              >
                <FormattedMessage id='exchangeConfigure.viewExchangeRules' />
              </a>
            ) : null
          }
        </div>
        <div className='u-margin-top@md u-margin-top-none u-margin-bottom-small text-center' aria-hidden={isShowingSlide}>
          { promo.size ? this.renderPromoHeader(promo) : null }
        </div>
        <div className='o-layout o-layout--center'>
          <div className='o-layout__item u-2/3@md u-1/1'>
            <div className='o-layout o-layout--large'>
              <div className='o-layout__item exchange-configure-reset' aria-hidden={isShowingSlide}>
                <span
                  className='exchange-configure-reset__icon icon-reset'
                  onClick={this.resetExchangeComponent}
                  onKeyUp={handleKeyPressEnter(e => this.resetExchangeComponent(e))}
                  tabIndex={getTabIndex(isShowingSlide)}
                  aria-label='Reset exchange programs'
                  title='Reset exchange programs'
                  role='button'
                />
              </div>
              <div className='o-layout__item'>
                {this.renderExchangeBoxes()}
              </div>
              <div className={`${elementsToHideClass} o-layout__item`} aria-hidden={isShowingSlide}>
                <div className='u-margin-top-large'>

                  <div className='u-bv'>
                    <span className='exchange-configure-question text-center text-weight-8 text-header-3 u-margin-bottom-small'>
                      <FormattedMessage id='exchange.exchangeAmountMessage' />
                    </span>

                    {
                      this.shouldShowSelectProgramsMessage() ? this.renderSelectProgramsMessage() : null
                    }

                    <ExchangeSlider {...this.props} />

                  </div>

                  <div className='u-margin-top'>
                    {this.renderExchangeSummary()}
                  </div>

                  <ExchangeRules
                    ref={el => this.exchangeRulesTextEl = el}
                    classes='u-margin-bottom-large u-margin-top text-color-muted'
                    fromMembershipId={fromMembership.get('id')}
                    {...this.props}
                  />
                  { this.renderExchangeButton() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderWelcomeMessage = () => {
    const { isShowingSlide } = this.props;
    return (
      <div className='text-center u-padding-top' aria-hidden={isShowingSlide}>
        <h1 className='text-weight-1 u-margin-vertical-none u-margin-top@md'>
          <FormattedMessage id='exchange.welcome' />
        </h1>
        <p className='u-margin-top-none'>
          <FormattedMessage id='exchange.subheader' />
        </p>
      </div>
    );
  };

  render() {
    const {
      renderIframedHeading,
      renderWelcomeMessage,
      renderMainExchangeUI
    } = this;

    const { clientData, formatter } = this.props;
    const isIframed = !!clientData.get('isIframed');
    return (
      <div className='exchange'>
        <Helmet>
          <title>{formatter.formatMessage({ id: 'pageTitle.exchange' })}</title>
          <meta
            name='description'
            content={formatter.formatMessage({ id: 'pageTitle.exchange' })}
          />
        </Helmet>
        <div className='exchange-configure u-padding@md u-padding-small'>
          <div className='section'>
            {isIframed ? renderIframedHeading() : renderWelcomeMessage()}
            {renderMainExchangeUI()}
          </div>
        </div>
      </div>
    );
  }
}

ExchangeConfigure.propTypes = {
  iframeManager: PropTypes.object,
  googleTagManager: PropTypes.object,
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  isShowingSlide: PropTypes.bool,
  isFetchingFromLps: PropTypes.bool,
  isFetchingToLps: PropTypes.bool,
  addSelectMembership: PropTypes.func,
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  allEligibleFromLpIds: ImmutablePropTypes.listOf(PropTypes.string),
  allEligibleToLpIds: ImmutablePropTypes.listOf(PropTypes.string),
  eligibleFromLps: ImmutablePropTypes.map,
  eligibleToLps: ImmutablePropTypes.map,
  offerSet: ImmutablePropTypes.map,
  selectedOffer: ImmutablePropTypes.map,
  isFetchingOfferSet: PropTypes.bool,
  fromAmount: PropTypes.number,
  toAmount: PropTypes.number,
  fromMembership: ImmutablePropTypes.map,
  toMembership: ImmutablePropTypes.map,
  fromLp: ImmutablePropTypes.map,
  toLp: ImmutablePropTypes.map,
  amounts: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  order: ImmutablePropTypes.map,
  exchangeError: ImmutablePropTypes.map,
  shouldResetExchange: PropTypes.bool,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  hasUpdatedMemberships: PropTypes.bool,
  hasFetchedFinalOfferSet: PropTypes.bool,
  user: ImmutablePropTypes.map,
  hasCreatedAccount: PropTypes.bool,
  replace: PropTypes.func,
  push: PropTypes.func,
  fetchEligibleLps: PropTypes.func,
  setExchangeFromMembershipId: PropTypes.func,
  setExchangeToMembershipId: PropTypes.func,
  fetchMemberships: PropTypes.func,
  postNewMembership: PropTypes.func,
  fetchOfferSet: PropTypes.func,
  setSelectedOffer: PropTypes.func,
  resetAmounts: PropTypes.func,
  resetExchange: PropTypes.func,
  setIsShowingSlide: PropTypes.func,
  setPageMessage: PropTypes.func,
  setModalContentKey: PropTypes.func,
  setSelectedExchange: PropTypes.func,
  setHasFetchedFinalOfferSet: PropTypes.func,
  setShouldResetExchange: PropTypes.func,
  setRequireRegistration: PropTypes.func,
  deleteMembership: PropTypes.func
};

function mapStateToProps(state) {
  return {
    messages: state.app.get('messages'),
    iframeManager: state.app.get('iframeManager'),
    googleTagManager: state.app.get('googleTagManager'),
    clientData: state.app.get('clientData'),
    shouldDisableProgramAddition: state.wps.get('disableBlwProgramAddition'),
    formatter: state.app.get('formatter'),
    isShowingSlide: state.app.get('isShowingSlide'),
    isFetchingLps: state.lps.get('isFetchingLps'),
    isFetchingFromLps: state.lps.get('isFetchingFromLps'),
    isFetchingToLps: state.lps.get('isFetchingToLps'),
    allLps: state.lps.get('allLps'),
    allEligibleFromLpIds: state.lps.get('allEligibleFromLpIds'),
    allEligibleToLpIds: state.lps.get('allEligibleToLpIds'),
    addedLps: state.lps.get('addedLps'),
    eligibleFromLps: state.lps.get('eligibleFromLps'),
    eligibleToLps: state.lps.get('eligibleToLps'),
    offerSet: state.exchange.get('offerSet'),
    selectedOffer: state.exchange.get('selectedOffer'),
    isFetchingOfferSet: state.exchange.get('isFetchingOfferSet'),
    fromAmount: state.exchange.get('fromAmount'),
    fromMembership: getMembershipFromState(state, 'from'),
    fromLp: getLpFromState(state, 'from'),
    toAmount: state.exchange.get('toAmount'),
    toMembership: getMembershipFromState(state, 'to'),
    toLp: getLpFromState(state, 'to'),
    amounts: state.exchange.get('amounts'),
    amountIndex: state.exchange.get('amountIndex'),
    order: state.exchange.get('order'),
    exchangeError: state.exchange.get('exchangeError'),
    shouldResetExchange: state.exchange.get('shouldResetExchange'),
    memberships: state.memberships.get('data'),
    hasUpdatedMemberships: state.memberships.get('hasUpdatedMemberships'),
    hasInitializedMemberships: state.memberships.get('hasInitializedMemberships'),
    isUpdatingMemberships: state.memberships.get('isUpdatingMemberships'),
    hasFetchedFinalOfferSet: state.memberships.get('hasFetchedFinalOfferSet'),
    user: state.user.get('data'),
    hasCreatedAccount: state.user.get('hasCreatedAccount'),
    isCreatingAccount: state.user.get('isCreatingAccount'),
    isLinkingAccount: state.user.get('isLinkingAccount'),
    createAccountError: state.user.get('createAccountError'),
    linkAccountEmail: state.user.get('linkAccountEmail'),
    linkAccountError: state.user.get('linkAccountError'),
    hasFetchedPromos: state.promos.get('hasFetchedPromos'),
    promos: state.promos.get('promos')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    replace,
    push,
    addSelectMembership,
    createAccount,
    fetchEligibleLps,
    setEligibleLps,
    resetEligibleFromLps,
    resetEligibleToLps,
    setExchangeFromMembershipId,
    setExchangeToMembershipId,
    fetchMemberships,
    fetchPromos,
    postNewMembership,
    fetchOfferSet,
    setSelectedOffer,
    resetAmounts,
    resetExchange,
    setSelectedAmount,
    registerMembership,
    setIsShowingSlide,
    linkAccount,
    setPageMessage,
    setModalContentKey,
    setSelectedExchange,
    setHasFetchedFinalOfferSet,
    setShouldResetExchange,
    deleteMembership
  }, dispatch);
}

export const ExchangeConfigureContainer = connect(mapStateToProps, mapDispatchToProps)(ExchangeConfigure);
