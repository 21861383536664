import { fromJS } from 'immutable';

const initialState = fromJS({
  membership: {},
  isFetchingMembership: false,
  errors: [],
  lp: {},
  isAttemptingRegistration: false,
  membershipId: '',
  lpId: '',
  partnerMv: ''
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_IS_FETCHING_MEMBERSHIP':
      return state.set('isFetchingMembership', action.isFetchingMembership);
    case 'SET_MEMBERSHIP':
      return state.set('membership', fromJS(action.membership));
    case 'SET_REGISTER_MEMBERSHIP_ERRORS':
      return state.set('errors', fromJS(action.errors));
    case 'SET_REGISTER_MEMBERSHIP_LP':
      return state.set('lp', fromJS(action.lp));
    case 'SET_REGISTER_MEMBERSHIP_IS_ATTEMPTING_REGISTRATION':
      return state.set('isAttemptingRegistration', action.isAttemptingRegistration);
    case 'SET_MEMBERSHIP_ID':
      return state.set('membershipId', fromJS(action.membershipId));
    case 'SET_PARTNER_MV':
      return state.set('partnerMv', fromJS(action.partnerMv));
    case 'SET_REGISTER_MEMBERSHIP_LP_ID':
      return state.set('lpId', action.lpId);
    default:
      return state;
  }
}
