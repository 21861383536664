import React from 'react';
import PropTypes from 'prop-types';

class FullOfferTerms extends React.Component {
    renderContent = (section, key) => (
      <div key={key}>
        {section.content.map((paragraph, key) => (
          <div key={key} className='u-margin-bottom'>
            <div dangerouslySetInnerHTML={{ __html: paragraph }} />
          </div>
        ))}
      </div>
    )

    render() {
      const { terms } = this.props;
      return (
        <div className='fullOfferTerms'>
          {terms.map((section, key) => this.renderContent(section, key))}
        </div>
      );
    }
}

FullOfferTerms.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.string)
  }))
};

export default FullOfferTerms;
