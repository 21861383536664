import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { TextInput } from './TextInput';

const MEMBER_ID_FIELD = 'identifyingFactors.memberId';

export class MembershipRegistrationForm extends Component {
  constructor(props) {
    super(props);

    const { membership } = this.props;
    const credentials = membership.get('credentials').set(MEMBER_ID_FIELD, '');

    this.state = {
      credentials,
      triggerValidation: 0
    };
  }

  componentDidUpdate(prevProps) {
    const {
      membership,
      isAttemptingRegistration,
      registerMembership,
      setIsAttemptingRegistration
    } = this.props;
    const { credentials } = this.state;

    const gotIsAttemptingRegistration = !prevProps.isAttemptingRegistration && isAttemptingRegistration;
    if (gotIsAttemptingRegistration) {
      this.triggerValidation();
      if (this.areCredentialsValid()) {
        const membershipWithCredentials = Object.assign({}, membership.toJSON(), { credentials: credentials.toJSON() });
        registerMembership(membershipWithCredentials);
      } else {
        setIsAttemptingRegistration(false);
      }
    }
  }

  setCredentialValue = (value, name) => {
    const { credentials } = this.state;
    this.setState({ credentials: credentials.set(name, value) });
  };

  getValueForField = name => this.state.credentials.get(name, '')

  getPlaceHolder = (fieldName, label) => {
    let result = label;
    if (fieldName === MEMBER_ID_FIELD) {
      result = this.props.membership.getIn(['credentials', MEMBER_ID_FIELD]);
    }

    return result;
  };

  areCredentialsValid = () => {
    const { lp } = this.props;
    const { credentials } = this.state;
    const lpSchema = lp.get('schema');
    const validatedFields = lpSchema.map(field => {
      const name = field.get('name');
      const err = this.validateField(credentials.get(name), name);
      return typeof err !== 'string';
    });
    return !validatedFields.contains(false);
  };

  triggerValidation = () => {
    const { triggerValidation } = this.state;
    this.setState({ triggerValidation: triggerValidation + 1 });
  };

  validateField = (value = '', name = '') => {
    const { lp } = this.props;

    const lpSchema = lp.get('schema');

    const field = lpSchema.find(field => field.get('name') === name);
    const required = field.get('required');
    const maxLength = field.get('maxLength');
    const minLength = field.get('minLength');

    if (required && !value.length) {
      return 'this field is required';
    }

    const shouldValidate = required || value.length;
    if (!shouldValidate) {
      return null;
    }
    const minLengthRequired = minLength && value.length < minLength;
    if (minLengthRequired) {
      return `Minimum size is ${minLength}`;
    }

    const maxLengthRequired = maxLength && value.length > maxLength;
    if (maxLengthRequired) {
      return `Maximum size is ${maxLength}`;
    }
  };

  render() {
    const {
      onSubmit,
      className,
      lp,
      isAttemptingRegistration
    } = this.props;
    const { triggerValidation } = this.state;
    const lpSchema = lp.get('schema');

    return (
      <form onSubmit={onSubmit} className={className}>
        <div className='u-padding-horizontal'>
          {lpSchema.map(field => {
            const name = field.get('name');
            const label = field.get('label');
            const fieldValue = this.getValueForField(name);
            const placeHolder = this.getPlaceHolder(name, label);
            const isMemberId = name === MEMBER_ID_FIELD;
            const shouldHideLabel = isMemberId && !fieldValue && !placeHolder || !fieldValue && !isMemberId;

            return (
              <TextInput
                key={name}
                name={name}
                type={field.get('type')}
                label={label}
                placeholder={placeHolder}
                validator={this.validateField}
                triggerValidation={triggerValidation}
                disabled={isAttemptingRegistration}
                onChange={this.setCredentialValue}
                value={fieldValue}
                hideLabel={shouldHideLabel}
              />
            );
          })}
        </div>
      </form>
    );
  }
}

MembershipRegistrationForm.propTypes = {
  membership: ImmutablePropTypes.map.isRequired,
  isAttemptingRegistration: PropTypes.bool.isRequired,
  registerMembership: PropTypes.func.isRequired,
  lp: ImmutablePropTypes.map.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  setIsAttemptingRegistration: PropTypes.func.isRequired
};
