export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatNumber(num) {
  if (!num || Number.isNaN(num)) {
    return num;
  }
  const str = num.toString().split('.');
  if (str[0].length > 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  return str.join('.');
}

// getNumberFromString converts a string to a number.
// Currently assumes that the input is a string with only digits, commas and periods.
export function getNumberFromString(val) {
  if (val && typeof val === 'string') {
    return parseInt(val.replace(/[,\.]/g, ''));
  } else if (!val || !isValidNumber(val)) {
    return 0;
  }
  return val;
}

export function isValidNumber(val) {
  return typeof val === 'number' && !Number.isNaN(val);
}
