/**
   Component that opens and closes to show more content
   @class Drawer Component
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { handleKeyPressEnter, handleKeyPressEscape } from './utils/accessibility-helpers';

function generateGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

class Drawer extends React.Component {
  static defaultProps = {
    isOpen: false,
    header: null
  };

  state = {
    isOpen: !!this.props.isOpen,
    id: `drawer__body-${generateGuid()}`
  };

  componentDidMount() {
    this.drawerEl.addEventListener('transitionend', this.onTransitionEnd);
    if (this.state.isOpen) {
      this.onTransitionEnd();
      this.updateClassList(true);
      this.height = this.getHeight();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      this.toggleDrawer();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;
    const wasOpen = prevState.isOpen;
    if (isOpen !== wasOpen) {
      setTimeout(() => this.updateClassList(isOpen));
    }
  }

  componentWillUnmount() {
    this.drawerEl.removeEventListener('transitionend', this.onTransitionEnd);
  }

  onDrawerKeyUp = event => {
    handleKeyPressEnter(this.toggleDrawer)(event);
    handleKeyPressEscape(this.closeDrawer)(event);
  };

  onTransitionEnd = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.drawerEl.classList.add(this.afterOpenClass);
    } else {
      this.drawerHeaderEl.classList.remove(this.drawerHeaderOpenClass);
    }
  };

  getHeight = () => {
    const _cssText = this.drawerEl.style.cssText;
    this.drawerEl.style.cssText = 'visibility: hidden; position: absolute; height: auto; width: 100%;';
    const height = `${this.drawerEl.offsetHeight}px`;
    this.drawerEl.style.cssText = _cssText;
    return height;
  };

  afterOpenClass = 'drawer__body--after-open';

  closeDrawer = () => {
    this.setState({ isOpen: false });
    this.height = this.getHeight();
  };

  drawerEl = null;

  drawerHeaderEl = null;
  drawerHeaderOpenClass = 'drawer__header--open';

  height = null;

  openClass = 'drawer__body--open';

  toggleDrawer = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.height = this.getHeight();
  };

  updateClassList = isOpen => {
    if (isOpen) {
      this.drawerHeaderEl.classList.add(this.drawerHeaderOpenClass);
      this.drawerEl.classList.add(this.openClass);
    } else {
      this.drawerEl.classList.remove(this.afterOpenClass);
      // delay to allow css animation to execute properly
      setTimeout(() => this.drawerEl.classList.remove(this.openClass), 50);
    }
  };

  renderStyle = () => {
    if (!this.drawerEl) { return; }
    const { id } = this.state;
    return (
      <style>
        {`#${id}.${this.openClass} { height: ${this.height} }`}
      </style>
    );
  };

  render() {
    const { isOpen, id } = this.state;
    const { children, header, classes } = this.props;
    return (
      <div className={classnames('drawer', classes)}>
        {this.renderStyle()}
        <div
          ref={el => this.drawerHeaderEl = el}
          className='drawer__header'
          role='button'
          tabIndex='0'
          aria-expanded={isOpen}
          aria-controls={id}
          onClick={this.toggleDrawer}
          onKeyUp={this.onDrawerKeyUp}
        >
          <div className='drawer__header__content'>
            { header }
          </div>
          <i className={classnames('drawer__header__icon', { 'unsullied-icon-chevron-up': isOpen, 'unsullied-icon-chevron-down': !isOpen })} />
        </div>
        <div className='drawer__body' id={id} ref={el => this.drawerEl = el}>
          <div
            className='drawer__body-content'
            aria-hidden={!isOpen}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.node,
  classes: PropTypes.string
};

export default Drawer;
