import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { getUniqueLogoPath, getLogoPath } from './utils/image-helpers';
import constants from '../utils/constants';
import { validateVerificationCode } from '../utils/validations';
import { FormattedMessage } from 'react-intl';
import FancyField from './FancyField';
import FancyButton from './FancyButton';

class VerificationCode extends React.Component {
  state = {
    verificationCode: '',
    fadeOut: false
  };

  UNSAFE_componentWillMount() {
    const { appContext, googleTagManager } = this.props;
    googleTagManager.pushGtmData({
      action: constants.ANALYTICS_GOTO_VERIFICATION_CODE,
      appContext
    });
  }

  componentDidUpdate(prevProps) {
    const { hasCreatedAccount, linkAccountError, onError, fadeOut } = this.props;
    if (!prevProps.hasCreatedAccount && hasCreatedAccount) {
      if (fadeOut) {
        this.setState({ fadeOut: true });
        setTimeout(() => {
          this.onSuccess();
        }, constants.baseAnimationLength);
      } else {
        this.onSuccess();
      }
    }

    if (linkAccountError.size && onError) {
      onError(linkAccountError.first());
    }
  }

  onSuccess = () => {
    const { onSuccess } = this.props;
    if (onSuccess) {
      onSuccess();
    }
  };

  getIsVerificationCodeInvalid = () => typeof validateVerificationCode(this.state.verificationCode) === 'string';

  setVerficationCode = verificationCode => {
    this.setState({ verificationCode });
  };

  linkAccountErrorEl = null;

  openModal = () => {
    const { setModalContentKey } = this.props;

    setModalContentKey(constants.modals.createAccountVerificationCode);
  };

  resendCode = () => {
    const { linkAccountEmail, sendCode } = this.props;
    if (!linkAccountEmail) {
      return;
    }
    sendCode(linkAccountEmail);
    this.openModal();
  };

  resendCodeTagEl = null;

  submitVerificationCode = e => {
    e.preventDefault();
    const { linkAccount, linkAccountEmail } = this.props;
    const { verificationCode } = this.state;
    linkAccount(linkAccountEmail, verificationCode);
  };

  submitVerificationCodeEl = null;

  render() {
    const { fadeOut } = this.state;
    const { clientData, linkAccountError, isCreatingAccount, formatter } = this.props;
    const wpName = clientData.get('wpName');
    const wpCode = clientData.get('wpCode');
    const partnerLogoAlt = formatter.formatMessage({ id: 'shared.partnerLogo' }, { lpName: wpName });
    const logoPath = getUniqueLogoPath(wpCode);

    return (
      <div className={classnames('verification-code u-padding-top-large u-padding-horizontal u-margin-bottom transition--base', { 'opacity-0': fadeOut, 'opacity-10': !fadeOut })}>
        <div className='o-layout o-layout--center'>
          <form onSubmit={this.submitVerificationCode}>
            <div className='o-layout__item u-2/3@md u-1/1'>

              <div className='o-layout o-layout--center'>
                <div className='o-layout__item u-1/2'>
                  <img alt={partnerLogoAlt} className='u-1/1' src={logoPath} />
                </div>
              </div>


              <h2 className='u-padding-vertical rule'>
                <span className='slide__header__span'>
                  {formatter.formatMessage({ id: 'verificationCode.linkAccountTitle' })}
                </span>
              </h2>

              <div className='u-padding-horizontal'>
                <h3>
                  <FormattedMessage id='verificationCode.accountSafetyMessage' />
                </h3>
                <p id='verification-code-email-msg'>
                  <FormattedMessage id='verificationCode.codeEmailedMessage' />
                </p>
              </div>

              {
                linkAccountError.size ? (
                  <div className='message message--error'>
                    <ul>
                      {
                        linkAccountError.get('errors').map(error => {
                          const errorCode = error.get('code');
                          return (
                            <li
                              key={errorCode}
                              ref={el => this.linkAccountErrorEl = el}
                              className='text-base-size-palm'
                            >
                              <span
                                aria-label={formatter.formatMessage({ id: errorCode })}
                                aria-live='assertive'
                                role='alert'
                              >
                                <FormattedMessage id={errorCode} />
                              </span>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </div>
                ) : null
              }

              <div className='card create-account'>
                <div className='o-layout'>
                  <div className='o-layout__item'>
                    <div>
                      <div className='u-margin-vertical-large'>
                        <FancyField
                          classes='text-center'
                          type='text'
                          label={formatter.formatMessage({ id: 'verificationCode.verificationCodeLabel' })}
                          placeholder={formatter.formatMessage({ id: 'verificationCode.verificationCodePlaceholder' })}
                          validator={validateVerificationCode}
                          onChange={this.setVerficationCode}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <FancyButton
                ref={el => this.submitVerificationCodeEl = el}
                classes='btn btn-primary u-1/1 u-padding'
                type='submit'
                label='verificationCode.linkAccountsButtonMessageText'
                disabled={this.getIsVerificationCodeInvalid()}
                trigger={isCreatingAccount}
                onClick={this.submitVerificationCode}
              />

              <a ref={el => this.resendCodeTagEl = el} className='u-margin-top ib' onClick={this.resendCode}>
                <FormattedMessage id='verificationCode.codeNotReceivedLink' />
              </a>

              <div className='text-center u-padding-vertical u-margin-top-huge'>
                <img alt='Points logo' className='u-1/4' src={getLogoPath('points_powered_by_points')} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

VerificationCode.propTypes = {
  appContext: PropTypes.string,
  googleTagManager: PropTypes.object,
  hasCreatedAccount: PropTypes.bool,
  onError: PropTypes.func,
  fadeOut: PropTypes.bool,
  linkAccountError: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    PropTypes.bool
  ]),
  onSuccess: PropTypes.func,
  linkAccount: PropTypes.func,
  linkAccountEmail: PropTypes.string,
  clientData: ImmutablePropTypes.map,
  isCreatingAccount: PropTypes.bool,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  sendCode: PropTypes.func,
  setModalContentKey: PropTypes.func
};

export default VerificationCode;
