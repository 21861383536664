import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { EarnRedeemFooter } from './EarnRedeemFooter';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { fromJS } from 'immutable';
import { externalRedirect } from '../utils/url';

const defaultTransaction = fromJS({
  isSendingToSelf: true
});

const getHeroText = successfulRedeemTransaction => {
  let recipient;
  if (successfulRedeemTransaction.get('isSendingToSelf')) recipient = 'Self';
  else recipient = 'Others';
  return {
    header: `redeem.success.hero.to${recipient}.header`,
    subheading: `redeem.success.hero.to${recipient}.subheading`,
    translationValues: {
      points: successfulRedeemTransaction.get('fromAmount') ? successfulRedeemTransaction.get('fromAmount').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
      credit: successfulRedeemTransaction.get('toAmount') ? `$${successfulRedeemTransaction.get('toAmount') / 100}` : ''
    }
  };
};

const getDescription = successfulRedeemTransaction => {
  let recipient;
  if (successfulRedeemTransaction.get('isSendingToSelf')) recipient = 'Self';
  else recipient = 'Others';
  return `redeem.success.to${recipient}.description`;
};

const RedeemFlowSuccess = props => {
  window.scrollTo(0, 0);
  const { renderHero, push, partnerLpId, clientData } = props;
  let { successfulRedeemTransaction } = props;
  if (!successfulRedeemTransaction.size) successfulRedeemTransaction = defaultTransaction;
  const heroText = getHeroText(successfulRedeemTransaction);
  const shopAndEarnUrls = clientData.get('shopAndEarnUrls');
  const shopAndEarnUrl = shopAndEarnUrls.get(partnerLpId, shopAndEarnUrls.get('default'));

  return (
    <div className='redeem earnAndRedeem'>
      {renderHero(heroText)}
      <div className='redeem__success__transaction__container'>
        <div id='redeem__success__transaction__header'><FormattedMessage id='redeem.success.header' /></div>
        <div id='redeem__success__transaction__description'><FormattedMessage id={getDescription(successfulRedeemTransaction)} /></div>
      </div>
      <div className='redeem__success__ad__container'>
        <div className='redeem__success__ad__btnContainer'>
          <div id='redeem__success__ad__redeem'>
            <div id='redeem__success__ad__redeemCTA'><FormattedMessage id='redeem.success.redeem.CTA' /></div>
            <button className='redeem__success__ad__btn' onClick={() => externalRedirect('/redeem-flow')}>
              <FormattedMessage id='redeem.success.redeem.CTA.btn.text' />
            </button>
          </div>
          <div className='vl' />
          <div id='redeem__success__ad__earn'>
            <div id='redeem__success__ad__earnCTA'><FormattedMessage id='redeem.success.earn.CTA' /></div>
            <button className='redeem__success__ad__btn' onClick={() => externalRedirect(shopAndEarnUrl)}>
              <FormattedMessage id='redeem.success.earn.CTA.btn.text' />
            </button>
          </div>
        </div>
        <div className='redeem__success__ad__earnBanner'>
          <div id='redeem__success__ad__earnBanner__textContainer'>
            <div id='redeem__success__ad__earnBanner__header'><FormattedMessage id='redeem.success.ad.header' /></div>
            <div id='redeem__success__ad__earnBanner__description'><FormattedHTMLMessage id='redeem.success.ad.description' /></div>
          </div>
          <button className='redeem__success__ad__earnBanner__btn' onClick={() => push('/earn-flow')}>
            <FormattedMessage id='redeem.success.ad.btn.text' />
          </button>
        </div>
      </div>
      <div id='earnFlow__footer__container'>
        <EarnRedeemFooter />
      </div>
    </div>
  );
};

RedeemFlowSuccess.propTypes = {
  renderHero: PropTypes.func,
  successfulRedeemTransaction: ImmutablePropTypes.map,
  push: PropTypes.func,
  partnerLpId: PropTypes.string,
  clientData: ImmutablePropTypes.map
};

export const RedeemFlowSuccessContainer = RedeemFlowSuccess;
