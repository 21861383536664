import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { FormattedHTMLMessage } from 'react-intl';
import { getQueryParamsAsObject } from '../utils/url';
import Slide from './Slide';
import Checkbox from './Checkbox';
import ActionLink from './ActionLink';
import FocusTrap from './FocusTrap';
import FullOfferTerms from './FullOfferTerms';
import EarnTcs from './EarnTcs';
import { FaQuestionCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { setIsShowingSlide } from '../action-creators/app';

class UnconnectedEarnRedeemCheckBoxes extends React.Component {
    state = {
      earnPromoSlideContent: Map(),
      toggleSlide: 0
    }

    setEarnPromoSlideContent = content => {
      const { setIsShowingSlide } = this.props;
      if (content) {
        this.setState({ toggleSlide: this.state.toggleSlide + 1 });
        // This happens every time we want to show the slide (we forced the slide to be hidden with the shouldHideOnLoad hack, so isShowingSlide is never updated)
        // To fix this, we correct the omited call to setIsShowingSlide
        setIsShowingSlide(true);
      }
      this.setState({ earnPromoSlideContent: Map(content) });
    };

    renderEarnPromoModal = () => {
      const { formatter, setIsShowingSlide, isShowingSlide } = this.props;
      const { earnPromoSlideContent, toggleSlide } = this.state;
      const disableHtmlFormatting = earnPromoSlideContent.get('disableHtmlFormatting');
      const description = earnPromoSlideContent.get('description');
      const header = earnPromoSlideContent.get('header');
      const queryParams = getQueryParamsAsObject();

      let content = description;
      if (!disableHtmlFormatting && description) {
        content = <FormattedHTMLMessage id={description} />;
      }

      return (
        <FocusTrap isActive={isShowingSlide}>
          <Slide
            handleClose={this.setEarnPromoSlideContent}
            formatter={formatter}
            header={header ? formatter.formatMessage({ id: header }) : null}
            setIsShowingSlide={setIsShowingSlide}
            toggleSlide={toggleSlide}
            shouldHideOnLoad
            disableClose={queryParams.close === 'disabled'}
          >
            <div className='section section--compact u-padding-small text-left'>
              { earnPromoSlideContent.size ? content : null }
            </div>
          </Slide>
        </FocusTrap>
      );
    };

      showFullOfferTerms = () => {
        const { messages } = this.props;
        this.setEarnPromoSlideContent({
          header: 'earn.fullOfferTerms.slide.header',
          description: <FullOfferTerms terms={messages.get('earn.fullOfferTerms.description').toJSON()} />,
          disableHtmlFormatting: true
        });
      };

      showPointsTCsModal = () => {
        const { messages } = this.props;
        this.setEarnPromoSlideContent({
          header: 'earn.pointsTCs',
          description: <EarnTcs tcs={messages.get('earn.pointsTCsTerms')} />,
          disableHtmlFormatting: true
        });
      };

      renderLinkAccountsCheckbox = () => (
        <div className='flex flex-space-between'>
          <div className='checkbox__container flex-left text-left'>
            <Checkbox
              name='isTCCheckboxChecked'
              checked={this.props.isTCCheckboxChecked}
              onChange={this.props.handleTCsCheckboxChange}
            />
            {this.renderAgreementStatement()}
          </div>
        </div>
      )

      renderFullOfferTermsCheckbox = () => (
        <div className='flex flex-space-between'>
          <div className='checkbox__container flex flex-left text-left'>
            <Checkbox
              name='isFullOfferTermsChecked'
              checked={this.props.isFullOfferTermsChecked}
              onChange={this.props.handleFullOfferCheckboxChange}
            />
            {this.renderFullOfferTermsAgreementStatement()}
          </div>
        </div>
      )

      renderTooltipText = () => {
        const { messages } = this.props;
        const tooltipText = messages.get('earn.toolTip');
        return (
          <p className='earn__tooltipText'>
            <span dangerouslySetInnerHTML={{ __html: tooltipText }} />
          </p>
        );
      }

      renderAgreementStatement = () => {
        const { messages, clientData } = this.props;
        const firstStatement = messages.get('earn.agreementStatement.first');
        const secondStatement = messages.get('earn.agreementStatement.second');
        const showPrivacyPolicy = clientData.get('showPrivacyPolicy');

        return (
          <div id='earnAndRedeem__earn__pointsTCs__agreementStatementContainer'>
            <span>
              <span  dangerouslySetInnerHTML={{ __html: firstStatement }} />
              {showPrivacyPolicy && <span dangerouslySetInnerHTML={{ __html: secondStatement }} />}
            </span>
            {showPrivacyPolicy && (
              <span id='earnAndRedeem__earn__pointsTCs__toolTip'>
                <FaQuestionCircle data-tip data-for='earn__pointsAgreementHelper' className='earn__helpIcon' />
                <ReactTooltip id='earn__pointsAgreementHelper' place='top' type='dark' effect='float'>
                  {this.renderTooltipText()}
                </ReactTooltip>
              </span>
            )}
            {this.renderEarnPromoModal()}
          </div>
        );
      }


      renderFullOfferTermsAgreementStatement = () => {
        const { messages } = this.props;
        const statement = messages.get('earn.fullOfferTerms.agreementStatement');
        return (
          <div>
            <div id='earn__agreementStatementText' dangerouslySetInnerHTML={{ __html: statement }} />
          </div>
        );
      }

      renderPointsTCs = () => {
        const { clientData } = this.props;
        const showLinkAccCheckbox = clientData.get('showLinkAccCheckbox');
        const showFullTandC = clientData.get('showFullTandC');
        return (
          <div className='earnAndRedeem__earn__pointsTCs__checkboxes'>
            {showLinkAccCheckbox ? this.renderLinkAccountsCheckbox() : this.renderPointsTCsAsLink() }
            {showFullTandC ? this.renderFullOfferTermsCheckbox() :  null }
          </div>
        );
      }

      renderPointsTCsAsLink = () => {
        const { isShowingSlide } = this.props;
        return (
          <div className='u-margin-top-large'>
            <ActionLink
              classes='earn__points-tcs'
              onClickAction={this.showPointsTCsModal}
              text='earn.pointsTCs'
              isShowingSlide={isShowingSlide}
            />
            {this.renderEarnPromoModal()}
          </div>
        );
      };

      render() {
        return (
          <div className={`${this.props.containerClassName}`}>
            { this.renderPointsTCs() }
          </div>
        );
      }
}

UnconnectedEarnRedeemCheckBoxes.propTypes = {
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  messages: ImmutablePropTypes.map,
  isShowingSlide: PropTypes.bool,
  setIsShowingSlide: PropTypes.func,
  handleFullOfferCheckboxChange: PropTypes.func,
  handleTCsCheckboxChange: PropTypes.func,
  isTCCheckboxChecked: PropTypes.bool,
  isFullOfferTermsChecked: PropTypes.bool,
  containerClassName: PropTypes.string
};

const mapStateToProps = state => ({
  clientData: state.app.get('clientData'),
  formatter: state.app.get('formatter'),
  messages: state.app.get('messages'),
  isShowingSlide: state.app.get('isShowingSlide')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setIsShowingSlide
}, dispatch);

export const EarnRedeemCheckBoxes = connect(mapStateToProps, mapDispatchToProps)(UnconnectedEarnRedeemCheckBoxes);
