/**
@class exchange Action Creator
*/
/*-------------------------------
 *
 * Sync Actions
 *
 -------------------------------*/
import Exchange from '../api/exchange';
import Memberships from '../api/memberships';
import { addMembership, hasUpdatedMemberships } from './memberships';

/**
sets exchange context for exchange select
@method setExchangeContext
@param {String} context
*/
export function setExchangeContext(context) {
  return {
    type: 'SET_EXCHANGE_CONTEXT',
    context
  };
}

export function setExchangeFromMembershipId(membershipId) {
  return {
    type: 'SET_EXCHANGE_FROM_MEMBERSHIP',
    membershipId
  };
}

export function setExchangeToMembershipId(membershipId) {
  return {
    type: 'SET_EXCHANGE_TO_MEMBERSHIP',
    membershipId
  };
}


export function setSelectedOffer(selectedOffer) {
  return {
    type: 'SET_SELECTED_OFFER',
    selectedOffer
  };
}

export function setSelectedAmount(amountIndex) {
  return {
    type: 'SET_SELECTED_AMOUNT',
    amountIndex
  };
}

export function resetExchange() {
  return {
    type: 'RESET_EXCHANGE'
  };
}

export function resetOfferSet() {
  return {
    type: 'RESET_OFFER_SET'
  };
}

export function resetAmounts() {
  return {
    type: 'RESET_AMOUNTS'
  };
}

export function setOfferSet(offerSet) {
  return {
    type: 'SET_OFFER_SET',
    offerSet
  };
}

export function setOrder(order) {
  return {
    type: 'SET_ORDER',
    order
  };
}

export function isFetchingOfferSet(isFetchingOfferSet) {
  return {
    type: 'IS_FETCHING_OFFER_SET',
    isFetchingOfferSet
  };
}

export function isSubmittingExchange(isSubmittingExchange) {
  return {
    type: 'IS_SUBMITTING_EXCHANGE',
    isSubmittingExchange
  };
}

export function resetExchangeError() {
  return {
    type: 'RESET_EXCHANGE_ERROR'
  };
}

export function setExchangeError(error) {
  return {
    type: 'SET_EXCHANGE_ERROR',
    error
  };
}

export function setSelectedExchange(selectedExchange) {
  return {
    type: 'SET_SELECTED_EXCHANGE',
    selectedExchange
  };
}

export function setShouldResetExchange(shouldResetExchange) {
  return {
    type: 'SET_SHOULD_RESET_EXCHANGE',
    shouldResetExchange
  };
}

/*-------------------------------
 *
 * Async Actions
 *
 -------------------------------*/

export function addSelectMembership(lpId) {
  return dispatch => {
    dispatch(hasUpdatedMemberships(false));
    Memberships.postNewMembership(lpId).then(membership => {
      dispatch(addMembership(membership));
      dispatch(setExchangeToMembershipId(membership.id));
      dispatch(hasUpdatedMemberships(true));
    });
  };
}

export function fetchOfferSet(params) {
  return dispatch => {
    dispatch(isFetchingOfferSet(true));
    Exchange.fetchOfferSet(params)
      .then(offerSet => {
        dispatch(setOfferSet(offerSet));
        dispatch(isFetchingOfferSet(false));
      });
  };
}

export function submitExchange(fraudUniqueId, exchange) {
  return (dispatch, getState) => {
    dispatch(isSubmittingExchange(true));
    return Exchange.submitExchange(fraudUniqueId, exchange).then(order => {
      const iframeManager = getState().app.get('iframeManager');
      dispatch(setOrder(order));
      dispatch(isSubmittingExchange(false));
      iframeManager.postParentExchangeSuccessful();
      iframeManager.scrollParentFrameToTop();
    }).catch(e => {
      dispatch(setExchangeError(e));
      dispatch(isSubmittingExchange(false));
    });
  };
}
