import parseUrl from 'parse-url';

export function  getQueryParamsAsObject(urlParams = window.location.search) {
  if (!urlParams) {
    return {};
  }

  const queryParams = urlParams.substring(1).split('&');
  const params = {};
  queryParams.forEach(param => {
    const keyVal = param.split('=');
    const key = decodeURIComponent(keyVal[0]);
    const val = decodeURIComponent(keyVal[1]);
    if (key && val) {
      params[key] = val;
    }
  });

  return params;
}

export function externalRedirect(url) {
  window.location.assign(url);
}

export function openNewWindow(url) {
  window.open(url);
}

export function getLocationHref() {
  return window.location.href;
}

export function getQueryParams(url) {
  if (!url) return url;

  const parts = parseUrl(url);
  const result = parts.search;
  return result;
}

export function checkIfAllowedOnPage(clientData, user, memberships) {
  const queryParams = getQueryParamsAsObject();
  const url = window.location.href;
  const hasAllowedChildPath = url.includes('/full-offer-terms?close=disabled') || url.includes('/points-account-terms?close=disabled');
  if (!queryParams.mv && !user.size && !memberships.size && !hasAllowedChildPath) {
    const anonymousUserRedirectUrl = clientData.get('anonymousUserRedirectUrl') || '/';
    externalRedirect(anonymousUserRedirectUrl);
  } else {
    return true;
  }
}

export function reloadWithoutMv(push, location) {
  delete location.query.mv;
  push({
    pathname: location.pathname,
    query: location.query
  });
}

export default {
  externalRedirect,
  getLocationHref,
  openNewWindow,
  getQueryParams,
  getQueryParamsAsObject
};
