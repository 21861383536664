/**
Component that stands in as styled input
@class Fancy Button Component
@param {String} name name of input
@param {String} type type of input (text, number, etc..)
@param {Integer} triggerValidation updating counter to trigger validation
@param {String} label label of input
@param {String} placeholder placeholder of input
@param {String} validator
@param initialVal
@param {Method} onChange method that is called on change
*/

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class FancyField extends React.Component {
  state = {
    value: this.props.initialVal || '',
    hasAttemptedInput: false,
    isValid: false,
    errorMessage: ''
  };

  UNSAFE_componentWillMount() {
    this.initValidation(this.state.value);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.triggerValidation !== nextProps.triggerValidation) {
      this.initValidation(this.state.value, true);
    }
  }

  handleBlur = e => {
    this.initValidation(e.target.value);
  };

  handleChange = e => {
    const { value } = e.target;
    const { validator } = this.props;
    this.setState({ value });
    if (this.state.hasAttemptedInput || !validator) {
      if (validator) {
        this.validate(value);
      }
      if (typeof this.props.onChange === 'function') {
        this.props.onChange(value, this.props.name);
      }
    }
  };

  handleEnterKeypress = e => {
    if (e.keyCode === 13 || e.keyCode === '13') {
      this.initValidation(e.target.value);
    }
  };

  initValidation = (value = '', forceValidation = false) => {
    const hasAttemptedInput = value.length || forceValidation;
    if (hasAttemptedInput) {
      if (typeof this.props.validator === 'function') {
        this.validate(value);
      }

      this.setState({ hasAttemptedInput, value });

      if (typeof this.props.onChange === 'function') {
        this.props.onChange(value, this.props.name);
      }
    }
  };

  validate = value => {
    const errorMessage = this.props.validator(value, this.props.name);
    const isValid = typeof errorMessage !== 'string';
    if (isValid) {
      this.setState({ isValid });
    } else {
      this.setState({ isValid, errorMessage });
    }
  };

  render() {
    const { value, hasAttemptedInput, errorMessage, isValid } = this.state;
    const shouldShowError = hasAttemptedInput && !isValid;
    const { classes = '',
      disabled,
      label,
      placeholder,
      onBlur,
      type } = this.props;
    const { name = label } = this.props;
    const dashedName = name ? name.split(' ').join('-') : '';
    const dashedLabel = `${dashedName}-label`;
    const errorLabel = `${dashedName}-error-description`;

    return (
      <div className={classnames('fancy-field', classes, { 'fancy-field--has-content': value.length || hasAttemptedInput })}>
        <label
          className={classnames('fancy-field__label', { 'fancy-field__label--error': shouldShowError })}
          htmlFor={dashedLabel}
        >
          {shouldShowError ? <span id={errorLabel}>{errorMessage}</span> : <span>{label}</span>}
        </label>
        {/* http://stackoverflow.com/questions/15738259/disabling-chrome-autofil */}
        <input
          autoComplete='new-password'
          aria-label={label}
          aria-invalid={shouldShowError}
          aria-describedby={shouldShowError ? errorLabel : null}
          className={classnames('u-1/1', 'fancy-field__input', { 'fancy-field__input--error': shouldShowError })}
          disabled={disabled || false}
          id={dashedLabel}
          name={name}
          onChange={this.handleChange}
          onBlur={onBlur || this.handleBlur}
          onKeyDown={this.handleEnterKeypress}
          placeholder={placeholder}
          type={type || 'text'}
          value={value}
        />
      </div>
    );
  }
}

FancyField.propTypes = {
  initialVal: PropTypes.string,
  triggerValidation: PropTypes.number,
  validator: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

export default FancyField;
