import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';

import FancyButton from './FancyButton';
import { getLogoPath } from './utils/image-helpers';

class PromoDetails extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isRegisteringAccount: false
    };
  }

  renderLpLogo = () => {
    const { allLps, promo } = this.props;
    const lp = allLps.find(lp => lp.get('id') === promo.get('lpId')) || Map();

    return (
      <div className='o-layout o-layout--center'>
        <div className='o-layout__item u-1/2'>
          <img alt={`${lp.get('name')} logo`} className='u-1/1' src={getLogoPath(lp.getIn(['content', 'code']))} />
        </div>
      </div>
    );
  };

  render() {
    const { closePromoDetails, formatter, promo } = this.props;

    return (
      <div className='transition--base u-padding-horizontal-tiny u-padding-horizontal@md u-padding-top-large u-margin-bottom'>
        <div className='o-layout o-layout--center'>
          <div className='o-layout__item u-1/2@lg u-2/3@md u-1/1'>
            { this.renderLpLogo() }

            <h2 className='u-padding-vertical rule'>
              <span className='slide__header__span'>
                {formatter.formatMessage({ id: 'promoSlide.heading' })}
              </span>
            </h2>

            <div className='promo-details'>
              <h3 className='promo-details__header'> { promo.get('balanceTrackerHeader') } </h3>
              <p className='promo-details__body'> { promo.get('balanceTrackerBody') } </p>
              <div className='promo-details__terms u-margin-top-large'>
                <h3>Terms and conditions</h3>
                <p> { promo.get('termsAndConditions') } </p>
              </div>
            </div>

            <FancyButton
              ref={el => this.registerMembershipBtnEl = el}
              classes='btn btn-primary u-1/1 u-margin-top-huge'
              type='submit'
              label='promoSlide.button'
              trigger={this.state.isRegisteringAccount}
              onClick={closePromoDetails}
            />
          </div>
        </div>
      </div>
    );
  }
}

PromoDetails.propTypes = {
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  closePromoDetails: PropTypes.func,
  promo: ImmutablePropTypes.map
};

export default PromoDetails;
