import { List, fromJS } from 'immutable';

const initialState = fromJS({
  isLoggedIn: false,
  isSingleSigningOn: false,
  loginErrors: []
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_IS_SINGLE_SIGNING_ON':
      return state.set('isSingleSigningOn', action.isSingleSigningOn);

    case 'IS_LOGGED_IN':
      return state.set('isLoggedIn', action.isLoggedIn);

    case 'LOGIN_ERRORS':
      return state.set('loginErrors', List(action.loginErrors));

    default:
      return state;
  }
}
