import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { getUniqueLogoPath, getLogoPath } from './utils/image-helpers';


export class Error extends React.Component {
  getText = () => {
    const { appError, location } = this.props;
    const isMaintenance = location.pathname === '/maintenance';
    const header = isMaintenance ? 'maintenance.header' : appError.get('header');
    const description = isMaintenance ? 'maintenance.description' : appError.get('description');
    const contact = isMaintenance ? 'maintenance.contact' : appError.get('contact');

    return {
      header,
      description,
      contact
    };
  };

  renderContactSupportLink = () => {
    const { contact } = this.getText();
    if (!contact) return;

    return (
      <div className='error__contact u-margin-vertical-large-huge'>
        <FormattedHTMLMessage id={contact} />
      </div>
    );
  };

  renderHeaderText = () => {
    const { header } = this.getText();
    if (!header) return;
    return (
      <h2 className='error__header text-weight-8 u-margin-bottom'>
        <FormattedMessage id={header} />
      </h2>
    );
  };

  renderLogo = () => {
    const { clientData, formatter, location } = this.props;
    const wpName = clientData.get('wpName');
    const wpCode = clientData.get('wpCode');
    const isMaintenance = location.pathname === '/maintenance';
    const partnerLogoAlt = formatter.formatMessage({ id: 'shared.partnerLogo' }, { lpName: wpName });
    const logoPath = getUniqueLogoPath(wpCode);

    if (!isMaintenance) return;

    return (
      <img className='u-1/3' alt={partnerLogoAlt} src={logoPath} />
    );
  };

  render() {
    window.scrollTo(0, 0);
    const { formatter, clientData } = this.props;
    // There should always be a description
    const { description } = this.getText();

    return (
      <div className='error-component text-center section u-padding-horizontal u-margin-top-large'>
        <Helmet>
          <title>{formatter.formatMessage({ id: 'pageTitle.error' })}</title>
          <meta
            name='description'
            content={formatter.formatMessage({ id: 'pageTitle.error' })}
          />
        </Helmet>
        {this.renderLogo()}
        {this.renderHeaderText()}

        <p className='u-margin-top-none'>
          <FormattedHTMLMessage id={description} />
        </p>

        {this.renderContactSupportLink()}

        { clientData.get('noPointsLogoFooter') ?
          null :
          <img alt='Points logo' id='error__footer' className='footer__logo u-margin-top' src={getLogoPath('points_powered_by_points')} /> }
      </div>
    );
  }
}

Error.propTypes = {
  appError: ImmutablePropTypes.map,
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

function mapStateToProps(state) {
  return {
    appError: state.app.get('appError'),
    clientData: state.app.get('clientData'),
    formatter: state.app.get('formatter')
  };
}

export const ErrorContainer = connect(mapStateToProps)(Error);
