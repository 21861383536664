import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FancyButton from 'react-fancy-button';
import { v4 as uuidv4 } from 'uuid';

import constants from '../utils/constants';
import { externalRedirect } from '../utils/url';
import { handleKeyPressEscape } from './utils/accessibility-helpers';
import { getMembershipFromState, getLpFromState } from './utils/state-helpers';
import { getEnvironment } from '../utils/application';
import { addKountScript, removeKountScript } from './utils/fraud-helpers';
import { setModalContentKey } from '../action-creators/app';
import { submitExchange, resetExchangeError, setShouldResetExchange } from '../action-creators/exchange';

import ModalHeader from './ModalHeader';
import ExchangeRules from './ExchangeRules';
import { formatNumber } from '../utils/string';

export class ExchangeConfirmationModal extends React.Component {
  state = {
    fraudUniqueId: uuidv4().split('-').join(''),
    isRedirecting: false,
    addedKountScript: false
  };

  componentWillUnmount() {
    removeKountScript();
  }

  closeModal = () => {
    const { setModalContentKey } = this.props;

    setModalContentKey(null);
    this.resetError();
  };

  finishExchange = () => {
    const { clientData, setModalContentKey, setShouldResetExchange } = this.props;
    const afterExchangeRedirectUrl = clientData.get('afterExchangeRedirectUrl');

    this.triggerAnalyticsEvent({
      action: constants.ANALYTICS_COMPLETE_EXCHANGE
    });

    setModalContentKey(null);
    setShouldResetExchange(true);
    if (afterExchangeRedirectUrl) {
      this.setState({ isRedirecting: true }, externalRedirect(afterExchangeRedirectUrl));
    }
  };

  resetError = () => {
    const { resetExchangeError } = this.props;

    setTimeout(resetExchangeError, constants.baseAnimationLength);
  };

  smartClose = () => {
    const { order, exchangeError } = this.props;

    if (order.size) {
      this.finishExchange();
    } else if (exchangeError.size) {
      this.closeModal();
    } else {
      this.resetError();
    }
  };

  submitExchange = () => {
    const { selectedExchange, submitExchange } = this.props;
    const { fraudUniqueId } = this.state;

    const fromMembership = selectedExchange.fromMembership;
    const toMembership = selectedExchange.toMembership;
    const fromLp = selectedExchange.fromLp;
    const toLp = selectedExchange.toLp;

    this.triggerAnalyticsEvent({
      action: constants.ANALYTICS_SUBMIT_EXCHANGE,
      exchangeOutPartner: `${fromLp.getIn(['content', 'companyName'])} ${fromLp.get('name')}`,
      exchangeInPartner: `${toLp.getIn(['content', 'companyName'])} ${toLp.get('name')}`,
      milesOutAccount: fromMembership.getIn(['memberDetails', 'balance']),
      milesInAccount: toMembership.getIn(['memberDetails', 'balance']),
      milesExchangeOut: selectedExchange.fromAmount,
      milesExchangeIn: selectedExchange.toAmount
    });
    submitExchange(fraudUniqueId, selectedExchange);
  };

  triggerAnalyticsEvent = data => {
    this.props.googleTagManager.pushGtmData(Object.assign({
      context: 'exchange'
    }, data));
  };

  renderExchangeError = () => {
    const { exchangeError, formatter, fromLp, fromMembership, selectedOffer } = this.props;
    const errorCode = exchangeError.get('errors').first().get('description');

    return (
      <div>
        <div className='message message--error'>
          <span
            className='text-base-size-palm'
            aria-label={formatter.formatMessage({ id: 'exchangeConfigure.somethingWentWrong' })}
          >
            <FormattedMessage id='exchangeConfigure.somethingWentWrong' />
          </span>
        </div>
        <div className='u-padding'>
          <ExchangeRules
            classes='u-margin-vertical'
            fromLp={fromLp}
            fromMembershipId={fromMembership.get('id')}
            selectedOffer={selectedOffer}
            formatter={formatter}
          />
          <p className='text-base-size-palm'>
            <FormattedMessage id={errorCode} />
          </p>
          <button className='btn btn-primary u-1/1' onClick={this.closeModal}>
            <FormattedMessage id='exchangeConfigure.exchangeErrorClose' />
          </button>
        </div>
      </div>
    );
  };

  renderExchangeOrder = () => {
    const { order } = this.props;
    return (
      <div>
        <div className='message message--success-exchange'>
          <div className='text-base-size-palm'>
            <FormattedHTMLMessage id='exchange.successfulTransactionMessage' />
          </div>
        </div>
        <div className='u-padding'>
          <p className='text-base-size-palm u-margin-vertical-large text-center'>
            <FormattedHTMLMessage id='exchangeConfigure.successExchangeMessage' values={{ email: order.get('confirmationEmail') }} />
          </p>
          <button className='btn btn-primary u-1/1' onClick={this.finishExchange}>
            <FormattedMessage id='exchange.successfulTransactionButton' />
          </button>
        </div>
      </div>
    );
  };

  renderExchangeReview = () => {
    const { fromAmount, toAmount, isSubmittingExchange, user, formatter, fromLp, toLp } = this.props;
    const { fraudUniqueId, addedKountScript } = this.state;
    const env = getEnvironment();
    const confirmMessageValues = {
      fromAmount: formatNumber(fromAmount),
      fromCurrency: fromLp.getIn(['content', 'currencyNameLong']),
      toAmount: formatNumber(toAmount),
      toCurrency: toLp.getIn(['content', 'currencyNameLong']),
      confirmEmail: user.get('email')
    };
    const confirmMessage = <FormattedHTMLMessage id='exchangeConfigure.confirmMessage' values={confirmMessageValues} />;
    const kountDataCollectorUrl = constants.kountUrls[`${env}ScriptUrl`];
    const kountImgUrl = constants.kountUrls.imgUrl;
    const kountScriptSrc = `${kountDataCollectorUrl}${fraudUniqueId}`;
    const kountImgSrc = `${kountImgUrl}${fraudUniqueId}`;

    if (!addedKountScript) {
      addKountScript(kountScriptSrc);
      this.setState({ addedKountScript: true });
    }


    return (
      <div className='u-padding'>
        <div className='push-double--bottom text-center text-base-size-palm u-margin-bottom'>
          { confirmMessage }
        </div>
        <FancyButton
          classes='btn btn-primary u-1/1 btn-small-padding'
          type='submit'
          disabled={isSubmittingExchange}
          label={formatter.formatMessage({ id: 'exchange.confirmExchangeButton' })}
          trigger={isSubmittingExchange}
          onClick={this.submitExchange}
        />
        <img width='1' height='1' src={kountImgSrc} className='hide' alt='' />
      </div>
    );
  };

  render() {
    const { isSubmittingExchange, order, exchangeError, formatter, setModalContentKey } = this.props;
    const { isRedirecting } = this.state;
    const shouldShowExchangeReview = !order.size && !exchangeError.size;

    return (
      <div onKeyUp={handleKeyPressEscape(this.smartClose)}>
        <ModalHeader
          header='exchange.confirmExchangeModalHeader'
          hideCloseButton={isSubmittingExchange}
          afterCloseClick={this.smartClose}
          formatter={formatter}
          setModalContentKey={setModalContentKey}
        />
        { shouldShowExchangeReview ? this.renderExchangeReview() : null }
        <div aria-live='assertive'>
          { order.size ? this.renderExchangeOrder() : null }
          { exchangeError.size && !isRedirecting ? this.renderExchangeError() : null }
        </div>
      </div>
    );
  }
}

ExchangeConfirmationModal.propTypes = {
  isSubmittingExchange: PropTypes.bool,
  order: ImmutablePropTypes.map,
  exchangeError: ImmutablePropTypes.map,
  fromAmount: PropTypes.number,
  toAmount: PropTypes.number,
  user: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  fromLp: ImmutablePropTypes.map,
  toLp: ImmutablePropTypes.map,
  googleTagManager: PropTypes.object,
  selectedExchange: PropTypes.object,
  selectedOffer: ImmutablePropTypes.map,
  fromMembership: ImmutablePropTypes.map,
  clientData: ImmutablePropTypes.map,
  submitExchange: PropTypes.func,
  setModalContentKey: PropTypes.func,
  resetExchangeError: PropTypes.func,
  setShouldResetExchange: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isSubmittingExchange: state.exchange.get('isSubmittingExchange'),
    order: state.exchange.get('order'),
    exchangeError: state.exchange.get('exchangeError'),
    fromAmount: state.exchange.get('fromAmount'),
    toAmount: state.exchange.get('toAmount'),
    user: state.user.get('data'),
    formatter: state.app.get('formatter'),
    fromLp: getLpFromState(state, 'from'),
    toLp: getLpFromState(state, 'to'),
    googleTagManager: state.app.get('googleTagManager'),
    selectedExchange: state.exchange.get('selectedExchange'),
    selectedOffer: state.exchange.get('selectedOffer'),
    fromMembership: getMembershipFromState(state, 'from'),
    clientData: state.app.get('clientData')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    submitExchange,
    setModalContentKey,
    resetExchangeError,
    setShouldResetExchange
  }, dispatch);
}

export const ExchangeConfirmationModalContainer =
  connect(mapStateToProps, mapDispatchToProps)(ExchangeConfirmationModal);
