export default {
  devEnvironments: ['local', 'rc', 'sandbox', 'staging'],
  devEnvKey: 'qa',
  prodEnvKey: 'prod',
  emailRegExPattern: /^[0-9A-Za-z-._+%]+@[0-9A-Za-z-._+]+(\.[0-9A-Za-z]+)$/,
  passwordRegExPattern: /(.*[0-9].*[A-Za-z].*)|(.*[A-Za-z].*[0-9].*)/,
  telephoneRegExPattern: /^[0-9\-(\)\.]*$/,
  passwordMinLength: 6,
  passwordMaxLength: 20,
  phoneMinLength: 8,
  phoneMaxLength: 20,
  firstNameMaxLength: 60,
  lastNameMaxLength: 60,
  firstNameMinLength: 2,
  lastNameMinLength: 2,
  baseAnimationLength: 300,
  longerAnimationLength: 500,
  longestAnimationLength: 1000,
  headerLogoHeight: '35px',
  baseSpacingUnit: 24,
  resultsPerPage: 25,
  defaultLocale: 'en-US',
  keyEnter: 'Enter',
  keyEscape1: 'Escape',
  keyEscape2: 'Esc',
  keyArrowRight: 'ArrowRight',
  keyArrowLeft: 'ArrowLeft',
  keyHome: 'Home',
  keyEnd: 'End',
  dateFormat: 'M/D/YY',
  dateFormatActivity: 'MMMM D, YYYY',

  noOfferSelected: 'noOfferSelected',
  noFromAmount: 'noFromAmount',
  notRegisteredMemberships: 'notRegisteredMemberships',
  mobileMaxWidth: 749,

  POLLING_DELAY: 2000,
  USER_ROLE_GUEST: 'guest',
  USER_ROLE_USER: 'user',
  MEMBER_DETAILS_STATUS_UNREGISTERED: 'unregistered',
  MEMBER_DETAILS_STATUS_PENDING: 'pending',
  MEMBER_DETAILS_STATUS_SUCCESS: 'success',
  MEMBER_DETAILS_STATUS_ERROR: 'error',

  LINK_MEMBERSHIP_MODE: 'link',
  EDIT_LINKED_MEMBERSHIP_MODE: 'edit',

  ANALYTICS_CREATE_ACCOUNT: 'createAccount',
  ANALYTICS_LINK_ACCOUNT: 'linkAccount',
  ANALYTICS_SHOW_MEMBERSHIP_MENU: 'showMembershipMenu',
  ANALYTICS_GOTO_REGISTER: 'goToRegister',
  ANALYTICS_GOTO_SIGNUP: 'goToSignUp',
  ANALYTICS_GOTO_EXCHANGE: 'goToExchange',
  ANALYTICS_GOTO_ACTIVITY: 'goToActivity',
  ANALYTICS_GOTO_EARN: 'goToEarn',
  ANALYTICS_GOTO_VERIFICATION_CODE: 'goToVerificationCode',
  ANALYTICS_GOTO_BALANCE_TRACKER: 'goToBalanceTracker',
  ANALYTICS_CLICK_DELETE_LP: 'clickDeleteLp',
  ANALYTICS_DELETE_LP: 'deleteProgram',
  ANALYTICS_REGISTER_LP: 'registerProgram',
  ANALYTICS_ADD_LP: 'addProgram',
  ANALYTICS_EXCHANGE_IN_SELECT_REGISTERED_LP: 'exchangeInSelectRegisteredLp',
  ANALYTICS_EXCHANGE_OUT_SELECT_REGISTERED_LP: 'exchangeOutSelectRegisteredLp',
  ANALYTICS_SHOW_EXCHANGE_CONFIRM: 'showExchangeConfirm',
  ANALYTICS_SUBMIT_EXCHANGE: 'submitExchange',
  ANALYTICS_COMPLETE_EXCHANGE: 'completeExchange',
  ANALYTICS_COMPLETE_EARN_LANDING: 'completeEarnLanding',

  APP_ERROR_TIMEOUT: {
    description: 'wallet.timeoutError'
  },
  APP_ERROR_CONTACT_POINTS_SUPPORT: {
    description: 'wallet.contactPointsSupport',
    header: 'wallet.defaultErrorHeader'
  },
  APP_ERROR_DEFAULT: {
    header: 'wallet.defaultErrorHeader',
    description: 'wallet.defaultError',
    contact: 'wallet.contactPartnerSupport'
  },
  APP_ERROR_INVALID_PARAMS: {
    header: 'wallet.invalidParamsErrorHeader',
    description: 'wallet.invalidParamsError',
    contact: 'wallet.contactPartnerSupport'
  },

  APP_ERROR_LINKING_EARN: {
    header: 'earn.linkAccount.error.header',
    description: 'earn.linkAccount.error.subheading',
    contact: 'earn.linkAccount.error.description'
  },

  STORAGE: {
    ACCESS_TOKEN: 'accessToken',
    PARTNER_SESSION_ID: 'partnerSessionId',
    PARTNER_POST_URL: 'partnerPostUrl'
  },

  pendingOrdersStatuses: ['pendingWalletSignup', 'pendingLpRegistration', 'creditPaused'],
  createAccount: {
    balanceTracker: 'balanceTracker',
    exchange: 'exchange'
  },

  orderType: {
    exchange: 'EXCHANGE',
    earn: 'EARN',
    redeemDebit: 'REDEEM_DEBIT'
  },

  orderSubType: {
    milesEarnedPerDollarSpent: 'milesEarnedPerDollarSpent'
  },

  orderStatus: {
    pending: 'PENDING',
    complete: 'COMPLETE',
    failed: 'FAILED',
    cancelled: 'CANCELLED'
  },

  product: {
    buy: 'buy',
    earn: 'earn',
    activity: 'activity',
    exchange: 'exchange',
    balanceTracker: 'balanceTracker',
    redeem: 'redeem'
  },

  featureFlag: {
    navigationTermsAndConditions: 'navTermsAndConditions'
  },

  amexSSOWidgetLibrary: {
    qa: {
      url: 'https://flexpartnerlogin-qa.americanexpress.com/js/1.0/FREE2.min.js',
      checksum: 'sha384-Yd1umnJVVLlePJiEFtjh2tMqZNyyTp9SQWg0wT+C8inGLe6zMPlT5uSSzTw0YK6K'
    },
    prod: {
      url: 'https://flexpartnerlogin.americanexpress.com/js/1.0/FRE.min.js',
      checksum: 'sha384-6HntQTwR+IfJEX4gktN5g2zZXVmLvBjZNNlGUvjJHblYeQrAPX+WEzSaJVIun04+'
    }
  },

  lpsWithSSOWidget: {
    amex: '1543',
    testChurros: '5506'
  },

  memberAuthenticatorKey: 'params',

  lyftRedirectUrls: {
    linkingSuccess: '/earn-flow/success',
    // For linking errors, we should go to /earn-flow/linking-error, but there is a bug on the BE that needs to be resolved first regarding guest users. Please watch DLW-4035.
    linkingError: '/error',
    redemptionSuccess: '/redeem-flow/success',
    redemptionError: '/redeem-flow/redeeming-error',
    signOut: '/start',
    redeemPage: '/redeem-flow',
    earnPage: '/earn-flow'
  },

  exchange: {
    inputValidationErrorMax: 'exchange.validationMaxAmountError',
    inputValidationErrorMin: 'exchange.validationMinAmountError',
    i18nAmountsErrorMax: 'exchange.exceededExchangeAmounts',
    i18nAmountsErrorMin: 'exchange.minExchangeAmountsError',
    i18nAmountsErrorIncrement: 'exchange.incrementalExchangeAmounts',
    i18AmountsDefaultMessage: 'exchange.exchangeAmountsFor'
  },

  kountUrls: {
    imgUrl: 'https://assets.points.com/logo.htm?m=164300&s=',
    prodScriptUrl: 'https://ssl.kaptcha.com/collect/sdk?m=164300&s=',
    qaScriptUrl: 'https://tst.kaptcha.com/collect/sdk?m=164300&s='
  },

  timeoutEarnRedirect: 2000,

  modals: {
    exchangeDisabled: 'exchangeDisabled',
    exchangeRules: 'exchangeRules',
    exchangeConfirmation: 'exchangeConfirmation',
    exchangeFromSelect: 'exchangeFromSelect',
    exchangeToSelect: 'exchangeToSelect',
    createAccountVerificationCode: 'createAccountVerificationCode'
  },

  errorCodes: {
    duplicateMemberId: 'DUPLICATE_MEMBER_ID'
  },

  fixEarnOrderErrors: {
    requireOrderId: 'FIX_EARN_ORDER_ERROR_REQUIRE_ORDER_ID',
    invalidSession: 'FIX_EARN_ORDER_ERROR_INVALID_SESSION',
    lpNotFound: 'FIX_EARN_ORDER_ERROR_LP_NOT_FOUND',
    membershipNotFound: 'FIX_EARN_ORDER_ERROR_MEMBERSHIP_NOT_FOUND',
    orderNotFound: 'FIX_EARN_ORDER_ERROR_ORDER_NOT_FOUND',
    orderUpdateIssue: 'FIX_EARN_ORDER_ERROR_ORDER_UPDATE_ISSUE',
    genericIssue: 'FIX_EARN_ORDER_ERROR_GENERIC_ISSUE',
    registrationUrlNotFound: 'FIX_EARN_ORDER_ERROR_REGISTRATION_URL_NOT_FOUND'
  },

  earnIntegrationType: {
    lyft: 'lyft',
    performanceHorizons: 'performance-horizons'
  },

  publicLandingPageRegex: /^\/(start|earn)/i,
  logOutPathRegex: /^\/(start|hiltonhonorslyft)/i,

  lyftAppBadgeUrls: {
    'apple': 'https://itunes.apple.com/us/app/lyft-on-demand-ridesharing/id529379082?mt=8',
    'google-play': 'https://play.google.com/store/apps/details?id=me.lyft.android',
    'amazon-app': 'https://www.amazon.com/Lyft-Taxi-App-Alternative/dp/B01M19LARV',
    'microsoft': 'https://www.microsoft.com/store/apps/9PJKS5V4V997?ocid=badge'
  },

  lyftAppDownloadUrl: 'https://lyft.sng.link/Adnq0/mf37?_dl=lyft%3A%2F%2F'
};
