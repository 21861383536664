/**
 @class Settings API
 */

/**
 `GET /public/settings/{clientId}` get settings for a given BLW client
 @method fetchClientData
 @param {String} clientId
 */
export function fetchClientData(clientId, locale) {
  return fetch(`/public/settings/${clientId}/${locale}`)
    .then(res => res.json());
}

export default { fetchClientData };
