import React from 'react';
import PropTypes from 'prop-types';
import { EarnRedeemFooter } from './EarnRedeemFooter';
import { SwitchEarnToRedeemBox } from './SwitchEarnToRedeemBox';
import { FormattedHTMLMessage } from 'react-intl';
import { AdContainer } from './AdContainer';

const EarnFlowError = ({ renderHero }) => {
  window.scrollTo(0, 0);
  const heroText = {
    header: 'earn.linkAccount.error.header',
    subheading: 'earn.linkAccount.error.subheading'
  };
  return (
    <div className='earn earnAndRedeem'>
      {renderHero(heroText)}
      <div className='earnFlow__errorMsg'>
        <FormattedHTMLMessage id='earn.linkAccount.error.description' />
      </div>
      <AdContainer bannerSrc='banner-background.png' />
      <SwitchEarnToRedeemBox />
      <div id='earnFlow__footer__container'>
        <EarnRedeemFooter />
      </div>
    </div>
  );
};

EarnFlowError.propTypes = {
  renderHero: PropTypes.func
};

export const EarnFlowErrorContainer = EarnFlowError;
