import constants from './constants';

let formatter;

export function setIntlFormatterOnValidations(intl) {
  formatter = intl;
}

const _formatMessage = (id, value = {}) => {
  if (!formatter) {
    return id;
  }
  return formatter.formatMessage({ id }, value);
};

export function validateEmail(email) {
  if (!email.length) {
    return _formatMessage('validations.requiredField');
  }

  if (!email.match(constants.emailRegExPattern)) {
    return _formatMessage('validations.invalidEmail');
  }
}

export function validateVerificationCode(code) {
  if (!code.length) {
    return _formatMessage('validations.requiredField');
  }

  if (code.length < 6 || code.length > 6) {
    return _formatMessage('validations.invalidCodeLength');
  }
}

export function validateRequiredField(value) {
  if (typeof value === 'boolean') { return; }
  if (typeof value === 'number' && value === 0) { return; }
  const isEmptyImmutable = value && value.toJSON && !value.size;
  const isEmptyArray = Array.isArray(value) && (!value.length || value.join('').trim() === '');
  if (isEmptyImmutable || isEmptyArray || !value) {
    return _formatMessage('validations.requiredField');
  }
}

export function compareValidation(getValue, id) {
  return function comparator(value) {
    if (value !== getValue()) {
      return _formatMessage(id);
    }
  };
}
