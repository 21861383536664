if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

let _store;

export default {

  setStore(store) {
    _store = store;
  },

  triggerPageView(url, title = '', data) {
    if (!title) {
      title = url.split('/').slice(1).join('.');
    }
    this.pushGtmData(Object.assign({
      event: 'BLWVirtualPageview',
      virtualPageURL: url,
      virtualPageTitle: title
    }, data));
  },

  pushGtmData(data) {
    if (!_store) return;

    const eventName = window.location.pathname.includes('/earn') ? 'earnEvent' : 'blwEvent';

    const vars = {
      clientName: 'default',
      walletParterName: _store.getState().app.getIn(['clientData', 'wpCode']),
      locale: _store.getState().app.get('locale'),
      userRole: _store.getState().user.getIn(['data', 'role']),
      event: eventName,

      // analytics event action
      action: null,

      errorCode: null,

      // exchange lp id
      lpId: null,

      // exchange lp name
      lpName: null,

      context: null,
      status: null,

      // is user registered?
      isRegistered: null,

      // earn specific properties
      winningOfferId: null,
      isFastTrack: null
    };

    window.dataLayer.push(Object.assign(vars, data));
  }
};
