import classnames from 'classnames';
import { List, Map } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FancyButton from 'react-fancy-button';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedHTMLMessage, FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import { fetchLocale } from '../action-creators/app';
import {
  fetchMembership,
  login,
  registerMembership,
  setIsAttemptingRegistration,
  submitRegistrationForm,
  validateRedirectUrlsAndLogin
} from '../action-creators/register-membership';

import constants from '../utils/constants';
import { getQueryParamsAsObject, externalRedirect } from '../utils/url';

import { IntlContainer } from './Intl';
import { JoinNowLink } from './JoinNowLink';

import { MembershipRegistrationForm } from './MembershipRegistrationForm';
import { getLogoPath } from './utils/image-helpers';
import { shouldHideMemberData } from './utils/wps-helpers';

export class InlineCredentialCatcher extends Component {
    state = {
      membershipId: this.props.params.id,
      showSuccessUI: false,
      mode: constants.LINK_MEMBERSHIP_MODE
    };

    componentDidMount() {
      const { clientData, login, fetchLocale, push, params, validateRedirectUrlsAndLogin } = this.props;

      const membershipId = params.id;
      const queryParams = getQueryParamsAsObject();
      const mv = queryParams.mv;
      const locale = clientData.get('locale');
      const redirectUrlParam = queryParams.partnerRedirectUrls;

      fetchLocale(locale);
      if (redirectUrlParam) {
        const decodedAndParsedUrls = JSON.parse(window.atob(redirectUrlParam));
        validateRedirectUrlsAndLogin(decodedAndParsedUrls, mv);
      } else {
        login(mv);
      }

      push(`/register-membership/${membershipId}`);
    }

    componentDidUpdate(prevProps) {
      const {
        clientData,
        isLoggedIn,
        fetchMembership,
        membership
      } = this.props;

      const { membershipId } = this.state;
      const gotLoggedIn = !prevProps.isLoggedIn && isLoggedIn;
      const fetchedInitialMembership = prevProps.membership.isEmpty() && !membership.isEmpty();
      const membershipHasUpdated = !prevProps.membership.isEmpty() && prevProps.membership.get('updatedAt') !== membership.get('updatedAt');

      if (gotLoggedIn) {
        fetchMembership(membershipId);
      }

      if (fetchedInitialMembership) {
        this.onFetchingInitialMembership(
          clientData.getIn(['credentialCatchers', 'inline', 'showBlankFormOnUpdate']),
          membership.get('isRegistered')
        );
      }

      if (membershipHasUpdated) {
        this.onUpdatingMembership(membership.get('memberDetailsStatus'));
      }
    }

  onUpdatingMembership = memberDetailsStatus => {
    if (memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_SUCCESS) {
      this.setState({ showSuccessUI: true });
    }
  }

  onFetchingInitialMembership = (showBlankFormOnUpdate, alreadyRegistered) => {
    const { EDIT_LINKED_MEMBERSHIP_MODE, LINK_MEMBERSHIP_MODE } = constants;
    const mode = !showBlankFormOnUpdate && alreadyRegistered ? EDIT_LINKED_MEMBERSHIP_MODE : LINK_MEMBERSHIP_MODE;

    this.setState({
      mode
    });
  }

  getAccountIdLabel = membership => {
    const accountId = membership.getIn(['credentials', 'identifyingFactors.memberId']) || '';
    return accountId ? `#${accountId}` : '';
  };

  renderLpLogo = () => {
    const { lp } = this.props;

    if (lp.isEmpty()) {
      return null;
    }

    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpCode = lp.getIn(['content', 'code']);

    return (
      <div className='register_membership__program_logo'>
        <img
          alt={`${lpCompanyName} logo`}
          src={getLogoPath(lpCode)}
        />
      </div>
    );
  };

  renderMembershipErrors = () => {
    const { formatter, membership } = this.props;

    const isPolling = membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    const membershipErrors = membership.get('memberDetailsErrors') || List();

    if (membershipErrors.isEmpty() || isPolling) {
      return null;
    }

    return (
      <div className='register_membership__message_error'>
        {
          membershipErrors.map(error => {
            const defaultMessage = formatter.formatMessage({ id: 'membershipRegistration.defaultError' });
            return (
              <div
                key={error}
                className='u-margin-vertical-none'
                aria-live='assertive'
                role='alert'
                aria-label={formatter.formatMessage({ id: `membershipRegistration.${error}`, defaultMessage })}
              >
                <FormattedMessage id={`membershipRegistration.${error}`} defaultMessage={defaultMessage} />
              </div>
            );
          })
        }
      </div>
    );
  };

  renderRegistrationForm = () => {
    const {
      lp,
      membership,
      isAttemptingRegistration,
      setIsAttemptingRegistration,
      submitRegistrationForm,
      registerMembership,
      formatter,
      clientData
    } = this.props;

    const { mode } = this.state;

    const buttonText = formatter.formatMessage({ id: `credentialCatcher.${mode}.heading` });
    const signUpUrl = lp.getIn(['content', 'websiteUrl']);
    const showBlankFormOnUpdate = clientData.getIn(['credentialCatchers', 'inline', 'showBlankFormOnUpdate']);

    return (
      <div className='register_membership__form_container'>
        {this.renderMembershipErrors()}
        <MembershipRegistrationForm
          className='register_membership__form'
          lp={lp}
          membership={showBlankFormOnUpdate ? membership.set('credentials', Map()) : membership}
          registerMembership={registerMembership}
          onSubmit={submitRegistrationForm}
          isAttemptingRegistration={isAttemptingRegistration}
          setIsAttemptingRegistration={setIsAttemptingRegistration}
        />
        <div className='register_membership__btn_wrapper'>
          <FancyButton
            classes='btn btn-primary register_membership__btn'
            label={buttonText}
            trigger={isAttemptingRegistration}
            onClick={submitRegistrationForm}
          />
        </div>

        {signUpUrl &&
          <JoinNowLink signupUrl={signUpUrl} />
        }
      </div>
    );
  };

  renderSuccessUI = () => {
    const { membership, lp, formatter, clientData } = this.props;

    if (lp.isEmpty() || membership.isEmpty()) {
      return null;
    }

    const memberDetailsStatus = membership.get('memberDetailsStatus');
    const accountIdLabel = this.getAccountIdLabel(membership);
    const logoName = `${lp.get('name')} logo`;
    const lpCode = lp.getIn(['content', 'code']);
    const updatedAtLabel = moment(membership.get('updatedAt')).format('dddd, MMMM Do YYYY');
    const updatedPrefix = formatter.formatMessage({ id: 'credentialCatcher.dummyCard.updated' });

    const unregisteredCircle = function unregisteredMark(className) {
      const selectToRegisterAriaLabel = formatter.formatMessage({ id: 'membershipcard.registerAriaLabel' }, { lpName: membership.get('lpName') });

      return (
        <div
          aria-label={selectToRegisterAriaLabel}
          role='button'
          className={classnames('circle', className)}
        >
          <div className='circle__content circle__content--smaller-font'>
            <FormattedMessage id='membershipcard.circleMessage' />
          </div>
        </div>
      );
    };

    const circleWithError = function errorMark(className) {
      const selectAriaLabel = formatter.formatMessage({ id: 'membershipcard.selectAriaLabel' }, { lpName: membership.get('lpName') });

      return (
        <div
          aria-label={selectAriaLabel}
          role='button'
          className={classnames('circle', className)}
        >
          <div className='circle__content circle__content--smaller-font'>
            <FormattedMessage id='membershipcard.circleMessageError' />
          </div>
        </div>
      );
    };

    const circleWithMembershipBalance = function balanceMark(className) {
      const balance = membership.getIn(['memberDetails', 'balance']);
      const ariaLabel = formatter.formatMessage({ id: 'membershipcard.registeredProgram' }, {
        balance,
        lpName: membership.get('lpName')
      });
      const shouldHideBalance = shouldHideMemberData(clientData, lp.get('id'));
      const hiddenBalanceLabel = formatter.formatMessage({ id: 'membershipcard.hiddenBalanceLabel' });
      const balanceLabel = formatter.formatNumber(balance);
      const registeredProgramLabel = shouldHideBalance ? hiddenBalanceLabel : balanceLabel;

      return (
        <div
          aria-label={ariaLabel}
          role='button'
          className={classnames('circle circle--with-sub-content', className)}
        >
          <div className={classnames('circle__content', { 'text-size-big': shouldHideBalance })}>
            {registeredProgramLabel}
            {
              !shouldHideBalance && (
                <div className='circle__sub-content'>
                  {lp.getIn(['content', 'currencyNameShort'])}
                </div>
              )
            }
          </div>
        </div>
      );
    };

    return (
      <div className='register_membership__card u-padding'>
        <div className='membership-card u-margin-bottom membership-card--no-action'>
          <div className='membership-card-desk'>
            <div className='u-padding-small u-padding-horizontal'>
              <img
                className='membership-card__img u-1/1'
                alt={logoName}
                src={getLogoPath(lpCode)}
              />
            </div>
            <div className='u-margin-bottom u-margin-bottom'>
              {
                (() => {
                  switch (memberDetailsStatus) {
                    case constants.MEMBER_DETAILS_STATUS_UNREGISTERED:
                      return unregisteredCircle('circle--action');
                    case constants.MEMBER_DETAILS_STATUS_PENDING:
                      return circleWithMembershipBalance('circle--muted');
                    case constants.MEMBER_DETAILS_STATUS_SUCCESS:
                      return circleWithMembershipBalance('circle--info');
                    case constants.MEMBER_DETAILS_STATUS_ERROR:
                      return circleWithError('circle--error');
                  }
                })()
              }

              {membership.isEmpty() ? unregisteredCircle('circle--action') : null}
            </div>

            <div className='register_membership__card_separator'>&nbsp;</div>

            <div className='fs-exclude text-center text-size-small'> {accountIdLabel}</div>
            <div className='text-center text-size-x-small'> {updatedPrefix} {updatedAtLabel}</div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  renderErrors = () => {
    const { errors, clientData, locale } = this.props;
    const errorUrl = clientData.getIn(['credentialCatchers', 'redirectUrls', locale, 'error']);

    if (errorUrl) {
      return externalRedirect(errorUrl);
    }
    return (
      <div className='register_membership__wrapper'>
        <h1 className='register_membership__heading'>
          <FormattedHTMLMessage id='credentialCatcher.error' />
        </h1>
        {
          errors.map((errId, i) => (
            <p className='register_membership_error' key={`register_membership_error_${i}`}><FormattedHTMLMessage id={errId} /></p>
          ))
        }
      </div>
    );
  };

  renderContent = () => {
    const { lp, membership, clientData, locale } = this.props;
    const { showSuccessUI, mode } = this.state;

    const successExternalUrl = clientData.getIn(['credentialCatchers', 'redirectUrls', locale, 'success']);
    const values = { lpName: membership.get('lpName') };

    if (membership.isEmpty() || lp.isEmpty()) return null;

    if (showSuccessUI && successExternalUrl) {
      return externalRedirect(successExternalUrl);
    }
    return (
      <div className='register_membership__wrapper'>
        {showSuccessUI ? (
          <div className='register_membership__success_msg'>
            <FormattedHTMLMessage id={`credentialCatcher.${mode}.success`} values={values} />
            {this.renderSuccessUI()}
          </div>
        ) : (
          <div>
            <h1 className='register_membership__heading'>
              <FormattedMessage id={`credentialCatcher.${mode}.heading`} />
            </h1>
            {this.renderLpLogo()}
            {this.renderRegistrationForm()}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { locale, messages, errors } = this.props;

    if (!locale || !messages.size) {
      return null;
    }

    return (
      <IntlProvider locale={locale} textComponent='span' messages={messages.toJSON()}>
        <IntlContainer>
          {errors.isEmpty() ? this.renderContent() : this.renderErrors()}
        </IntlContainer>
      </IntlProvider>
    );
  }
}

InlineCredentialCatcher.propTypes = {
  login: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  fetchLocale: PropTypes.func.isRequired,
  fetchMembership: PropTypes.func.isRequired,
  submitRegistrationForm: PropTypes.func.isRequired,
  registerMembership: PropTypes.func.isRequired,
  setIsAttemptingRegistration: PropTypes.func.isRequired,
  locale: PropTypes.string,
  messages: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func,
    formatNumber: PropTypes.func
  }),
  clientData: ImmutablePropTypes.map.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  membership: ImmutablePropTypes.map,
  lp: ImmutablePropTypes.map,
  errors: ImmutablePropTypes.list,
  isAttemptingRegistration: PropTypes.bool.isRequired,
  params: PropTypes.object,
  validateRedirectUrlsAndLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  locale: state.app.get('locale'),
  messages: state.app.get('messages'),
  formatter: state.app.get('formatter'),
  clientData: state.app.get('clientData'),
  isLoggedIn: state.auth.get('isLoggedIn'),
  membership: state.registerMembership.get('membership'),
  lp: state.registerMembership.get('lp'),
  errors: state.registerMembership.get('errors'),
  isAttemptingRegistration: state.registerMembership.get('isAttemptingRegistration')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  push,
  fetchLocale,
  fetchMembership,
  submitRegistrationForm,
  registerMembership,
  setIsAttemptingRegistration,
  validateRedirectUrlsAndLogin
}, dispatch);

export const InlineCredentialCatcherContainer = connect(mapStateToProps, mapDispatchToProps)(InlineCredentialCatcher);
