/**
Component that animates three dots
@class Loading Dots Component
*/
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const LoadingDots = ({ isAnimating, classes, dotClass }) => (
  <div className={classnames('loading-dots', { 'loading-dots--animated': isAnimating }, classes)}>
    <div className={classnames('loading-dots__dot loading-dots__dot--1', dotClass)} />
    <div className={classnames('loading-dots__dot loading-dots__dot--2', dotClass)} />
    <div className={classnames('loading-dots__dot loading-dots__dot--3', dotClass)} />
  </div>
);

LoadingDots.propTypes = {
  isAnimating: PropTypes.bool,
  classes: PropTypes.string,
  dotClass: PropTypes.string
};

export default LoadingDots;
