import { fromJS } from 'immutable';

const initialState = fromJS({
  orders: [],
  ordersTotalCount: 0,
  isFetchingOrders: false,
  pendingEarnOrders: [],
  pendingActivity: [],
  selectedOrder: {}
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_ORDERS':
      return state.set('orders', fromJS(action.orders));
    case 'SET_ORDERS_TOTAL_COUNT':
      return state.set('ordersTotalCount', action.ordersTotalCount);
    case 'SET_PENDING_EARN_ORDERS': {
      const pendingEarnOrders = fromJS(action.pendingEarnOrders) || initialState.get('pendingEarnOrders');
      return state.set('pendingEarnOrders', pendingEarnOrders);
    }
    case 'SET_PENDING_ACTIVITY': {
      const pendingActivity = fromJS(action.pendingActivity) || initialState.get('pendingActivity');
      return state.set('pendingActivity', pendingActivity);
    }
    case 'SET_IS_FETCHING_ORDERS':
      return state.set('isFetchingOrders', action.isFetchingOrders);
    case 'SET_SELECTED_ORDER':
      return state.set('selectedOrder', fromJS(action.selectedOrder));
    default:
      return state;
  }
}
