import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { formatNumber } from '../utils/string';

class ExchangeRules extends React.Component {
  exchangeRulesEl = null;

  render() {
    const getTermsAndConditionsString = () => {
      const {
        fromMembershipId,
        selectedOffer,
        formatter,
        fromLp
      } = this.props;

      if (!fromMembershipId || selectedOffer.isEmpty()) {
        return '';
      }

      const {
        supportedTiers,
        lpName,
        currencyNameLong: currency,
        maxTransactionsOutPerYear,
        maxPointsOutPerYear,
        maxPointsOutPerDay
      } = fromLp.get('content').toJSON();

      const {
        minPointsOut,
        maxPointsOut,
        minBalance
      } = selectedOffer.toJSON();

      const programAndCompany = lpName;
      let supportedTiersString = '';
      let exchangeRulesText = '';

      if (supportedTiersString && supportedTiersString.length > 0) {
        supportedTiersString = supportedTiers.join(', ');
      }

      if (minPointsOut > 0 && maxPointsOut > 0) {
        exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.minMaxPerExchange' }, {
          programAndCompany,
          minPointsOut: formatNumber(minPointsOut),
          currency,
          maxPointsOut: formatNumber(maxPointsOut)
        });
      }
      if (minPointsOut > 0 && !maxPointsOut) {
        exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.minPointsPerExchange' }, {
          programAndCompany,
          currency,
          minPointsOut: formatNumber(minPointsOut)
        });
      }
      if (!minPointsOut && maxPointsOut > 0) {
        exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.maxPointsPerExchange' }, {
          programAndCompany,
          currency,
          maxPointsOut: formatNumber(maxPointsOut)
        });
      }
      if (maxPointsOutPerYear > 0) {
        exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.maxPointsPerExchangePerYear' }, {
          programAndCompany,
          currency,
          maxPointsOut: formatNumber(maxPointsOut)
        });
      }
      if (maxPointsOutPerDay > 0) {
        exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.maxPointsOutPerDay' }, {
          programAndCompany,
          currency,
          maxPointsOutPerDay: formatNumber(maxPointsOutPerDay)
        });
      }
      if (!minPointsOut && !maxPointsOut && !maxPointsOutPerYear && !maxPointsOutPerDay && !minBalance) {
        exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.noMinsOrLimits' }, {
          programAndCompany
        });
      }
      if (minBalance > 0) {
        if (supportedTiers && supportedTiers.length <= 0) {
          exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.minBalanceRequired' }, {
            programAndCompany,
            currency,
            minBalance: formatNumber(minBalance)
          });
        } else {
          exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.minBalanceOrTierRequirement' }, {
            programAndCompany,
            currency,
            supportedTiersString,
            minBalance: formatNumber(minBalance)
          });
        }
      }
      if (maxTransactionsOutPerYear > 0) {
        exchangeRulesText += formatter.formatMessage({ id: 'exchangeRules.maxTransactionsOutPerYear' }, {
          programAndCompany,
          maxTransactionsOutPerYear
        });
      }
      return `${exchangeRulesText}`;
    };

    const { formatter } = this.props;
    const classes = classnames(['exchange-rules', this.props.classes]);
    const exchangeRulesText = getTermsAndConditionsString();
    const labelText = formatter.formatMessage({ id: 'exchangeRules.exchangeRulesLabel' });
    const exchangeRulesLabel = `${labelText || ''} `;
    return (
      <div className={classes} ref={el => this.exchangeRulesEl = el}>
        {exchangeRulesLabel} {exchangeRulesText}
      </div>
    );
  }
}

ExchangeRules.propTypes = {
  fromMembershipId: PropTypes.string,
  selectedOffer: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  fromLp: ImmutablePropTypes.map,
  classes: PropTypes.string
};

export default ExchangeRules;
