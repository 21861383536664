import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { capitalize } from '../utils/string';
import classnames from 'classnames';
import constants from '../utils/constants';

import { FormattedMessage } from 'react-intl';
import Spinner from './Spinner';
import Slide from './Slide';
import MembershipRegistration from './MembershipRegistration';
import LoadingDots from './LoadingDots';
import { handleKeyPressEnter, getTabIndex } from './utils/accessibility-helpers';
import { generateExternalCredentialCatcherUrl } from '../components/utils/credential-catcher-helpers';
import { selectUserMemberValidation } from '../selectors/memberships';
import FocusTrap from './FocusTrap';
import { getLogoPath } from './utils/image-helpers';


export class ExchangeSelectBox extends React.Component {
  state = {
    isShowingMembershipRegistration: false,
    closeMembershipRegistration: 0
  };

  getAriaLabelForExchange = membership => {
    const { direction, formatter, lp } = this.props;
    if (!membership.size) {
      return formatter.formatMessage({ id: 'exchangeSelect.ariaLabelForExchange' });
    }
    const id = `exchangeSelect.ariaLabelForExchangeWithMembership${capitalize(direction)}`;
    const membershipBalanceAsNumber = membership.getIn(['memberDetails', 'balance']) || 0;
    const membershipBalance = membershipBalanceAsNumber.toString(); // .toString() used because otherwise 0 gets translated to empty string
    const lpName = lp.get('name');
    return formatter.formatMessage({ id }, { membershipBalance, lpName });
  };

  closeMembershipRegistration = () => {
    const { closeMembershipRegistration } = this.state;
    this.setState({ closeMembershipRegistration: closeMembershipRegistration + 1 });
  };

  hideMembershipRegistration = () => {
    this.setState({ isShowingMembershipRegistration: false });
  };

  openModal = () => {
    const { direction, setModalContentKey } = this.props;

    switch (direction) {
      case 'from':
        setModalContentKey(constants.modals.exchangeFromSelect);
        break;
      case 'to':
        setModalContentKey(constants.modals.exchangeToSelect);
        break;
    }
  };

  selectBoxEl = null;

  showLpSelectOptions = e => {
    e.preventDefault();
    const { direction, iframeManager, googleTagManager } = this.props;
    this.openModal();
    googleTagManager.triggerPageView(
      `/exchange/configure/${direction}-program`,
      `exchange.configure.${direction}-program`
    );
    iframeManager.scrollParentFrameToTop();
  };

  showMembershipRegistration = () => {
    const { direction, googleTagManager } = this.props;
    this.setState({ isShowingMembershipRegistration: true });
    googleTagManager.triggerPageView(
      `/exchange/register/${direction}-program`,
      `exchange.register.${direction}-program`
    );
  };

  trackAnalyticsEvent = data => {
    this.props.googleTagManager.pushGtmData(Object.assign({
      context: 'exchange'
    }, data));
  };

  renderBox = () => {
    const { isFetchingOfferSet, isFetchingEligibleLps, membership } = this.props;
    const isFetching = isFetchingOfferSet || isFetchingEligibleLps;
    const shouldShowBoxSpinner = membership.isEmpty() && isFetching;
    let boxContent;
    if (shouldShowBoxSpinner) {
      boxContent = this.renderBoxSpinner();
    } else if (membership.isEmpty()) {
      boxContent = this.renderDefaultSelectLpIcon();
    } else {
      boxContent = this.renderLpLogo();
    }
    return (
      <div className='box__content'>
        { boxContent }
      </div>
    );
  };

  renderBoxSpinner = () => (
    <div className='box__spinner'>
      <Spinner />
    </div>
  );

  renderDefaultSelectLpIcon = () => (
    <img alt='exchange default icon' className='u-10/12' src='/static/images/exchange_default_icon.svg' />
  )

  renderEligibleLpsSpinner = () => (
    <div className='u-margin-top-huge'>
      <Spinner />
    </div>
  );

  renderLoadingDots = () => (
    <div className='text-center'>
      <LoadingDots isAnimating />
    </div>
  );

  renderLpLogo = () => {
    const { lp, membership } = this.props;
    const isMembershipStatusPending = membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    const lpCode = lp.getIn(['content', 'code']);

    return (
      <img
        alt={`${lp.get('name')} logo`}
        className={classnames('u-10/12', { 'filter-grayscale opacity-5': isMembershipStatusPending })}
        src={getLogoPath(lpCode)}
      />
    );
  };

  renderRegisterLink = () => {
    const { lp } = this.props;
    const wpLpConfig = lp.getIn(['config', 'credentialCatcher'], Map());
    const credentialCatcherType = wpLpConfig.get('enabled');

    if (credentialCatcherType === 'external') {
      const credentialCatcherConfig = wpLpConfig.get(credentialCatcherType);
      return (
        this.renderExternalRegisterLink(credentialCatcherConfig)
      );
    }

    return (
      this.renderInlineRegisterLink()
    );
  };

  renderInlineRegisterLink = () => {
    const { formatter, isShowingSlide } = this.props;
    const registerProgram = formatter.formatMessage({ id: 'exchangeSelect.registerProgramLink' });
    const modalWillOpenString = formatter.formatMessage({ id: 'accessibility.modalWillOpen' });

    return (
      <p className='text-center'>
        <a
          onKeyUp={handleKeyPressEnter(this.showMembershipRegistration)}
          aria-label={`${registerProgram}. ${modalWillOpenString}`}
          onClick={this.showMembershipRegistration}
          tabIndex={getTabIndex(isShowingSlide)}
          aria-hidden={isShowingSlide}
        >
          <FormattedMessage id='exchangeSelect.registerProgramLink' />
        </a>
      </p>
    );
  };

  renderExternalRegisterLink = credentialCatcherConfig => {
    const { formatter, userMemberValidationUrl, membership } = this.props;
    const registerProgramMessage = formatter.formatMessage({ id: 'exchangeSelect.registerProgramLink' });
    const callbackUrl = window.location.origin;
    const membershipId = membership.get('id');
    const credentialCatcherUrl = generateExternalCredentialCatcherUrl(
      credentialCatcherConfig,
      userMemberValidationUrl,
      callbackUrl,
      membershipId
    );

    return (
      <p className='text-center'>
        <a
          aria-label={`${registerProgramMessage}.`}
          target='_blank' // open in a new tab
          rel='noreferrer'
          href={credentialCatcherUrl}
        >
          <FormattedMessage id='exchangeSelect.registerProgramLink' />
        </a>
      </p>
    );
  };

  renderRegisterLpUI = () => {
    const { formatter, isShowingSlide, membership } = this.props;
    return (
      <FocusTrap isActive={isShowingSlide}>
        <Slide
          handleClose={this.hideMembershipRegistration}
          closeSlide={this.state.closeMembershipRegistration}
          setIsShowingSlide={this.props.setIsShowingSlide}
          formatter={formatter}
        >
          <MembershipRegistration
            closeMembershipRegistration={this.closeMembershipRegistration}
            membership={membership}
            {...this.props}
          />
        </Slide>
      </FocusTrap>
    );
  };

  render() {
    const { user, isShowingSlide, isFetchingEligibleLps, membership } = this.props;
    const userIsGuest = user.get('role') === 'guest';
    const isMembershipStatusPending = membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    const shouldShowRegisterLink = !userIsGuest && membership.size && !membership.get('isRegistered') && !isMembershipStatusPending;

    return (
      <div className='exchange-select'>
        <div
          className='box box--hoverable'
          ref={el => this.selectBoxEl = el}
          role='button'
          tabIndex={getTabIndex(isShowingSlide)}
          aria-label={this.getAriaLabelForExchange(membership)}
          onKeyUp={handleKeyPressEnter(event => this.showLpSelectOptions(event))}
          onClick={this.showLpSelectOptions}
          aria-hidden={isShowingSlide}
        >
          { this.renderBox() }
        </div>
        {shouldShowRegisterLink ? this.renderRegisterLink() : null}
        <div className='outer-container__hide-scrollbar'>
          {this.state.isShowingMembershipRegistration ? this.renderRegisterLpUI() : null}
        </div>

        {isMembershipStatusPending || isFetchingEligibleLps ? this.renderLoadingDots() : null}
      </div>
    );
  }
}

ExchangeSelectBox.propTypes = {
  user: ImmutablePropTypes.map,
  isShowingSlide: PropTypes.bool,
  isFetchingEligibleLps: PropTypes.bool,
  membership: ImmutablePropTypes.map,
  direction: PropTypes.string,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  lp: ImmutablePropTypes.map,
  isFetchingOfferSet: PropTypes.bool,
  googleTagManager: PropTypes.object,
  iframeManager: PropTypes.object,
  setIsShowingSlide: PropTypes.func,
  setModalContentKey: PropTypes.func,
  userMemberValidationUrl: PropTypes.string
};

function mapStateToProps(state) {
  return {
    userMemberValidationUrl: selectUserMemberValidation(state)
  };
}

export default connect(mapStateToProps)(ExchangeSelectBox);
