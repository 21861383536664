import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { earnPerformanceHorizonsRedirect } from '../action-creators/earn';

export class _EarnPerformanceHorizonsIntegration extends React.Component {
  componentDidMount() {
    const { earnPerformanceHorizonsRedirect, promoRedirectUrl } = this.props;

    if (promoRedirectUrl !== '') {
      earnPerformanceHorizonsRedirect(promoRedirectUrl);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { earnPerformanceHorizonsRedirect, promoRedirectUrl } = this.props;

    if (promoRedirectUrl !== nextProps.promoRedirectUrl) {
      earnPerformanceHorizonsRedirect(nextProps.promoRedirectUrl);
    }
  }

  render() {
    return null;
  }
}

_EarnPerformanceHorizonsIntegration.propTypes = {
  earnPerformanceHorizonsRedirect: PropTypes.func,
  promoRedirectUrl: PropTypes.string
};

function mapStateToProps(state) {
  return {
    promoRedirectUrl: state.earn.get('promoRedirectUrl')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    earnPerformanceHorizonsRedirect
  }, dispatch);
}

export const EarnPerformanceHorizonsIntegration = connect(
  mapStateToProps,
  mapDispatchToProps
)(_EarnPerformanceHorizonsIntegration);
