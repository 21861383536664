/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds.
 * @method debounce
 * @param {Function} func to be called after debounce period
 * @param {Number} wait time to wait before calling the function
* */
export function debounce(func, wait, ...args) {
  let timeout;
  return function handler() {
    const later = () => {
      timeout = null;
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function waitTimeout(ms, callback) {
  (new Promise(resolve => {
    setTimeout(resolve, ms);
  })).then(callback);
}
