/**
 @class Balance Trackers Component
 */
import Masonry from 'masonry-layout';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';

import constants from '../utils/constants';

import { getPosition, isElementInViewport, scrollTo } from '../utils/dom';
import CheckmarkLoader from './CheckmarkLoader';

import FocusTrap from './FocusTrap';
import MembershipCard from './MembershipCard';
import PromoCard from './PromoCard';
import Slide from './Slide';
import { getTabIndex, handleKeyPressEnter, shouldBeHiddenFromVoiceOver } from './utils/accessibility-helpers';

class BalanceTracker extends React.Component {
    state = {
      selectedMembershipId: null,
      shouldScrollToLp: false,
      isShowingAddProgramsUI: false,
      addedLpsHistory: [],
      filterAddedLpsSearch: '',
      closeAddProgramsUI: 0
    };

    componentDidMount() {
      if (!this.props.disableMasonry) {
        this.masonry = new Masonry(this.balanceTrackerProgramsEl, {
          itemSelector: '.balance-tracker__item',
          transitionDuration: `${constants.baseAnimationLength}ms`
        });
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { lastAddedLpId } = this.props;
      const nextLastAddedLpId = nextProps.lastAddedLpId;
      if (nextLastAddedLpId && lastAddedLpId !== nextLastAddedLpId) {
        this.setState({ shouldScrollToLp: true });
      }
    }

    componentDidUpdate() {
      const { shouldScrollToLp } = this.state;
      if (this.masonry && !this.props.disableMasonry) {
        this.masonry.reloadItems();
        this.masonry.layout();
      }

      if (shouldScrollToLp) {
        setTimeout(() => {
          this.scrollToMembershipCard(this.props.lastAddedLpId);
          this.setState({ shouldScrollToLp: false });
        }, constants.baseAnimationLength + 300);
      }
    }

    getAddLpsList = () => {
      const { allLps } = this.props;
      const { filterAddedLpsSearch } = this.state;
      const lps = allLps.filter(lp => {
        if (filterAddedLpsSearch.length) {
          const lpName = `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`.toLowerCase();
          return lpName.indexOf(filterAddedLpsSearch.toLowerCase()) > -1;
        }
        return true;
      });

      const categories = [];

      lps.forEach(lp => {
        const cats = lp.getIn(['content', 'categories']) || '';
        const listOfCats = cats.split(',');
        listOfCats.forEach(cat => {
          let bucket = categories.find(_bucket => _bucket.name === cat);

          if (!bucket) {
            bucket = { name: cat, lps: [] };
            categories.push(bucket);
          }

          bucket.lps.push(lp);
        });
      });
      return categories;
    };

    getHasAdded = lp => {
      const { addedLpsHistory } = this.state;
      const lpId = lp.get('id');
      const hasAddedProgram = !!addedLpsHistory.find(lp => lp.id === lpId && !lp.removed);
      return hasAddedProgram;
    };

    getSortedMemberships = () => {
      const { memberships, clientData } = this.props;
      const clientLpId = clientData.get('clientLpId');

      const sortedMemberships = memberships.sort((mem1, mem2) => {
        mem1 = mem1.toJSON();
        mem2 = mem2.toJSON();

        // Return -1 if mem1 should go first
        // Return 0 if mem1 and mem2 are equal
        // Return 1 if mem2 should go first

        // if there's a clientLp, its membership will always be on top
        if (clientLpId !== '') {
          if (mem1.lpId === clientLpId) {
            return -1;
          }
          if (mem2.lpId === clientLpId) {
            return 1;
          }
        }

        // One registered - registered wins
        if (mem1.isRegistered && !mem2.isRegistered) {
          return -1;
        }

        if (!mem1.isRegistered && mem2.isRegistered) {
          return 1;
        }

        // Both registered - highest balance wins
        if (mem1.isRegistered && mem2.isRegistered) {
          if (mem1.memberDetails.balance > mem2.memberDetails.balance) {
            return -1;
          } else if (mem1.memberDetails.balance < mem2.memberDetails.balance) {
            return 1;
          }
          return 0;
        }

        // Both unregistered - membership with error wins
        if (!mem1.isRegistered && !mem2.isRegistered) {
          if (mem1.memberDetailsErrors && !mem2.memberDetailsErrors) {
            return -1;
          } else if (!mem1.memberDetailsErrors && mem2.memberDetailsErrors) {
            return 1;
          }
          return 0;
        }
      });
      return sortedMemberships;
    };

    setSelectedMembershipId = selectedMembershipId => {
      this.setState({ selectedMembershipId });
    };

    addLpButtonEl = null;
    addLpsFilterEl = null;

    balanceTrackerProgramsEl = null;
    bucketCountEl = null;
    bucketNameEl = null;

    closeAddProgramsUI = () => {
      const { iframeManager, googleTagManager, setIsShowingSlide, location } = this.props;
      this.setState({
        isShowingAddProgramsUI: false,
        filterAddedLpsSearch: '',
        addedLpsHistory: []
      });
      setIsShowingSlide(false);
      iframeManager.scrollParentFrameToTop();
      googleTagManager.triggerPageView(location.pathname);
    };

    filterAddLpsList = e => {
      const { value } = e.target;
      this.setState({ filterAddedLpsSearch: value });
    };

    openAddProgramsUI = () => {
      const { iframeManager, googleTagManager, location } = this.props;

      this.setState({
        isShowingAddProgramsUI: true
      });
      iframeManager.scrollParentFrameToTop();
      googleTagManager.triggerPageView('/wallet/add-programs', 'wallet.add-programs', {
        location: location.pathname
      });
    };

    scrollToMembershipCard = lpId => {
      const { device } = this.props;
      const membershipCard = this[`${lpId}El`]?.membershipCardEl;
      const position = getPosition(membershipCard);

      // palm devices need more padding because of the fixed 'add programs' button
      const scrollPadding = device === 'palm' ? 75 : 0;
      const newScrollPosition = window.scrollY + position.y - constants.baseSpacingUnit - scrollPadding;

      if (!isElementInViewport(membershipCard)) {
        scrollTo(document.body, newScrollPosition, constants.baseAnimationLength);
        setTimeout(() => {
          this.props.afterScrollToMembershipCard();
        }, constants.baseAnimationLength);
      }
    };

    toggleAddedLp = lp => {
      const { addedLpsHistory } = this.state;
      const lpId = lp.get('id');
      const hasAddedProgram = !!addedLpsHistory.find(lp => lp.id === lpId && !lp.removed);

      this.updateAddedLpsHistory(lp, hasAddedProgram);
    };

    updateAddedLpsHistory = (lp, removed) => {
      const { addedLpsHistory } = this.state;
      const lpId = lp.get('id');
      const lpName = lp.get('name');
      const lpCoName = lp.getIn(['content', 'companyName']);
      const entry = {
        id: lpId,
        name: lpName,
        coName: lpCoName,
        removed
      };
      if (removed) {
        const newAddedLpsHistory = addedLpsHistory.map(lp => {
          if (lp.id === lpId) {
            lp.removed = true;
            return lp;
          }
          return lp;
        });
        return this.setState({ addedLpsHistory: newAddedLpsHistory });
      }
      addedLpsHistory.unshift(entry);
      this.setState({ addedLpsHistory });
    };

    postNewMemberships = () => {
      const { memberships, postNewMembership, googleTagManager } = this.props;
      const { addedLpsHistory } = this.state;
      const lpsToAdd = addedLpsHistory.filter(lp => !lp.removed);
      lpsToAdd.forEach(lp => {
        googleTagManager.pushGtmData({
          action: constants.ANALYTICS_ADD_LP,
          [constants.ANALYTICS_ADD_LP]: `${lp.coName} ${lp.name}`,
          context: 'balanceTracker'
        });
        postNewMembership(lp.id, memberships);
      });
      this.setState({ addedLpsHistory: [] });
      this.closeAddProgramsUI();
    }

    membershipInWallet = lp => {
      const { memberships } = this.props;
      return !!memberships.find(membership => membership.get('lpId') === lp.get('id'));
    };

    renderAddProgramButton = () => {
      const { formatter, isShowingSlide, shouldDisableProgramAddition } = this.props;
      const { selectedMembershipId } = this.state;
      const addProgramAriaLabel = formatter.formatMessage({ id: 'balanceTracker.addProgramText' });
      const modalWillOpenString = formatter.formatMessage({ id: 'accessibility.modalWillOpen' });
      const tabIndexValue = getTabIndex(isShowingSlide || selectedMembershipId);

      if (shouldDisableProgramAddition) {
        return;
      }

      return (
        <div className='balance-tracker__item balance-tracker__item--add o-layout__item u-1/3@md u-1/1'>
          <div
            id='viewAllPrograms_addLpToWallet'
            data-lp-name='viewAllPrograms'
            data-status='selected'
            ref={el => this.addLpButtonEl = el}
            tabIndex={tabIndexValue}
            aria-hidden={shouldBeHiddenFromVoiceOver(tabIndexValue)}
            role='button'
            className='balance-tracker__add u-margin-bottom'
            aria-label={`${addProgramAriaLabel}. ${modalWillOpenString}`}
            onClick={this.openAddProgramsUI}
            onKeyUp={handleKeyPressEnter(this.openAddProgramsUI)}
          >
            <i className='balance-tracker__add-icon icon-add' /> <FormattedMessage
              id='balanceTracker.addProgramText'
            />
          </div>
        </div>
      );
    };

    renderAddProgramsUI = () => {
      const { formatter, isShowingSlide } = this.props;
      return (
        <FocusTrap isActive={isShowingSlide}>
          <Slide
            handleClose={this.closeAddProgramsUI}
            setIsShowingSlide={this.props.setIsShowingSlide}
            closeSlide={this.state.closeAddProgramsUI}
            header={formatter.formatMessage({ id: 'addPrograms.header' })}
            customFooter={this.renderDoneButton}
            formatter={formatter}
          >
            <div className='u-padding-horizontal-none@md'>
              <div className='o-layout o-layout--flush o-layout--center'>
                <div className='o-layout o-layout--flush'>
                  <div className='o-layout__item u-1/2@lg u-1/1'>
                    <div className='section text-left'>
                      <input
                        ref={el => this.addLpsFilterEl = el}
                        className='u-margin-vertical-small u-margin-vertical-large@lg input-text'
                        type='text'
                        placeholder={formatter.formatMessage({ id: 'addPrograms.searchPlaceholder' })}
                        onChange={this.filterAddLpsList}
                        aria-label={formatter.formatMessage({ id: 'addPrograms.searchPlaceholder' })}
                      />
                      {
                        !this.getAddLpsList().length ? (
                          <div
                            className='text-center text-base-size-palm text-color-muted u-padding-top'
                          >
                            <FormattedMessage id='addPrograms.noResults' />
                          </div>
                        ) : null
                      }
                      {
                        this.getAddLpsList().map(bucket => (
                          <div key={bucket.name} className='u-margin-bottom'>
                            <div
                              className='section-heading u-padding-horizontal-small'
                              role='heading'
                              aria-level='3'
                            >
                              <span ref={el => this.bucketNameEl = el}>
                                {formatter.formatMessage({ id: `addProgram.category.${bucket.name}` })}
                              </span>&nbsp;
                              <span
                                ref={el => this.bucketCountEl = el}
                                className='float-right'
                              >({bucket.lps.length})
                              </span>
                            </div>
                            <ul className='add-lps list list--bare text-base-size-palm'>
                              {
                                bucket.lps.map(lp => {
                                  const hasAdded = this.getHasAdded(lp);
                                  const name = `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`;
                                  return (
                                    <li
                                      id={`${name}_addLpToWallet`}
                                      data-lp-name={name}
                                      data-location='(programSlider)'
                                      data-status={!hasAdded ? 'selected' : 'unselected'}
                                      key={lp.get('id')}
                                      onClick={() => {
                                        this.toggleAddedLp(lp);
                                      }}
                                      role='button'
                                      tabIndex='0'
                                      aria-label={`Select this option to ${hasAdded ? 'remove' : 'add'} ${name}`}
                                      onKeyUp={handleKeyPressEnter(() => this.toggleAddedLp(lp))}
                                      className='add-lps__item u-bb text-left u-clearfix u-padding-vertical-tiny hover-main text-base-size-palm'
                                    >
                                      <div
                                        className='u-padding-tiny u-padding-bottom-none'
                                      >
                                        <div className='float-left'>
                                          {name}
                                        </div>
                                        {
                                          this.membershipInWallet(lp) ? (
                                            <div
                                              className='float-right u-margin-right-tiny'
                                            >
                                              <i className='icon-wallet' />
                                            </div>
                                          ) : (
                                            <div className='float-right'>
                                              <CheckmarkLoader
                                                trigger={hasAdded}
                                              />
                                            </div>
                                          )
                                        }
                                      </div>
                                    </li>
                                  );
                                })
                              }
                            </ul>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </FocusTrap>
      );
    };

    renderDoneButton = () => {
      const { formatter } = this.props;

      return (
        <div className='text-center u-margin-vertical-large@lg'>
          <button
            id='savePrograms_addLpToWallet'
            data-lp-name='savePrograms'
            data-status='selected'
            tabIndex='0'
            className='btn btn-primary btn-small-padding'
            aria-label={formatter.formatMessage({ id: 'programs.save' })}
            onClick={this.postNewMemberships}
            onKeyUp={handleKeyPressEnter(this.postNewMemberships)}
          >
            {formatter.formatMessage({ id: 'programs.save' })}
          </button>
        </div>
      );
    };

    renderMembershipCards = sortedMemberships => {
      const memberships = [];

      sortedMemberships.forEach((membership, index) => {
        if (!membership || !membership.size) {
          return null;
        }
        const lpId = membership.get('lpId');
        const lp = this.props.getLpById(lpId);

        if (!lp) {
          return;
        }

        const membershipId = membership.get('id');
        const isSelected = this.state.selectedMembershipId === membershipId;

        memberships.push((
          <div key={lpId + index} className='balance-tracker__item o-layout__item u-1/3@md u-1/1'>
            <MembershipCard
              {...this.props}
              ref={el => this[`${lpId}El`] = el}
              lp={lp}
              membership={membership}
              isSelected={isSelected}
              setSelectedMembershipId={this.setSelectedMembershipId}
              userMemberValidationUrl={this.props.userMemberValidationUrl}
            />
          </div>
        ));
      });

      return memberships;
    };

    shouldRenderLocalizedPromo = promo => {
      const { locale } = this.props;
      const promoLocale = promo.get('locale');
      if (locale === promoLocale) return true;

      const promoLocaleLanguage = promoLocale.split('-')[0];
      const localeLanguage = locale.split('-')[0];
      return promoLocaleLanguage === localeLanguage;
    }

    renderPromos = () => {
      const { getLpById, promos } = this.props;

      if (!promos || !promos.size) {
        return null;
      }

      const promoCards = [];
      promos.forEach((promo, index) => {
        if (!promo) {
          return;
        }

        const lp = getLpById(promo.get('lpId'));
        if (this.shouldRenderLocalizedPromo(promo)) {
          promoCards.push((
            <div key={`promo${index}`} className='balance-tracker__item o-layout__item u-1/3@md u-1/1'>
              <PromoCard
                promo={promo}
                lp={lp}
                {...this.props}
              />
            </div>
          ));
        }
      });
      return promoCards;
    };

    render() {
      const { clientData, elementsToHideClass } = this.props;
      const sortedMemberships = this.getSortedMemberships();
      const clientLpId = clientData.get('clientLpId');
      const isClientMembership = membership => membership.get('lpId') === clientLpId;
      const clientMemberships = sortedMemberships.filter(isClientMembership);
      const nonClientMemberships = sortedMemberships.filterNot(isClientMembership);


      return (
        <div>
          <div
            className={`${elementsToHideClass} balance-tracker u-padding-horizontal-none 
            u-padding-horizontal@lg u-padding-top-small u-padding-top@md`}
          >
            <div className='section'>
              <div className='u-padding-horizontal-small u-padding-horizontal@md'>
                <div
                  className='balance-tracker__items o-layout'
                  ref={el => this.balanceTrackerProgramsEl = el}
                >
                  {this.renderMembershipCards(clientMemberships)}
                  {this.renderPromos()}
                  {this.renderMembershipCards(nonClientMemberships)}
                  {this.renderAddProgramButton()}
                </div>
              </div>
            </div>
          </div>

          <div className='outer-container__hide-scrollbar'>
            {this.state.isShowingAddProgramsUI ? this.renderAddProgramsUI() : null}
          </div>

        </div>
      );
    }
}

BalanceTracker.propTypes = {
  afterScrollToMembershipCard: PropTypes.func,
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  clientData: ImmutablePropTypes.map,
  deleteMembership: PropTypes.func,
  device: PropTypes.string,
  disableMasonry: PropTypes.bool,
  elementsToHideClass: PropTypes.string,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  getLpById: PropTypes.func,
  googleTagManager: PropTypes.object,
  iframeManager: PropTypes.object,
  isShowingSlide: PropTypes.bool,
  lastAddedLpId: PropTypes.string,
  locale: PropTypes.string,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  postNewMembership: PropTypes.func,
  promos: ImmutablePropTypes.list,
  setIsShowingSlide: PropTypes.func,
  shouldDisableProgramAddition: PropTypes.bool,
  userMemberValidationUrl: PropTypes.string
};

export default BalanceTracker;
