import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { setIntlFormatter } from '../action-creators/app';
import { setIntlFormatterOnValidations } from '../utils/validations';

export class Intl extends React.Component {
  UNSAFE_componentWillMount() {
    const { intl, setIntlFormatter } = this.props;
    setIntlFormatterOnValidations(intl);
    setIntlFormatter(intl);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl, setIntlFormatter } = this.props;

    if (!intl || !nextProps.intl) {
      return;
    }

    const gotLocale = !intl.locale && !!nextProps.intl.locale;
    const gotMessages = !intl.messages && !!nextProps.intl.messages;
    if (gotLocale || gotMessages) {
      setIntlFormatter(nextProps.intl);
    }
  }

  render() {
    const { formatter, children } = this.props;

    return (
      <div className='intl-component'>
        {formatter ? children : null}
      </div>
    );
  }
}

Intl.propTypes = {
  intl: PropTypes.any.isRequired,
  setIntlFormatter: PropTypes.func,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

function mapStateToProps(state) {
  return {
    formatter: state.app.get('formatter')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setIntlFormatter
  }, dispatch);
}

export const IntlContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Intl));
