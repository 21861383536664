/**
   @class Lps Action Creator
 */
import Lps from '../api/lps';
import { uniq } from '../utils/tools';

/*-------------------------------
 *
 * Sync Actions
 *
   -------------------------------*/

/**
   set sboolean for UI to indicate whether the `GET /v1/lps` API request
   has compmleted
   @method isFetchingLps
   @param {Boolean} isFetchingLps
 */
export function isFetchingLps(isFetchingLps) {
  return {
    type: 'IS_FETCHING_LPS',
    isFetchingLps
  };
}

/**
   sets lps
   @method setLps
   @param {List[Map]} lps
 */
export function setLps(lps) {
  return {
    type: 'SET_ALL_LPS',
    lps
  };
}

/**
   sets preferred lps
   @method setPreferredLps
   @param {List[Map]} preferredLpIds Immutable array of lpIds
 */
export function setPreferredLps(preferredLpIds) {
  return {
    type: 'SET_PREFERRED_LPS',
    preferredLpIds
  };
}

export function setRemainingLps(memberships) {
  return {
    type: 'SET_REMAINING_LPS',
    memberships
  };
}

/**
   sets lps
   @method setEligibleLps
   @param {List[Map]} lps
 */
export function setEligibleLps(eligibleLps, direction) {
  return {
    type: 'SET_ELIGIBLE_LPS',
    direction,
    eligibleLps
  };
}

/**
   resets lps to empty Immutable Map
   @method resetEligibleFromLps
 */
export function resetEligibleFromLps() {
  return {
    type: 'RESET_ELIGIBLE_FROM_LPS'
  };
}

/**
   resets eligibleLps to empty Immutable Map
   @method resetEligibleToLps
 */
export function resetEligibleToLps() {
  return {
    type: 'RESET_ELIGIBLE_TO_LPS'
  };
}

/**
   sets boolean to indicate to UI if eligibleLps API request is not complete
   @method isFetchingEligibleLps
   @param {Boolean} isFetchingEligibleLps
   @param {String} direction indicator for which exchange select box is fetching for
 */
export function isFetchingEligibleLps(direction, isFetchingEligibleLps) {
  return {
    type: 'IS_FETCHING_ELIGIBLE_LPS',
    isFetchingEligibleLps,
    direction
  };
}

export function setAllEligibleLpIds(allEligibleLpIds) {
  return {
    type: 'SET_ALL_ELIGIBLE_LP_IDS',
    allEligibleLpIds
  };
}

export function setAllEligibleFromLpIds(allEligibleFromLpIds) {
  return {
    type: 'SET_ALL_ELIGIBLE_FROM_LP_IDS',
    allEligibleFromLpIds
  };
}


export function setAllEligibleToLpIds(allEligibleToLpIds) {
  return {
    type: 'SET_ALL_ELIGIBLE_TO_LP_IDS',
    allEligibleToLpIds
  };
}

/*-------------------------------
 *
 * Async Actions
 *
   -------------------------------*/

function completeLpsCall(lps, preferredLpIds) {
  return dispatch => {
    dispatch(setLps(lps));
    dispatch(setPreferredLps(preferredLpIds));
    dispatch(isFetchingLps(false));
  };
}
/**
   `GET /v1/lps` and sets lps and preferredLps
   @method fetchLps
   @param {String} preferredLpsString comma separated lpids. eg "2832,5003,0062"
 */
export function fetchLps(preferredLpsString = '') {
  return dispatch => {
    const preferredLpIds = preferredLpsString.split(',').filter(lpId => !!lpId.length);

    dispatch(isFetchingLps(true));
    Lps.fetchLps()
      .then(lps => {
        dispatch(completeLpsCall(lps, preferredLpIds));
      });
  };
}

/**
   `GET /public/lps` and sets lps and preferredLps
   @method fetchPublicLps
   @param {String} clientId Id of client
   @param {String} preferredLpsString comma separated lpids. eg "2832,5003,0062"
 */
export function fetchPublicLps(preferredLpsString = '', clientId = '') {
  return dispatch => {
    const preferredLpIds = preferredLpsString.split(',').filter(lpId => !!lpId.length);

    dispatch(isFetchingLps(true));
    return Lps.fetchPublicLps(clientId)
      .then(lps => {
        dispatch(completeLpsCall(lps, preferredLpIds));
      });
  };
}

function buildEligibleLpParams(direction, clientLpId) {
  const query = {
    direction,
    orderType: 'exchange'
  };

  if (clientLpId) {
    // if present, always include clientLp in the equation
    if (direction === 'from') {
      query.toLpId = clientLpId;
    } else {
      query.fromLpId = clientLpId;
    }
  }
  return query;
}

export function fetchAllEligibleLps(clientLpId) {
  return dispatch => {
    const fetchToLps = Lps.fetchEligibleLps(buildEligibleLpParams('to', clientLpId));
    const fetchFromLps = Lps.fetchEligibleLps(buildEligibleLpParams('from', clientLpId));
    fetchFromLps.then(responseFrom => {
      fetchToLps.then(responseTo => {
        // We're using this nested promise instead of Promise.all() because we need all the values simultaneously, while also being able to differentiate them
        const {
          eligibleFromLpIds, eligibleToLpIds
        } = getEligibleLpIdsFromResponses(responseFrom, responseTo, clientLpId);

        dispatch(setAllEligibleFromLpIds(eligibleFromLpIds));
        dispatch(setAllEligibleToLpIds(eligibleToLpIds));
        dispatch(setAllEligibleLpIds(uniq(eligibleFromLpIds.concat(eligibleToLpIds))));
      });
    });
  };
}

function getEligibleLpIdsFromResponses(responseFrom, responseTo, clientLpId) {
  const clientLpIdIsEligibleFrom = clientLpId && responseTo.lpIds.length > 0;
  const clientLpIdIsEligibleTo = clientLpId && responseFrom.lpIds.length > 0;
  const eligibleFromLpIds = responseFrom.lpIds.concat(clientLpIdIsEligibleFrom ? [clientLpId] : []);
  const eligibleToLpIds = responseTo.lpIds.concat(clientLpIdIsEligibleTo ? [clientLpId] : []);
  return { eligibleFromLpIds, eligibleToLpIds };
}

/**
   `GET /v1/eligible-lps` with query parameters
   (eg: 'direction', 'fromLpId', 'toLpId')
   @method fetchEligibleLps
   @param {Object} params
 */
export function fetchEligibleLps(params) {
  const { direction } = params;
  return dispatch => {
    dispatch(isFetchingEligibleLps(direction, true));
    Lps.fetchEligibleLps(params)
      .then(eligibleLps => {
        dispatch(setEligibleLps(eligibleLps, direction));
        dispatch(isFetchingEligibleLps(direction, false));
      });
  };
}
