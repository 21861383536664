import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import HeroOverlayTemplate1 from './HeroOverlayTemplate1';

const HeroOverlay = props => {
  const { overlayContent } = props;
  // Once more templates are added, this component will be the one
  // to decide what overlay template component to render based on
  // a overlayType property in the promo object
  return (
    <HeroOverlayTemplate1
      overlayContent={overlayContent}
    />
  );
};

HeroOverlay.propTypes = {
  overlayContent: ImmutablePropTypes.list
};

export default HeroOverlay;
