import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

export const JoinNowLink = ({ signupUrl }) => {
  const msgValues = { signupUrl };

  return (
    <p className='join_now_link u-margin-top text-center text-size-normal'>
      <FormattedHTMLMessage id='registration.joinNow.link' values={msgValues} />
    </p>
  );
};

JoinNowLink.propTypes = {
  signupUrl: PropTypes.string.isRequired
};
