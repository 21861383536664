const scriptId = 'kountScript';

const startKountDataCollection = () => {
  // need to disable the linting for the definition of 'ka' as this class is from the data collector sdk from Kount which is added to the document each time a transaction is made
  // eslint-disable-next-line no-undef
  const client = new ka.ClientSDK();
  client.autoLoadEvents();
};

export const removeKountScript = () => {
  // 'ibody' is the arbitrary id the Kount SDK prescribes for its iframes
  const prevScript = document.getElementById(scriptId);
  const prevIframe = document.getElementById('ibody');
  if (prevScript) prevScript.remove();
  if (prevIframe) prevIframe.remove();
};

export const addKountScript = scriptSrc => {
  /*
    Cleans up the existing kount script tag and iframe if they exist (ie. user opened confirmation modal multiple times)
    The iframe is added automatically by the Kount SDK once the script has fully loaded
  */
  removeKountScript();

  const script = document.createElement('script');
  script.id = scriptId;
  script.src = scriptSrc;
  script.type = 'text/javascript';
  script.addEventListener('load', () => {
    startKountDataCollection();
  });

  document.getElementsByTagName('head')[0].appendChild(script);
};
