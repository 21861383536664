import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getTabIndex, handleKeyPressArrowRight, handleKeyPressArrowLeft, handleKeyPressHome, handleKeyPressEnd } from './utils/accessibility-helpers';

function maxmin(pos, min, max) {
  if (pos < min) { return min; }
  if (pos > max) { return max; }
  return pos;
}

class RangeSlider extends React.Component {
  static defaultProps = {
    ariaLabel: 'Range Slider',
    className: '',
    isDisabled: false,
    isShowingSlide: false,
    min: 0,
    max: 100,
    onChange: () => {},
    step: 1,
    value: 0
  };

  state = {
    limit: 0,
    grab: 0
  };

  componentDidMount() {
    this.setupSlider();
    window.addEventListener('resize', this.setupSlider);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setupSlider);
  }

  getPositionFromValue = value => {
    const { limit } = this.state;
    const { min, max } = this.props;
    const divisor = max - min;
    const percentage = divisor !== 0 ? (value - min) / divisor : 0.5;
    return Math.round(percentage * limit);
  };

  getValueFromPosition = pos => {
    const { limit } = this.state;
    const { min, max, step } = this.props;
    const rangeSize = max - min;
    const availableSteps = rangeSize / step;
    const percentage = (maxmin(pos, 0, limit) / (limit || 1));
    return step * Math.round(percentage * availableSteps) + min;
  };

  setupSlider = () => {
    const sliderPos = this.sliderEl.offsetWidth;
    const handlePos = this.handleEl.offsetWidth;
    this.setState({
      limit: sliderPos - handlePos,
      grab: handlePos / 2
    });
  };

  coordinates = pos => {
    const value = this.getValueFromPosition(pos);
    const handlePos = this.getPositionFromValue(value);
    const fillPos = handlePos + this.state.grab;
    return {
      fill: fillPos,
      handle: handlePos
    };
  };

  fillEl = null;

  handleDragEnd = () => {
    document.removeEventListener('mousemove', this.handleDragStart);
    document.removeEventListener('mouseup', this.handleDragEnd);
    document.removeEventListener('touchmove', this.handleDragStart);
    document.removeEventListener('touchend', this.handleDragEnd);
  };

  handleDragStart = e => {
    const { onChange } = this.props;
    if (!onChange) return;
    const value = this.position(e);
    onChange(value);
  };

  handleEl = null;

  handleKnobMouseDown = () => {
    document.addEventListener('mousemove', this.handleDragStart);
    document.addEventListener('touchmove', this.handleDragStart);
    document.addEventListener('mouseup', this.handleDragEnd);
    document.addEventListener('touchend', this.handleDragEnd);
  };

  handleNoop = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleSliderArrowKeys = event => {
    handleKeyPressArrowLeft(() => this.moveSliderWithKeyboard(-1))(event);
    handleKeyPressArrowRight(() => this.moveSliderWithKeyboard(1))(event);
    handleKeyPressHome(() => this.moveSliderMinMax('start'))(event);
    handleKeyPressEnd(() => this.moveSliderMinMax('end'))(event);
  };

  handleSliderMouseDown = e => {
    const { onChange } = this.props;
    if (!onChange) return;
    const value = this.position(e);
    onChange(value);
  };

  moveSliderMinMax = type => {
    const { max, onChange } = this.props;
    const min = 0;
    onChange(type === 'start' ? min : max);
  };

  moveSliderWithKeyboard = direction => {
    const { step, value, max, onChange } = this.props;
    const newValue = value + step * direction;
    const newValueWithinRange = maxmin(newValue, 0, max);
    onChange(newValueWithinRange);
  };

  position = e => {
    const node = this.sliderEl;
    const coordinate = e.touches ? e.touches[0].clientX : e.clientX;
    const direction = node.getBoundingClientRect().left;
    const pos = coordinate - direction - this.state.grab;
    return this.getValueFromPosition(pos);
  };

  sliderEl = null;

  render() {
    const { value, min, max, ariaLabel } = this.props;
    const position = this.getPositionFromValue(value);
    const coords = this.coordinates(position);
    const fillStyle = { width: `${coords.fill}px` };
    const handleStyle = { left: `${coords.handle}px` };
    const isDisabled = max === 0 || this.props.isDisabled;

    return (
      <div
        ref={el => this.sliderEl = el}
        className={classnames('rangeslider', { 'rangeslider--disabled': isDisabled }, this.props.className)}
      >
        <div
          ref={el => this.fillEl = el}
          className='rangeslider__fill'
          style={fillStyle}
        />
        <div
          ref={el => this.handleEl = el}
          tabIndex={getTabIndex(this.props.isShowingSlide)}
          aria-valuenow={value}
          aria-valuemin={min}
          aria-valuemax={max}
          role='slider'
          aria-label={ariaLabel}
          className='rangeslider__handle'
          onMouseDown={isDisabled ? this.handleNoop : this.handleKnobMouseDown}
          onTouchStart={isDisabled ? this.handleNoop : this.handleKnobMouseDown}
          onKeyDown={this.handleSliderArrowKeys}
          style={handleStyle}
        />
      </div>
    );
  }
}

RangeSlider.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isShowingSlide: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.number
};

export default RangeSlider;
