/**
Component that acts as a button with spinner
@class Fancy Button Component
@param {String} classes string of classes on button element
@param {String} type type of button
@param {String} label content in button
@param {Boolean} trigger hides/shows spinner
@param {String} onClick action on click
*/
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from './Spinner';
import { FormattedMessage } from 'react-intl';

class FancyButton extends React.Component {
  fancyButtonEl = null;

  handleClick = e => {
    e.preventDefault();
    if (!this.props.isSpinClickable && this.props.trigger) {
      return;
    }
    this.props.onClick(e);
  };

  render() {
    const opts = {
      color: '#fff',
      position: 'relative'
    };
    return (
      <button
        ref={el => this.fancyButtonEl = el}
        type={this.props.type}
        className={classnames('fancy-button', this.props.classes)}
        disabled={this.props.disabled}
        onClick={this.handleClick}
      >
        {
          this.props.trigger ? (
            <div className='u-padding-small'>
              <Spinner opts={opts} />
            </div>
          ) : (
            <span>
              <FormattedMessage id={this.props.label} />
            </span>
          )
        }
      </button>
    );
  }
}

FancyButton.propTypes = {
  isSpinClickable: PropTypes.bool,
  trigger: PropTypes.bool,
  type: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default FancyButton;
