import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { capitalize, formatNumber, getNumberFromString } from '../utils/string';
import constants from '../utils/constants';

import { FormattedHTMLMessage } from 'react-intl';
import RangeSlider from './RangeSlider';
import FancyField from 'react-fancy-field';
import { getTabIndex } from './utils/accessibility-helpers';

const initialState = () => ({
  inputFromAmount: '0', // input values that tie to rangeslider and input field
  inputToAmount: '0',
  hasManuallyChangedTo: false, // booleans to update to/from input amounts on blur
  hasManuallyChangedFrom: false,
  exchangeSliderMessage: constants.exchange.i18AmountsDefaultMessage
});

class ExchangeSlider extends React.Component {
  state = initialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      fromAmount,
      toAmount,
      shouldResetExchange
    } = this.props;
    // To update input amounts from rangeslider changes
    if (fromAmount !== nextProps.fromAmount || toAmount !== nextProps.toAmount) {
      this.setState({
        inputFromAmount: formatNumber(nextProps.fromAmount),
        inputToAmount: formatNumber(nextProps.toAmount)
      });
    }

    if (!shouldResetExchange && nextProps.shouldResetExchange) {
      this.setState(initialState());
    }
  }

  getClosestAmountIndex = (inputNum, direction) => {
    const { amounts } = this.props;

    let index = 0;
    let bestDifference = Infinity;
    amounts.forEach((amount, amountIndex) => {
      const currentDifference = Math.abs(amount.get(direction) - inputNum);
      if (currentDifference < bestDifference) {
        bestDifference = currentDifference;
        index = amountIndex;
      }
    });
    return index;
  };

  getStatusCode = (inputNum, direction, closestIndex) => {
    const { amounts } = this.props;
    if (amounts.isEmpty()) {
      return undefined;
    }
    const maxExchangeAmount = amounts.last().get(direction);
    const minExchangeAmount = amounts.first().get(direction);
    const greaterThanMax = inputNum > maxExchangeAmount;
    const lessThanMin = inputNum < minExchangeAmount;
    const closestAmount = amounts.getIn([closestIndex, direction]);
    const amountExists = inputNum === closestAmount;

    if (greaterThanMax) {
      return 'errorMax';
    } else if (lessThanMin) {
      return 'errorMin';
    } else if (!amountExists) {
      return 'errorIncrement';
    }
    return 'success';
  };

  setAmount = newAmountsIndex => {
    const { amountIndex, setSelectedAmount } = this.props;
    if (amountIndex !== newAmountsIndex) {
      setSelectedAmount(newAmountsIndex);
    }
  };

  setExchangeAmountsMessage = statusCode => {
    this.setState({ exchangeSliderMessage: this.i18nTokenFromStatusCode(statusCode) });
  };

  setHasManuallyChanged = (direction, value) => {
    if (direction === 'fromAmount') {
      this.setState({ hasManuallyChangedFrom: value });
    } else {
      this.setState({ hasManuallyChangedTo: value });
    }
  };

  setMessageFromInput = (inputNum, direction, closestIndex) => {
    const statusCode = this.getStatusCode(inputNum, direction, closestIndex);
    this.setExchangeAmountsMessage(statusCode);
  };

  areEitherMembershipsPendingStatus = () => {
    const { fromMembership, toMembership } = this.props;
    const fromMembershipIsPending = fromMembership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    const toMembershipIsPending = toMembership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    return fromMembershipIsPending || toMembershipIsPending;
  };

  i18nTokenFromStatusCode = type => {
    switch (type) {
      case 'errorMax':
        return constants.exchange.i18nAmountsErrorMax;
      case 'errorMin':
        return constants.exchange.i18nAmountsErrorMin;
      case 'errorIncrement':
        return constants.exchange.i18nAmountsErrorIncrement;
      default:
        return constants.exchange.i18AmountsDefaultMessage;
    }
  };

  submitInputFieldContents = (inputVal, direction) => {
    const { amounts } = this.props;
    const { hasManuallyChangedFrom, hasManuallyChangedTo, inputFromAmount, inputToAmount } = this.state;
    const inputNum = getNumberFromString(inputVal);
    this.setState({
      inputFromAmount: formatNumber(inputFromAmount),
      inputToAmount: formatNumber(inputToAmount)
    });

    if (hasManuallyChangedFrom || hasManuallyChangedTo) {
      // If the user is trying to submit a number that doesn't appear in the amounts array, fix it using the closest amount
      const index = this.getClosestAmountIndex(inputNum, direction);
      const closestAmount = amounts.getIn([index, direction]);
      const key = `input${capitalize(direction)}`;
      this.setState({ [key]: formatNumber(closestAmount) });
      //

      this.setMessageFromInput(inputNum, direction, index);
      this.setAmount(index);
      this.setHasManuallyChanged(direction, false);
    }
  };

  updateUnformattedInput = (val, direction) => {
    val = getNumberFromString(val);
    if (direction === 'fromAmount') {
      this.setState({
        inputFromAmount: val,
        hasManuallyChangedFrom: true
      });
    } else {
      this.setState({
        inputToAmount: val,
        hasManuallyChangedTo: true
      });
    }
  };

  validateInputField = (val, direction) => {
    const { formatter } = this.props;
    const inputNum = getNumberFromString(val);

    const index = this.getClosestAmountIndex(inputNum, direction);
    const statusCode = this.getStatusCode(inputNum, direction, index);

    switch (statusCode) {
      case 'errorMax':
        return formatter.formatMessage({ id: constants.exchange.inputValidationErrorMax });
      case 'errorMin':
        return formatter.formatMessage({ id: constants.exchange.inputValidationErrorMin });
      default:
        return undefined;
    }
  };

  renderExchangeAmounts = () => {
    const { amounts, selectedOffer, fromLp } = this.props;
    const { exchangeSliderMessage } = this.state;
    if (amounts.isEmpty()) {
      return;
    }
    const maxFromAmount = amounts.last().get('fromAmount');
    const minAmount = selectedOffer.get('minPointsOut');
    const incrementAmount = selectedOffer.get('increment');
    const values = {
      maxFromAmount: formatNumber(maxFromAmount || 0),
      fromLpCurrency: fromLp.getIn(['content', 'currencyNameLong']),
      incrementAmount,
      minAmount: formatNumber(minAmount || 0)
    };
    return (
      <div>
        <FormattedHTMLMessage id={exchangeSliderMessage} values={values} />
      </div>
    );
  };

  renderExchangeSliderAndInputs = () => {
    const { amounts, amountIndex, isShowingSlide, fromAmount, toAmount, fromLp, toLp, formatter } = this.props;
    const exchangeSliderAriaLabel = `${fromAmount || 0} ${fromLp.getIn(['content', 'currencyNameShort'])} for ${toAmount || 0} ${toLp.getIn(['content', 'currencyNameShort'])}`;
    const { inputFromAmount, inputToAmount } = this.state;
    const fromProgramLabel = fromLp.getIn(['content', 'currencyNameLong']) || formatter.formatMessage({ id: 'exchangeSelect.exchangeFromModalHeader' });
    const toProgramLabel = toLp.getIn(['content', 'currencyNameLong']) || formatter.formatMessage({ id: 'exchangeSelect.exchangeToModalHeader' });

    return (
      <div className='u-margin-bottom u-padding-horizontal u-padding-horizontal-none@md'>
        <RangeSlider
          max={amounts.size ? amounts.size - 1 : 0}
          onChange={index => {
            this.setAmount(index);
          }}
          value={amountIndex}
          isShowingSlide={isShowingSlide}
          disabled={this.areEitherMembershipsPendingStatus()}
          ariaValText={exchangeSliderAriaLabel}
          ariaLabel='Exchange points slider'
        />

        <div className='o-layout u-margin-top-large'>
          <div className='o-layout__item u-5/11'>
            <FancyField
              name='fromAmount'
              type='input'
              classes='input-center text-center range-slider__input-field'
              label={fromProgramLabel}
              placeholder={fromProgramLabel}
              tabIndex={getTabIndex(isShowingSlide)}
              value={inputFromAmount}
              onChange={this.updateUnformattedInput}
              onEnter={this.submitInputFieldContents}
              onBlur={this.submitInputFieldContents}
              validator={this.validateInputField}
              ariaHidden={false} // This avoids an issue with FancyField where it changes the visibility of the field and interfering with screen readers
            />
          </div>
          <div className='o-layout__item u-1/11 text-center text-size-big text-color-muted'>
            =
          </div>
          <div className='o-layout__item u-5/11'>
            <FancyField
              name='toAmount'
              type='input'
              classes='input-center text-center range-slider__input-field'
              label={toProgramLabel}
              placeholder={toProgramLabel}
              tabIndex={getTabIndex(isShowingSlide)}
              value={inputToAmount}
              onChange={this.updateUnformattedInput}
              onEnter={this.submitInputFieldContents}
              onBlur={this.submitInputFieldContents}
              validator={this.validateInputField}
              ariaHidden={false} // This avoids an issue with FancyField where it changes the visibility of the field and interfering with screen readers
            />
          </div>
        </div>
      </div>
    );
  };

  renderExchangeSliderMessage = () => {
    const { amounts, fromMembership, toMembership } = this.props;
    const { exchangeSliderMessage } = this.state;
    const areBothMembershipsSelected = fromMembership.size && toMembership.size;
    const shouldShowExchangeAmounts = areBothMembershipsSelected && amounts.size;
    let textClass;
    let isAlertMessage;
    switch (exchangeSliderMessage) {
      case constants.exchange.i18nAmountsErrorMax:
        textClass = 'exchange-configure-amounts__description--error';
        isAlertMessage = true;
        break;
      case constants.exchange.i18nAmountsErrorMin:
        textClass = 'exchange-configure-amounts__description--error';
        isAlertMessage = true;
        break;
      case constants.exchange.i18nAmountsErrorIncrement:
        textClass = 'exchange-configure-amounts__description--warning';
        isAlertMessage = true;
        break;
      default:
        textClass = 'exchange-configure-amounts__description';
        isAlertMessage = false;
        break;
    }
    return (
      <div
        className={`u-padding-bottom text-center text-base-size-palm ${textClass}`}
        aria-live={isAlertMessage ? 'assertive' : 'off'}
      >
        {
          shouldShowExchangeAmounts ? this.renderExchangeAmounts() : null
        }
      </div>
    );
  };

  render() {
    return (
      <div>
        { this.renderExchangeSliderAndInputs() }
        { this.renderExchangeSliderMessage() }
      </div>
    );
  }
}

ExchangeSlider.propTypes = {
  fromAmount: PropTypes.number,
  toAmount: PropTypes.number,
  shouldResetExchange: PropTypes.bool,
  amounts: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  amountIndex: PropTypes.number,
  isShowingSlide: PropTypes.bool,
  fromLp: ImmutablePropTypes.map,
  toLp: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  fromMembership: ImmutablePropTypes.map,
  toMembership: ImmutablePropTypes.map,
  selectedOffer: ImmutablePropTypes.map,
  setSelectedAmount: PropTypes.func
};

export default ExchangeSlider;
