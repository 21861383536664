/**
 @class Memberships API
 */

import { addAuthTokenToHeaders, request } from './utils/httpUtils';
import { StatusCodes } from 'http-status-codes';

/**
 `GET /v1/memberships?{refresh}` get all user's memberships
 @method fetchMemberships
 @param {Boolean} shouldRefresh to send refresh param
 */
export function fetchMemberships(shouldRefresh) {
  const options = addAuthTokenToHeaders();
  return fetch(`/v1/memberships${shouldRefresh ? '?refresh=true' : ''}`, options)
    .then(res => res.json())
    .then(payload => payload.memberships);
}

/**
 `GET /v1/memberships/{membershipId}?{refresh}` get user's single membership
 @method fetchMembership
 @param {Object} membership
 @param {Boolean} shouldRefresh to send refresh param
 */
export function fetchMembership(membershipId, shouldRefresh) {
  const options = addAuthTokenToHeaders();
  return fetch(`/v1/memberships/${membershipId}${shouldRefresh ? '?refresh=true' : ''}`, options)
    .then(res => res.json())
    .then(payload => payload);
}

/**
 `POST /v1/memberships` post membership to API to add to user's list of memberships
 @method postNewMembership
 @param {String} lpId
 */
export function postNewMembership(lpId) {
  const options = {
    method: 'post',
    body: JSON.stringify({ lpId })
  };
  return request('/v1/memberships', options)
    .then(data => data.json);
}

/**
 `DELETE /v1/memberships/{membershipId}` deletes membership from user's list
 of memberships
 @method deleteMembership
 @param {String} membershipId
 */
export function deleteMembership(membershipId) {
  const options = addAuthTokenToHeaders();
  options.method = 'delete';
  return fetch(`/v1/memberships/${membershipId}`, options)
    .then(res => res.status);
}

/**
 `PUT /v1/memberships/{membershipId}` updates user's membership
 @method registerMembership
 @param {Object} membership
 */
export function registerMembership(membership) {
  const { lpId, credentials, id } = membership;
  const options = {
    method: 'put',
    body: JSON.stringify({ lpId, credentials })
  };
  return request(`/v1/memberships/${id}?updateEarnOrders=true`, options)
    .then(data => data.json);
}

/**
 `PUT /v1/memberships/{membershipId}/mv` updates user's membership with a given MV
 @method registerMembershipMv
 @param {String} membershipId
 @param {String} lpMv
 */
export function registerMembershipMv(membershipId, lpMv) {
  const options = {
    method: 'put',
    body: JSON.stringify({ token: lpMv })
  };
  return request(`/v1/memberships/${membershipId}/update-mv`, options)
    .then(data => data.json);
}

/**
 `GET /v1/wps/{wpId}/lps/{lpId}` fetches the wp-lp config to know the method of registering the membership
 @method fetchWpLpConfig
 @param {String} wpId
 @param {String} lpId
 */
function fetchOneWpLpConfig(wpId, lpId) {
  const options = addAuthTokenToHeaders({ disableErrors: true });
  return request(`/v1/wps/${wpId}/lps/${lpId}/config`, options)
    .then(data => data.json)
    .catch(e =>  {
      if (e.status === StatusCodes.NOT_FOUND) {
        return { config: {} };
      }
      const err = `Fetching WpLpConfig resulted in ${e}`;
      throw err;
    });
}

/**
 * GET /v1/memberships/{membershipId}/links fetches registration link for a member
 *
 * @method generateCredentialCatcherURL
 * @param {String} membershipId
 */
function generateCredentialCatcherURL(membershipId) {
  const options = addAuthTokenToHeaders({ disableErrors: true });
  return request(`/v1/memberships/${membershipId}/links`, options)
    .then(data => data.json);
}

/**
 * GET /public/validate/wps/redirect-url (returns partner redirect url after BE validation is successful)
 * @method validateRedirectUrl
 * @param {String} clientId
 * @param {String} redirectUrl
 */
const validateRedirectUrls = (clientId, redirectUrl) =>
  request(`/public/validate/wps/redirect-url?clientId=${clientId}&partnerRedirectURL=${redirectUrl}`)
    .then(data => data.json);

export default {
  fetchMemberships,
  fetchOneWpLpConfig,
  postNewMembership,
  deleteMembership,
  generateCredentialCatcherURL,
  registerMembership,
  registerMembershipMv,
  fetchMembership,
  validateRedirectUrls
};
