import React from 'react';
import PropTypes from 'prop-types';
import Drawer from './Drawer';

class FAQ extends React.Component {
  renderSingleFaq = (faq, key) => (
    <Drawer
      key={key}
      isOpen={false}
      header={faq.question}
      classes='faq'
    >
      <div className='u-margin-bottom'>
        <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
      </div>
    </Drawer>
  );

  renderFAQByCategory = (category, key) => (
    <div key={key}>
      <br />
      <div className='u-margin-top'>
        <div dangerouslySetInnerHTML={{ __html: category.header }} />
      </div>
      {category.content.map((faq, key) => this.renderSingleFaq(faq, key))}
    </div>
  );

  render() {
    const { faqs, footnote, showFAQByCategory } = this.props;
    return (
      <div>
        { showFAQByCategory ? faqs.map((category, key) => this.renderFAQByCategory(category, key)) :
          faqs.map((faq, key) => this.renderSingleFaq(faq, key)) }
        <p className='faq__footnotes'>{ footnote }</p>
      </div>
    );
  }
}

FAQ.propTypes = {
  faqs: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string
    })),
    PropTypes.arrayOf(PropTypes.shape({
      header: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string
      }))
    }))
  ]),
  footnote: PropTypes.string
};

export default FAQ;
