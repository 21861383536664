import { List } from 'immutable';

export function isImmutable(obj) {
  return obj !== null && typeof obj === 'object' && !!obj.toJSON;
}

export function getById(collection, id) {
  return collection.find(el => el.get('id') === id);
}

export function getValidationFromField(name, fields) {
  return fields.find(field => field.name.toString() === name.toString()).validators;
}

export function isFieldValid(value, validators) {
  validators = Array.isArray(validators) ? validators : [validators];
  return validators.every(validator => typeof validator(value) !== 'string');
}

export function isFormValid(fields, obj) {
  fields = Array.isArray(fields) ? fields : [fields];
  const _isImmutable = isImmutable(obj);

  return fields.every(field => {
    if (!field.validators || field.ignoreValidators) { return true; }
    let value = _isImmutable ? obj.get(field.name) : obj[field.name];
    if (Array.isArray(field.name)) {
      value = _isImmutable ? obj.getIn(field.name) : obj[field.name];
    }
    return isFieldValid(value, field.validators);
  });
}

export function isFeatureEnabled(name, features = List()) {
  return features.includes(name);
}

/**
 * animated scroll function
 * @method scrollTo
 * @param {Number} to pixel value of scroll position (example: 0 is top, 100 is 100px down)
 * @param {Number} duration value in ms for animation duration
 * @param {Element} element DOM element to scroll
 *
 * */
export function scrollTop(duration = 0, to = 0, element = document.documentElement) {
  const tickLength = 10;
  const difference = to - element.scrollTop;
  const perTick = difference / duration * tickLength;
  if (duration <= 0) {
    return element.scrollTop = 0;
  }
  setTimeout(() => {
    element.scrollTop += perTick;
    if (element.scrollTop === to) return;
    scrollTop(duration - tickLength, to, element);
  }, tickLength);
}
