import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

export const SwitchEarnToRedeemBox = () => (
  <div className='switchEarnToRedeemBox__parentContainer'>
    <div className='switchEarnToRedeemBox__subContainer'>
      <div className='switchEarnToRedeemBox__header'>
        <FormattedMessage id='earn.linkAccount.switchBox.header' />
      </div>
      <div className='switchEarnToRedeemBox__subheading'>
        <FormattedMessage id='earn.linkAccount.switchBox.subheading' />
      </div>
      <div className='switchEarnToRedeemBox__description'>
        <FormattedMessage id='earn.linkAccount.switchBox.description' />
      </div>
      <div className='switchEarnToRedeemBox__link'>
        <FormattedHTMLMessage
          id='earn.linkAccount.switchBox.link.text'
        />
      </div>
    </div>
  </div>
);

