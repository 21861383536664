import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';

import constants from '../utils/constants';
import { getMembershipFromState, getLpFromState } from './utils/state-helpers';

import { setModalContentKey } from '../action-creators/app';

import ModalHeader from './ModalHeader';

export class ExchangeDisabledModal extends React.Component {
  areSuccessfulMembershipsSelected = () => {
    const { fromMembership, toMembership } = this.props;

    const fromMembershipIsSuccess = fromMembership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_SUCCESS;
    const toMembershipIsSuccess = toMembership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_SUCCESS;

    return fromMembershipIsSuccess && toMembershipIsSuccess;
  };

  disabledMessageId = () => {
    const { selectedOffer, fromAmount } = this.props;
    const hasSelectedOffer = !!selectedOffer.size;
    const hasFromAmount = fromAmount > 0;
    const successfulMembershipsSelected = this.areSuccessfulMembershipsSelected();

    if (!hasSelectedOffer) {
      return 'exchange.selectProgramFirst';
    } else if (!hasFromAmount) {
      return 'EXCHANGE_INSUFFICIENT_POINTS';
    } else if (!successfulMembershipsSelected) {
      return 'exchange.lpRegistrationDisabledMessage';
    }
    return null;
  };

  render() {
    const { formatter, fromLp, setModalContentKey } = this.props;
    const currency = fromLp.getIn(['content', 'currencyNameLong']);
    const messageId = this.disabledMessageId();

    return (
      <div>
        <ModalHeader
          header='exchange.disabledModalHeader'
          formatter={formatter}
          setModalContentKey={setModalContentKey}
        />
        <div className='text-center text-base-size-palm u-padding'>
          <FormattedHTMLMessage id={messageId} values={{ currency }} />
        </div>
      </div>
    );
  }
}

ExchangeDisabledModal.propTypes = {
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  fromLp: ImmutablePropTypes.map,
  selectedOffer: ImmutablePropTypes.map,
  fromAmount: PropTypes.number,
  fromMembership: ImmutablePropTypes.map,
  toMembership: ImmutablePropTypes.map,
  setModalContentKey: PropTypes.func
};

function mapStateToProps(state) {
  return {
    selectedOffer: state.exchange.get('selectedOffer'),
    fromAmount: state.exchange.get('fromAmount'),
    fromMembership: getMembershipFromState(state, 'from'),
    toMembership: getMembershipFromState(state, 'to'),
    formatter: state.app.get('formatter'),
    fromLp: getLpFromState(state, 'from')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setModalContentKey
  }, dispatch);
}

export const ExchangeDisabledModalContainer = connect(mapStateToProps, mapDispatchToProps)(ExchangeDisabledModal);
