import { Map, List, fromJS } from 'immutable';
import { compact } from '../utils/immutable';

const initialState = fromJS({
  isFetchingLps: false,
  isFetchingFromLps: false,
  isFetchingToLps: false,
  eligibleToLps: {},
  eligibleFromLps: {},
  allEligibleLpIds: [],
  allEligibleFromLpIds: [],
  allEligibleToLpIds: [],
  allLps: [],
  preferredLps: [],
  remainingLps: []
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'IS_FETCHING_LPS':
      return state.set('isFetchingLps', action.isFetchingLps);

    case 'IS_FETCHING_ELIGIBLE_LPS': {
      const direction = action.direction === 'to' ? 'To' : 'From';
      return state.set(`isFetching${direction}Lps`, action.isFetchingEligibleLps);
    }

    case 'SET_ALL_LPS':
      return state.set('allLps', fromJS(action.lps));

    case 'SET_PREFERRED_LPS': {
      const allLps = state.get('allLps');
      const preferredLps = action.preferredLpIds.map(lpId => allLps.find(lp => lp.get('id') === lpId)).filter(lp => typeof lp !== 'undefined');
      return state.set('preferredLps', fromJS(preferredLps));
    }

    case 'SET_ALL_ELIGIBLE_LP_IDS': {
      return state.set('allEligibleLpIds', fromJS(action.allEligibleLpIds));
    }

    case 'SET_ALL_ELIGIBLE_FROM_LP_IDS': {
      return state.set('allEligibleFromLpIds', fromJS(action.allEligibleFromLpIds));
    }

    case 'SET_ALL_ELIGIBLE_TO_LP_IDS': {
      return state.set('allEligibleToLpIds', fromJS(action.allEligibleToLpIds));
    }

    case 'SET_REMAINING_LPS': {
      const memberships = fromJS(action.memberships);
      const allLps = state.get('allLps');
      const preferredLps = state.get('preferredLps');
      let remainingLps = allLps.filter(lp => {
        const hasMembership = memberships.find(membership => lp.get('id') === membership.get('lpId'));
        return !hasMembership;
      });

      const preferreds = remainingLps.filter(lp => preferredLps.indexOf(lp) > -1);

      // Move preferredLps to the top of remaining Lps
      preferreds.forEach(lp => {
        const index = remainingLps.indexOf(lp);
        remainingLps = remainingLps.delete(index).unshift(lp);
      });

      return state.set('remainingLps', remainingLps);
    }

    case 'RESET_EXCHANGE':
      return state.merge({ eligibleToLps: Map(), eligibleFromLps: Map() });

    case 'RESET_ELIGIBLE_FROM_LPS':
      return state.set('eligibleFromLps', Map());

    case 'RESET_ELIGIBLE_TO_LPS':
      return state.set('eligibleToLps', Map());

    case 'SET_ELIGIBLE_LPS': {
      const lps = {};
      const allLps = state.get('allLps');
      const eligibleLps = fromJS(action.eligibleLps) || Map();
      const included = eligibleLps.get('included') || List();
      const available = eligibleLps.get('available') || List();
      const direction = action.direction === 'from' ? 'From' : 'To';
      const includedLpIds = included.map(lp => lp.get('lpId'));
      const availableLpIds = available.map(lp => lp.get('lpId'));
      lps.available = compact(availableLpIds.map(lpId => allLps.find(lp => lp.get('id') === lpId)));
      lps.included = compact(includedLpIds.map(lpId => allLps.find(lp => lp.get('id') === lpId)));
      return state.set(`eligible${direction}Lps`, Map(lps));
    }

    default:
      return state;
  }
}
