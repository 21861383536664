import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import RadioButtonList from './RadioButtonList';

const RedeemCountrySelection = props => {
  const { countryOptions, optionName, setSelectedCurrency, useFieldsets } = props;
  return (
    <div className='redeemForm__country'>
      <div className='redeemForm__countryInfo'>
        <div className='redeemForm__countryInfo__one'>
          <FormattedMessage id='redeem.offer.country.one' />
        </div>
        <div className='redeemForm__countryInfo__info'>
          <FormattedMessage id='redeem.offer.country.title' />
        </div>
      </div>
      <div className='redeemForm__countryOptions'>
        <RadioButtonList
          options={countryOptions}
          optionName={optionName}
          onOptionSelection={setSelectedCurrency}
          useFieldsets={useFieldsets}
        />
      </div>
      <div className='redeemForm__countryConditions'>
        <FormattedMessage id='redeem.offer.country.conditions' />
      </div>
    </div>
  );
};

RedeemCountrySelection.propTypes = {
  countryOptions: ImmutablePropTypes.list,
  optionName: PropTypes.string,
  setSelectedCurrency: PropTypes.func,
  useFieldsets: PropTypes.bool
};

export default RedeemCountrySelection;
