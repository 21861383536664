import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedHTMLMessage, FormattedNumber } from 'react-intl';
import { Map } from 'immutable';
import { handleKeyPressEnter } from './utils/accessibility-helpers';
import { getLogoPath } from './utils/image-helpers';

const ModalLpRowItem = ({ lp, membership = Map(), formatter, shouldHideBalance, onClick, rowLabel }) => {
  const balance = membership.getIn(['memberDetails', 'balance']) || 0;
  const accountId = membership.getIn(['credentials', 'identifyingFactors.memberId']) || '';
  const accountIdLabel = !accountId ? '' : <FormattedHTMLMessage id='exchangeSelect.accountIdLabel' values={{ accountId }} />;
  const selectProgramMsg = formatter.formatMessage({ id: 'exchangeSelect.selectProgramMessage' });
  const availableMsg = formatter.formatMessage({ id: 'exchangeSelect.available' });
  const registeredMsg = formatter.formatMessage({ id: 'exchangeSelect.registered' });

  return (
    <div
      id={!membership.get('isRegistered') ? 'exchangeModal_addLpToWallet' : null}
      className='exchange-select-row-item u-bb u-padding-vertical-small u-padding-vertical-none@md'
      role='menuitem'
      tabIndex='0'
      onClick={() => onClick(lp, membership)}
      onKeyUp={handleKeyPressEnter(() => onClick(lp, membership))}
      data-lp-name={lp.get('name')}
      data-location='(exchangeModal)'
      data-status='selected'
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/4@md u-1/3'>
          <img className='exchange-select-row-item__img u-1/1 u-margin-left-small' alt={lp.get('name')} src={getLogoPath(lp.getIn(['content', 'code']))} />
        </div>
        <div className='o-layout__item u-2/4@md u-1/3 text-center text-base-size-palm'>
          <div className='u-padding-top@md u-padding-top-small'>
            { accountIdLabel }
          </div>
        </div>
        <div className='o-layout__item u-1/4@md u-1/3 text-right text-base-size-palm'>
          <div className='u-padding-top@md u-padding-top-small u-padding-right'>
            {
              membership.size ? (
                <div>
                  {
                    membership.get('isRegistered') ? (
                      <div>
                        { shouldHideBalance ?
                          <div className='text-weight-8'>
                            {registeredMsg}
                          </div> :
                          <div>
                            <span className='text-weight-8'>
                              <FormattedNumber value={balance} />
                            </span>
                            <div className='text-weight-1'>
                              { availableMsg }
                            </div>
                          </div> }
                      </div>
                    ) : (
                      <div className='text-weight-1'>
                        { selectProgramMsg }
                      </div>
                    )
                  }
                </div>
              ) : (
                <div className='text-weight-1'>
                  { rowLabel }
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

ModalLpRowItem.propTypes = {
  lp: ImmutablePropTypes.map,
  membership: ImmutablePropTypes.map,
  rowLabel: PropTypes.string,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  shouldHideBalance: PropTypes.bool,
  onClick: PropTypes.func
};

export default ModalLpRowItem;
