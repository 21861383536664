import classnames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setIsShowingSlide, setModalContentKey, setPageMessage, setRequireRegistration } from '../action-creators/app';
import { setEarnMembershipSelectionLpId } from '../action-creators/earn';
import { resetExchange } from '../action-creators/exchange';
import {
  deleteMembership,
  fetchMemberships,
  postNewMembership,
  registerMembership,
  setHasDeleteMembershipError
} from '../action-creators/memberships';
import { fetchPendingActivity, fetchPendingEarnOrders, updateOrder } from '../action-creators/orders';
import { fetchPromos } from '../action-creators/promos';
import { selectLpById } from '../selectors/lps';
import { selectUserMemberValidation } from '../selectors/memberships';
import constants from '../utils/constants';

import BalanceTracker from './BalanceTracker';
import DeleteMembershipModal from './DeleteMembershipModal';

import FocusTrap from './FocusTrap';
import LpRowItem from './LpRowItem';

import MembershipRegistration from './MembershipRegistration';
import MembershipSelection from './MembershipSelection';
import Slide from './Slide';
import Spinner from './Spinner';
import { getTabIndex, shouldBeHiddenFromVoiceOver } from './utils/accessibility-helpers';
import { isFeatureEnabled } from './utils/react-component-helpers';

export class WalletLanding extends React.Component {
  lpRowItemEl = null;
  remainingLpsListEl = null;
  walletSelectHeadingEl = null;

  constructor(props) {
    super(props);
    const { fetchPendingActivity } = props;
    const role = props.user.get('role');
    if (role === constants.USER_ROLE_USER) {
      fetchPendingActivity();
    }

    this.state = {
      isDeleteMembershipModalOpen: false,
      isShowingAllLps: false,
      selectedMembership: Map(),
      lastAddedLpId: null,
      isShowingRegistrationForm: false,
      closeModal: 0
    };
  }

  componentDidMount() {
    const { hasFetchedPromos, fetchPromos } = this.props;
    if (!hasFetchedPromos) {
      fetchPromos(constants.product.exchange);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location, user, fetchPendingEarnOrders, clientData,
      setIsShowingSlide, hasCreatedAccount, memberships
    } = this.props;
    const { USER_ROLE_USER } = constants;
    const { selectedMembershipId } = location.query;

    const isEarnEnabled = isFeatureEnabled(constants.product.earn, clientData.get('enabledFeatures'));
    if (nextProps.hasCreatedAccount && !hasCreatedAccount) {
      if (!selectedMembershipId) {
        setIsShowingSlide(false);
      } else {
        this.setState({
          isShowingRegistrationForm: !!selectedMembershipId,
          selectedMembership: memberships.find(membership => membership.get('id') === selectedMembershipId)
        });
      }
    }

    const isRoleUser = nextProps.user.get('role') === USER_ROLE_USER && user.get('role') !== USER_ROLE_USER;
    if (nextProps.hasCreatedAccount && isEarnEnabled && isRoleUser) {
      fetchPendingEarnOrders();
    }
  }

  componentDidUpdate() {
    const { iframeManager } = this.props;
    if (!iframeManager.isRunning()) {
      iframeManager.initIframeRunLoop();
      iframeManager.scrollParentFrameToTop();
    }
  }

    getEarnOrdersWithPendingMembershipSelection = lpId => {
      const { pendingActivity } = this.props;
      return pendingActivity.find(order => (
        order.getIn(['to', 'lpId']) === lpId && this.isOrderPendingMembershipSelection(order)
      )) || Map();
    };

    getSortedRemainingLps = () => {
      const { remainingLps, preferredLps } = this.props;

      if (!remainingLps.size) {
        return List();
      }
      const preferredLpIds = preferredLps.map(lp => lp.get('id'));

      return remainingLps.sort((lp1, lp2) => {
        const lpId1 = lp1.get('id');
        const lpId2 = lp2.get('id');
        if (preferredLpIds.contains(lpId1) && !preferredLpIds.contains(lpId2)) {
          return -1;
        }
        if (!preferredLpIds.contains(lpId1) && preferredLpIds.contains(lpId2)) {
          return 1;
        }
      });
    };

    setOrderMembership = membershipId => {
      const { earnMembershipSelectionLpId, updateOrder } = this.props;
      const order = this.getEarnOrdersWithPendingMembershipSelection(earnMembershipSelectionLpId);
      const updatedData = Map({
        toMembershipId: membershipId
      });
      const orderId = order.get('orderId');
      if (orderId) {
        updateOrder(orderId, updatedData);
      }
      this.closeEarnMembershipSelection();
    };

    closeMembershipRegistration = () => {
      const { setIsShowingSlide } = this.props;
      this.setState({ isShowingRegistrationForm: false });
      setIsShowingSlide(false);
    };

    addMembership = lp => {
      const { memberships, postNewMembership, iframeManager } = this.props;
      const lpId = lp.get('id');

      this.trackAnalyticsEvent({
        action: constants.ANALYTICS_ADD_LP,
        [constants.ANALYTICS_ADD_LP]: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
        lpId
      });

      postNewMembership(lpId, memberships);
      this.setState({ lastAddedLpId: lpId });
      this.closeModal();
      iframeManager.scrollParentFrameToTop();
    };

    closeDisabledDeleteMessage = () => {

    };

    closeEarnMembershipSelection = () => {
      this.props.setEarnMembershipSelectionLpId('');
    };

    closeMembershipRegistrationForm = () => {
      const { setRequireRegistration } = this.props;
      this.props.push('/wallet');
      this.setState({ isShowingRegistrationForm: false });
      setIsShowingSlide(false);
      setRequireRegistration(false);
    };

    closeModal = () => {
      const { closeModal } = this.state;
      this.setState({ closeModal: closeModal + 1 });
    };

    // For the time being this is intended only for Earn, so we only check the `to` membership
    isOrderPendingMembershipSelection = order =>
      (order.get('status') === constants.orderStatus.pending) && order.getIn(['to', 'pickedArbitrarily']);


    openEarnMembershipSelection = (lpId = '') => {
      this.props.setEarnMembershipSelectionLpId(lpId);
    };

    openMembershipRegistrationForm = selectedMembership => {
      const role = this.props.user.get('role');
      let isShowingRegistrationForm = false;

      if (role === constants.USER_ROLE_GUEST) {
        this.props.push(`/wallet/create-account?selectedMembershipId=${selectedMembership.get('id')}`);
      } else if (role === constants.USER_ROLE_USER) {
        isShowingRegistrationForm = true;
      }

      this.setState({
        selectedMembership,
        isShowingRegistrationForm
      });
    };

    resetLastAddedLpId = () => {
      this.setState({ lastAddedLpId: null });
    };

    showDisabledDeleteMessage = () => {
      const { setPageMessage, formatter } = this.props;
      setPageMessage({
        type: 'warning',
        message: formatter.formatMessage({ id: 'membershipcard.disabledDelete' }),
        afterClose: this.closeDisabledDeleteMessage
      });
    };

    slideEl = null;

    toggleAllPrograms = () => {
      const { isShowingAllLps } = this.state;
      this.setState({
        isShowingAllLps: !isShowingAllLps
      });

      if (!isShowingAllLps) {
        this.props.iframeManager.scrollParentFrameToTop();
      }
    };

    toggleDeleteMembershipModal = status => {
      const { setHasDeleteMembershipError } = this.props;
      setHasDeleteMembershipError(false);
      this.setState({ isDeleteMembershipModalOpen: status });
    }

    trackAnalyticsEvent = data => {
      const { googleTagManager } = this.props;
      googleTagManager.pushGtmData(Object.assign({ context: 'balanceTracker' }, data));
    };

    renderEarnMembershipSelection = () => {
      const { getLpById, earnMembershipSelectionLpId, formatter, memberships, isShowingSlide } = this.props;
      const lp = getLpById(earnMembershipSelectionLpId);
      const instructions = formatter.formatMessage({ id: 'membershipSelect.instructionsEarn' });

      return (
        <FocusTrap isActive={isShowingSlide}>
          <Slide
            handleClose={this.closeEarnMembershipSelection}
            formatter={formatter}
          >
            <MembershipSelection
              formatter={formatter}
              lp={lp}
              memberships={memberships}
              onSubmit={this.setOrderMembership}
              instructions={instructions}
            />
          </Slide>
        </FocusTrap>
      );
    };

    renderLpRowItems = lps => {
      const { preferredLps, isShowingSlide } = this.props;
      return (
        <div
          className='section u-padding-horizontal-small u-padding-horizontal-none@md'
          ref={el => this.remainingLpsListEl = el}
        >
          <div className='u-padding-top@lg u-padding@md u-padding-bottom-none@md'>
            {
              lps.map(lp => (
                <LpRowItem
                  ref={el => this.lpRowItemEl = el}
                  key={lp.get('id')}
                  lp={fromJS(lp)}
                  onClick={() => this.addMembership(lp)}
                  actionLabel='wallet.addProgram'
                  isShowingSlide={isShowingSlide}
                  preferredLps={preferredLps}
                />
              ))
            }
          </div>
        </div>
      );
    };

    renderProgramsHeader = () => {
      const { device, isShowingSlide } = this.props;
      const tabIndexValue = getTabIndex(isShowingSlide);
      const isPalmDevice = device === 'palm';
      if (isPalmDevice) {
        return (
          <h2
            aria-hidden={shouldBeHiddenFromVoiceOver(tabIndexValue)}
            className='section-heading u-margin-left-small u-margin-left@md u-margin-vertical-tiny u-margin-top@md u-margin-top-none text-header-3'
            ref={el => this.walletSelectHeadingEl = el}
          >
            <FormattedMessage id='programs.header' />
          </h2>
        );
      }
      return (
        <h2
          aria-hidden={shouldBeHiddenFromVoiceOver(tabIndexValue)}
          className='section u-padding-left u-padding-top text-header-3'
        >
          <FormattedMessage id='programs.header' />
        </h2>
      );
    };

    renderRegistration = () => {
      const { children, getLpById, memberships, setIsShowingSlide, isShowingSlide, formatter,
        requireRegistration } = this.props;
      const { selectedMembership, isDeleteMembershipModalOpen } = this.state;

      /** Find the selected membership from this.props.memberships because the local state
          memberships isn't updated from the redux store after opening the form.
       */
      const membership =  memberships.find(membership => membership.get('id') === selectedMembership.get('id'));

      return (
        <FocusTrap isActive={isShowingSlide}>
          <Slide
            ref={el => this.slideEl = el}
            setIsShowingSlide={setIsShowingSlide}
            handleClose={this.closeMembershipRegistrationForm}
            formatter={formatter}
            disableClose={requireRegistration}
          >
            {children || null}
            {!children && selectedMembership && selectedMembership.size ? (
              <MembershipRegistration
                membership={membership}
                closeMembershipRegistration={this.closeMembershipRegistration}
                fadeIn
                toggleDeleteMembershipModal={this.toggleDeleteMembershipModal}
                {...this.props}
              />
            ) : null}
          </Slide>
          {isDeleteMembershipModalOpen &&
          <DeleteMembershipModal
            toggleDeleteMembershipModal={this.toggleDeleteMembershipModal}
            membership={selectedMembership}
            lp={getLpById(selectedMembership.get('lpId'))}
            {...this.props}
          />}
        </FocusTrap>
      );
    };

    render() {
      const { isShowingRegistrationForm } = this.state;
      const {
        clientData,
        earnMembershipSelectionLpId,
        location,
        formatter,
        isShowingSlide,
        shouldDisableProgramAddition
      } = this.props;

      const maxLpsToShowcase = clientData.get('maxLpsToShowcase') > 0 ? clientData.get('maxLpsToShowcase') : 0;
      const isCreatingAccount = location.pathname.split('/').includes('create-account');
      const remainingLpsList = this.getSortedRemainingLps().slice(0, maxLpsToShowcase);
      const tabIndexValue = getTabIndex(isShowingSlide);
      const ariaHiddenValue = shouldBeHiddenFromVoiceOver(tabIndexValue);
      const elementsToHideClass = classnames({
        'hostedExchangeElement--isShowing': !isShowingSlide,
        'hostedExchangeElement--notShowing': isShowingSlide
      });
        // These elements need to be hidden when the slider is up because when the page is rendered in an iframe and the user agent is an iPhone, these elements tend to behave strangely.

      const welcomeMessage = (
        <p className='text-weight-1 u-margin-bottom u-margin-bottom-large@lg'>
          <FormattedHTMLMessage id='wallet.subwelcome' />
        </p>
      );

      return (
        <div className='u-padding-bottom'>
          <Helmet>
            <title>{formatter.formatMessage({ id: 'pageTitle.wallet' })}</title>
            <meta
              name='description'
              content={formatter.formatMessage({ id: 'pageTitle.wallet' })}
            />
          </Helmet>
          <div className='outer-container__hide-scrollbar'>
            {isShowingRegistrationForm || isCreatingAccount ? this.renderRegistration() : null}
          </div>
          <div className='outer-container__hide-scrollbar'>
            {earnMembershipSelectionLpId ? this.renderEarnMembershipSelection() : null}
          </div>

          {
            clientData.get('isIframed') ? (
              <div className='text-center u-padding u-padding-bottom-none'>
                <h1 className='u-margin-top-none thin-header' aria-hidden={ariaHiddenValue}>
                  <FormattedMessage id='wallet.header' />
                </h1>
                <span aria-hidden={ariaHiddenValue}>{welcomeMessage}</span>
              </div>
            ) : (
              <div className='section'>
                <div className='u-padding-vertical-none u-padding-vertical@md u-padding-top@md'>
                  <div className='o-layout o-layout--center'>
                    <div className='o-layout__item u-10/12@md u-1/1'>
                      <h1
                        className='text-center text-weight-1'
                        aria-hidden={ariaHiddenValue}
                      >
                        <FormattedMessage id='wallet.welcome' />
                      </h1>
                    </div>
                    <div className='o-layout__item u-8/12@md u-1/1'>
                      <div className='text-center' aria-hidden={ariaHiddenValue}>
                        {welcomeMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            this.props.allLps.size ? (
              <div>
                <BalanceTracker
                  lastAddedLpId={this.state.lastAddedLpId}
                  afterScrollToMembershipCard={this.resetLastAddedLpId}
                  showDisabledDeleteMessage={this.showDisabledDeleteMessage}
                  openMembershipRegistrationForm={this.openMembershipRegistrationForm}
                  openEarnMembershipSelection={this.openEarnMembershipSelection}
                  getEarnOrdersWithPendingMembershipSelection={this.getEarnOrdersWithPendingMembershipSelection}
                  elementsToHideClass={elementsToHideClass}
                  {...this.props}
                />

                {
                  remainingLpsList.size && !shouldDisableProgramAddition ? (
                    <div className={elementsToHideClass}>
                      {this.renderProgramsHeader()}
                      {this.renderLpRowItems(remainingLpsList)}
                    </div>
                  ) : null
                }

              </div>
            ) : (
              <div className='u-margin-vertical-huge'>
                <Spinner />
              </div>
            )
          }
        </div>
      );
    }
}

WalletLanding.propTypes = {
  fetchPendingActivity: PropTypes.func,
  user: ImmutablePropTypes.map,
  fetchPendingEarnOrders: PropTypes.func,
  clientData: ImmutablePropTypes.map,
  hasCreatedAccount: PropTypes.bool,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  iframeManager: PropTypes.object,
  postNewMembership: PropTypes.func,
  setPageMessage: PropTypes.func,
  push: PropTypes.func,
  setIsShowingSlide: PropTypes.func,
  googleTagManager: PropTypes.object,
  remainingLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  preferredLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  earnMembershipSelectionLpId: PropTypes.string,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  isShowingSlide: PropTypes.bool,
  hasFetchedPromos: PropTypes.bool,
  shouldDisableProgramAddition: PropTypes.bool,
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  locale: PropTypes.string,
  device: PropTypes.string,
  requireRegistration: PropTypes.bool,
  userMemberValidationUrl: PropTypes.string,
  updateOrder: PropTypes.func,
  pendingActivity: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  setEarnMembershipSelectionLpId: PropTypes.func,
  setRequireRegistration: PropTypes.func,
  closeMembershipRegistration: PropTypes.func,
  setHasDeleteMembershipError: PropTypes.func,
  fetchPromos: PropTypes.func,
  getLpById: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    iframeManager: state.app.get('iframeManager'),
    messages: state.app.get('messages'),
    googleTagManager: state.app.get('googleTagManager'),
    device: state.app.get('device'),
    clientData: state.app.get('clientData'),
    shouldDisableProgramAddition: state.wps.get('disableBlwProgramAddition'),
    isShowingSlide: state.app.get('isShowingSlide'),
    requireRegistration: state.app.get('requireRegistration'),
    allLps: state.lps.get('allLps'),
    locale: state.app.get('locale'),
    remainingLps: state.lps.get('remainingLps'),
    allEligibleLpIds: state.lps.get('allEligibleLpIds'),
    preferredLps: state.lps.get('preferredLps'),
    addedLps: state.lps.get('addedLps'),
    isFetchingLps: state.lps.get('isFetchingLps'),
    memberships: state.memberships.get('data'),
    hasInitializedMemberships: state.memberships.get('hasInitializedMemberships'),
    hasUpdatedMemberships: state.memberships.get('hasUpdatedMemberships'),
    isUpdatingMemberships: state.memberships.get('isUpdatingMemberships'),
    isDeletingMembership: state.memberships.get('isDeletingMembership'),
    hasDeleteMembershipError: state.memberships.get('hasDeleteMembershipError'),
    user: state.user.get('data'),
    hasCreatedAccount: state.user.get('hasCreatedAccount'),
    pendingEarnOrders: state.orders.get('pendingEarnOrders'),
    pendingActivity: state.orders.get('pendingActivity'),
    formatter: state.app.get('formatter'),
    earnMembershipSelectionLpId: state.earn.get('earnMembershipSelectionLpId'),
    hasFetchedPromos: state.promos.get('hasFetchedPromos'),
    promos: state.promos.get('promos'),
    userMemberValidationUrl: selectUserMemberValidation(state),
    getLpById: selectLpById(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    push,
    fetchMemberships,
    postNewMembership,
    deleteMembership,
    resetExchange,
    setIsShowingSlide,
    setRequireRegistration,
    registerMembership,
    fetchPendingEarnOrders,
    fetchPromos,
    setPageMessage,
    setEarnMembershipSelectionLpId,
    fetchPendingActivity,
    updateOrder,
    setModalContentKey,
    setHasDeleteMembershipError
  }, dispatch);
}

export const WalletLandingContainer = connect(mapStateToProps, mapDispatchToProps)(WalletLanding);
