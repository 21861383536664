import { request, serializeQueryParams } from './utils/httpUtils';

export function fetchPromos(params = {}) {
  return request(`/v1/promos?${serializeQueryParams(params)}`)
    .then(data => data.json.promotions);
}

export default {
  fetchPromos
};
