import React from 'react';

const Exchange = props => (
  <div className='exchange'>
    {props.children}
  </div>
);

Exchange.propTypes = {};

export default Exchange;
