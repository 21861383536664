import constants from '../../utils/constants';
import { push } from 'react-router-redux';
import { setLoginErrors } from '../../action-creators/auth';
import { setAppError } from '../../action-creators/app';

let _store;
const { ACCESS_TOKEN, PARTNER_POST_URL, PARTNER_SESSION_ID } = constants.STORAGE;

export const addAuthTokenToHeaders = (options = {}) => {
  if (!options.headers) {
    options.headers = {};
  }
  options.headers.authorization = `Bearer ${getAuthToken()}`;
  options.headers['accept-language'] = `${_store.getState().app.get('locale')}`;
  return options;
};

export const setAuthToken = token => {
  if (!token) {
    return;
  }
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getAuthToken = () => localStorage.getItem(ACCESS_TOKEN);

export const serializeQueryParams = params =>
  Object.entries(params)
    .reduce((str, [key, value]) => {
      if (value !== undefined && value !== '') {
        const prefix = str.length ? '&' : '';
        return str += `${prefix}${key}=${value}`;
      }
      return str;
    }, '');

export const setPartnerSessionId = sessionID => {
  if (!sessionID) {
    return;
  }
  localStorage.setItem(PARTNER_SESSION_ID, sessionID);
};

export const getPartnerSessionId = () => localStorage.getItem(PARTNER_SESSION_ID);

export const setPartnerPostUrl = partnerPostUrl => {
  localStorage.setItem(PARTNER_POST_URL, partnerPostUrl);
};

export const getPartnerPostUrl = () => localStorage.getItem(PARTNER_POST_URL);

const rejectJson = (json, cb, status) => {
  json.then(payload => cb({
    status,
    errors: payload.errors
  })).catch(() => cb({ status }));
};

export const request = (url, opts = {}, allowRedirect = true) => {
  const options = addAuthTokenToHeaders();
  Object.assign(options, opts);
  delete options.disableErrors;

  return new Promise((resolve, reject) => {
    fetch(url, options).then(res => {
      const { status } = res;
      const json = res.json();
      if (status >= 200 && status < 300) {
        json
          .then(json => resolve({ json, res }))
          .catch(() => resolve({ json: {}, res }));
      } else if (status >= 500 && status !== 504) {
        _store.dispatch(setLoginErrors('500'));
        if (allowRedirect) {
          _store.dispatch(push('/'));
        }
      } else if (status === 404) {
        // 404 will mean that json is probably not valid
        // which means we should reject immediately
        if (!opts.disableErrors) {
          // for fetchLocale - we don't want to redirect on 404
          _store.dispatch(setAppError(constants.APP_ERROR_INVALID_PARAMS));
          if (allowRedirect) {
            _store.dispatch(push('/error'));
          }
        }
        rejectJson(json, reject, status);
      } else if (status === 504) {
        _store.dispatch(setAppError(constants.APP_ERROR_TIMEOUT));
        if (allowRedirect) {
          _store.dispatch(push('/error'));
        }
      } else {
        rejectJson(json, reject, status);
      }
    }).catch(() => {
      _store.dispatch(setAppError(constants.APP_ERROR_DEFAULT));
      if (allowRedirect) {
        _store.dispatch(push('/error'));
      }
    });
  });
};

export const getTotalCountFromHeader = response => {
  const totalCount = parseInt(response.headers.get('x-total-count'));
  return Number.isNaN(totalCount) ? undefined : totalCount;
};

export const setHttpUtilsStore = store => {
  _store = store;
};

export default { addAuthTokenToHeaders, serializeQueryParams, getTotalCountFromHeader, setAuthToken };
