import parseUrl from 'parse-url';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { v4 as uuidv4 } from 'uuid';
import { getEnvironment } from '../utils/application';
import constants from '../utils/constants';
// The functionality in this component is specific to AMEX, but subject to change in the future.
class SSOWidget extends Component {
  state = { walletId: null };

  componentDidMount() {
    window.initWidget = this.initWidget;

    const { clearLocalStorage, handleErrors } = this.props;
    const authResponse = this.getAuthResponse();

    if (authResponse) {
      clearLocalStorage();
      if (authResponse.error) {
        const error = authResponse.error;
        handleErrors([{ code: error.code, message: error.message }]);
      } else {
        this.setWalletIdInState(authResponse.walletId);
      }
    }
  }

  setWalletIdInState(walletId) {
    this.setState({ walletId });
  }

  getAuthResponse() {
    const { pageUrl } = this.props;
    const parsedUrl = parseUrl(pageUrl);
    const keys = Object.keys(parsedUrl.query);
    let authResponse;

    keys.forEach(paramKey => {
      const value = parsedUrl.query[paramKey];

      if (!value) {
        const json = JSON.parse(paramKey);

        if (json.contextId) {
          authResponse = json;
        }
      }
    });

    if (!authResponse) {
      console.log('No response was found, this is an initiating URL -  ', pageUrl);
    }

    return authResponse;
  }

  initWidget = () => {
    const { clientData, pageUrl } = this.props;

    window.fre.init({
      partner_id: clientData.getIn(['ssoWidgetPartnerId', getEnvironment()]),
      context_id: uuidv4().split('-').join(''),
      locale: 'en_US',
      country: 'US',
      callback: pageUrl
    });
  };

  render() {
    const { submitMembershipRegistration } = this.props;

    if (this.getAuthResponse()) {
      const { walletId } = this.state;
      if (walletId) {
        submitMembershipRegistration(walletId);
      }

      return null;
    }

    const config = constants.amexSSOWidgetLibrary[getEnvironment()];

    return (
      <div>
        <Helmet>
          <script
            src={config.url}
            type='text/javascript'
            onLoad='initWidget()'
            integrity={config.checksum}
            crossOrigin='anonymous'
          />
        </Helmet>
        <div id='amex-frenroll-placeholder' className='register_membership__widget_wrapper' />
      </div>
    );
  }
}

SSOWidget.propTypes = {
  clearLocalStorage: PropTypes.func.isRequired,
  clientData: ImmutablePropTypes.map.isRequired,
  handleErrors: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  submitMembershipRegistration: PropTypes.func.isRequired
};

export default SSOWidget;
