import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

export function isValidSession() {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode(token);
  const now = moment();
  const tokenExpiration = moment.unix(decodedToken.exp);
  return tokenExpiration.diff(now) >= 0;
}
