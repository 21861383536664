import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import appReducer from './app';
import authReducer from './auth';
import exchangeReducer from './exchange';
import redeemReducer from './redeem';
import earnReducer from './earn';
import lpsReducer from './lps';
import membershipsReducer from './memberships';
import ordersReducer from './orders';
import promosReducer from './promos';
import userReducer from './user';
import wpsReducer from './wps';
import registerMembershipReducer from './register-membership';
import fixEarnOrderReducer from './fix-earn-order';

export const rootReducer = combineReducers({
  routing: routerReducer,
  app: appReducer,
  auth: authReducer,
  exchange: exchangeReducer,
  redeem: redeemReducer,
  earn: earnReducer,
  lps: lpsReducer,
  memberships: membershipsReducer,
  orders: ordersReducer,
  promos: promosReducer,
  user: userReducer,
  wps: wpsReducer,
  registerMembership: registerMembershipReducer,
  fixEarnOrder: fixEarnOrderReducer
});
