import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import constants from '../utils/constants';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

class RedemptionAmountWidget extends React.Component {
  componentDidMount() {
    const { redemptionDenominations, setRedemptionAmount } = this.props;
    this.setDefaultDenomination(redemptionDenominations, setRedemptionAmount);
  }

  componentDidUpdate(prevProps) {
    const { redemptionDenominations, setRedemptionAmount } = this.props;
    // if the redemptionDenominations changed to another country's offers
    if (redemptionDenominations.toJSON()[0].toLpId !== prevProps.redemptionDenominations.toJSON()[0].toLpId) {
      this.setDefaultDenomination(redemptionDenominations, setRedemptionAmount);
    }
  }

  setDefaultDenomination = (redemptionDenominations, setRedemptionAmount) => {
    const defaultDenomination = redemptionDenominations.find(offer => offer.get('selected'));
    if (defaultDenomination) setRedemptionAmount(defaultDenomination);
  }

  renderOptions = option => {
    if (!option.get('credit') || !option.get('points') || typeof option.get('disabled') === 'undefined' || typeof option.get('selected') === 'undefined') return null;
    const pointsAmount = option.get('points').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const creditAmount = <FormattedNumber value={option.get('credit')} style='currency' currency='USD' currencyDisplay='symbol' maximumSignificantDigits={2} />;
    const wrapperClasses = classnames(
      'redemptionWidgetOption',
      { 'redemptionWidgetOption--disabled': option.get('disabled') },
      { 'redemptionWidgetOption--selected': option.get('selected') }
    );
    return (
      <div key={`${option.get('credit')}-${option.get('points')}`} className={wrapperClasses} onClick={e => this.selectDenomination(e, option)}>
        <div className='redemptionWidgetOption__credit'>
          <p><FormattedMessage id='redeem.offer.redemption.credit' values={{ credit: creditAmount }} /></p>
        </div>
        <div className='redemptionWidgetOption__points'>
          <p><FormattedMessage id='redeem.offer.redemption.points' values={{ points: pointsAmount }} /></p>
        </div>
      </div>
    );
  };

  selectDenomination = (event, denomination) => {
    if (denomination.get('disabled')) {
      event.preventDefault();
    } else {
      this.props.setRedemptionAmount(denomination);
    }
  };

  render() {
    const { redemptionDenominations, wallet, minBalance, balance, logo } = this.props;
    if (!redemptionDenominations.size || !wallet || !minBalance || !logo) return null;

    const selectedDenomination = redemptionDenominations.find(denomination => denomination.get('selected')) || false;
    const canRedeem = balance > minBalance;
    const remainingPoints = (canRedeem && selectedDenomination) ? (balance - selectedDenomination.get('points')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;

    return (
      <div className='redemptionAmountWidget'>
        <div className='redemptionAmountWidget__denominations'>
          <div className='redemptionAmountWidget__card'>
            <div id='widgetCard'>
              {canRedeem && selectedDenomination && <FormattedNumber value={selectedDenomination.get('credit')} style='currency' currency='USD' currencyDisplay='symbol' maximumSignificantDigits={2} />}
              {!canRedeem && !selectedDenomination && <FormattedNumber value={0} style='currency' currency='USD' currencyDisplay='symbol' maximumSignificantDigits={2} />}
              <img
                alt={`${wallet} logo`}
                height={constants.headerLogoHeight}
                src={logo}
              />
            </div>
          </div>
          <div className='redemptionAmountWidget__options'>
            {redemptionDenominations.map(denomination => this.renderOptions(denomination))}
          </div>
        </div>
        {canRedeem && <div className='redemptionAmountWidget__remainingBalance'><FormattedMessage id='redeem.offer.redemption.remainingPoints' values={{ remaining: remainingPoints }} /></div>}
      </div>
    );
  }
}

RedemptionAmountWidget.propTypes = {
  redemptionDenominations: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  wallet: PropTypes.string,
  minBalance: PropTypes.number,
  logo: PropTypes.string,
  balance: PropTypes.number,
  setRedemptionAmount: PropTypes.func
};

export default RedemptionAmountWidget;
