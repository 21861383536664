import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import constants from '../utils/constants';
import { getLogoPath, getLocalImage } from './utils/image-helpers';
import { useSelector } from 'react-redux';

const getImage = (partner, bannerSrc) => (<img
  className='banner__adImg'
  src={getLocalImage(partner, bannerSrc)}
  alt='Banner ad background'
/>);

export const BannerAd = props => {
  const partner = useSelector(state => state.app.getIn(['clientData', 'partnerDirName']));
  const wpCode = useSelector(state => state.app.getIn(['clientData', 'wpCode']));
  const { path, bannerSrc } = props;

  return (
    <div className='earnAndRedeem__bannerAd__container'>
      <div className='earnAndRedeem__bannerAd'>
        <div className='earnAndRedeem__adImg'>
          { getImage(partner, bannerSrc) }
          <div className='adImg__overLay'>
            <div className='adImg__text'>
              <p className='overlay__text'>
                <FormattedHTMLMessage id={`ad.${path}.overlayText`} />
              </p>
            </div>
          </div>
        </div>
        <div className='earnAndRedeem__bannerAd__section'>
          <img
            alt='Ad Logo'
            src={getLogoPath(wpCode)}
          />
          <div>
            <p className='earnAndRedeem__bannerAd__promo'>
              <FormattedHTMLMessage id={`ad.${path}.header`} />
            </p>
            <p className='earnAndRedeem__bannerAd__description'>
              <FormattedMessage id={`ad.${path}.paragraph`} />
            </p>
          </div>
          <a href={constants.lyftAppDownloadUrl} target='_blank' rel='noopener noreferrer'>
            <button
              id='bannerAd__download__btn'
            >
              <FormattedMessage id={`ad.${path}.btnText`} />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

BannerAd.propTypes = {
  path: PropTypes.string.isRequired,
  bannerSrc: PropTypes.string.isRequired
};
