import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import constants from '../utils/constants';
import { checkIfAllowedOnPage, reloadWithoutMv } from '../utils/url';
import {
  getLocalImage,
  getLogoPath
} from './utils/image-helpers';

import { FormattedMessage } from 'react-intl';
import { getClientId } from '../utils/application';
import { setIsShowingSlide } from '../action-creators/app';
import { fetchClientPromos, fetchEarnIntegration } from '../action-creators/earn';
import Spinner from './Spinner';
import { DynamicHero } from './DynamicHero';
import EarnPartnerIntegration from './EarnPartnerIntegration';
import { EarnRedeemFooter } from './EarnRedeemFooter';
import EarnFlowInterstitial from './EarnFlowInterstitial';
import { EarnRedeemCheckBoxes } from './EarnRedeemCheckBoxes';

export class EarnFlow extends React.Component {
  state = {
    windowWidth: window.innerWidth,
    isTCCheckboxChecked: false,
    isFullOfferTermsChecked: false
  };

  UNSAFE_componentWillMount() {
    const {
      fetchClientPromos,
      locale,
      partnerLpId,
      clientData,
      user,
      memberships
    } = this.props;
    window.scrollTo(0, 0);
    const allowedOnPage = checkIfAllowedOnPage(clientData, user, memberships);
    const promoCopyParams = {
      orderType: constants.product.earn,
      orderSubtype: constants.orderSubType.milesEarnedPerDollarSpent,
      locale
    };
    if (partnerLpId) {
      promoCopyParams.lpId = partnerLpId;
    }
    if (allowedOnPage) {
      fetchClientPromos(getClientId(), promoCopyParams);
    }
    window.addEventListener('resize', this.setWindowWidth);
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { isLoggedIn, location, push } = this.props;

    if (nextProps.isLoggedIn && !isLoggedIn) {
      reloadWithoutMv(push, location);
    }
  }

  setWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  submitEarn = e => {
    const {
      partnerLpId,
      fetchEarnIntegration,
      locale,
      promoData,
      user,
      googleTagManager
    } = this.props;

    const isFastTrack = true;
    const email = user.get('email');

    e.preventDefault();

    fetchEarnIntegration({
      email,
      clientId: getClientId(),
      lpId: partnerLpId,
      orderType: constants.product.earn,
      orderSubtype: constants.orderSubType.milesEarnedPerDollarSpent,
      locale
    });
    googleTagManager.pushGtmData({
      winningOfferId: promoData.get('offerId'),
      isFastTrack,
      action: constants.ANALYTICS_COMPLETE_EARN_LANDING
    });
  };

  handleTCsCheckboxChange = () => {
    this.setState({ isTCCheckboxChecked: !this.state.isTCCheckboxChecked });
  }

  handleFullOfferCheckboxChange = () => {
    this.setState({ isFullOfferTermsChecked: !this.state.isFullOfferTermsChecked });
  }

  renderHero = heroText => {
    const { clientData } = this.props;
    const partnerDirName = clientData.get('partnerDirName');
    const heroDesktopImage = clientData.get('heroDesktopImage');
    const heroMobileImage = clientData.get('heroMobileImage');

    const shouldDisplayMobileImage = this.state.windowWidth <= constants.mobileMaxWidth;
    const desktopHeroImage = getLocalImage(partnerDirName, heroDesktopImage);
    const mobileHeroImage = getLocalImage(partnerDirName, heroMobileImage);
    const heroContent = {
      image: (shouldDisplayMobileImage && mobileHeroImage) ? mobileHeroImage : desktopHeroImage,
      ...heroText
    };

    return (
      <DynamicHero {...heroContent} />
    );
  };

  renderLinkAccBtn = () => {
    const { isTCCheckboxChecked, isFullOfferTermsChecked } = this.state;
    return (
      <button
        onClick={this.submitEarn}
        id='earnAndRedeem__earn__linkAccBtn'
        disabled={!isTCCheckboxChecked || !isFullOfferTermsChecked}
      >
        <FormattedMessage id='earn.submitButton' />
      </button>
    );
  };

  renderContent = () => {
    const { isTCCheckboxChecked, isFullOfferTermsChecked } = this.state;
    return (
      <div className='earnAndRedeem__earn__termsAndConditions__content'>
        <EarnRedeemCheckBoxes
          isTCCheckboxChecked={isTCCheckboxChecked}
          isFullOfferTermsChecked={isFullOfferTermsChecked}
          handleTCsCheckboxChange={this.handleTCsCheckboxChange}
          handleFullOfferCheckboxChange={this.handleFullOfferCheckboxChange}
          containerClassName='earnAndRedeem__earn__pointsTCs__container'
        />
        { this.renderLinkAccBtn() }
        <div id='earnAndRedeem__earn__linkingCondition'>
          <FormattedMessage id='earn.linkAccount.conditions' />
        </div>
      </div>
    );
  }

  renderParentUI = () => {
    const { shouldShowEarnInterstitial, allLps, partnerLpId } = this.props;
    const partnerLp = allLps.find(lp => lp.get('id') === partnerLpId);
    const logo = getLogoPath(partnerLp.getIn(['content', 'code']));
    const alt = partnerLp.getIn(['content', 'lpName']);
    const heroText = {
      header: 'earn.linkAccount.hero.header',
      subheading: 'earn.linkAccount.hero.subheading'
    };
    return (
      <div className='earn earnAndRedeem'>
        {this.renderHero(heroText)}
        {this.renderContent()}
        <EarnRedeemFooter />
        <EarnPartnerIntegration
          type={this.props.integration}
          isShowing={this.props.shouldExecutePartnerIntegration}
        />
        <EarnFlowInterstitial
          isShowing={shouldShowEarnInterstitial}
          logo={logo}
          alt={alt}
        />
      </div>
    );
  }

  render() {
    const { children, promoData } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        renderHero: this.renderHero
      }));

    if (promoData.isEmpty()) {
      return <Spinner />;
    } else if (children) {
      return <div className='earn earnAndRedeem'>{ childrenWithProps }</div>;
    } return this.renderParentUI();
  }
}

EarnFlow.propTypes = {
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  googleTagManager: PropTypes.object,
  locale: PropTypes.string,
  messages: ImmutablePropTypes.map,
  partnerLpId: PropTypes.string,
  isShowingSlide: PropTypes.bool,
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  promoData: ImmutablePropTypes.map,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  user: ImmutablePropTypes.map,
  shouldShowEarnInterstitial: PropTypes.bool,
  fetchClientPromos: PropTypes.func,
  setIsShowingSlide: PropTypes.func,
  fetchEarnIntegration: PropTypes.func,
  integration: PropTypes.string,
  shouldExecutePartnerIntegration: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  push: PropTypes.func
};

function mapStateToProps(state) {
  return {
    clientData: state.app.get('clientData'),
    formatter: state.app.get('formatter'),
    googleTagManager: state.app.get('googleTagManager'),
    locale: state.app.get('locale'),
    messages: state.app.get('messages'),
    partnerLpId: state.app.get('partnerLpId'),
    isShowingSlide: state.app.get('isShowingSlide'),
    allLps: state.lps.get('allLps'),
    promoData: state.earn.get('promoData'),
    memberships: state.memberships.get('data'),
    user: state.user.get('data'),
    shouldShowEarnInterstitial: state.earn.get('shouldShowEarnInterstitial'),
    integration: state.earn.get('integration'),
    shouldExecutePartnerIntegration: state.earn.get('shouldExecutePartnerIntegration'),
    isLoggedIn: state.auth.get('isLoggedIn')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchClientPromos,
    setIsShowingSlide,
    fetchEarnIntegration,
    push
  }, dispatch);
}

export const EarnFlowContainer = connect(mapStateToProps, mapDispatchToProps)(EarnFlow);
