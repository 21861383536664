import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import constants from '../utils/constants';
import iframeManager from '../utils/iframe-manager';
import { handleKeyPressEscape } from './utils/accessibility-helpers';

class Slide extends React.Component {
  static defaultProps = {
    setIsShowingSlide: () => {},
    toggleSlide: 0,
    shouldHideOnLoad: false
  };

  state = {
    isShowing: false,
    restoreIframeContainerElement: () => {}
  };

  componentDidMount() {
    const { setIsShowingSlide, shouldHideOnLoad } = this.props;
    // this prop (shouldHideOnLoad) should be removed. This is just a quick fix as a request from the PM.
    if (shouldHideOnLoad) {
      return;
    }
    document.querySelector('html').style.overflow = 'hidden';
    // delay fix issue with browser and css animation
    setTimeout(() => {
      this.setState({ isShowing: true });
      if (this.closeButtonEl) {
        this.closeButtonEl.focus();
      }
    }, constants.longerAnimationLength);

    if (setIsShowingSlide) {
      setIsShowingSlide(true);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.toggleSlide !== this.props.toggleSlide) {
      this.setState({ isShowing: !this.state.isShowing });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.closeSlide === this.props.closeSlide + 1) {
      this.performClose();
    }

    // Since Slide is fixed, the parent doesn't recognize its height, which causes issues in an iframe setting
    // In order to send the right height we set the slide as the current container element for the iframe manager
    const willShow = !this.state.isShowing && nextState.isShowing;
    if (willShow) {
      if (iframeManager) {
        setTimeout(() => {
          if (this.slideEl) {
            const { restore } = iframeManager.swapContainerElement(this.slideEl);
            this.setState({ restoreIframeContainerElement: restore });
          }
        });
      }

      setTimeout(() => {
        if (this.closeButtonEl) {
          this.closeButtonEl.focus();
        }
      }, constants.longerAnimationLength);
    }

    const willNoLongerShow = this.state.isShowing && !nextState.isShowing;
    if (willNoLongerShow) {
      this.state.restoreIframeContainerElement();
    }
  }

  componentWillUnmount() {
    document.querySelector('html').style.overflow = 'auto';
    this.state.restoreIframeContainerElement();
  }

  close = e => {
    e.stopPropagation();
    const { classList } = e.target;
    const shouldClose = classList.contains('slide__close-button') || classList.contains('slide__close-icon');
    if (shouldClose) {
      this.performClose();
      document.querySelector('html').style.overflow = 'auto';
    }
  };

  closeButtonEl = null;

  performClose = () => {
    const { setIsShowingSlide } = this.props;

    if (typeof this.props.performClose === 'function') {
      return this.props.performClose();
    }

    this.setState({ isShowing: false });
    if (setIsShowingSlide) {
      setIsShowingSlide(false);
    }
    setTimeout(() => {
      if (typeof this.props.handleClose === 'function') {
        this.props.handleClose();
      }
    }, constants.longerAnimationLength);
  };

  slideEl = null;

  renderCloseButton = () => {
    const { header, formatter, triggerClose } = this.props;
    const modalName = header ? `'${header}'` : '';
    const closeButtonAriaLabel = formatter ? formatter.formatMessage({ id: 'accessibility.pressEscapeToCloseModal' }, { modalName }) : 'Close window';
    return (
      <button
        type='button'
        onClick={this.close}
        ref={el => this.closeButtonEl = el}
        aria-label={closeButtonAriaLabel}
        className='btn btn-transparent slide__close-button'
        disabled={triggerClose}
      >
        <i className='slide__close-icon icon-close' />
      </button>
    );
  };

  renderInfo = () => {
    const { header, customFooter, disableClose } = this.props;

    return (
      <div
        ref={el => this.slideEl = el}
        className='section'
        onKeyUp={disableClose ? null : handleKeyPressEscape(this.performClose)}
      >
        <div className='slide__close text-right u-margin-top'>
          { disableClose ? null : this.renderCloseButton() }
        </div>
        <div className='slide__header'>
          {
            header ? (
              <div className='slide__header-text'>
                <h2 className='u-padding-vertical-small u-margin-top-none'>
                  { header }
                </h2>
              </div>
            ) : null
          }
        </div>

        <div className='slide__content'>
          {this.props.children}
        </div>
        <div className='slide__footer'>
          {customFooter ? customFooter() : null}
        </div>
      </div>
    );
  };

  render() {
    const { isShowing } = this.state;
    // The aria-label is neccessary to abvoid moving from the modal to the background elements
    // where the modal is visible.
    return (
      <div className={classnames('slide', this.props.className, { 'slide--showing': isShowing })} role='dialog' aria-live='assertive' aria-label='dialog' aria-hidden={isShowing ? 'false' : 'true'}>
        <div className='inner-container__hide-scrollbar'>
          {this.renderInfo()}
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  setIsShowingSlide: PropTypes.func,
  toggleSlide: PropTypes.number,
  shouldHideOnLoad: PropTypes.bool,
  closeSlide: PropTypes.number,
  handleClose: PropTypes.func,
  performClose: PropTypes.func,
  header: PropTypes.string,
  customFooter: PropTypes.func,
  disableClose: PropTypes.bool,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  className: PropTypes.string
};

export default Slide;
