import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { replace } from 'react-router-redux';
import { FormattedMessage } from 'react-intl';
import { addKountScript, removeKountScript } from './utils/fraud-helpers';
import { getEnvironment } from '../utils/application';
import constants from '../utils/constants';
import Spinner from './Spinner';
import { v4 as uuidv4 } from 'uuid';
import { redeem, setIsRedeeming } from '../action-creators/redeem';
import {
  createAccount,
  linkAccount,
  sendCode,
  setIsLinkingAccount,
  setIsCreatingAccount,
  setCreateAccountError,
  setLinkAccountError
} from '../action-creators/auth';
import { selectMembershipByPartnerLpId } from '../selectors/memberships';

export class UnconnectedRedeemModalContent extends React.Component {
    state = {
      fraudUniqueId: uuidv4().split('-').join(''),
      addedKountScript: false,
      OTPinput: '',
      resentCode: false,
      haslinkAccErrors: false,
      modalContent: false
    }

    componentDidMount() {
      this.calculateContent();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.hasCreatedAccount || nextProps.isLinkingAccount) {
        this.calculateContent();
      }
      if (nextProps.linkAccountError.size || nextProps.linkAccountError) {
        this.setState({ haslinkAccErrors: true });
      }
      if (!this.props.createAccountError.size && nextProps.createAccountError.size) {
        replace('/error');
      }
    }

    componentWillUnmount() {
      const { setIsLinkingAccount, setIsCreatingAccount, setCreateAccountError, setIsRedeeming, setLinkAccountError } = this.props;
      removeKountScript();
      setIsLinkingAccount(false);
      setIsCreatingAccount(false);
      setCreateAccountError({});
      setLinkAccountError(false);
      setIsRedeeming(false);
    }

    handleOTPInput = e => {
      const { value } = e.target;
      this.setState({ OTPinput: value });
    }

    submitVerificationCode = email => {
      const { OTPinput } = this.state;
      const { linkAccount } = this.props;
      this.setState({ haslinkAccErrors: false }, () => linkAccount(email, OTPinput));
    }

    resendCode = email => {
      const { sendCode } = this.props;
      sendCode(email);
      this.setState({ resentCode: true });
    }

    submitRedemption = e => {
      e.preventDefault();
      const { redemptionDetails, redeem, fromMembership } = this.props;
      const { fraudUniqueId } = this.state;
      const { currencyCode, selectedDenomination, phoneNumber, isSendingToSelf } = redemptionDetails;
      const req = {
        fraudUniqueId,
        isSendingToSelf,
        fromAmount: selectedDenomination.get('points'),
        fromMembershipId: fromMembership.id,
        fromOfferId: selectedDenomination.get('fromOfferId'),
        toAmount: selectedDenomination.get('credit') * 100,
        toOfferId: selectedDenomination.get('toOfferId'),
        toLpId: selectedDenomination.get('toLpId'),
        fulfillment: {
          currencyCode,
          memberId: `+1${phoneNumber.split('-').join('')}`,
          email: fromMembership.credentials['identifyingFactors.email'],
          'identifyingFactors.firstName': fromMembership.credentials['identifyingFactors.firstName'],
          'identifyingFactors.lastName': fromMembership.credentials['identifyingFactors.lastName']
        }
      };
      redeem(req);
    }

    renderVerificationCodeErrMsg = () => (
      <div id='redeem__modal__verificationContent__error'>
        <FormattedMessage id='verificationCode.errorMsg' />
      </div>
    )

    renderResentCodeMsg = () => (
      <div id='redeem__modal__verificationContent__resentCodeText'>
        <FormattedMessage id='verificationCode.codeEmailedAgainMessage' />
      </div>
    );

    renderSpinner = () => (
      <div className='redeem__modal__spinner'>
        <Spinner />
      </div>
    )

    renderRedemptionReview = () => {
      const { redemptionDetails, toggleModal, isRedeeming } = this.props;
      const { fraudUniqueId, addedKountScript } = this.state;
      const env = getEnvironment();
      const kountDataCollectorUrl = constants.kountUrls[`${env}ScriptUrl`];
      const kountImgUrl = constants.kountUrls.imgUrl;
      const kountScriptSrc = `${kountDataCollectorUrl}${fraudUniqueId}`;
      const kountImgSrc = `${kountImgUrl}${fraudUniqueId}`;

      if (!addedKountScript) {
        addKountScript(kountScriptSrc);
        this.setState({ addedKountScript: true });
      }
      return (
        <div className='redeem__modal__redemptionReview'>
          <div id='redeem__modal__redemptionReview__credit'>
            <FormattedMessage id='redeem.offer.review.creditMsg' values={{ toAmount: redemptionDetails.selectedDenomination.get('credit') }} />
          </div>
          <div className='fs-exclude' id='redeem__modal__redemptionReview__phoneNumber'>
            <FormattedMessage id='redeem.offer.review.phoneNumber' values={{ phoneNumber: redemptionDetails.phoneNumber }} />
          </div>
          <div id='redeem__modal__redemptionReview__confirmationStatement'>
            <FormattedMessage id='redeem.offer.review.confirmationStatement' />
          </div>
          <button
            id='redeem__modal__redemptionReview__btn'
            onClick={this.submitRedemption}
            disabled={isRedeeming}
          >
            { isRedeeming ? <FormattedMessage id='redeem.offer.review.redeemingMsg' />
              : <FormattedMessage id='redeem.offer.review.btnText' /> }
          </button>
          <a
            href='#'
            onClick={toggleModal}
          >
            <FormattedMessage id='redeem.offer.review.closeModal.text' />
          </a>
          <img width='1' height='1' src={kountImgSrc} className='hide' alt='' />
        </div>
      );
    }

    renderVerificationContent = () => {
      const { formatter, user, isCreatingAccount } = this.props;
      const { OTPinput, resentCode, haslinkAccErrors } = this.state;
      const email = user.get('email');
      const placeholder = formatter.formatMessage({ id: 'verificationCode.verificationCodePlaceholder' });
      return (
        <div className='redeem__modal__verificationContent'>
          <div id='redeem__modal__verificationContent__header'>
            <FormattedMessage id='verificationCode.accountSafetyMessage' values={{ email }} />
          </div>
          <div id='redeem__modal__verificationContent__description'>
            <FormattedMessage id='verificationCode.codeEmailedMessage' values={{ email }} />
          </div>
          { haslinkAccErrors && this.renderVerificationCodeErrMsg()}
          <input
            className='redeem__modal__verificationContent__input'
            name='OTPinput'
            placeholder={placeholder}
            onChange={this.handleOTPInput}
            value={OTPinput}
          />
          <button
            onClick={() => this.submitVerificationCode(email)}
            id='redeem__modal__verificationContent__btn'
            disabled={isCreatingAccount}
          >
            { isCreatingAccount ? <FormattedMessage id='redeem.offer.review.loadingMsg' />
              : <FormattedMessage id='redeem.offer.verificationContent.btn' /> }
          </button>
          <a
            href='#'
            onClick={() => this.resendCode(email)}
          >
            <FormattedMessage id='redeem.offer.verificationContent.resendText' />
          </a>
          { resentCode && this.renderResentCodeMsg() }
        </div>
      );
    }

    calculateContent = () => {
      const {
        user,
        createAccount,
        isLinkingAccount,
        isCreatingAccount,
        hasCreatedAccount
      } = this.props;
      const role = user.get('role');
      const email = user.get('email');
      if (role !== constants.USER_ROLE_GUEST) {
        this.setState({ modalContent: 'redemptionReview' });
      } else if (role === constants.USER_ROLE_GUEST && !isLinkingAccount && !isCreatingAccount && !hasCreatedAccount) {
        createAccount(email);
      } else if (role === constants.USER_ROLE_GUEST && isLinkingAccount) {
        this.setState({ modalContent: 'verificationContent' });
      }
      return null;
    }

    renderContent = modalContent => {
      switch (modalContent) {
        case 'redemptionReview':
          return this.renderRedemptionReview();
        case 'verificationContent':
          return this.renderVerificationContent();
        default:
          return this.renderSpinner();
      }
    }

    render() {
      const { modalContent } = this.state;
      return (
        <div>
          { modalContent ? this.renderContent(modalContent) : this.renderSpinner() }
        </div>
      );
    }
}

UnconnectedRedeemModalContent.propTypes = {
  user: ImmutablePropTypes.map,
  isLinkingAccount: PropTypes.bool,
  isCreatingAccount: PropTypes.bool,
  hasCreatedAccount: PropTypes.bool,
  redemptionDetails: PropTypes.object.isRequired,
  linkAccount: PropTypes.func,
  createAccount: PropTypes.func,
  sendCode: PropTypes.func,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  toggleModal: PropTypes.func.isRequired,
  setIsCreatingAccount: PropTypes.func,
  setIsLinkingAccount: PropTypes.func,
  setCreateAccountError: PropTypes.func,
  setLinkAccountError: PropTypes.func,
  linkAccountError: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    PropTypes.bool
  ]),
  createAccountError: ImmutablePropTypes.map,
  redeem: PropTypes.func,
  isRedeeming: PropTypes.bool,
  setIsRedeeming: PropTypes.func,
  fromMembership: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.user.get('data'),
  isCreatingAccount: state.user.get('isCreatingAccount'),
  isLinkingAccount: state.user.get('isLinkingAccount'),
  hasCreatedAccount: state.user.get('hasCreatedAccount'),
  linkAccountError: state.user.get('linkAccountError'),
  createAccountError: state.user.get('createAccountError'),
  isFetchingUser: state.user.get('isFetchingUser'),
  formatter: state.app.get('formatter'),
  isRedeeming: state.redeem.get('isRedeeming'),
  fromMembership: selectMembershipByPartnerLpId(state)?.toJSON?.()
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createAccount,
  linkAccount,
  sendCode,
  setIsLinkingAccount,
  setIsCreatingAccount,
  setCreateAccountError,
  setLinkAccountError,
  setIsRedeeming,
  redeem
}, dispatch);

export const RedeemModalContent = connect(mapStateToProps, mapDispatchToProps)(UnconnectedRedeemModalContent);
