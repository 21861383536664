import { fromJS } from 'immutable';

const initialState = fromJS({
  redeemOffers: {},
  successfulRedeemTransaction: {},
  isFetchingRedeemOffers: false,
  isRedeeming: false,
  redemptionError: ''
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_FETCHING_REDEEM_OFFERS':
      return state.set('isFetchingRedeemOffers', action.status);
    case 'SET_IS_REDEEMING':
      return state.set('isRedeeming', action.status);
    case 'SET_REDEEM_OFFERS':
      return state.set('redeemOffers', fromJS(action.offers));
    case 'SET_SUCCESSFUL_REDEEM_TRANSACTION':
      return state.set('successfulRedeemTransaction', fromJS(action.transaction));
    case 'SET_REDEMPTION_ERROR':
      return state.set('redemptionError', action.error);
    default:
      return state;
  }
};
