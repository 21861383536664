import Memberships from '../api/memberships';
import Auth from '../api/auth';
import Lps from '../api/lps';

import { onLogin, setIsSingleSigningOn } from './auth';

import constants from '../../src/utils/constants';
import { getClientId } from '../utils/application';
import { isValidSession } from '../utils/jwt';
import { setClientSpecificData } from './app';

export function setIsFetchingMembership(isFetchingMembership) {
  return {
    type: 'SET_IS_FETCHING_MEMBERSHIP',
    isFetchingMembership
  };
}

export function setMembership(membership) {
  return {
    type: 'SET_MEMBERSHIP',
    membership
  };
}

export function setRegisterMembershipErrors(errors) {
  return {
    type: 'SET_REGISTER_MEMBERSHIP_ERRORS',
    errors
  };
}

export function setLp(lp) {
  return {
    type: 'SET_REGISTER_MEMBERSHIP_LP',
    lp
  };
}

export function setIsAttemptingRegistration(isAttemptingRegistration) {
  return {
    type: 'SET_REGISTER_MEMBERSHIP_IS_ATTEMPTING_REGISTRATION',
    isAttemptingRegistration
  };
}

export function setMembershipId(membershipId) {
  return {
    type: 'SET_MEMBERSHIP_ID',
    membershipId
  };
}

export function setPartnerMv(partnerMv) {
  return {
    type: 'SET_PARTNER_MV',
    partnerMv
  };
}

export function setLpId(lpId) {
  return {
    type: 'SET_REGISTER_MEMBERSHIP_LP_ID',
    lpId
  };
}

export function showRegistrationErrors() {
  return dispatch => {
    dispatch(setRegisterMembershipErrors(['credentialCatcher.register.error']));
    dispatch(setIsAttemptingRegistration(false));
  };
}

export function submitRegistrationForm() {
  return dispatch => {
    dispatch(setIsAttemptingRegistration(true));
  };
}

export function setWidgetParameters(membershipId, partnerMv, lpId) {
  return dispatch => {
    dispatch(setMembershipId(membershipId));
    dispatch(setPartnerMv(partnerMv));
    dispatch(setLpId(lpId));
  };
}

export function fetchMembership(membershipId) {
  return dispatch => {
    dispatch(setIsFetchingMembership(true));
    Memberships.fetchMembership(membershipId, false).then(membership => {
      dispatch(setMembership(membership));
      dispatch(setIsFetchingMembership(false));
      const lpId = membership.lpId;
      Lps.fetchLps()
        .then(lps => {
          const lp = lps.find(lp => lp.id === lpId);
          if (lp) {
            dispatch(setLp(lp));
          } else {
            dispatch(setRegisterMembershipErrors(['credentialCatcher.lp.error']));
          }
        });
    }, () => {
      dispatch(setRegisterMembershipErrors(['credentialCatcher.membership.error']));
      dispatch(setIsFetchingMembership(false));
    });
  };
}

export function registerMembership(membership) {
  return dispatch => Memberships.registerMembership(membership)
    .then(registeredMembership => {
      dispatch(setMembership(registeredMembership));
      dispatch(registerMembershipPolling(registeredMembership.id));
    }, () => {
      dispatch(setRegisterMembershipErrors(['credentialCatcher.register.error']));
      dispatch(setIsAttemptingRegistration(false));
    });
}

export function registerMembershipMv(membershipId, lpMv) {
  return dispatch => {
    dispatch(setIsAttemptingRegistration(true));
    return Memberships.registerMembershipMv(membershipId, lpMv)
      .then(registeredMembership => {
        dispatch(setMembership(registeredMembership));
        return dispatch(registerMembershipPolling(registeredMembership.id));
      }, () => {
        dispatch(setRegisterMembershipErrors(['credentialCatcher.register.error']));
        dispatch(setIsAttemptingRegistration(false));
      });
  };
}

export function registerMembershipPolling(membershipId) {
  return dispatch => {
    dispatch(setIsFetchingMembership(true));
    return Memberships.fetchMembership(membershipId, false)
      .then(membership => {
        dispatch(setMembership(membership));
        const isPending = membership.memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_PENDING;
        if (isPending) {
          setTimeout(() => {
            dispatch(registerMembershipPolling(membershipId));
          }, constants.POLLING_DELAY);
        } else {
          dispatch(setIsAttemptingRegistration(false));
          dispatch(setIsFetchingMembership(false));
        }
      }, () => {
        dispatch(setRegisterMembershipErrors(['credentialCatcher.membership.error']));
        dispatch(setIsAttemptingRegistration(false));
        dispatch(setIsFetchingMembership(false));
      });
  };
}

export function login(mv) {
  return dispatch => {
    const clientId = getClientId();
    if (mv) {
      dispatch(setIsSingleSigningOn(true));
      return Auth.postSSO(clientId, mv)
        .then(() => {
          dispatch(onLogin(true));
          dispatch(setIsSingleSigningOn(false));
        }, () => {
          dispatch(setRegisterMembershipErrors(['credentialCatcher.login.error']));
          dispatch(setIsSingleSigningOn(false));
        });
    } else if (isValidSession()) {
      dispatch(onLogin(true));
    } else {
      dispatch(setRegisterMembershipErrors(['credentialCatcher.login.error']));
    }
  };
}

export const validateRedirectUrlsAndLogin = (redirectUrls, mv) =>
  (dispatch, getState) => {
    const promises = [];
    const locale = getState().app.get('locale');
    const currentClientData = getState().app.get('clientData');
    const clientId = currentClientData.get('clientId');

    if (redirectUrls.partnerRedirectUrlSuccess) {
      promises.push(Memberships.validateRedirectUrls(clientId, redirectUrls.partnerRedirectUrlSuccess));
    }
    if (redirectUrls.partnerRedirectUrlError) {
      promises.push(Memberships.validateRedirectUrls(clientId, redirectUrls.partnerRedirectUrlError));
    }

    const existingSuccessUrl = currentClientData.getIn(['credentialCatchers', 'redirectUrls', locale, 'success']);
    const existingErrorUrl = currentClientData.getIn(['credentialCatchers', 'redirectUrls', locale, 'error']);
    return Promise.allSettled(promises)
      .then(([redirectUrlSuccess, redirectUrlError]) => {
        const updatedRedirectUrls = {
          success: redirectUrlSuccess?.value?.partnerRedirectURL || existingSuccessUrl,
          error: redirectUrlError?.value?.partnerRedirectURL || existingErrorUrl
        };
        const updatedClientData = currentClientData.setIn(['credentialCatchers', 'redirectUrls', locale], updatedRedirectUrls).toJS();
        dispatch(setClientSpecificData(updatedClientData));
        dispatch(login(mv));
      })
      .catch(() => {
        dispatch(login(mv));
      });
  };
