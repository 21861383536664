/**
@class Lps API
*/
import { addAuthTokenToHeaders, serializeQueryParams, request } from './utils/httpUtils';

/**
`GET /v1/lps`
@method fetchLps
*/
export function fetchLps() {
  const options = addAuthTokenToHeaders();
  return fetch('/v1/lps', options)
    .then(res => res.json())
    .then(payload => filterNonGiftCardLps(payload.lps));
}

/**
`GET /public/lps`
@method fetchPublicLps
*/
export function fetchPublicLps(clientId) {
  return request(`/public/lps?${serializeQueryParams({ clientId })}`)
    .then(res => filterNonGiftCardLps(res.json.lps));
}

function filterNonGiftCardLps(lps) {
  const nonGiftCardLps = lps.filter(lp => lp.content.type !== 'giftCard');
  return nonGiftCardLps.filter(lp => lp.content.isEnabled);
}

/**
 `GET /v1/offer-sets?<params>`
 fetch offer set for exchange
 @method fetchOfferSet
 @param {Object} opts
 */
export function fetchEligibleLps(params) {
  const query = {
    orderType: 'exchange',
    ...params
  };
  return request(`/v1/eligible-lps?${serializeQueryParams(query)}`)
    .then(res => res.json);
}

export default { fetchLps, fetchPublicLps, fetchEligibleLps };
