import TextField from '@mui/material/TextField';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';


export class TextInput extends React.Component {
  state = {
    hasAttemptedInput: false,
    isValid: false,
    errorMessage: ''
  };

  componentDidMount() {
    const { value } = this.props;
    const hasAttemptedInput = value.length > 0;

    if (hasAttemptedInput) {
      this.updateHasAttemptedInput(hasAttemptedInput);
      this.validate(value);
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props.triggerValidation !== nextProps.triggerValidation) {
      this.updateHasAttemptedInput();
      this.validate(this.props.value);
    }
  }

  handleBlur = e => {
    this.updateHasAttemptedInput();

    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(e.target.value);
    } else {
      this.validate(e.target.value);
    }
  };

  handleChange = e => {
    const { value } = e.target;
    const { name, onChange } = this.props;
    this.validate(value);
    onChange(value, name);
  }

  updateHasAttemptedInput = (value = true) => {
    this.setState({ hasAttemptedInput: value });
  }

  validate = value => {
    const errorMessage = this.props.validator(value, this.props.name);
    const isValid = typeof errorMessage !== 'string';

    if (isValid) {
      this.setState({ isValid });
    } else {
      this.setState({ isValid, errorMessage });
    }
  };

  render() {
    const { hasAttemptedInput, errorMessage, isValid } = this.state;
    const shouldShowError = hasAttemptedInput && !isValid;

    const { classes = '',
      disabled,
      label,
      placeholder,
      type,
      value,
      hideLabel } = this.props;
    const { name = label } = this.props;
    const namePrefix = name ? name.split(' ').join('-') : '';
    const inputId = `${namePrefix}-label`;
    const errorLabel = `${namePrefix}-error-description`;

    return (
      <div className={classnames('text-field fs-exclude', classes)}>
        <TextField
          aria-describedby={shouldShowError ? errorLabel : null}
          aria-invalid={shouldShowError}
          autoComplete='new-password'
          className='u-1/1, text-field__input'
          disabled={disabled}
          error={shouldShowError}
          FormHelperTextProps={{
            role: 'alert',
            id: { errorLabel }
          }}
          fullWidth
          helperText={shouldShowError ? errorMessage.toUpperCase() : null}
          id={inputId}
          label={label}
          InputLabelProps={{
            className: classnames('text-field__label', { 'text-field__label--hidden': hideLabel }),
            shrink: true
          }}
          name={name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleEnterKeypress}
          placeholder={placeholder || label}
          type={type}
          value={value}
          variant='standard'
        />
      </div>
    );
  }
}

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  triggerValidation: PropTypes.number,
  validator: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  hideLabel: PropTypes.bool
};

TextInput.defaultProps = {
  type: 'text',
  label: '',
  disabled: false,
  classes: null,
  placeholder: '',
  onBlur: null,
  hideLabel: false
};

