export function getBrowserLocale() {
  // Should work for all supported browsers
  const locale = navigator.browserLanguage || navigator.language;
  return locale;
}

export function normalizeLocaleString(localeString) {
  const localeComponents = localeString.split('-');
  if (localeComponents.length > 1) {
    const uppercaseLocale = localeComponents[1].toUpperCase();
    const normalizedLocale = `${localeComponents[0]}-${uppercaseLocale}`;
    return normalizedLocale;
  }
  return localeString;
}
