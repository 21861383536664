import React from 'react';

class EarnPointsTermsContainer extends React.Component {
    componentDidMount = () => {
      if (this.props.showPointsTCsModal) {
        this.props.showPointsTCsModal();
      }
    }

    render() {
      return null;
    }
}

export default EarnPointsTermsContainer;
