import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, goBack } from 'react-router-redux';
import { createAccount, linkAccount, sendCode } from '../action-creators/auth';
import { setShowCreateAccount, setIsShowingSlide, setPageMessage, setModalContentKey } from '../action-creators/app';
import constants from '../utils/constants';
import { fetchMemberships } from '../action-creators/memberships';

import NewAccount from './NewAccount';
import VerificationCode from './VerificationCode';

export class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const pathnames = location.pathname.split('/');
    let appContext;
    if (pathnames.includes('wallet')) {
      appContext = constants.createAccount.balanceTracker;
    } else if (pathnames.includes('exchange')) {
      appContext = constants.createAccount.exchange;
    }

    this.state = {
      selectedLpId: location.query.selectedLpId,
      selectedLp: null,
      appContext
    };
  }

  UNSAFE_componentWillMount() {
    this.guardRoute();
    if (this.props.lps.size) {
      this.setSelectedLp();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { user, lps } = nextProps;

    if (!this.props.user.size && user.size) {
      this.guardRoute(user);
    }
    if (!this.props.lps.size && lps.size) {
      this.setSelectedLp();
    }
  }

  onCreateAccountError = errorCode => {
    this.trackAnalyticsEvent({
      action: constants.ANALYTICS_CREATE_ACCOUNT,
      status: 'error',
      errorCode
    });
  };

  onCreateAccountSuccess = () => {
    this.afterCreateAccountSuccess('create');
  };

  onLinkAccountError = errorCode => {
    this.trackAnalyticsEvent({
      action: constants.ANALYTICS_LINK_ACCOUNT,
      status: 'error',
      errorCode
    });
  };

  onLinkAccountSuccess = () => {
    this.afterCreateAccountSuccess('link');
  };

  setSelectedLp = () => {
    const { selectedLpId } = this.state;
    this.setState({ selectedLp: this.props.lps.find(lp => lp.get('id') === selectedLpId) });
  };

  afterCreateAccountSuccess = type => {
    const {
      setPageMessage,
      setShowCreateAccount,
      fetchMemberships,
      productType,
      push,
      formatter
    } = this.props;
    const { selectedLp, appContext } = this.state;

    if (productType !== constants.product.buy) {
      fetchMemberships(true, true);
    }

    // variable for Buy route
    setShowCreateAccount(false);

    const selectedLpIdParam = selectedLp ? `?selectedLpId=${selectedLp.get('id')}` : '';
    const route = appContext === constants.createAccount.exchange ? '/exchange/configure' : '/wallet';

    this.gtmCreateAccountEvent(type);
    setPageMessage({
      type: 'success',
      message: formatter.formatMessage({ id: 'createAccount.successfulAccountCreationMessage' })
    });

    setTimeout(() => {
      push(`${route}${selectedLpIdParam}`);
    }, constants.baseAnimationLength);
  };

  gtmCreateAccountEvent = type => {
    const { appContext, selectedLp } = this.state;
    let action;
    switch (type) {
      case 'create':
        action = constants.ANALYTICS_CREATE_ACCOUNT;
        break;
      case 'link':
        action = constants.ANALYTICS_LINK_ACCOUNT;
        break;
    }

    let event = {
      action,
      status: 'success'
    };
    if (appContext === constants.createAccount.balanceTracker && selectedLp) {
      event = Object.assign(event, {
        lpName: `${selectedLp.getIn(['content', 'companyName'])} ${selectedLp.get('name')}`,
        lpId: selectedLp.get('id')
      });
    }

    this.trackAnalyticsEvent(event);
  };

  guardRoute = (user = this.props.user) => {
    const role = user.get('role');
    const { appContext } = this.state;
    if (role === constants.USER_ROLE_USER) {
      if (appContext === constants.createAccount.balanceTracker) {
        this.props.push('/wallet');
      }
    }
  };

  trackAnalyticsEvent = data => {
    const { appContext } = this.state;
    this.props.googleTagManager.pushGtmData(Object.assign({ appContext }, data));
  };

  render() {
    const { appContext } = this.state;
    const { isLinkingAccount } = this.props;

    if (isLinkingAccount) {
      return (<VerificationCode
        {...this.props}
        appContext={appContext}
        fadeOut
        onSuccess={this.onLinkAccountSuccess}
        setIsShowingSlide={this.props.setIsShowingSlide}
        onError={this.onLinkAccountError}
        setModalContentKey={this.props.setModalContentKey}
      />);
    }
    return (<NewAccount
      {...this.props}
      fadeOut
      onSuccess={this.onCreateAccountSuccess}
      onError={this.onCreateAccountError}
    />);
  }
}

CreateAccount.propTypes = {
  user: ImmutablePropTypes.map,
  lps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  googleTagManager: PropTypes.object,
  productType: PropTypes.string,
  isLinkingAccount: PropTypes.bool,
  push: PropTypes.func,
  setIsShowingSlide: PropTypes.func,
  setShowCreateAccount: PropTypes.func,
  fetchMemberships: PropTypes.func,
  setPageMessage: PropTypes.func,
  setModalContentKey: PropTypes.func,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

function mapStateToProps(state) {
  return {
    user: state.user.get('data'),
    clientData: state.app.get('clientData'),
    formatter: state.app.get('formatter'),
    messages: state.app.get('messages'),
    createAccountError: state.user.get('createAccountError'),
    lps: state.lps.get('allLps'),
    googleTagManager: state.app.get('googleTagManager'),
    productType: state.app.get('productType'),
    linkAccountError: state.user.get('linkAccountError'),
    linkAccountEmail: state.user.get('linkAccountEmail'),
    hasCreatedAccount: state.user.get('hasCreatedAccount'),
    isCreatingAccount: state.user.get('isCreatingAccount'),
    isLinkingAccount: state.user.get('isLinkingAccount')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    push,
    goBack,
    createAccount,
    linkAccount,
    sendCode,
    setIsShowingSlide,
    setShowCreateAccount,
    fetchMemberships,
    setPageMessage,
    setModalContentKey
  }, dispatch);
}

export const CreateAccountContainer = connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
