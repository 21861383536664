import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { setModalContentKey } from '../action-creators/app';

import ModalHeader from './ModalHeader';

export const VerificationCodeSentModal = ({ formatter, setModalContentKey }) => (
  <div>
    <ModalHeader
      header='verificationCode.resendCodeModalHeader'
      formatter={formatter}
      setModalContentKey={setModalContentKey}
    />
    <div className='u-padding message'>
      <FormattedMessage id='verificationCode.codeEmailedAgainMessage' />
      <div className='text-center u-margin-top-large'>
        <button
          className='btn btn-primary u-1/1'
          onClick={() => setModalContentKey(null)}
        >
          Okay!
        </button>
      </div>
    </div>
  </div>
);

VerificationCodeSentModal.propTypes = {
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  setModalContentKey: PropTypes.func
};

function mapStateToProps(state) {
  return {
    formatter: state.app.get('formatter')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setModalContentKey
  }, dispatch);
}

export const VerificationCodeSentModalContainer =
  connect(mapStateToProps, mapDispatchToProps)(VerificationCodeSentModal);
