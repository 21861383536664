import { slide as Menu } from 'react-burger-menu';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const HamburgerMenu = props => {
  const {
    isOpen,
    onStateChange,
    signOutMsg,
    balance,
    signOutRedirectionURL,
    currencyName,
    firstName,
    renderUserDetailsBtn,
    renderLogoImages
  } = props;

  return (
    <Menu
      isOpen={isOpen}
      onStateChange={onStateChange}
    >
      <div className='header__userDetailsNav__hamburgerMenu'>
        {renderLogoImages()}
        <div id='hamburgerMenu__name'>
          <FormattedMessage id='hamburgerMenu.name' values={{ firstName }} />
        </div>
        <div id='hamburgerMenu__currency'>
          <FormattedMessage id='hamburgerMenu.currency' values={{ currencyName }} />
        </div>
        <div id='hamburgerMenu__balance'>
          {balance}
        </div>
        <a id='hamburgerMenu__signOut' href={signOutRedirectionURL}>{signOutMsg}</a>
        {renderUserDetailsBtn()}
      </div>
    </Menu>
  );
};

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool,
  onStateChange: PropTypes.func,
  signOutRedirectionURL: PropTypes.string,
  signOutMsg: PropTypes.string,
  firstName: PropTypes.string,
  renderUserDetailsBtn: PropTypes.func,
  currencyName: PropTypes.string,
  balance: PropTypes.string,
  renderLogoImages: PropTypes.func
};

export default HamburgerMenu;
