import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { Map, List } from 'immutable';

import constants from '../utils/constants';
import { capitalize } from '../utils/string';
import { getLpFromState } from './utils/state-helpers';
import { shouldHideMemberData } from './utils/wps-helpers';

import { setModalContentKey } from '../action-creators/app';
import { setExchangeFromMembershipId, setExchangeToMembershipId, resetAmounts } from '../action-creators/exchange';
import { postNewMembership, setHasFetchedFinalOfferSet } from '../action-creators/memberships';

import ModalHeader from './ModalHeader';
import ModalLpRowItem from './ModalLpRowItem';

export class ExchangeSelectModal extends React.Component {
  state = {
    newMembershipLpId: ''
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hasUpdatedMemberships } = this.props;
    const hasFinishedFetchingMemberships = !hasUpdatedMemberships && nextProps.hasUpdatedMemberships;
    if (hasFinishedFetchingMemberships) {
      const { newMembershipLpId } = this.state;
      if (newMembershipLpId) {
        const lp = nextProps.allLps.find(lp => lp.get('id') === newMembershipLpId) || Map();
        const membership = nextProps.memberships.find(membership => membership.get('lpId') === newMembershipLpId);
        this.selectMembership(lp, membership);
        this.setState({ newMembershipLpId: '' });
      }
    }
  }

  onSelect = (selectedLp = Map(), direction, selectedMembership) => {
    const { resetAmounts, setHasFetchedFinalOfferSet } = this.props;
    resetAmounts();

    if (!selectedMembership || selectedMembership.isEmpty()) {
      selectedMembership = this.getMembershipByLpId(selectedLp.get('id'));
    }
    const isSuccessfulMembership = selectedMembership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_SUCCESS;
    if (!isSuccessfulMembership) {
      setHasFetchedFinalOfferSet(false);
    }
  };

  getAllMembershipsForLp = (lp = Map(), alreadySeen) => {
    // memoize lps already seen to avoid duplicates. This happens because `/eligible-lps` returns
    // repeated lps when the user has more than one membership for a given program
    const lpId = lp.get('id');
    if (alreadySeen[lpId]) {
      return;
    }
    alreadySeen[lpId] = true;
    const { memberships } = this.props;
    return memberships.filter(membership => membership.get('lpId') === lpId);
  };

  getMembershipByLpId = lpId => {
    const { memberships } = this.props;
    const matchingMemberships = memberships.filter(membership => membership.get('lpId') === lpId);
    if (!matchingMemberships.size) {
      return Map();
    }
    // give preference to registered memberships
    return matchingMemberships.find(membership => membership.get('isRegistered')) || matchingMemberships.first();
  };

  getMembershipIdFromLpId = lpId => this.getMembershipByLpId(lpId).get('id');

  getSortedRowData = (eligibleLps = List()) => {
    const alreadySeen = {};
    const memberships = eligibleLps.flatMap(lp => this.getAllMembershipsForLp(lp, alreadySeen));
    const sortedMemberships = memberships.sortBy(membership => !membership.get('isRegistered'));
    return this.convertToRowData(eligibleLps, sortedMemberships);
  };

  // Duplicates in ExchangeConfigure
  setMembership = (direction, membershipId) => {
    if (membershipId) {
      if (direction === 'from') {
        // We remove the *to* membership before setting the *from* membership so that
        // the offer sets aren't fetched immediately and we have time to automatically
        // choose the *to* membership.
        this.props.setExchangeToMembershipId('');
      }
      if (direction === 'from') {
        this.props.setExchangeFromMembershipId(membershipId);
      } else {
        this.props.setExchangeToMembershipId(membershipId);
      }
    }
  };

  addAndSelectMembership = lp => {
    const {
      memberships,
      postNewMembership,
      googleTagManager
    } = this.props;
    const lpId = lp.get('id');

    googleTagManager.pushGtmData({
      action: constants.ANALYTICS_ADD_LP,
      [constants.ANALYTICS_ADD_LP]: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
      context: 'exchangeSelectModal',
      lpId
    });
    postNewMembership(lpId, memberships);
    this.setState({ newMembershipLpId: lpId });
    // Setting a value for newMembershipLpId in the state will cause the component to select
    // the new membership after it's created (such thing happens on `componentWillReceiveProps`)
  };

  convertToRowData = (lps, memberships) => {
    if (memberships && memberships.size) {
      return memberships.map(membership => ({
        lp: lps.find(lp => lp.get('id') === membership.get('lpId')),
        membership
      }));
    }
    return lps.map(lp => ({ lp, membership: Map() }));
  };

  otherDirection = () => {
    const { direction } = this.props;
    if (direction === 'from') {
      return 'to';
    }
    return 'from';
  };

  otherLp = () => {
    const { fromLp, toLp, direction } = this.props;
    if (direction === 'from') {
      return toLp;
    }
    return fromLp;
  };

  selectMembership = (selectedLp, selectedMembership = Map()) => {
    const {
      direction,
      googleTagManager,
      membership,
      setModalContentKey
    } = this.props;

    setModalContentKey(null);

    const lpName = `${selectedLp.getIn(['content', 'companyName'])} ${selectedLp.get('name')}`;
    const balance = selectedMembership.getIn(['memberDetails', 'balance']);

    let gtmData = {
      context: 'exchangeSelectModal',
      lpId: selectedLp.get('id')
    };

    if (direction === 'from') {
      gtmData = Object.assign({
        action: constants.ANALYTICS_EXCHANGE_OUT_SELECT_REGISTERED_LP,
        exchangeOutPartner: lpName,
        milesOutAccount: balance
      }, gtmData);
    } else {
      gtmData = Object.assign({
        action: constants.ANALYTICS_EXCHANGE_IN_SELECT_REGISTERED_LP,
        exchangeInPartner: lpName,
        milesInAccount: balance
      }, gtmData);
    }

    googleTagManager.pushGtmData(gtmData);

    // close the modal before settingLp to avoid showing a flash of spinner
    setTimeout(() => {
      const selectedMembershipId = selectedMembership.get('id');
      const selectionHasNoChanges = selectedMembershipId === membership.get('id');
      if (selectionHasNoChanges) {
        return;
      }

      if (selectedMembership.size) {
        this.setMembership(direction, selectedMembershipId);
      }
      this.onSelect(selectedLp, direction, selectedMembership);
    }, constants.baseAnimationLength);
  };

  renderEligibleLps = (rowData, sectionHeader, rowLabel) => {
    const { formatter, clientData } = this.props;
    return (
      <div role='menu'>
        <h3 className='section-heading u-padding-vertical-tiny u-padding-left-small u-margin-none u-bb text-weight-8'>
          {sectionHeader}
        </h3>
        {
          rowData.map(row => {
            const { lp, membership } = row;
            const lpId = lp.get('id');
            const membershipId = membership.get('id');
            const shouldHideBalance = shouldHideMemberData(clientData, lpId);

            return (
              <ModalLpRowItem
                key={`${lpId}-${membershipId}`}
                formatter={formatter}
                lp={lp}
                membership={membership}
                shouldHideBalance={shouldHideBalance}
                onClick={membership && membership.size ? this.selectMembership : this.addAndSelectMembership}
                rowLabel={rowLabel}
              />
            );
          })
        }
      </div>
    );
  };

  render() {
    const { direction, eligibleLps, formatter, shouldDisableProgramAddition } = this.props;

    const availableRowData = this.convertToRowData(eligibleLps.get('available') || List());
    const includedRowData = this.getSortedRowData(eligibleLps.get('included'));
    const myProgramsHeader = formatter.formatMessage({ id: 'exchangeSelect.addMyProgramHeader' });
    const selectProgramMsg = formatter.formatMessage({ id: 'exchangeSelect.selectProgramMessage' });
    const otherProgramsHeader = formatter.formatMessage({ id: 'exchangeSelect.addOtherProgramHeader' });

    return (
      <div>
        <ModalHeader
          header={`exchangeSelect.exchange${capitalize(direction)}ModalHeader`}
          setModalContentKey={this.props.setModalContentKey}
          formatter={formatter}
        />
        {includedRowData.size ? this.renderEligibleLps(includedRowData, myProgramsHeader, selectProgramMsg) : null}
        {availableRowData.size && !shouldDisableProgramAddition ?
          this.renderEligibleLps(availableRowData, otherProgramsHeader, selectProgramMsg) : null}
      </div>
    );
  }
}

ExchangeSelectModal.propTypes = {
  membership: ImmutablePropTypes.map,
  eligibleLps: ImmutablePropTypes.map,
  direction: PropTypes.string,
  hasUpdatedMemberships: PropTypes.bool,
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  shouldDisableProgramAddition: PropTypes.bool,
  googleTagManager: PropTypes.object,
  clientData: ImmutablePropTypes.map,
  fromLp: ImmutablePropTypes.map,
  toLp: ImmutablePropTypes.map,
  postNewMembership: PropTypes.func,
  setExchangeFromMembershipId: PropTypes.func,
  setExchangeToMembershipId: PropTypes.func,
  resetAmounts: PropTypes.func,
  setHasFetchedFinalOfferSet: PropTypes.func,
  setModalContentKey: PropTypes.func
};

export function commonMapStateToProps(state) {
  return {
    hasUpdatedMemberships: state.memberships.get('hasUpdatedMemberships'),
    allLps: state.lps.get('allLps'),
    memberships: state.memberships.get('data'),
    formatter: state.app.get('formatter'),
    shouldDisableProgramAddition: state.wps.get('disableBlwProgramAddition'),
    googleTagManager: state.app.get('googleTagManager'),
    clientData: state.app.get('clientData'),
    fromLp: getLpFromState(state, 'from'),
    toLp: getLpFromState(state, 'to')
  };
}

export function commonMapDispatchToProps(dispatch) {
  return bindActionCreators({
    postNewMembership,
    setExchangeFromMembershipId,
    setExchangeToMembershipId,
    resetAmounts,
    setHasFetchedFinalOfferSet,
    setModalContentKey
  }, dispatch);
}
