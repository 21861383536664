import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EarnRedeemFooter } from './EarnRedeemFooter';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

class UnconnectedRedeemFlowError extends Component {
  UNSAFE_componentWillMount = () => {
    window.scrollTo(0, 0);
  }

  getErrMsg = (redemptionError, uniqueErrors) => {
    if (uniqueErrors.find(err => err === redemptionError)) {
      return redemptionError;
    }
    return 'redeem.error.generalMsg';
  }

  returnToHome = event => {
    const { push } = this.props;
    event.preventDefault();
    push('/start');
  };

  render() {
    const { redemptionError, clientData } = this.props;
    const uniqueErrors = clientData.get('redemptionErrorCodes') ? clientData.get('redemptionErrorCodes') : [];

    return (
      <div className='redeemError'>
        <h1 className='redeemError__errorMsg'>
          <FormattedMessage id={this.getErrMsg(redemptionError, uniqueErrors) || 'redeem.error.generalMsg'} />
        </h1>
        <button
          id='redeemError__homeBtn'
          onClick={this.returnToHome}
        >
          <FormattedMessage id='redeem.error.backToHome' />
        </button>
        <div id='earnFlow__footer__container'>
          <EarnRedeemFooter />
        </div>
      </div>
    );
  }
}

UnconnectedRedeemFlowError.propTypes = {
  redemptionError: PropTypes.string,
  clientData: ImmutablePropTypes.map,
  push: PropTypes.func
};

const mapStateToProps = state => ({
  redemptionError: state.redeem.get('redemptionError'),
  clientData: state.app.get('clientData')
});

export const RedeemFlowErrorContainer = connect(mapStateToProps, null)(UnconnectedRedeemFlowError);
