import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS, Map } from 'immutable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import constants from '../utils/constants';
import {
  getLocalImage,
  getLogoPath
} from './utils/image-helpers';
import { getClientId } from '../utils/application';
import { fetchClientPromos } from '../action-creators/earn';
import { DynamicHero } from './DynamicHero';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import RedemptionAmountWidget from './RedemptionAmountWidget';
import { EarnRedeemFooter } from './EarnRedeemFooter';
import { checkIfAllowedOnPage, reloadWithoutMv } from '../utils/url';
import Spinner from './Spinner';
import RedeemRecipientSection from './RedeemRecipientSection';
import { EarnRedeemCheckBoxes } from './EarnRedeemCheckBoxes';
import { fetchRedeemOffers } from '../action-creators/redeem';
import { push } from 'react-router-redux';
import { RedeemModalContent } from './RedeemModalContent';
import RedeemCountrySelection from './RedeemCountrySelection';
import { selectMembershipByPartnerLpId } from '../selectors/memberships';

export class UnconnectedRedeemFlow extends React.Component {
  state = {
    windowWidth: window.innerWidth,
    selectedDenomination: fromJS({}),
    selectedCurrency: this.props.clientData.get('redemptionCountryDetails').toJSON()[0].currency,
    isSendingToSelf: true,
    phoneNumber: '',
    isPhoneNumFormatCorrect: null,
    isTCCheckboxChecked: false,
    isFullOfferTermsChecked: false,
    isModalOpen: false
  };

  UNSAFE_componentWillMount() {
    const {
      fetchClientPromos,
      locale,
      partnerLpId,
      clientData,
      memberships,
      user
    } = this.props;
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.setWindowWidth);
    const allowedOnPage = checkIfAllowedOnPage(clientData, user, memberships);
    const promoCopyParams = {
      orderType: constants.product.earn,
      locale
    };
    if (partnerLpId) {
      promoCopyParams.lpId = partnerLpId;
    }
    if (allowedOnPage) {
      fetchClientPromos(getClientId(), promoCopyParams);
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { clientData, fetchRedeemOffers, redeemOffers, isFetchingRedeemOffers, isLoggedIn, location, push } = this.props;
    const { fromMembership } = nextProps;
    const { redemptionDenominations, redemptionCountryDetails } = clientData.toJSON();

    if (nextProps.fromMembership && !redeemOffers.size && !isFetchingRedeemOffers && !nextProps.isFetchingRedeemOffers) {
      fetchRedeemOffers(
        redemptionDenominations,
        redemptionCountryDetails,
        fromMembership
      );
    }

    if (nextProps.isLoggedIn && !isLoggedIn) {
      reloadWithoutMv(push, location);
    }
  }

  setWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  renderHero = heroText => {
    const { clientData } = this.props;
    const partnerDirName = clientData.get('partnerDirName');
    const heroDesktopImage = clientData.get('heroDesktopImage');
    const heroMobileImage = clientData.get('heroMobileImage');

    const shouldDisplayMobileImage = this.state.windowWidth <= constants.mobileMaxWidth;
    const desktopHeroImage = getLocalImage(partnerDirName, heroDesktopImage);
    const mobileHeroImage = getLocalImage(partnerDirName, heroMobileImage);
    const heroContent = {
      image: (shouldDisplayMobileImage && mobileHeroImage) ? mobileHeroImage : desktopHeroImage,
      ...heroText
    };

    return (
      <DynamicHero {...heroContent} />
    );
  };

  renderUserInformation = () => {
    const { user, redeemOffers, fromMembership } = this.props;
    const { selectedCurrency } = this.state;
    const minBalanceForRedemption = redeemOffers.toJSON()[selectedCurrency][0].points;
    const firstName = user.get('firstName') || 'User';
    let balance = fromMembership.memberDetails.balance || 0;
    const hasEnoughPointsToRedeem = balance > minBalanceForRedemption;
    balance = balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedMinBalance = minBalanceForRedemption.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return (
      <div className='redeem__userInformation'>
        <h3 className='redeem__userInformation__userName'>
          <FormattedHTMLMessage id='redeem.user.hiName' values={{ firstName }} />
        </h3>
        <p className='redeem__userInformation__userPoints'>
          <FormattedMessage id='redeem.user.yourPoints' values={{ points: balance }} />
        </p>
        <div className='redeem__userInformation__instructions'>
          {hasEnoughPointsToRedeem && <FormattedMessage id='redeem.offer.description' />}
          {!hasEnoughPointsToRedeem && <FormattedMessage id='redeem.offer.description.notEnoughPoints' values={{ minimum: formattedMinBalance  }} />}
        </div>
      </div>
    );
  };

  getRedemptionDenominationData = () => {
    const { selectedDenomination, selectedCurrency } = this.state;

    const { clientData, redeemOffers, fromMembership } = this.props;
    if (!clientData.size) return null;
    const balance = fromMembership.memberDetails.balance || 0;
    const offersOfCurrency = redeemOffers.get(selectedCurrency);
    // Highest redemption possible is the default highlighted/selected option
    const highestPointsCanRedeem = offersOfCurrency.map(denomination => denomination.get('points')).reduce((acc, curr) => curr < balance ? curr : acc, 0);
    return offersOfCurrency.map(pairs => {
      const isSelected = !selectedDenomination.get('points') ? pairs.get('points') === highestPointsCanRedeem : pairs.get('points') === selectedDenomination.get('points');
      const denomination = {
        ...pairs.toJSON(),
        disabled: pairs.get('points') > balance,
        selected: isSelected
      };
      return Map(denomination);
    });
  };

  setRedemptionAmount = denomination => {
    this.setState({ selectedDenomination: denomination });
  };

  renderRedemptionWidget = () => {
    const { clientData, redeemOffers, fromMembership } = this.props;
    const { selectedCurrency } = this.state;
    const wpCode = clientData.get('wpCode');
    const widgetData = {
      redemptionDenominations: this.getRedemptionDenominationData(),
      balance: fromMembership ? fromMembership.memberDetails.balance : 0,
      minBalance: redeemOffers.toJSON()[selectedCurrency][0].points,
      wallet: wpCode,
      logo: getLogoPath(wpCode),
      setRedemptionAmount: this.setRedemptionAmount
    };
    return (
      <div className='redeem__redemptionDenominations section'>
        <span className='redeem__redemptionDenominations__description'>
          <div className='redeem__redemptionDenominations__number'>
            <FormattedMessage id='redeem.offer.redemption.two' />
          </div>
          <div className='redeem__redemptionDenominations__text'>
            <FormattedMessage id='redeem.offer.redemption.info' />
          </div>
        </span>
        <RedemptionAmountWidget {...widgetData} />
      </div>
    );
  };

  getCountriesAsOptions = () => {
    const { clientData, redeemOffers } = this.props;
    if (!clientData.size) return null;
    const redemptionCountryDetails = clientData.get('redemptionCountryDetails').toJSON();
    return redemptionCountryDetails.map((country, index) => ({
      id: country.currency,
      label: `redeem.offer.country.${country.currency.toLowerCase()}`,
      hasFancyLabel: true,
      isDefault: index === 0,
      disabled: country.disabled || !redeemOffers.get(country.currency).size
    }));
  };

  setSelectedCurrency = selectedCurrency => {
    this.setState({ selectedDenomination: fromJS({}), selectedCurrency });
  };

  getCountrySelection = () => {
    const countryOptions = fromJS(this.getCountriesAsOptions());
    return (<RedeemCountrySelection
      countryOptions={countryOptions}
      optionName='country'
      setSelectedCurrency={this.setSelectedCurrency}
      useFieldsets={false}
    />);
  };

  getPhoneNumberOptions = () => {
    const { clientData } = this.props;
    if (!clientData.size) return null;
    const recipients = clientData.get('redemptionRecipients').toJSON();
    return recipients.map((recipient, index) => ({
      id: recipient.type,
      label: `redeem.offer.recipient.${recipient.type.toLowerCase()}`,
      hasFancyLabel: true,
      isDefault: index === 0,
      disabled: recipient.disabled
    }));
  };

  setPhoneNumberOption = recipient => {
    this.setState({ isSendingToSelf: recipient === 'self' });
  };

  setPhoneNumber = e => {
    const { name, value } = e.target;
    const digitsAndHyphensPattern = new RegExp(/^[\d\-]*$/);
    const fullPhonePattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    if (!digitsAndHyphensPattern.test(value)) return;
    else if (fullPhonePattern.test(value)) return this.formatAndSetNumber(value, [name]);
    this.setState({ [name]: value });
  };

  formatAndSetNumber = (value, [name]) => {
    const regex = /\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g;
    const format = '$1-$2-$3';
    const formattedNumber = value.replace(regex, format);
    this.setState({
      [name]: formattedNumber,
      isPhoneNumFormatCorrect: true
    });
  }

  checkNumFormat = e => {
    const { value } = e.target;
    const pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    this.setState({ isPhoneNumFormatCorrect: pattern.test(value) });
  }

  renderSendingToSelfCondition = () => (
    <div className='redeemForm__recipientConditions'>
      <FormattedMessage id='redeem.offer.recipient.self.conditions' />
      <FormattedMessage id='redeem.offer.recipient.self.conditions-2' />
    </div>
  );

  renderSendingToOthersCondition = () => (
    <div className='redeemForm__recipientConditions'>
      <FormattedMessage id='redeem.offer.recipient.friendsAndFamily.conditions' />
    </div>
  );

  renderNumFormatErrMsg = () => (
    <div id='redeemForm__recipient__phoneNumber__errMsg'>
      <FormattedMessage id='redeem.offer.recipient.numberErr' />
    </div>
  )

  handleTCsCheckboxChange = () => {
    this.setState({ isTCCheckboxChecked: !this.state.isTCCheckboxChecked });
  }

  handleFullOfferCheckboxChange = () => {
    this.setState({ isFullOfferTermsChecked: !this.state.isFullOfferTermsChecked });
  }

  toggleModal = e => {
    e.preventDefault();
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  renderPhoneNumberSelection = () => (
    <RedeemRecipientSection
      getPhoneNumberOptions={this.getPhoneNumberOptions}
      isSendingToSelf={this.state.isSendingToSelf}
      isPhoneNumFormatCorrect={this.state.isPhoneNumFormatCorrect}
      phoneNumber={this.state.phoneNumber}
      setPhoneNumberOption={this.setPhoneNumberOption}
      setPhoneNumber={this.setPhoneNumber}
      checkNumFormat={this.checkNumFormat}
      useFieldsets={false}
      renderNumFormatErrMsg={this.renderNumFormatErrMsg}
      renderSendingToSelfCondition={this.renderSendingToSelfCondition}
      renderSendingToOthersCondition={this.renderSendingToOthersCondition}
    />
  );

  renderCheckBoxes = () => {
    const { isTCCheckboxChecked, isFullOfferTermsChecked } = this.state;
    return (
      <EarnRedeemCheckBoxes
        isTCCheckboxChecked={isTCCheckboxChecked}
        isFullOfferTermsChecked={isFullOfferTermsChecked}
        handleTCsCheckboxChange={this.handleTCsCheckboxChange}
        handleFullOfferCheckboxChange={this.handleFullOfferCheckboxChange}
        containerClassName='earnAndRedeem__redeem__pointsTCs__container'
      />
    );
  }

  renderModal = () => {
    const {
      isModalOpen,
      selectedDenomination,
      selectedCurrency,
      phoneNumber,
      isSendingToSelf
    } = this.state;

    const redemptionDetails = {
      currencyCode: selectedCurrency,
      selectedDenomination,
      phoneNumber,
      isSendingToSelf
    };

    if (isModalOpen) {
      return (
        <div className='redeem__modal__overlay'>
          <div className='redeem__modal__container'>
            <button id='redeem__modal__closeBtn' onClick={this.toggleModal}>x</button>
            <div className='redeem__modal__content'>
              <RedeemModalContent
                redemptionDetails={redemptionDetails}
                toggleModal={this.toggleModal}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  renderRedeemBtn = () => {
    const { fromMembership } = this.props;
    const {
      selectedDenomination,
      selectedCurrency,
      isSendingToSelf,
      phoneNumber,
      isPhoneNumFormatCorrect,
      isTCCheckboxChecked,
      isFullOfferTermsChecked
    } = this.state;

    const areFieldsValid =
    selectedDenomination.size
    && selectedCurrency !== ''
    && typeof isSendingToSelf !== 'undefined'
    && phoneNumber !== ''
    && isPhoneNumFormatCorrect
    && isTCCheckboxChecked
    && isFullOfferTermsChecked
    && Object.keys(fromMembership).length > 0;

    return (
      <button
        id='redeem__form__redeemBtn'
        disabled={!areFieldsValid}
        onClick={this.toggleModal}
      >
        <FormattedMessage id='redeem.offer.redeemBtn.text' />
      </button>
    );
  }

  renderParentUI = () => {
    const heroText = {
      header: 'redeem.hero.header',
      subheading: 'redeem.hero.subheader'
    };
    return (
      <div className='redeem earnAndRedeem'>
        {this.renderHero(heroText)}
        <div className='redeem__contentWrapper'>
          {this.renderUserInformation()}
          <div className='redeem__form redeemForm'>
            {this.getCountrySelection()}
            {this.renderRedemptionWidget()}
            {this.renderPhoneNumberSelection()}
            <div className='redeem__form__checkboxAndBtn'>
              {this.renderCheckBoxes()}
              {this.renderRedeemBtn()}
            </div>
          </div>
          {this.renderModal()}
          <EarnRedeemFooter />
        </div>
      </div>
    );
  };

  render() {
    const { clientData, promoData, redeemOffers, children,
      successfulRedeemTransaction, user, push, partnerLpId } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        clientData,
        partnerLpId,
        renderHero: this.renderHero,
        successfulRedeemTransaction,
        push
      }));

    if (promoData.isEmpty() || !redeemOffers.size || !user.size) {
      return <Spinner />;
    } else if (children) {
      return <div className='redeem earnAndRedeem'>{ childrenWithProps }</div>;
    } return this.renderParentUI();
  }
}

UnconnectedRedeemFlow.propTypes = {
  clientData: ImmutablePropTypes.map,
  locale: PropTypes.string,
  messages: ImmutablePropTypes.map,
  partnerLpId: PropTypes.string,
  promoData: ImmutablePropTypes.map,
  fetchClientPromos: PropTypes.func,
  fetchRedeemOffers: PropTypes.func,
  user: ImmutablePropTypes.map,
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  redeemOffers: ImmutablePropTypes.map,
  isFetchingRedeemOffers: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  successfulRedeemTransaction: ImmutablePropTypes.map,
  push: PropTypes.func
};

const mapStateToProps = state => ({
  clientData: state.app.get('clientData'),
  locale: state.app.get('locale'),
  messages: state.app.get('messages'),
  partnerLpId: state.app.get('partnerLpId'),
  promoData: state.earn.get('promoData'),
  user: state.user.get('data'),
  memberships: state.memberships.get('data'),
  redeemOffers: state.redeem.get('redeemOffers'),
  isFetchingRedeemOffers: state.redeem.get('isFetchingRedeemOffers'),
  isLoggedIn: state.auth.get('isLoggedIn'),
  successfulRedeemTransaction: state.redeem.get('successfulRedeemTransaction'),

  // TODO: Redeem Flow and Earn Flow to use immutable data types only
  fromMembership: selectMembershipByPartnerLpId(state)?.toJSON?.()
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchClientPromos,
  fetchRedeemOffers,
  push
}, dispatch);

export const RedeemFlowContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedRedeemFlow);
