export const isValidOffer = (offer, categoryOfCall) => {
  if (offer.available) {
    const fromCategory = offer.available[0].fromCategory || 'from';
    const toCategory = offer.available[0].toCategory || 'to';
    return fromCategory === toCategory && fromCategory === categoryOfCall;
  }
  return false;
};

export const containsAllOffers = (offers, expectedLastKey, expectedLength) =>
  offers[expectedLastKey] && Object.keys(offers).length === expectedLength;
