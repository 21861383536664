import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import appUtils from '../utils/url';
import { generateExternalCredentialCatcherUrl } from '../components/utils/credential-catcher-helpers';
import constants from '../utils/constants';
import { getTabIndex, shouldBeHiddenFromVoiceOver } from './utils/accessibility-helpers';
import { getPosition, isElementInViewport, scrollTo } from '../utils/dom';

import { FormattedMessage } from 'react-intl';
import { formatNumber } from '../utils/string';
import { shouldHideMemberData } from './utils/wps-helpers';
import { isFeatureEnabled } from './utils/react-component-helpers';
import { getLogoPath } from './utils/image-helpers';
import { Map } from 'immutable';

class MembershipCard extends React.Component {
    exchangeLinkEl = null;

    membershipCardEl = null;
    membershipCardLogoEl = null;

    membershipCardMenuEl = null;
    pendingBalanceEl = null;
    registerLinkEl = null;
    removeLpLinkEl = null;

    componentDidUpdate() {
      this.focusMembershipCardHelper();
    }

    getAccountIdLabel = (membership, identifyingFactor) => {
      const accountId = membership.getIn(['credentials', identifyingFactor], '');
      return accountId;
    };


    focusMembershipCardHelper = () => {
      const { isSelected, device } = this.props;
      const isPalmDevice = device === 'palm';
      if (!isSelected) return;

      if (!isPalmDevice) {
        setTimeout(() => {
          if (this.registerLinkEl) {
            this.registerLinkEl.focus();
          } else if (this.exchangeLinkEl) {
            this.exchangeLinkEl.focus();
          } else if (this.removeLpLinkEl) {
            this.removeLpLinkEl.focus();
          }
        }, constants.baseAnimationLength);
      } else {
        const membershipCardMenu = this.membershipCardMenuEl;
        const position = getPosition(membershipCardMenu);
        const newScrollPosition = window.scrollY + position.y - 200;
        setTimeout(() => {
          if (!isElementInViewport(membershipCardMenu)) {
            scrollTo(document.body, newScrollPosition, constants.baseAnimationLength);
          }
        }, constants.baseAnimationLength);
      }
    };

    goToExchange = () => {
      const { membership, resetExchange, clientData, lp, push } = this.props;
      this.triggerAnalyticsEvent(constants.ANALYTICS_GOTO_EXCHANGE);
      resetExchange();
      const clientLpId = clientData.get('clientLpId');
      const isClientLpId = membership.get('lpId') === clientLpId;
      const direction = clientLpId && !isClientLpId ? 'to' : 'from';
      const lpId = lp.get('id');
      const membershipParam = membership && membership.size ? `&${direction}MembershipId=${membership.get('id')}` : '';
      push(`/exchange/configure?${direction}LpId=${lpId}${membershipParam}`);
    };

    goToRegister = () => {
      const { membership, user, lp } = this.props;

      switch (user.get('role')) {
        case constants.USER_ROLE_GUEST:
          this.triggerAnalyticsEvent(constants.ANALYTICS_GOTO_SIGNUP);
          break;
        case constants.USER_ROLE_USER:
          this.triggerAnalyticsEvent(constants.ANALYTICS_GOTO_REGISTER, {
            event: 'BLWVirtualPageview',
            virtualPageURL: '/wallet/membership/register',
            virtualPageTitle: 'wallet.membership.register'
          });
          break;
      }

      const wpLpConfig = lp.getIn(['config', 'credentialCatcher'], Map());
      const credentialCatcherType = wpLpConfig.get('enabled');

      if (credentialCatcherType === 'external') {
        const externalConfig = wpLpConfig.get(credentialCatcherType);
        const membershipId = membership.get('id');
        const url = generateExternalCredentialCatcherUrl(
          externalConfig,
          this.props.userMemberValidationUrl,
          window.location.origin,
          membershipId
        );

        appUtils.openNewWindow(url);
      } else {
        this.props.openMembershipRegistrationForm(membership);
      }
    };

    hasPendingActivity = () => {
      const countPendingOrdersByDirection = direction => {
        const { pendingActivity, lp, membership } = this.props;
        const membershipId = membership.get('id');
        return pendingActivity.count(order => {
          const hasCorrectLpId = lp.get('id') === order.getIn([direction, 'lpId']);
          const hasCorrectMembershipId = !membershipId || membershipId === order.getIn([direction, 'membershipId']);
          return hasCorrectMembershipId && hasCorrectLpId && this.isOrderPending(order);
        });
      };

      return countPendingOrdersByDirection('from') || countPendingOrdersByDirection('to');
    };

    formatBalanceLabel = balance => {
      const { formatter } = this.props;
      if (typeof balance === 'number') {
        const balanceLabel = balance.toLocaleString();
        return balanceLabel;
      }
      return formatter.formatNumber(balance);
    };

    isOrderPending = order => order.get('status') === constants.orderStatus.pending

    hasMembershipLevel = membership => !!membership.getIn(['memberDetails', 'membershipLevel']);

    triggerAnalyticsEvent = (action, data) => {
      const { lp, membership, googleTagManager } = this.props;
      googleTagManager.pushGtmData(Object.assign({
        action,
        context: 'balanceTracker',
        lpName: `${lp.getIn(['content', 'companyName'])} ${lp.get('name')}`,
        lpId: lp.get('id'),
        isRegistered: membership.get('isRegistered')
      }, data));
    };

    setAndFollowLink = shouldBeClickable => {
      if (shouldBeClickable) return this.goToRegister();
    }

    shouldBeClickable = (memberDetailsStatus, isClientLpId) => (
      memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_UNREGISTERED ||
        memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_ERROR ||
        (!isClientLpId)
    );

    shouldShowExchangeLink = () => {
      const { clientData } = this.props;
      return isFeatureEnabled(constants.product.exchange, clientData.get('enabledFeatures'));
    }

    getIdentifyingFactorForAccountIdLabel = () => {
      const { clientData, membership } = this.props;
      const lpId = membership.get('lpId');
      const lpsThatRenderAnotherValueThanMemberId = clientData.get('replaceMemberIdInMembershipCard') ? clientData.get('replaceMemberIdInMembershipCard').toJSON() : {};
      if (lpsThatRenderAnotherValueThanMemberId[lpId]) return lpsThatRenderAnotherValueThanMemberId[lpId];
      return 'identifyingFactors.memberId';
    };

    renderDesktopMembershipCard = () => {
      const { clientData, lp, membership, isShowingSlide, formatter } = this.props;
      const isClientLpId = membership.get('lpId') === clientData.get('clientLpId');
      const memberDetailsStatus = membership.get('memberDetailsStatus');
      const membershipLevel = membership.getIn(['memberDetails', 'membershipLevel']) || '';
      const tabIndexValue = getTabIndex(isShowingSlide);
      const identifyingFactor = this.getIdentifyingFactorForAccountIdLabel();
      const accountIdLabel = this.getAccountIdLabel(membership, identifyingFactor);
      const shouldBeClickable = this.shouldBeClickable(memberDetailsStatus, isClientLpId);

      const unregisteredCircle = className => {
        const selectToRegisterAriaLabel = formatter.formatMessage({ id: 'membershipcard.registerAriaLabel' }, { lpName: membership.get('lpName') });
        return (
          <div
            aria-label={selectToRegisterAriaLabel}
            role='button'
            tabIndex={tabIndexValue}
            className={classnames('circle', className)}
            onClick={e => {
              e.stopPropagation();
              this.goToRegister();
            }}
            ref={el => this.registerLinkEl = el}
          >
            <div className='circle__content circle__content--smaller-font'>
              <FormattedMessage id='membershipcard.circleMessage' />
            </div>
          </div>
        );
      };

      const circleWithError = className => {
        const selectAriaLabel = formatter.formatMessage({ id: 'membershipcard.selectAriaLabel' }, { lpName: membership.get('lpName') });
        return (
          <div
            aria-label={selectAriaLabel}
            role='button'
            tabIndex={tabIndexValue}
            className={classnames('circle', className)}
            onClick={e => {
              e.stopPropagation();
              this.goToRegister();
            }}
            ref={el => this.registerLinkEl = el}
          >
            <div className='circle__content circle__content--smaller-font'>
              <FormattedMessage id='membershipcard.circleMessageError' />
            </div>
          </div>
        );
      };

      const circleWithMembershipBalance = className => {
        const balance = membership.getIn(['memberDetails', 'balance']);
        const ariaLabel = formatter.formatMessage({ id: 'membershipcard.registeredProgram' }, {
          balance,
          lpName: membership.get('lpName')
        });
        const shouldHideBalance = shouldHideMemberData(clientData, lp.get('id'));
        const hiddenBalanceLabel = formatter.formatMessage({ id: 'membershipcard.hiddenBalanceLabel' });
        const balanceLabel = this.formatBalanceLabel(balance);
        const registeredProgramLabel = shouldHideBalance ? hiddenBalanceLabel : balanceLabel;
        return (
          <div
            aria-label={ariaLabel}
            role='button'
            tabIndex={tabIndexValue}
            className={classnames('circle circle--with-sub-content', className)}
          >
            <div className={classnames('circle__content', { 'text-size-big': shouldHideBalance })}>
              {registeredProgramLabel}
              {
                !shouldHideBalance && (
                  <div className='circle__sub-content'>
                    {lp.getIn(['content', 'currencyNameShort'])}
                  </div>
                )
              }
            </div>
          </div>
        );
      };

      return (
        <div
          className={shouldBeClickable ? 'membership-card-desk' : 'membership-card-desk__non-clickable'}
          onClick={e => {
            e.stopPropagation();
            this.setAndFollowLink(shouldBeClickable);
          }}
        >
          <div className='u-padding-small u-padding-horizontal-large u-padding-bottom-none'>
            {this.renderLpLogo()}
          </div>
          <div className='u-margin-bottom-small'>
            {
              (() => {
                switch (memberDetailsStatus) {
                  case constants.MEMBER_DETAILS_STATUS_UNREGISTERED:
                    return unregisteredCircle('circle--action');
                  case constants.MEMBER_DETAILS_STATUS_PENDING:
                    return circleWithMembershipBalance('circle--muted');
                  case constants.MEMBER_DETAILS_STATUS_SUCCESS:
                    return circleWithMembershipBalance('circle--info');
                  case constants.MEMBER_DETAILS_STATUS_ERROR:
                    return circleWithError('circle--error');
                }
              })()
            }

            {!membership.size ? unregisteredCircle('circle--action') : null}

          </div>

          {this.renderPendingBalance()}
          {this.renderPendingActivity()}
          {
            this.hasMembershipLevel(membership) ? (
              <div className='membership-card__member-details flex flex-wrap'>
                <div
                  className='fs-exclude membership-card__member-id membership-card__memberId-text u-padding-horizontal-small u-margin-bottom-small text-center text-size-small'
                >{accountIdLabel}
                </div>
                <div
                  className='membership-card__membership-level u-padding-horizontal-small u-margin-bottom-small text-center text-size-small'
                >{membershipLevel}
                </div>
              </div>
            ) :
              <div
                className='fs-exclude membership-card__memberId-text u-padding-horizontal-small u-padding-bottom-small u-margin-bottom-small text-center text-size-small'
              >
                {`${accountIdLabel}`}
              </div>
          }

          {
            this.shouldShowExchangeLink() ? (
              <div
                className='membership-card__footer text-size-small u-padding-vertical-small arrow_box'
                onClick={e => {
                  e.stopPropagation();
                  this.goToExchange();
                }}
                ref={el => this.exchangeLinkEl = el}
              >
                {this.renderMembershipCardFooter()}
              </div>
            ) : null
          }
        </div>
      );
    };

    renderMembershipCardFooter = () => (
      <FormattedMessage id='membershipcard.makeExchange' />
    );

    renderLpLogo = () => {
      const { lp } = this.props;
      const logoName = `${lp.get('name')} logo`;
      const logoCode = lp.getIn(['content', 'code']);
      return (<img
        ref={el => this.membershipCardLogoEl = el}
        className='membership-card__img u-1/1'
        alt={logoName}
        src={getLogoPath(logoCode)}
      />);
    };

    renderPendingActivity = () => {
      const { lp, getEarnOrdersWithPendingMembershipSelection } = this.props;
      if (this.hasPendingActivity() <= 0) {
        return;
      }
      const pendingSelection = getEarnOrdersWithPendingMembershipSelection(lp.get('id'));
      return (
        <div className='text-center text-size-small'>
          {pendingSelection.size ? this.renderUpdateAccountInfoMessage() : null}
          <div className='u-padding-vertical-small u-bt text-color-alt1'>
            <FormattedMessage
              id='membershipcard.transactionsPendingActivity'
              values={{
                pendingActivity: this.hasPendingActivity()
              }}
            />
          </div>
        </div>
      );
    };

    renderPendingBalance = () => {
      const { lp, pendingEarnOrders, membership } = this.props;

      if (membership.get('isRegistered')) {
        return;
      }

      const pendingOrders = pendingEarnOrders.filter(order => order.getIn(['to', 'lpId']) === lp.get('id'));

      const pendingBalance = pendingOrders.map(order => order.getIn(['to', 'amount'])).reduce((sum, x) => sum + x, 0);
      if (!pendingBalance) {
        return;
      }

      return (
        <div
          className='text-center u-padding-small u-padding-top-none text-size-small'
          ref={el => this.pendingBalanceEl = el}
        >
          <FormattedMessage
            id='membershipcard.pendingBalanceCard'
            values={{
              balance: formatNumber(pendingBalance),
              currency: lp.getIn(['content', 'currencyNameLong'])
            }}
          />
        </div>
      );
    };

    renderUpdateAccountInfoMessage = () => {
      const { lp, formatter, openEarnMembershipSelection } = this.props;
      const selectMembershipForPendingEarnAriaLabel = formatter.formatMessage({ id: 'membershipcard.selectMembershipForPendingEarnAriaLabel' }, { lpName: lp.get('name') });
      const modalWillOpenString = formatter.formatMessage({ id: 'accessibility.modalWillOpen' });
      const lpId = lp.get('id');
      return (
        <div className='u-bt'>
          <button
            className='btn-unstyled u-padding-vertical-small text-color-error'
            aria-label={`${selectMembershipForPendingEarnAriaLabel}. ${modalWillOpenString}`}
            onClick={e => {
              e.stopPropagation();
              openEarnMembershipSelection(lpId);
            }}
            onKeyUp={e => {
              // This key handler could be removed if we don't explicitly handle the enter key press on the card menu (i.e. use semantic HTML instead)
              e.stopPropagation();
            }}
          >
            <FormattedMessage
              id='membershipcard.selectMembershipForPendingEarn'
              values={{
                lpName: lp.get('name')
              }}
            />
            <span className='icon-chevron-right u-margin-left-tiny' />
          </button>
        </div>
      );
    };

    render() {
      const { isShowingSlide, lp, membership, preferredLps } = this.props;
      const isPreferred = preferredLps.indexOf(lp) > -1;
      const memberDetailsStatus = membership.get('memberDetailsStatus');
      const isMembershipPending = memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_PENDING;
      const tabIndexValue = getTabIndex(isShowingSlide);

      return (
        <div
          aria-hidden={shouldBeHiddenFromVoiceOver(tabIndexValue)}
          ref={el => this.membershipCardEl = el}
          className={classnames('membership-card u-margin-bottom', {
            'membership-card--loading': isMembershipPending,
            'membership-card--preferred': isPreferred
          })}
        >
          {this.renderDesktopMembershipCard()}
        </div>
      );
    }
}

MembershipCard.propTypes = {
  isSelected: PropTypes.bool,
  device: PropTypes.string,
  isShowingSlide: PropTypes.bool,
  lp: ImmutablePropTypes.map,
  membership: ImmutablePropTypes.map,
  preferredLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  user: ImmutablePropTypes.map,
  pendingActivity: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  pendingEarnOrders: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  googleTagManager: PropTypes.object,
  getEarnOrdersWithPendingMembershipSelection: PropTypes.func,
  openEarnMembershipSelection: PropTypes.func,
  openMembershipRegistrationForm: PropTypes.func,
  resetExchange: PropTypes.func,
  push: PropTypes.func,
  userMemberValidationUrl: PropTypes.string
};

export default MembershipCard;
