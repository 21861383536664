const RUN_LOOP_TIMEOUT_DURATION = 200;

let parentURL;
let runLoopTimeout;
let containerEl;
let headerEl;
let containerElHeight;
let headerElHeight;
let isRunningRunLoop = false;
let _initialContainerEl;

function init(el, isIframed, _parentUrl, _headerEl) {
  if (!el) {
    return;
  }
  containerEl = el;
  _initialContainerEl = el;
  containerElHeight = el.scrollHeight;
  if (isIframed) {
    parentURL = document.referrer || _parentUrl;
  }
  if (_headerEl) {
    headerElHeight = _headerEl.scrollHeight;
    headerEl = _headerEl;
  }
}

function initIframeRunLoop() {
  if (!isRunningRunLoop) {
    _startRunLoop();
    isRunningRunLoop = true;
  }
}

function scrollParentFrameToTop() {
  _sendMessage(_scrollToTop());
}

function postParentExchangeSuccessful() {
  _sendMessage(_transactionExchangeSuccessful());
}

function swapContainerElement(newEl) {
  const _oldContainerEl = containerEl;
  containerEl = newEl;
  return {
    restore: () => {
      containerEl = _oldContainerEl;
    }
  };
}

export default {
  isRunning: () => isRunningRunLoop,
  init,
  scrollParentFrameToTop,
  postParentExchangeSuccessful,
  initIframeRunLoop,
  swapContainerElement
};

/**-----------------------------
 *     Actions
 * ----------------------------*/

function _updateHeight(height) {
  return {
    type: 'UPDATE_HEIGHT',
    height
  };
}

function _scrollToTop() {
  return {
    type: 'SCROLL_TO_TOP'
  };
}

function _transactionExchangeSuccessful() {
  return {
    type: 'TRANSACTION_EXCHANGE_SUCCESS'
  };
}

/**-----------------------------
 *     Private Methods
 * ----------------------------*/

function _startRunLoop() {
  (function runLoop() {
    _checkHeight();
    if (runLoopTimeout) {
      clearTimeout(runLoopTimeout);
    }
    runLoopTimeout = setTimeout(runLoop, RUN_LOOP_TIMEOUT_DURATION);
  }());
}

function _getTotalHeight(height) {
  return headerEl ? headerElHeight + height : height;
}

function _checkHeight() {
  if (!containerEl || !containerEl.scrollHeight) {
    // This is in case we swapped the containerElement, and didn't restore it. If we unmount the swapped node its height is 0.
    containerEl = _initialContainerEl;
  }
  const height = containerEl.scrollHeight;
  if (containerElHeight !== height) {
    _sendMessage(_updateHeight(_getTotalHeight(height)));
  }
  containerElHeight = height;
}

function _sendMessage(message) {
  if (!parentURL) { return; }

  if (window.webkit && webkit.messageHandlers.blwPostMessage) {
    webkit.messageHandlers.blwPostMessage.postMessage(message);
    return;
  } else if (window.Android && Android.showToast) {
    Android.showToast(message);
    return;
  }

  window.parent.postMessage(message, parentURL);
}
