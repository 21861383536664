import { Map, fromJS } from 'immutable';
import iframeManager from '../utils/iframe-manager';
import googleTagManager from '../utils/google-tag-manager';
import constants from '../utils/constants';

const initialData = Map({
  appError: Map(constants.APP_ERROR_DEFAULT),
  clientData: Map(),
  device: '',
  formatter: null,
  googleTagManager,
  isShowingSlide: false,
  isShowingModal: false,
  iframeManager,
  locale: constants.defaultLocale,
  messages: Map(),
  pageMessage: Map(),
  productType: '',
  partnerLpId: null,
  requireRegistration: false,
  modalContentKey: null
});

export default function (state = initialData, action = {}) {
  switch (action.type) {
    case 'SET_CLIENT_DATA':
      return state.set('clientData', fromJS(action.data));

    case 'SET_PAGE_MESSAGE':
      return state.set('pageMessage', fromJS(action.pageMessage || {}));

    case 'SET_LOCALE':
      return state.set('locale', action.locale);

    case 'SET_MESSAGES':
      return state.set('messages', fromJS(action.messages));

    case 'SET_DEVICE':
      return state.set('device', action.device);

    case 'SET_IS_SHOWING_SLIDE':
      return state.set('isShowingSlide', action.isShowingSlide);

    case 'SET_IS_SHOWING_MODAL':
      return state.set('isShowingModal', action.isShowingModal);

    case 'SET_INTL_FORMATTER':
      return state.set('formatter', action.formatter);

    case 'SET_APP_ERROR':
      return state.set('appError', fromJS(action.appError || initialData.get('appError')));

    case 'SET_PRODUCT_TYPE':
      return state.set('productType', action.productType);

    case 'SET_PARTNER_LP_ID':
      return state.set('partnerLpId', action.partnerLpId);

    case 'SET_SHOW_CREATE_ACCOUNT':
      return state.set('showCreateAccount', action.showCreateAccount);

    case 'SET_REQUIRE_REGISTRATION':
      return state.set('requireRegistration', action.requireRegistration);

    case 'SET_SHOW_MEMBERSHIP_REGISTRATION':
      return state.set('showMembershipRegistration', action.showMembershipRegistration);

    case 'SET_MODAL_CONTENT_KEY':
      return state.set('modalContentKey', action.modalContentKey);

    default:
      return state;
  }
}
