import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import { getLogoPath } from './utils/image-helpers';

class PromoCard extends React.Component {
  goToExchange = () => {
    const { lp, push, toMembershipId } = this.props;
    const lpId = lp.get('id');
    const membershipParam = toMembershipId ? `&toMembershipId=${toMembershipId}` : '';
    push(`/exchange/configure?toLpId=${lpId}${membershipParam}`);
  };

  renderLpLogo = () => {
    const { lp } = this.props;
    const logoName = `${lp.get('name')} logo`;
    const lpCode = lp.getIn(['content', 'code']);

    return (<img
      className='promo-card__img u-1/1'
      alt={logoName}
      src={getLogoPath(lpCode)}
    />);
  };

  render() {
    const { promo } = this.props;

    return (
      <button
        className='promo-card u-margin-bottom'
        onClick={this.goToExchange}
      >
        <div className='promo-card__logo u-padding-small u-padding-horizontal-large u-padding-bottom-none'>
          {this.renderLpLogo()}
        </div>
        <div className='promo-card__body u-padding-small'>
          <h2>{ promo.get('balanceTrackerHeader') }</h2>
          <p>
            { promo.get('balanceTrackerBody') }
          </p>
        </div>
        <div className='promo-card__footer u-padding-left-small'>
          <p><FormattedMessage id='promoCard.makeExchange' /></p>
        </div>
      </button>
    );
  }
}

PromoCard.propTypes = {
  lp: ImmutablePropTypes.map,
  promo: ImmutablePropTypes.map,
  push: PropTypes.func,
  toMembershipId: PropTypes.string
};

export default PromoCard;
