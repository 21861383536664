import { fromJS } from 'immutable';

const initialState = fromJS({
  allWps: [],
  disableBlwProgramAddition: false
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_ALL_WPS': {
      const { allWps } = action;
      const wps = fromJS(allWps || []);
      let newState = state.set('allWps', wps);
      const wp = wps.find(wp => wp.get('isCurrentWp'));
      if (wp) {
        newState = newState.set('disableBlwProgramAddition', wp.get('disableBlwProgramAddition'));
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}
