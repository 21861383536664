import React from 'react';

const Checkbox = props => (
  <form className='earn__checkbox'>
    <input
      type='checkbox'
      name={props.name}
      checked={props.checked}
      onChange={() => props.onChange(props.name, props.checked)}
    />
  </form>
);

export default Checkbox;
