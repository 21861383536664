import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

let lastId = 0;

class TermsAndConditions extends React.Component {
  getListItem = (item, getBulletNumber) => {
    function _getBulletNum() {
      if (getBulletNumber) {
        getBulletNumber();
      }
    }

    if (typeof item === 'string') {
      return (
        <li
          value={_getBulletNum()}
          className='u-margin-bottom'
          key={this.getListKey()}
          dangerouslySetInnerHTML={this.createMarkup(item)}
        />
      );
    } else if (typeof item === 'object') {
      return <li className='list--bare' key={this.getListKey()}>{ this.getMarkup(item) }</li>;
    }
  };

  getListKey = () => {
    lastId += 1;
    return `terms-${lastId}`;
  };

  getMarkup = message => {
    const { content } = message;
    switch (message.type) {
      case 'header1':
        return <h1 key={this.getListKey()} dangerouslySetInnerHTML={this.createMarkup(content)} />;
      case 'header2':
        return <h2 key={this.getListKey()} dangerouslySetInnerHTML={this.createMarkup(content)} />;
      case 'section':
        return <p key={this.getListKey()} dangerouslySetInnerHTML={this.createMarkup(content)} />;
      case 'olist': {
        let bullet = 1;
        const getBulletNumber = () => bullet += 1;
        return (
          <ol key={this.getListKey()} className='u-margin-left-small u-padding-left-none'>
            {content.map(item => this.getListItem(item, getBulletNumber))}
          </ol>
        );
      }
      case 'charList':
        return (
          <ol key={this.getListKey()} type='a' className='u-margin-left-small u-padding-left-none'>
            {content.map(item => this.getListItem(item))}
          </ol>
        );
    }
  };

  createMarkup = text => ({ __html: text });

  render() {
    return (
      <div className='terms-and-conditions'>
        <div className='termsAndConditions__header'>
          <FormattedMessage id='createAccount.termsAndConditions' />
        </div>
        {this.props.messages.get('termsAndConditions').toJSON().map(message => this.getMarkup(message))}
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  messages: ImmutablePropTypes.map
};

function mapStateToProps(state) {
  return {
    messages: state.app.get('messages')
  };
}

export const TermsAndConditionsContainer = connect(mapStateToProps)(TermsAndConditions);
