import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { FormattedHTMLMessage } from 'react-intl';
import { setIsShowingSlide } from '../action-creators/app';
import FAQ from './FAQ';
import EarnTcs from './EarnTcs';
import ActionLink from './ActionLink';
import FullOfferTerms from './FullOfferTerms';
import FocusTrap from './FocusTrap';
import Slide from './Slide';

class UnconnectedEarnRedeemFooter extends Component {
    state = {
      earnPromoSlideContent: Map(),
      toggleSlide: 0
    }

    setEarnPromoSlideContent = content => {
      const { setIsShowingSlide } = this.props;
      if (content) {
        this.setState({ toggleSlide: this.state.toggleSlide + 1 });
        // This happens every time we want to show the slide (we forced the slide to be hidden with the shouldHideOnLoad hack, so isShowingSlide is never updated)
        // To fix this, we correct the omited call to setIsShowingSlide
        setIsShowingSlide(true);
      }
      this.setState({ earnPromoSlideContent: Map(content) });
    };

    showFaqModal = () => {
      const { messages, promoData, clientData } = this.props;
      const showFAQByCategory = clientData.get('showFAQByCategory');
      this.setEarnPromoSlideContent({
        header: 'earn.faqs.header',
        description:
  <FAQ
    faqs={messages.get('earn.faqs.description').toJSON()}
    footnote={promoData ? `*${promoData.get('restrictions')}` : ''}
    showFAQByCategory={showFAQByCategory}
  />,
        disableHtmlFormatting: true
      });
    };

    showFullOfferTerms = () => {
      const { messages } = this.props;
      this.setEarnPromoSlideContent({
        header: 'earn.fullOfferTerms.slide.header',
        description: <FullOfferTerms terms={messages.get('earn.fullOfferTerms.description').toJSON()} />,
        disableHtmlFormatting: true
      });
    };

    showPointsTCsModal = () => {
      const { messages } = this.props;
      this.setEarnPromoSlideContent({
        header: 'earn.pointsTCs',
        description: <EarnTcs tcs={messages.get('earn.pointsTCsTerms')} />,
        disableHtmlFormatting: true
      });
    };

    showPointsPrivacyPolicyModal = () => {
      const { messages } = this.props;
      this.setEarnPromoSlideContent({
        header: 'earn.points.privacyPolicy.header',
        description: <EarnTcs tcs={messages.get('points.privacyPolicy')} />,
        disableHtmlFormatting: true
      });
    };

    showRestrictionsModal = () => {
      const { promoData } = this.props;
      if (!promoData || !promoData.size) return;

      this.setEarnPromoSlideContent({
        header: 'earn.howItWorks.shop.modal.header',
        description: promoData.get('restrictions'),
        disableHtmlFormatting: true
      });
    };

    renderTCs = () => {
      const { promoData } = this.props;
      return (
        <div className='section section--compact text-left u-bt u-padding-top-small'>
          <p
            className='u-margin-top text-size-x-small'
            dangerouslySetInnerHTML={{ __html: promoData.get('termsAndConditions') }}
          />
        </div>
      );
    };

    renderFullTandC = isShowingSlide => (
      <div className='section section--compact text-left u-padding-top-small'>
        <ActionLink
          classes='earn__fullOfferTerms'
          onClickAction={this.showFullOfferTerms}
          text='earn.fullOfferTerms.header'
          isShowingSlide={isShowingSlide}
        />
      </div>
    );

    renderPointsTCs = isShowingSlide => (
      <div className='section section--compact text-left u-padding-top-small'>
        <ActionLink
          classes='earn__points-tcs'
          onClickAction={this.showPointsTCsModal}
          text='earn.pointsTCs'
          isShowingSlide={isShowingSlide}
        />
      </div>
    );

    renderFAQs = isShowingSlide => (
      <div className='section section--compact text-left u-bt u-padding-top-small'>
        <ActionLink
          classes='earn__faqs'
          onClickAction={this.showFaqModal}
          text='earn.faqs.header'
          isShowingSlide={isShowingSlide}
        />
      </div>
    );

    renderPointsPrivacyPolicy = isShowingSlide => {
      const { clientData } = this.props;
      const showPrivacyPolicy = clientData.get('showPrivacyPolicy');
      if (showPrivacyPolicy) {
        return (
          <div className='section section--compact text-left u-padding-top-small'>
            <ActionLink
              classes='earn__pointsPrivacyPolicy'
              onClickAction={this.showPointsPrivacyPolicyModal}
              text='earn.points.privacyPolicy.header'
              isShowingSlide={isShowingSlide}
            />
          </div>
        );
      }
      return null;
    }

    renderEarnPromoDetails = () => {
      const { isShowingSlide } = this.props;
      return (
        <div className='earnAndRedeem__promoForm__subsection u-margin-top u-padding u-padding-large@md' aria-hidden={isShowingSlide}>
          {this.renderFAQs(isShowingSlide)}
          {this.renderFullTandC(isShowingSlide)}
          {this.renderPointsTCs(isShowingSlide)}
          {this.renderPointsPrivacyPolicy(isShowingSlide)}
          {this.renderTCs()}
        </div>
      );
    };

    renderEarnPromoModal = () => {
      const { formatter, setIsShowingSlide, isShowingSlide } = this.props;
      const { earnPromoSlideContent, toggleSlide } = this.state;
      const disableHtmlFormatting = earnPromoSlideContent.get('disableHtmlFormatting');
      const description = earnPromoSlideContent.get('description');
      const header = earnPromoSlideContent.get('header');

      let content = description;
      if (!disableHtmlFormatting && description) {
        content = <FormattedHTMLMessage id={description} />;
      }

      return (
        <FocusTrap isActive={isShowingSlide}>
          <Slide
            handleClose={this.setEarnPromoSlideContent}
            formatter={formatter}
            header={header ? formatter.formatMessage({ id: header }) : null}
            setIsShowingSlide={setIsShowingSlide}
            toggleSlide={toggleSlide}
            shouldHideOnLoad
          >
            <div className='section section--compact u-padding-small text-left'>
              {earnPromoSlideContent.size ? content : null}
            </div>
          </Slide>
        </FocusTrap>
      );
    };

    render() {
      return (
        <div>
          {this.renderEarnPromoModal()}
          {this.renderEarnPromoDetails()}
        </div>
      );
    }
}

UnconnectedEarnRedeemFooter.propTypes = {
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  messages: ImmutablePropTypes.map,
  isShowingSlide: PropTypes.bool,
  promoData: ImmutablePropTypes.map,
  setIsShowingSlide: PropTypes.func
};

const mapStateToProps = state => ({
  clientData: state.app.get('clientData'),
  formatter: state.app.get('formatter'),
  messages: state.app.get('messages'),
  isShowingSlide: state.app.get('isShowingSlide'),
  promoData: state.earn.get('promoData')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setIsShowingSlide
}, dispatch);

export const EarnRedeemFooter = connect(mapStateToProps, mapDispatchToProps)(UnconnectedEarnRedeemFooter);
