import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FancyButton from 'react-fancy-button';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedHTMLMessage, FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import { fetchLocale, setIsShowingSlide } from '../action-creators/app';
import {
  activateSlide,
  deactivateSlide,
  loadData,
  login,
  makeRegistrationAttempt,
  registerMembership,
  selectMembership,
  setIsAttemptingRegistration,
  triggerAnalyticsForgotInfo,
  triggerAnalyticsMembershipRegistrationForm
} from '../action-creators/fix-earn-order';

import constants from '../utils/constants';
import { externalRedirect, getQueryParamsAsObject } from '../utils/url';
import FocusTrap from './FocusTrap';

import { IntlContainer } from './Intl';

import { JoinNowLink } from './JoinNowLink';
import { MembershipRegistrationForm } from './MembershipRegistrationForm';
import MembershipSelectionForm from './MembershipSelectionForm';
import Slide from './Slide';
import Spinner from './Spinner';
import { getLogoPath } from './utils/image-helpers';

export class FixEarnOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: ''
    };
  }

  componentDidMount() {
    const { clientData, fetchLocale, params, login, push } = this.props;

    const orderId = params.id;
    const queryParams = getQueryParamsAsObject();
    const mv = queryParams.mv;
    const verifyEmailToken = queryParams.verifyEmailToken;
    const clientId = queryParams.clientId;
    const locale = clientData.get('locale');

    fetchLocale(locale);

    login(mv, verifyEmailToken, clientId);

    push(`/fix-earn-order/${orderId}`);
    this.setState({ orderId });
  }

  componentDidUpdate(prevProps) {
    const {
      isLoggedIn,
      loadData,
      shouldSelectMembership,
      activateSlide,
      deactivateSlide
    } = this.props;

    const { orderId } = this.state;

    const gotLoggedIn = !prevProps.isLoggedIn && isLoggedIn;
    if (gotLoggedIn) {
      loadData(orderId);
    }

    const gotShouldSelectMembership = !prevProps.shouldSelectMembership && shouldSelectMembership;
    if (gotShouldSelectMembership) {
      activateSlide();
    }

    const gotMembershipSelected = prevProps.shouldSelectMembership && !shouldSelectMembership;
    if (gotMembershipSelected) {
      deactivateSlide();
    }
  }

  onDoneClick = url => {
    externalRedirect(url);
  }

  renderError = error => {
    const { messages, clientData } = this.props;
    const fixEarnOrderErrors = constants.fixEarnOrderErrors;
    let showContactSupportLink = false;
    const contactSupportLink = <FormattedHTMLMessage id='partner.contactSupport' />;

    const isErrorPresent = messages.get(error);
    if (!isErrorPresent) {
      error = fixEarnOrderErrors.genericIssue;
    }

    switch (error) {
      case fixEarnOrderErrors.orderNotFound:
      case fixEarnOrderErrors.lpNotFound:
      case fixEarnOrderErrors.membershipNotFound:
      case fixEarnOrderErrors.genericIssue:
        showContactSupportLink = true;
    }

    return (
      <div className='fix_earn_order__content'>
        <div className='fix_earn_order__heading'>
          <FormattedMessage id={error} />
          { showContactSupportLink ? contactSupportLink : null }
        </div>

        { this.renderContactSupport() }

        { clientData.get('noPointsLogoFooter') ? null : this.renderFooterLogo() }
      </div>
    );
  }

  renderContent = () => {
    const { error, clientData } = this.props;

    if (error) {
      return this.renderError(error);
    }

    return (
      <div className='fix_earn_order__content'>
        { this.renderLpLogo() }

        { this.renderMainContent() }

        { this.renderContactSupport() }

        { clientData.get('noPointsLogoFooter') ? null : this.renderFooterLogo() }
      </div>
    );
  }

  renderSlide = () => {
    const {
      isShowingSlide,
      setIsShowingSlide,
      formatter,
      slideStatus,
      shouldSelectMembership,
      deactivateSlide,
      isAttemptingRegistration
    } = this.props;

    return (
      <div className='outer-container__hide-scrollbar'>
        <FocusTrap isActive={isShowingSlide}>
          <Slide
            toggleSlide={slideStatus}
            setIsShowingSlide={setIsShowingSlide}
            formatter={formatter}
            className='fix_earn_order__slide'
            disableClose={shouldSelectMembership}
            performClose={() => !isAttemptingRegistration && deactivateSlide()}
            triggerClose={isAttemptingRegistration}
            shouldHideOnLoad
          >
            {
              (() => {
                if (shouldSelectMembership) {
                  return this.renderMembershipSelection();
                }
                return this.renderRegistration();
              })()
            }
          </Slide>
        </FocusTrap>
      </div>
    );
  }

  renderMembershipSelection = () => {
    const { potentialMemberships, formatter, selectMembership, lp, isLoadingData } = this.props;
    return (
      <div className='fix_earn_order__content u-padding-top-huge'>
        { this.renderLpLogo() }

        { this.renderMembershipSelectionError() }

        <div className='fix_earn_order__heading'>
          <FormattedMessage id='fixEarnOrder.selectAccount' />
        </div>

        <MembershipSelectionForm
          lp={lp}
          memberships={potentialMemberships}
          onSubmit={selectMembership}
          formatter={formatter}
          trigger={isLoadingData}
        />
      </div>
    );
  }

  renderMembershipSelectionError = () => {
    const { error, formatter } = this.props;

    if (!error) {
      return null;
    }

    return (
      <div className='fix_earn_order__error'>
        <div className='message message--error'>
          <div
            key={error}
            className='u-margin-vertical-none'
            aria-live='assertive'
            role='alert'
            aria-label={formatter.formatMessage({ id: error })}
          >
            <FormattedMessage id={error} />
          </div>
        </div>
      </div>
    );
  }

  getShopAndEarnUrl = () => {
    const { lp, clientData } = this.props;
    const lpId = lp.get('id');
    const shopAndEarnUrlsMap = clientData.get('shopAndEarnUrls');

    if (!shopAndEarnUrlsMap) {
      return '';
    }

    if (shopAndEarnUrlsMap.has(lpId)) {
      return shopAndEarnUrlsMap.get(lpId);
    } else if (shopAndEarnUrlsMap.has('default')) {
      return shopAndEarnUrlsMap.get('default');
    }

    return '';
  }

  forgotInfo = lp => {
    const { triggerAnalyticsForgotInfo } = this.props;

    triggerAnalyticsForgotInfo(lp);
  }

  renderRegistration = () => {
    const { clientData, lp, membership, isAttemptingRegistration, makeRegistrationAttempt,
      registerMembership, formatter, setIsAttemptingRegistration } = this.props;

    if (lp.isEmpty() || membership.isEmpty()) {
      return null;
    }

    const signUpUrl = lp.getIn(['content', 'websiteUrl']);
    const wpName = clientData.get('wpName');
    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpName = lp.getIn(['content', 'lpName']);
    const lpURL = lp.getIn(['content', 'websiteUrl']);

    return (
      <div className='fix_earn_order__content'>
        { this.renderLpLogo() }

        <div className='text-header-3 fix_earn_order__step_display'>
          <FormattedMessage id='fixEarnOrder.linkAccount.instructions' />
        </div>

        <div className='fix_earn_order__heading'>
          <FormattedMessage id='fixEarnOrder.linkAccount.title' />
        </div>

        <div className='fix_earn_order__description'>
          <FormattedMessage
            id='fixEarnOrder.linkAccount.description'
            values={{
              lpCompanyName,
              lpName,
              wpName
            }}
          />
        </div>

        { this.renderMembershipErrors() }

        <div className='fix_earn_order__form_container'>
          <MembershipRegistrationForm
            className='fix_earn_order__form u-padding-vertical text-center'
            lp={lp}
            membership={membership}
            registerMembership={registerMembership}
            onSubmit={makeRegistrationAttempt}
            isAttemptingRegistration={isAttemptingRegistration}
            setIsAttemptingRegistration={setIsAttemptingRegistration}
          />
        </div>

        <div className='fix_earn_order__button'>
          <FancyButton
            classes='fix_earn_order__btn'
            label={formatter.formatMessage({ id: 'fixEarnOrder.linkAccount.actionButton' })}
            trigger={isAttemptingRegistration}
            onClick={makeRegistrationAttempt}
          />
        </div>

        {signUpUrl &&
          <JoinNowLink signupUrl={signUpUrl}  />
        }

        {
          lpURL ? (
            <div className='fix_earn_order__forgot_info'>
              <FormattedMessage id='fixEarnOrder.linkAccount.contactInformation1' />
              <a onClick={() => this.forgotInfo(lp)}>{ lpCompanyName } { lpName }</a> {}
              <FormattedMessage id='fixEarnOrder.linkAccount.contactInformation2' />
            </div>
          ) : null
        }

        { clientData.get('noPointsLogoFooter') ? null : this.renderFooterLogo() }
      </div>
    );
  }

  renderMembershipErrors = () => {
    const { formatter, membership, isAttemptingRegistration } = this.props;

    const isPolling = membership.get('memberDetailsStatus') === constants.MEMBER_DETAILS_STATUS_PENDING;
    const membershipErrors = membership.get('memberDetailsErrors') || List();

    if (membershipErrors.isEmpty() || isPolling || isAttemptingRegistration) {
      return null;
    }

    return (
      <div className='fix_earn_order__error'>
        <div className='message message--error'>
          {
            membershipErrors.map(error => {
              const defaultMessage = formatter.formatMessage({ id: 'membershipRegistration.defaultError' });
              return (
                <div
                  key={error}
                  className='u-margin-vertical-none'
                  aria-live='assertive'
                  role='alert'
                  aria-label={formatter.formatMessage({ id: `membershipRegistration.${error}`, defaultMessage })}
                >
                  <FormattedMessage id={`membershipRegistration.${error}`} defaultMessage={defaultMessage} />
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }

  renderHeader = () => {
    const { clientData } = this.props;
    const wpName = clientData.get('wpName');
    const wpCode = clientData.get('wpCode');

    return (
      <div className='header fix_earn_order__top_bar'>
        <img
          alt={`${wpName} logo`}
          src={getLogoPath(wpCode)}
        />
      </div>
    );
  }

  renderLpLogo = () => {
    const { lp } = this.props;

    if (lp.isEmpty()) {
      return null;
    }

    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpCode = lp.getIn(['content', 'code']);

    return (
      <div className='fix_earn_order__partner_logo'>
        <img
          alt={`${lpCompanyName} logo`}
          src={getLogoPath(lpCode)}
        />
      </div>
    );
  }

  renderContactSupport = () => (
    <div className='fix_earn_order__support'>
      <FormattedMessage id='fixEarnOrder.landing.questions' />
      <br />
      <FormattedHTMLMessage id='partner.contactSupport' />
    </div>
  );

  renderFooterLogo = () => (
    <div className='fix_earn_order__footer'>
      <img
        alt='powered by points logo'
        src={getLogoPath('points_powered_by_points')}
      />
    </div>
  )

  renderMainContent = () => {
    const { order, membership, lp, shouldSelectMembership } = this.props;

    const missingData = order.isEmpty() || membership.isEmpty() || lp.isEmpty();
    if (missingData || shouldSelectMembership) {
      return this.renderSpinner();
    }

    const orderStatus = order.get('status');
    const isMembershipRegistered = membership.get('isRegistered');

    switch (orderStatus) {
      case constants.orderStatus.complete:
        return this.renderOrderComplete();
      case constants.orderStatus.failed:
        return this.renderOrderFailed();
      case constants.orderStatus.cancelled:
        return this.renderOrderCancelled();
    }

    if (isMembershipRegistered) {
      return this.renderRegistrationComplete();
    }

    return this.renderRegistrationPending();
  }

  renderRegistrationPending = () => {
    const { clientData, isShowingSlide, lp, activateSlide,
      triggerAnalyticsMembershipRegistrationForm, formatter } = this.props;

    const lpName = lp.getIn(['content', 'lpName']);
    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpCurrencyNameLong = lp.getIn(['content', 'currencyNameLong']);
    const lpCurrencyNameShort = lp.getIn(['content', 'currencyNameShort']);
    const wpName = clientData.get('wpName');
    const currencyAndCompanyName = `${lpCompanyName} ${lpCurrencyNameLong}`;

    return (
      <div>
        <div className='text-header-3 fix_earn_order__step_display'>
          <FormattedMessage id='fixEarnOrder.landing.instructions' />
        </div>

        <div className='fix_earn_order__heading'>
          <FormattedHTMLMessage
            id='fixEarnOrder.landing.title'
            values={{
              currencyAndCompanyName
            }}
          />
        </div>

        <div className='fix_earn_order__description'>
          <FormattedHTMLMessage
            id='fixEarnOrder.landing.description'
            values={{
              lpCurrencyNameShort,
              wpName,
              lpCompanyName,
              lpName
            }}
          />
        </div>

        <div className='fix_earn_order__button'>
          <FancyButton
            classes='fix_earn_order__btn'
            label={formatter.formatMessage({ id: 'fixEarnOrder.landing.actionButton' })}
            onClick={() => { activateSlide(); triggerAnalyticsMembershipRegistrationForm(); }}
            disabled={isShowingSlide}
          />
        </div>
      </div>
    );
  }

  renderRegistrationComplete = () => {
    const { lp, formatter } = this.props;

    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpCurrencyNameLong = lp.getIn(['content', 'currencyNameLong']);
    const shopAndEarnURL = this.getShopAndEarnUrl();
    return (
      <div>
        <div className='fix_earn_order__heading'>
          <FormattedMessage id='fixEarnOrder.done.instructions' />
        </div>

        <div className='fix_earn_order__description'>
          <FormattedMessage
            id='fixEarnOrder.done.title'
            values={{
              lpCompanyName,
              lpCurrencyNameLong
            }}
          />
        </div>

        <div className='fix_earn_order__checkmark'>
          <div className='text-size-biggest text-color-success'>
            <FormattedHTMLMessage id='fixEarnOrder.done.description' />
            <i className='icon-circle-checkmark' />
          </div>
        </div>

        {
          shopAndEarnURL && (
            <div className='fix_earn_order__button'>
              <FancyButton
                classes='fix_earn_order__btn'
                label={formatter.formatMessage({ id: 'fixEarnOrder.done.actionButton' })}
                onClick={() => this.onDoneClick(shopAndEarnURL)}
              />
            </div>
          )
        }
      </div>
    );
  }

  renderOrderComplete = () => {
    const { order, lp, formatter } = this.props;

    const amount = order.getIn(['to', 'amount']);
    const lpCompanyName = lp.getIn(['content', 'companyName']);
    const lpCurrencyNameLong = lp.getIn(['content', 'currencyNameLong']);
    const shopAndEarnURL = this.getShopAndEarnUrl();

    return (
      <div>
        <div className='fix_earn_order__heading'>
          <FormattedMessage id='fixEarnOrder.transactionComplete' />
        </div>

        <div className='fix_earn_order__description'>
          <FormattedMessage
            id='fixEarnOrder.alreadyEarned'
            values={{
              amount,
              lpCompanyName,
              lpCurrencyNameLong
            }}
          />
        </div>

        <div className='fix_earn_order__checkmark'>
          <div className='text-size-biggest text-color-success'>
            <i className='icon-circle-checkmark' />
          </div>
        </div>

        {
          shopAndEarnURL && (
            <div className='fix_earn_order__button'>
              <FancyButton
                classes='fix_earn_order__btn'
                label={formatter.formatMessage({ id: 'fixEarnOrder.done.actionButton' })}
                onClick={() => this.onDoneClick(shopAndEarnURL)}
              />
            </div>
          )
        }
      </div>
    );
  }

  getContactSupportLink = () => <FormattedHTMLMessage id='partner.pleaseContactSupport' />;

  renderOrderFailed = () => {
    const contactSupportLink = this.getContactSupportLink();
    return (
      <div>
        <div className='fix_earn_order__heading'>
          <FormattedMessage id='fixEarnOrder.transactionFailed' />
        </div>

        <div className='fix_earn_order__description'>
          <FormattedMessage id='fixEarnOrder.problemWithOrder' /> { contactSupportLink }
        </div>
      </div>
    );
  }

  renderOrderCancelled = () => {
    const contactSupportLink = this.getContactSupportLink();
    return (
      <div>
        <div className='fix_earn_order__heading'>
          <FormattedMessage id='fixEarnOrder.transactionCancelledHeader' />
        </div>

        <div className='fix_earn_order__description'>
          <FormattedMessage id='fixEarnOrder.transactionIsCancelled' /> { contactSupportLink }
        </div>
      </div>
    );
  }

  renderSpinner = () => (
    <div className='fix_earn_order__content'>
      <div className='text-center u-padding-top-large fix_earn_order__spinner'>
        <Spinner opts={{ zIndex: 0 }} />
      </div>
    </div>
  )

  render() {
    const { locale, messages, isShowingSlide } = this.props;

    if (!locale || !messages.size) {
      return null;
    }

    return (
      <IntlProvider locale={locale} textComponent='span' messages={messages.toJSON()}>
        <IntlContainer>
          { this.renderSlide() }
          <div
            className='page sticky-footer fix_earn_order__page'
            aria-hidden={isShowingSlide}
          >
            <div className='sticky-footer__body'>
              { this.renderHeader() }
              { this.renderContent() }
            </div>
          </div>
        </IntlContainer>
      </IntlProvider>
    );
  }
}

FixEarnOrder.propTypes = {
  locale: PropTypes.string,
  messages: ImmutablePropTypes.map,
  isShowingSlide: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  error: PropTypes.string,
  params: PropTypes.object,
  isLoadingData: PropTypes.bool,
  order: ImmutablePropTypes.map,
  lp: ImmutablePropTypes.map,
  membership: ImmutablePropTypes.map,
  potentialMemberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  shouldSelectMembership: PropTypes.bool,
  slideStatus: PropTypes.number,
  isAttemptingRegistration: PropTypes.bool,
  fetchLocale: PropTypes.func,
  login: PropTypes.func,
  push: PropTypes.func,
  loadData: PropTypes.func,
  setIsShowingSlide: PropTypes.func,
  activateSlide: PropTypes.func,
  deactivateSlide: PropTypes.func,
  selectMembership: PropTypes.func,
  makeRegistrationAttempt: PropTypes.func,
  registerMembership: PropTypes.func,
  triggerAnalyticsMembershipRegistrationForm: PropTypes.func,
  triggerAnalyticsForgotInfo: PropTypes.func,
  setIsAttemptingRegistration: PropTypes.func
};

const mapStateToProps = state => ({
  locale: state.app.get('locale'),
  messages: state.app.get('messages'),
  isShowingSlide: state.app.get('isShowingSlide'),
  isLoggedIn: state.auth.get('isLoggedIn'),
  clientData: state.app.get('clientData'),
  formatter: state.app.get('formatter'),
  error: state.fixEarnOrder.get('error'),
  isLoadingData: state.fixEarnOrder.get('isLoadingData'),
  order: state.fixEarnOrder.get('order'),
  lp: state.fixEarnOrder.get('lp'),
  membership: state.fixEarnOrder.get('membership'),
  potentialMemberships: state.fixEarnOrder.get('potentialMemberships'),
  shouldSelectMembership: state.fixEarnOrder.get('shouldSelectMembership'),
  slideStatus: state.fixEarnOrder.get('slideStatus'),
  isAttemptingRegistration: state.fixEarnOrder.get('isAttemptingRegistration')
});


const mapDispatchToProps = dispatch => bindActionCreators({
  fetchLocale,
  login,
  push,
  loadData,
  setIsShowingSlide,
  activateSlide,
  deactivateSlide,
  selectMembership,
  makeRegistrationAttempt,
  registerMembership,
  triggerAnalyticsMembershipRegistrationForm,
  triggerAnalyticsForgotInfo,
  setIsAttemptingRegistration
}, dispatch);

export const FixEarnOrderContainer = connect(mapStateToProps, mapDispatchToProps)(FixEarnOrder);
