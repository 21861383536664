import { fromJS } from 'immutable';

const initialState = fromJS({
  isFetchingUser: false,
  isCreatingAccount: false,
  hasCreatedAccount: false,
  createAccountError: {},
  isLinkingAccount: false,
  linkAccountEmail: '',
  linkAccountError: false,
  data: {}
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'IS_FETCHING_USER':
      return state.set('isFetchingUser', action.isFetchingUser);

    case 'SET_USER':
      return state.set('data', fromJS(action.user));

    case 'CLEAR_USER_DATA':
      return state.set('data', fromJS({}));

    case 'SET_LINK_ACCOUNT_EMAIL':
      return state.set('linkAccountEmail', action.email);

    case 'HAS_CREATED_ACCOUNT':
      return state.set('hasCreatedAccount', true);

    case 'IS_CREATING_ACCOUNT':
      return state.set('isCreatingAccount', action.isCreatingAccount);

    case 'IS_LINKING_ACCOUNT':
      return state.set('isLinkingAccount', action.isLinkingAccount);

    case 'CREATE_ACCOUNT_ERROR':
      return state.set('createAccountError', fromJS(action.error));

    case 'LINK_ACCOUNT_ERROR':
      return state.set('linkAccountError', fromJS(action.error));

    default:
      return state;
  }
}
