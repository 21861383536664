import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class CheckmarkLoader extends React.Component {
  state = {
    shouldAnimate: true,
    triggerSuccess: false,
    shouldUpdate: true
  };

  UNSAFE_componentWillMount() {
    if (this.props.trigger) {
      this.setState({ shouldAnimate: false, triggerSuccess: true });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { trigger } = this.props;
    if (this.state.shouldUpdate) {
      this.setState({ triggerSuccess: nextProps.trigger });
    }
    if (nextProps.trigger !== trigger) {
      this.setState({ shouldAnimate: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ shouldUpdate: false });
  }

  _isMounted = false;
  circleBaseEl = null;
  circleLoadingEl = null;

  render() {
    const { trigger } = this.props;
    const { shouldAnimate, triggerSuccess } = this.state;

    const radius = 10; // MUST be the same as $checkmark-loader-radius value in _components.checkmark-loader.scss
    const strokeWidth = 3;
    const center = radius + strokeWidth;
    const size = 2 * center;

    return (
      <div className={classnames('checkmark-loader', { 'checkmark-loader--success': triggerSuccess })}>
        <i className={classnames('checkmark-loader__icon', { 'checkmark-loader__icon--success icon-check': triggerSuccess })} />
        <svg
          className='checkmark-loader__circle-container'
          width={size}
          height={size}
        >
          <circle
            ref={el => this.circleBaseEl = el}
            className='checkmark-loader__circle-base transition--base'
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
          />

          <circle
            ref={el => this.circleLoadingEl = el}
            className={classnames('checkmark-loader__circle-loading', {
              'transition--longer': shouldAnimate,
              'checkmark-loader__circle-loading--complete': trigger
            })}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
          />
        </svg>
      </div>
    );
  }
}

CheckmarkLoader.propTypes = {
  trigger: PropTypes.bool
};

export default CheckmarkLoader;
