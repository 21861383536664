import React from 'react';
import PropTypes from 'prop-types';

import { EarnPerformanceHorizonsIntegration } from './EarnPerformanceHorizonsIntegration';
import { EarnLyftIntegration } from './EarnLyftIntegration';

import constants from '../utils/constants';

const EarnPartnerIntegration = ({ type, isShowing }) => {
  if (!isShowing) {
    return null;
  }

  switch (type) {
    case constants.earnIntegrationType.lyft:
      return <EarnLyftIntegration />;
    case constants.earnIntegrationType.performanceHorizons:
      return <EarnPerformanceHorizonsIntegration />;
    default:
      return null;
  }
};

EarnPartnerIntegration.propTypes = {
  type: PropTypes.string,
  isShowing: PropTypes.bool
};

export default EarnPartnerIntegration;
