import urlJoin from 'url-join';
import appUtils from '../../utils/application';

export const generateExternalCredentialCatcherUrl = (
  credentialCatcherConfig,
  memberValidationUrl,
  callbackUrl,
  membershipId
) => {
  const redirectKey = credentialCatcherConfig.get('redirectKey');
  const baseRedirectUrl = credentialCatcherConfig.get(redirectKey);
  const encodedRedirectUrl = encodeURIComponent(callbackUrl);
  const encodedMvUrl = window.btoa(memberValidationUrl);

  const params = [`&mv=${encodedMvUrl}`, `&registrationId=${membershipId}`];
  if (!appUtils.isProdEnvironment()) params.push(`&redirectUrl=${encodedRedirectUrl}`);
  const url = urlJoin(baseRedirectUrl, ...params);

  return url;
};
