import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FormattedDate, FormattedNumber, FormattedMessage } from 'react-intl';
import LoadingDots from './LoadingDots';
import { Helmet } from 'react-helmet';
import { getCardArtPath } from './utils/image-helpers';

export class Membership extends React.Component {
  balanceEl = null;
  cardArtEl = null;

  loadingUiEl = null;
  memberIdEl = null;
  membershipNotFoundEl = null;

  renderCardArt = lp => {
    const { device } = this.props;
    const lpCode = lp.getIn(['content', 'code']);
    let cardArtPath;

    if (device === 'desk') {
      cardArtPath = getCardArtPath(lpCode, 'large');
    } else {
      cardArtPath = getCardArtPath(lpCode, 'med');
    }

    return (
      <div className='membership-details__card-art'>
        <div className='section'>
          <div className='u-padding u-padding-bottom-none'>
            <img ref={el => this.cardArtEl = el} alt={`${lp.get('lpName')} card art`} src={cardArtPath} className='u-1/1 block' />
          </div>
        </div>
      </div>
    );
  };

  renderDetailsRowItem = (label, value) => (
    <div className='u-bb u-padding-top-tiny u-padding-bottom-tiny'>
      <span className='text-weight-8 u-padding-left-small'>{label}</span>
      <div ref={`${label}Value`} className='float-right u-padding-right-small'>
        {value}
      </div>
    </div>
  );

  renderLoadingMembershipUi = text => (
    <div ref={el => this.loadingUiEl = el} className='text-center'>
      <p>
        {text}
      </p>
      <LoadingDots isAnimating />
    </div>
  );

  renderMembership = () => {
    const { membershipId, memberships, allLps } = this.props;
    const membership = memberships.find(mem => mem.get('id') === membershipId);

    if (!membership) {
      return (
        <p ref={el => this.membershipNotFoundEl = el}>
          <FormattedMessage id='membershipcard.membershipNotFound' />
        </p>
      );
    }

    const lp = allLps.find(_lp => _lp.get('id') === membership.get('lpId'));
    return (
      <div className='membership'>
        {this.renderMembershipDetails(membership, lp)}
      </div>
    );
  };

  renderMembershipDetails = (membership, lp) => {
    if (!membership.get('isRegistered')) {
      return this.renderCardArt(lp);
    }

    const { user } = this.props;
    const isPending = membership.get('memberDetailsStatus') === 'pending';
    const tierInfo = membership.getIn(['memberDetails', 'membershipLevel']);

    return (
      <div>
        {this.renderCardArt(lp)}
        {isPending ? this.renderLoadingMembershipUi('Loading Membership') : null}

        <div ref={el => this.balanceEl = el} className='text-center text-size-biggest text-weight-8 u-margin-top'>
          <FormattedNumber value={membership.getIn(['memberDetails', 'balance'])} />
        </div>

        <p className='text-center text-color-muted u-margin-top-none'>
          balance as of <FormattedDate value={membership.get('updatedAt')} />
        </p>

        <div className='text-center'>
          <div ref={el => this.memberIdEl = el} className='text-weight-8 text-size-bigger'>
            {membership.getIn(['memberDetails', 'memberId'])}
          </div>
          <p className='text-center text-color-muted u-margin-top-none'>
            account number
          </p>
        </div>

        <div className='section u-margin-top u-bt text-size-big'>
          {this.renderDetailsRowItem('Name', `${user.get('firstName')} ${user.get('lastName')}`)}
          {tierInfo ? this.renderDetailsRowItem('Status', tierInfo) : null}
        </div>
      </div>
    );
  };

  render() {
    const { memberships, formatter } = this.props;
    return (
      <div className='membership-container'>
        <Helmet>
          <title>{formatter.formatMessage({ id: 'pageTitle.membership' })}</title>
          <meta
            name='description'
            content={formatter.formatMessage({ id: 'pageTitle.membership' })}
          />
        </Helmet>
        {memberships.size ? this.renderMembership() : this.renderLoadingMembershipUi('Loading Memberships')}
      </div>
    );
  }
}

Membership.propTypes = {
  device: PropTypes.string,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  allLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  memberships: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  user: ImmutablePropTypes.map,
  membershipId: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return {
    device: state.app.get('device'),
    formatter: state.app.get('formatter'),
    allLps: state.lps.get('allLps'),
    memberships: state.memberships.get('data'),
    user: state.user.get('data'),
    membershipId: ownProps.params.id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export const MembershipContainer = connect(mapStateToProps, mapDispatchToProps)(Membership);
