import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, browserHistory, Redirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

/**-----------------------------
 *     Components
 * -----------------------------*/

import { AppContainer } from './components/App';
import { EarnContainer } from './components/Earn';
import { EarnFlowContainer } from './components/EarnFlow';
import { ActivityContainer } from './components/Activity';
import { ErrorContainer } from './components/Error';
import { CreateAccountContainer } from './components/CreateAccount';
import { MembershipContainer } from './components/Membership';
import { WalletLandingContainer } from './components/WalletLanding';
import { ExchangeConfigureContainer } from './components/ExchangeConfigure';
import Exchange from './components/Exchange';
import { IntlContainer } from './components/Intl';
import EarnPointsTermsContainer from './components/EarnPointsTermsContainer';
import { Start }  from './components/Start';
import { FixEarnOrderContainer } from './components/FixEarnOrder';
import EarnFullOfferTermsContainer from './components/EarnFullOfferTermsContainer';
import { InlineCredentialCatcherContainer } from './components/InlineCredentialCatcher';
import { ExternalCredentialCatcherContainer } from './components/ExternalCredentialCatcher';
import { WidgetCredentialCatcherContainer } from './components/WidgetCredentialCatcher';
import { EarnLyftIntegrationRedirect } from './components/EarnLyftIntegrationRedirect';
import { EarnFlowSuccessContainer } from './components/EarnFlowSuccess';
import { EarnFlowErrorContainer } from './components/EarnFlowError';
import { TermsAndConditionsContainer } from './components/TermsAndConditions';
import { RedeemFlowContainer } from './components/RedeemFlow';
import { RedeemFlowSuccessContainer } from './components/RedeemFlowSuccess';
import { RedeemFlowErrorContainer } from './components/RedeemFlowError';

class _Root extends React.Component {
  UNSAFE_componentWillMount() {
    const { store } = this.props;
    const history = syncHistoryWithStore(browserHistory, store);
    this.history = history;
    this._routes = this.setupRoutes();
  }

  setupRoutes = () => {
    const { store } = this.props;

    return [
      <Route
        key='fix-earn-order'
        path='fix-earn-order(/:id)'
        component={FixEarnOrderContainer}
        onChange={(p, n, r) => onChange(p, n, r, store)}
      />,
      <Route
        key='register-membership'
        path='register-membership(/:id)'
        component={InlineCredentialCatcherContainer}
        onChange={(p, n, r) => onChange(p, n, r, store)}
      />,
      <Route
        key='register-external-membership'
        path='register-external-membership(/:id)(/:partnerMv)'
        component={ExternalCredentialCatcherContainer}
        onChange={(p, n, r) => onChange(p, n, r, store)}
      />,
      <Route
        key='register-widget-membership'
        path='register-widget-membership(/:id)(/:partnerMv)'
        component={WidgetCredentialCatcherContainer}
        onChange={(p, n, r) => onChange(p, n, r, store)}
      />,
      <Route
        key='lyft-redirect'
        path='lyft-redirect'
        component={EarnLyftIntegrationRedirect}
        onChange={(p, n, r) => onChange(p, n, r, store)}
      />,
      <Route key='blw-routes' path='/' component={AppContainer}>
        <Route component={IntlContainer} onChange={(p, n, r) => onChange(p, n, r, store)}>
          <Route path='earn' component={EarnContainer} >
            <Route path='points-account-terms' component={EarnPointsTermsContainer} />
            <Route path='full-offer-terms' component={EarnFullOfferTermsContainer} />
          </Route>
          <Route path='error' component={ErrorContainer} />
          <Route path='maintenance' component={ErrorContainer} />
          <Route path='terms-and-conditions' component={TermsAndConditionsContainer} />
          <Route path='wallet' component={WalletLandingContainer}>
            <Route path='create-account' component={CreateAccountContainer} />
          </Route>
          <Route path='activity' component={ActivityContainer} />
          <Route path='exchange' component={Exchange}>
            <Route path='configure' component={ExchangeConfigureContainer}>
              <Route path='create-account' component={CreateAccountContainer} />
            </Route>
          </Route>
          <Route path='/memberships/:id' component={MembershipContainer} />
          <Route path='hiltonhonorslyft' component={Start} />
          <Route path='start' component={Start} />
          <Route path='earn-flow' component={EarnFlowContainer}>
            <Route path='success' component={EarnFlowSuccessContainer} />
            <Route path='linking-error' component={EarnFlowErrorContainer} />
          </Route>
          <Route path='redeem-flow' component={RedeemFlowContainer}>
            <Route path='success' component={RedeemFlowSuccessContainer} />
            <Route path='redeeming-error' component={RedeemFlowErrorContainer} />
          </Route>
          <Redirect from='*' to='/' />
        </Route>
      </Route>
    ];
  }

  _routes = null;

  history = null;

  render() {
    return (
      <div>
        <Router history={this.history}>
          {this._routes}
        </Router>
      </div>
    );
  }
}

/**-----------------------------
 *   routes on change for i18n
 * ----------------------------*/
let replaceKey = null;
function onChange(prevState, nextState, replace, store) {
  if (!store) return;
  const nextLocation = nextState.location;
  const { app } = store.getState();
  if (replaceKey === null) {
    replaceKey = nextLocation.key;
  } else if (replaceKey !== nextLocation.key) {
    replaceKey = null;
    return false;
  }

  const locale = app.get('locale');

  nextLocation.query.locale = locale;
  nextLocation.query.selectedLpId = prevState.location.query.selectedLpId;
  const googleTagManager = app.get('googleTagManager');
  googleTagManager.triggerPageView(nextLocation.pathname);

  replace(nextLocation);
}

function mapStateToProps(state) {
  return {
    clientData: state.app.get('clientData')
  };
}

const Root = connect(mapStateToProps)(_Root);

export default Root;
