import { request, serializeQueryParams } from './utils/httpUtils';

export function fetchOrders(params = {}) {
  return request(`/v1/orders?${serializeQueryParams(params)}`)
    .then(data => data);
}

export function fetchOrder(orderId) {
  return request(`/v1/orders/${orderId}`, {}, false).then(data => data.json);
}

export function updateOrder(orderId, updatedData = {}, allowRedirects = true) {
  const options = {
    method: 'put',
    body: JSON.stringify(updatedData)
  };

  return request(`/v1/orders/${orderId}`, options, allowRedirects)
    .then(data => data.json);
}

export default {
  fetchOrders,
  fetchOrder,
  updateOrder
};
