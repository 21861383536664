import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';

class HeroOverlayTemplate1 extends React.Component {
  overlayIsEmpty() {
    const { overlayContent } = this.props;
    const emptySectionCount = overlayContent.count(section => this.sectionIsEmpty(section));

    return emptySectionCount >= overlayContent.size;
  }

  sectionIsEmpty = section => {
    const copy = section.get('text');
    const highlight = section.get('highlight');

    return (copy === '' && highlight === '');
  }

  shouldEnlargeOverlaySections = () => {
    // Should enlarge if we have empty sections in the overlay
    const { overlayContent } = this.props;
    return overlayContent.count(section => this.sectionIsEmpty(section)) >= 1;
  }

  renderTextIcon = content => (
    <div className='overlay__icon'>
      {content}
    </div>
  );

  renderCurrencyIcon = content => (
    <div className='overlay__currency-icon'>
      <span className='overlay__currency-sign'>$</span>{content}
    </div>
  );

  renderIconSection = section => {
    switch (section.get('iconType')) {
      case 'currency':
        return this.renderCurrencyIcon(section.get('iconContent'));
      case 'text':
        return this.renderTextIcon(section.get('iconContent'));
      default:
        return null;
    }
  };

  renderOverlaySection(section, i) {
    return (
      <div key={`overlay-section-${i}`}>
        { !this.sectionIsEmpty(section) ?
          <div className='overlay__section'>
            { this.renderIconSection(section) }
            <div className='overlay__copy u-padding-horizontal'>
              <div className='overlay__text'>
                {section.get('text')}
              </div>
              <div className='overlay__highlight'>
                {section.get('highlight')}
              </div>
            </div>
          </div> :
          null
        }
      </div>
    );
  }

  render() {
    const { overlayContent } =  this.props;
    return (
      <div>
        { !this.overlayIsEmpty() ?
          <div className={classnames('earn__hero-overlay', { 'earn__hero-overlay--enlarged': this.shouldEnlargeOverlaySections() })}>
            { overlayContent.map((section, i) => this.renderOverlaySection(section, i)) }
          </div> :
          null
        }
      </div>
    );
  }
}

HeroOverlayTemplate1.propTypes = {
  overlayContent: ImmutablePropTypes.list
};

export default HeroOverlayTemplate1;
