import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Spinner from './Spinner';

const EarnFlowInterstitial = ({ isShowing, logo, alt }) => {
  if (isShowing) {
    return (
      <div id='earnAndRedeem__earn__interstitial__parentContainer'>
        <div id='earnAndRedeem__earn__interstitial__subContainer'>
          <img alt={alt} src={logo} />
          <FormattedMessage id='earn.redirect' />
          <Spinner opts={{ top: '70%' }} />
        </div>
      </div>
    );
  }
  return null;
};

EarnFlowInterstitial.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  alt: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
};

export default EarnFlowInterstitial;
