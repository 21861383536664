import constants from '../../utils/constants';

const debugKeyEvents = false;

function handleKeyPress(keyCode, callback, opts = {}) {
  return function handler(event) {
    if (debugKeyEvents) {
      console.log('Expected: ', keyCode, '\nObtained: ', event.key);
      console.log(event.target);
    }
    if (event.key === keyCode) {
      if (opts.suppressEventPropagation) {
        event.stopPropagation();
      }
      if (opts.preventDefault) {
        event.preventDefault();
      }
      return callback(event);
    }
  };
}

export function handleKeyPressEnter(callback) {
  return handleKeyPress(constants.keyEnter, callback);
}

export function handleKeyPressEscape(callback) {
  return handleKeyPress(constants.keyEscape1, callback) ||
    handleKeyPress(constants.keyEscape2, callback);
}

export function handleKeyPressArrowRight(callback) {
  return handleKeyPress(constants.keyArrowRight, callback);
}

export function handleKeyPressArrowLeft(callback) {
  return handleKeyPress(constants.keyArrowLeft, callback);
}

export function handleKeyPressHome(callback) {
  return handleKeyPress(constants.keyHome, callback, { preventDefault: true });
}

export function handleKeyPressEnd(callback) {
  return handleKeyPress(constants.keyEnd, callback, { preventDefault: true });
}

// tab-index -1 allows the element to be focusable
export function getTabIndex(isBeingCovered, shouldBeInTabOrder = true, focusable = true) {
  const shouldTab = shouldBeInTabOrder && !isBeingCovered;
  if (shouldTab) {
    return '0';
  } else if (focusable) {
    return '-1';
  }
  return null;
}

export function shouldBeHiddenFromVoiceOver(tabIndexValue) {
  return tabIndexValue === '-1' || tabIndexValue === -1;
}
