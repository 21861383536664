import React from 'react';
import PropTypes from 'prop-types';
import { EarnRedeemFooter } from './EarnRedeemFooter';
import NoticeBox from './NoticeBox';
import { SwitchEarnToRedeemBox } from './SwitchEarnToRedeemBox';
import { AdContainer } from './AdContainer';

const EarnFlowSuccess = ({ renderHero }) => {
  window.scrollTo(0, 0);
  const heroText = {
    header: 'earn.linkAccount.success.header',
    subheading: 'earn.linkAccount.success.subheading'
  };
  return (
    <div className='earn earnAndRedeem'>
      {renderHero(heroText)}
      <NoticeBox />
      <AdContainer bannerSrc='banner-background.png' />
      <SwitchEarnToRedeemBox />
      <div id='earnFlow__footer__container'>
        <EarnRedeemFooter />
      </div>
    </div>
  );
};

EarnFlowSuccess.propTypes = {
  renderHero: PropTypes.func
};

export const EarnFlowSuccessContainer = EarnFlowSuccess;
