import { connect } from 'react-redux';

import { getMembershipFromState } from './utils/state-helpers';

import { ExchangeSelectModal, commonMapStateToProps, commonMapDispatchToProps } from './ExchangeSelectModal';

function mapStateToProps(state) {
  return {
    direction: 'from',
    eligibleLps: state.lps.get('eligibleFromLps'),
    membership: getMembershipFromState(state, 'from'),
    ...commonMapStateToProps(state)
  };
}

export const ExchangeFromSelectModalContainer = connect(mapStateToProps, commonMapDispatchToProps)(ExchangeSelectModal);
