// https://www.kirupa.com/html5/get_element_position_using_javascript.htm
export function getPosition(element) {
  let x = 0;
  let y = 0;

  while (element) {
    x += (element.offsetLeft - element.scrollLeft + element.clientLeft);
    y += (element.offsetTop - element.scrollTop + element.clientTop);
    element = element.offsetParent;
  }
  return { x, y };
}

/**
 * animated scroll function
 * @method scrollTo
 * @param {Element} element DOM element to scroll
 * @param {Number} to pixel value of scroll position (example: 0 is top, 100 is 100px down)
 * @param {Number} duration value in ms for animation duration
 *
* */
export function scrollTo(element = document.body, to = 0, duration = 0) {
  const tickLength = 10;
  const difference = to - element.scrollTop;
  const perTick = difference / duration * tickLength;
  if (duration <= 0) { return; }

  setTimeout(() => {
    element.scrollTop += perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - tickLength);
  }, tickLength);
}

export function isElementInViewport(el) {
  const rect = el?.getBoundingClientRect();

  return (
    rect?.top >= 0 &&
    rect?.left >= 0 &&
    rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function getTabbableElements(parent) {
  if (!parent || parent.nodeType !== 1) {
    return [];
  }

  const selectors = [
    'input',
    'select',
    'a[href]',
    'textarea',
    'button',
    '[tabindex]'
  ];

  const elementCollection = parent.querySelectorAll(selectors);
  const possibleElements = [].slice.call(elementCollection);
  return possibleElements.filter(element => {
    const tabIndex = parseInt(element.getAttribute('tabIndex'), 10) || element.tabIndex;
    return tabIndex >= 0
      && !element.disabled
      && !(element.tagName === 'INPUT' && element.type === 'hidden')
      && !elementIsHidden(element);
  });
}

function elementIsHidden(node) {
  if (node === document.documentElement) {
    return false;
  }

  const computedStyle = window.getComputedStyle(node);
  const isHidden = computedStyle.visibility === 'hidden';
  const isDisplayNone = computedStyle.display === 'none';
  const pos = node.getBoundingClientRect();
  const isVisible = (pos.top >= 0) && (pos.bottom <= window.innerHeight);

  return isHidden || isDisplayNone || !isVisible;
}
