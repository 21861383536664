import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import constants from '../utils/constants';
import { handleKeyPressEnter, getTabIndex, shouldBeHiddenFromVoiceOver } from './utils/accessibility-helpers';
import { getLogoPath } from './utils/image-helpers';

class LpRowItem extends React.Component {
  state = {
    hasClicked: false
  };

  handleLpClick = () => {
    this.setState({ hasClicked: true });
    setTimeout(() => {
      this.props.onClick();
    }, constants.baseAnimationLength);
  };

  render() {
    const { lp, preferredLps, isShowingSlide, actionLabel } = this.props;
    const { hasClicked } = this.state;
    const name = lp.get('name');
    const companyName = lp.getIn(['content', 'companyName']);
    const logoPath = getLogoPath(lp.getIn(['content', 'code']));
    const isPreferred = preferredLps.indexOf(lp) > -1;
    const classes = classnames('lp-row-item u-margin-bottom@md u-margin-bottom-none', { 'lp-row-item--preferred': isPreferred, 'lp-row-item--clicked': hasClicked });
    const tabIndexValue = getTabIndex(isShowingSlide);

    return (
      <div
        id={`${name}_addLpToWallet`}
        className={classes}
        role='button'
        tabIndex={tabIndexValue}
        aria-hidden={shouldBeHiddenFromVoiceOver(tabIndexValue)}
        aria-label={`Select this option to add ${companyName} ${name}`}
        onClick={this.handleLpClick}
        onKeyUp={handleKeyPressEnter(this.handleLpClick)}
        data-location='(preferredPrograms)'
        data-status='selected'
        data-lp-name={name}
      >
        <div className='o-layout'>
          <div className='o-layout__item u-1/6@lg u-1/2@md u-3/6'>
            <div className='u-padding-left-small'>
              <img className='lp-row-item__img' alt={`${name} logo`} src={logoPath} />
            </div>
          </div>
          <div className='o-layout__item u-4/6@lg u-1/4@md u-1/6' />
          <div className='o-layout__item u-1/6@lg u-1/4@md u-2/6'>
            <div className='lp-row-item__link'>
              <FormattedMessage id={actionLabel} /> <i className='icon-chevron-right lp-row-item__affordance' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LpRowItem.propTypes = {
  lp: ImmutablePropTypes.map,
  preferredLps: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  isShowingSlide: PropTypes.bool,
  actionLabel: PropTypes.string,
  onClick: PropTypes.func
};

export default LpRowItem;
