/**
   @class Earn API
 */
import { request, serializeQueryParams, getAuthToken } from './utils/httpUtils';

export function fetchClientPromos(clientId, query) {
  return request(`/public/promos/${clientId}?${serializeQueryParams(query)}`)
    .then(payload => payload.json);
}

export function linkLyftAccount(authorizationCode, lpId) {
  const options = {
    method: 'post',
    body: JSON.stringify({
      authorizationCode,
      lpId
    })
  };

  return request('/v1/blw/partner-integration-lyft', options)
    .then(payload => payload.json);
}

export function fetchIntegrationRedirect(query, hasUser) {
  const options = {
    method: 'post',
    body: JSON.stringify(query)
  };
  const isAuthorized = getAuthToken() && hasUser;
  if (isAuthorized) {
    return request('/v1/blw/integration-redirect', options)
      .then(payload => payload.json);
  }
  return request('/auth/blw/integration-redirect', options)
    .then(payload => payload.json);
}


export default {
  fetchClientPromos,
  fetchIntegrationRedirect,
  linkLyftAccount
};
