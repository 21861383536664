import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import constants from '../utils/constants';
import classnames from 'classnames';
import { getUniqueLogoPath, getLogoPath } from './utils/image-helpers';
import { validateEmail } from '../utils/validations';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import FancyField from './FancyField';
import FancyButton from './FancyButton';

class NewAccount extends React.Component {
  state = {
    email: this.props.user.get('email') || '',
    fadeOut: false
  };

  componentDidUpdate(prevProps) {
    const { hasCreatedAccount, createAccountError, fadeOut, onError } = this.props;
    if (!prevProps.hasCreatedAccount && hasCreatedAccount) {
      if (fadeOut) {
        this.setState({ fadeOut: true });
        setTimeout(() => {
          this.onSuccess();
        }, constants.baseAnimationLength);
      } else {
        this.onSuccess();
      }
    }

    if (createAccountError.size && onError) {
      onError();
    }
  }

  onSuccess = () => {
    const { onSuccess } = this.props;
    if (onSuccess) {
      onSuccess();
    }
  };

  getIsEmailValid = () => typeof validateEmail(this.state.email) !== 'string';

  setEmailChange = email => {
    this.setState({ email });
  };

  createAccountBtnEl = null;

  showTermsLinkEl = null;

  submitCreateAccount = e => {
    e.preventDefault();
    if (this.getIsEmailValid()) {
      this.props.createAccount(this.state.email);
    }
  };

  getTandCsUrl = () => '/terms-and-conditions';

  render() {
    const { fadeOut } = this.state;
    const { clientData, formatter } = this.props;
    const wpName = clientData.get('wpName');
    const wpCode = clientData.get('wpCode');
    const partnerLogoAlt = formatter.formatMessage({ id: 'shared.partnerLogo' }, { lpName: wpName });
    const logoPath = getUniqueLogoPath(wpCode);
    const termsAndConditionsUrl = this.getTandCsUrl();

    return (
      <div className={classnames('new-account u-padding-horizontal-tiny u-padding-horizontal@md u-padding-top-large transition--base', { 'opacity-0': fadeOut, 'opacity-10': !fadeOut })}>
        <div className='o-layout o-layout--center'>
          <form id='new-account__form'>
            <div className='o-layout__item u-2/3@md u-1/1'>

              <div className='o-layout o-layout--center'>
                <div className='o-layout__item u-1/2'>
                  <img alt={partnerLogoAlt} className='u-1/1' src={logoPath} />
                </div>
              </div>

              <h3 className='u-padding-vertical rule'>
                <span className='slide__header__span'>
                  {formatter.formatMessage({ id: 'createAccount.welcome' })}
                </span>
              </h3>

              <div className='card create-account'>
                <div>
                  {
                    this.props.createAccountError.size ? (
                      <div className='message message--error'>
                        <ul>
                          {
                            this.props.createAccountError.get('errors').map(error => {
                              const errorCode = error.get('code');
                              return (
                                <li key={errorCode} className='text-base-size-palm'>
                                  <span aria-label={formatter.formatMessage({ id: errorCode })}>
                                    <FormattedHTMLMessage id={errorCode} />
                                  </span>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    ) : null
                  }

                  <div className='u-padding-horizontal-small'>
                    <div className='new-account__text o-layout'>
                      <div className='o-layout__item'>
                        <div className='text-center'>
                          <p className='new-account-first-msg text-color-highlight text-weight-8 text-size-big'>
                            <FormattedMessage id='createAccount.headerMessage' />
                          </p>
                          <p className='text-color-highlight'>
                            <FormattedMessage id='createAccount.subheaderMessage' />
                          </p>
                        </div>
                        <div className='new-account__email u-margin-top-large'>
                          <FancyField
                            type='email'
                            placeholder={formatter.formatMessage({ id: 'createAccount.email' })}
                            initialVal={this.state.email}
                            disabled={!!this.state.email}
                            validator={validateEmail}
                            onChange={this.setEmailChange}
                          />
                        </div>
                        <div className='new-account__terms-link u-bb u-margin-top u-padding-bottom-small'>
                          <a
                            ref={el => this.showTermsLinkEl = el}
                            className='link-with-icon hover-main text-base-size-palm'
                            href={termsAndConditionsUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <FormattedMessage id='createAccount.termsAndConditions' />
                          </a>
                          <i className='link-with-icon__icon icon-chevron-right' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='u-margin-top-large u-padding-horizontal-small'>
                <FancyButton
                  ref={el => this.createAccountBtnEl = el}
                  classes='btn btn-primary u-1/1 btn-large-padding'
                  label='createAccount.createAccountButtonText'
                  disabled={!this.getIsEmailValid()}
                  trigger={this.props.isCreatingAccount}
                  onClick={this.submitCreateAccount}
                />
              </div>
              <div className='text-center u-padding-vertical'>
                <img alt='Points logo' className='u-1/4' src={getLogoPath('points_powered_by_points')} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

NewAccount.propTypes = {
  user: ImmutablePropTypes.map,
  hasCreatedAccount: PropTypes.bool,
  fadeOut: PropTypes.bool,
  createAccountError: ImmutablePropTypes.map,
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  isCreatingAccount: PropTypes.bool,
  onError: PropTypes.func,
  createAccount: PropTypes.func,
  onSuccess: PropTypes.func
};

export default NewAccount;
