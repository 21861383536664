import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Spinner from './Spinner';
import constants from '../utils/constants';
import { getLogoPath } from './utils/image-helpers';

class Interstitial extends React.Component {
  static defaultProps = {
    loadingMessage: '',
    partnerLp: Map(),
    isShowing: false
  };

  renderHeaderImage = () => {
    const { partnerLp, formatter } = this.props;
    return (
      <div className='u-margin-vertical-large'>
        <img
          className='interstitial__img'
          alt={formatter.formatMessage({ id: 'shared.partnerLogo' }, { lpName: `${partnerLp.getIn(['content', 'companyName'])} ${partnerLp.get('name')}` })}
          height={constants.headerLogoHeight}
          src={getLogoPath(partnerLp.getIn(['content', 'code']))}
        />
      </div>
    );
  };

  render() {
    const { loadingMessage, isShowing } = this.props;
    return (
      <div className={classnames('interstitial u-padding-top-huge u-padding', { 'interstitial--showing': isShowing })} aria-hidden={isShowing ? 'false' : 'true'}>
        <div className='section'>
          { this.renderHeaderImage() }
          <FormattedMessage id={loadingMessage} />
        </div>
        <div className='u-margin-top-large'>
          {
            isShowing ? (
              <Spinner
                opts={{
                  radius: 15,
                  length: 5
                }}
              />
            ) : null
          }
        </div>
      </div>
    );
  }
}

Interstitial.propTypes = {
  loadingMessage: PropTypes.string,
  partnerLp: ImmutablePropTypes.map,
  isShowing: PropTypes.bool,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

export default Interstitial;
