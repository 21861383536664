/**
 @class Memberships Action Creator
 */
import { fromJS, Map } from 'immutable';
import constants from '../../src/utils/constants';

import googleTagManager from '../../src/utils/google-tag-manager';
import { setPageMessage } from '../action-creators/app';
import Memberships from '../api/memberships';
import { setRemainingLps } from './lps';

/**
 set boolean to indicate whether the `GET /v1/memberships` API request
 has ever been done (and finished).

 @method hasInitializedMemberships
 @param {Boolean} hasInitializedMemberships
 */
export function hasInitializedMemberships(hasInitializedMemberships) {
  return {
    type: 'HAS_INITIALIZED_MEMBERSHIPS',
    hasInitializedMemberships
  };
}

/**
 set boolean for UI to know when memberships are fresh (i.e. they have been polled and haven't been altered since)
 @method hasUpdatedMemberships
 @param {Boolean} hasUpdatedMemberships
 */
export function hasUpdatedMemberships(hasUpdatedMemberships) {
  return {
    type: 'HAS_UPDATED_MEMBERSHIPS',
    hasUpdatedMemberships
  };
}

/**
 calls `GET /v1/memberships/{membershipId}` poll for membership
 @method pollForMembershipDetails
 @private
 @param {Map} membership
 */
function pollForMembershipDetails(membership = {}) {
  return dispatch => {
    if (membership.memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_PENDING) {
      setTimeout(() => {
        dispatch(fetchMembership(membership.id));
      }, constants.POLLING_DELAY);
    } else {
      let gtmData = {
        action: constants.ANALYTICS_REGISTER_LP,
        status: membership.memberDetailsStatus,
        registerProgram: membership.lpName,
        lpId: membership.lpId,

        event: 'BLWVirtualPageview', // It feels weird to trigger a virtual page view for this and from here, but it's deliberate, as part of a marketing ask (https://trello.com/c/r6GPeVJb/29-ga-feedback-need-to-improve-information-for-the-virtual-pages)
        virtualPageURL: '/wallet/membership/confirm',
        virtualPageTitle: 'wallet.membership.confirm'
      };

      if (membership.memberDetailsErrors) {
        gtmData = Object.assign({
          errorCode: membership.memberDetailsErrors
        }, gtmData);
      }

      googleTagManager.pushGtmData(gtmData);
      dispatch(updateMembership(membership));
      dispatch(hasUpdatedMemberships(true));
    }
  };
}

/*-------------------------------
 *
 * Sync Actions
 *
   -------------------------------*/

/**
 sets user's membership for application
 @method setMemberships
 @param {List[Map]} memberships
 */
export function setMemberships(memberships) {
  return {
    type: 'SET_MEMBERSHIPS',
    memberships
  };
}

/**
 add membership to application state
 @method addMembership
 @param {Map} membership
 */
export function addMembership(membership) {
  return {
    type: 'ADD_MEMBERSHIP',
    membership
  };
}

/**
 remove membership to application state
 @method removeMembership
 @param {Map} membership
 */
export function removeMembership(membership) {
  return {
    type: 'REMOVE_MEMBERSHIP',
    membership
  };
}

/**
 edit user's membership in app state
 @method updateMembership
 @param {Map} membership
 */
export function updateMembership(membership) {
  return {
    type: 'UPDATE_MEMBERSHIP',
    membership
  };
}

/**
 set boolean for fetching the final offer set
 @method setHasFetchedFinalOfferSet
 @param {Boolean} hasFetchedFinalOfferSet
 */

export function setHasFetchedFinalOfferSet(hasFetchedFinalOfferSet) {
  return {
    type: 'SET_HAS_FETCHED_FINAL_OFFER_SET',
    hasFetchedFinalOfferSet
  };
}

/**
 set boolean to state if the app is updating the memberships
 @method isUpdatingMemberships
 @param {Boolean} isUpdatingMemberships
 */

export function isUpdatingMemberships(isUpdatingMemberships) {
  return {
    type: 'SET_IS_UPDATING_MEMBERSHIPS',
    isUpdatingMemberships
  };
}

/**
 set boolean to state if the app is in the middle of deleting a membership
 @method setIsDeletingMembership
 @param {Boolean} isDeletingMembership
 */

export function setIsDeletingMembership(isDeletingMembership) {
  return {
    type: 'SET_IS_DELETING_MEMBERSHIP',
    isDeletingMembership
  };
}

/**
 set boolean to state if there is an error with deleting a membership
 @method setHasDeleteMembershipError
 @param {Boolean} hasDeleteMembershipError
 */

export function setHasDeleteMembershipError(hasDeleteMembershipError) {
  return {
    type: 'SET_HAS_DELETE_MEMBERSHIP_ERROR',
    hasDeleteMembershipError
  };
}

/*-------------------------------
 *
 * Async Actions
 *
   -------------------------------*/

/**
 `POST /v1/memberships` adds a membership to the user in the DB
 @method postNewMembership
 @param {string} lpId
 @param {List} memberships
 */
export function postNewMembership(lpId, memberships) {
  return dispatch => {
    const hasMembership = memberships.find(membership => membership.get('lpId') === lpId);
    if (!hasMembership) {
      dispatch(hasUpdatedMemberships(false));
      Memberships.postNewMembership(lpId).then(membership => {
        dispatch(addMembership(membership));
        dispatch(hasUpdatedMemberships(true));
      });
    }
  };
}

/**
 `DELETE /v1/memberships/{membershipId}` removes user's membership from DB
 @method deleteMembership
 @param {Map} membership
 */

export function deleteMembership(membership) {
  return dispatch => {
    dispatch(setIsDeletingMembership(true));
    dispatch(hasUpdatedMemberships(false));
    return Memberships.deleteMembership(membership.get('id')).then(status => {
      if (status < 300) {
        dispatch(removeMembership(membership));
        dispatch(setIsDeletingMembership(false));
        dispatch(hasUpdatedMemberships(true));
      } else {
        dispatch(setHasDeleteMembershipError(true));
        dispatch(setIsDeletingMembership(false));
        dispatch(hasUpdatedMemberships(true));
      }
    })
      .catch(() => {
        dispatch(setHasDeleteMembershipError(true));
        dispatch(setIsDeletingMembership(false));
        dispatch(hasUpdatedMemberships(true));
      });
  };
}

/**
 `GET /v1/memberships?{shouldRefresh}` fetches user's memberships for application
 @method fetchMemberships
 @param {boolean} shouldRefresh
 @param {boolean} shouldPoll
 */
export function fetchMemberships(shouldRefresh, shouldPoll) {
  return dispatch => {
    dispatch(hasUpdatedMemberships(false));
    Memberships.fetchMemberships(shouldRefresh).then(memberships => {
      dispatch(setMemberships(memberships));
      dispatch(setRemainingLps(memberships));
      const containsPendingMemberships = memberships.some(membership => (
        membership.memberDetailsStatus === constants.MEMBER_DETAILS_STATUS_PENDING
      ));
      if (containsPendingMemberships && shouldPoll) {
        setTimeout(() => {
          dispatch(fetchMemberships(false, true));
        }, constants.POLLING_DELAY);
      } else {
        dispatch(hasInitializedMemberships(true));
        dispatch(hasUpdatedMemberships(shouldPoll));
      }
    });
  };
}

/**
 `GET /v1/memberships/{membershipId}` fetches a single user
 membership
 @method fetchMembership
 @param {Map} membership
 @param {Boolean} shouldRefresh
 */
export function fetchMembership(membershipId, shouldRefresh) {
  return dispatch => {
    dispatch(hasUpdatedMemberships(false));
    Memberships.fetchMembership(membershipId, shouldRefresh).then(updatedMembership => {
      dispatch(pollForMembershipDetails(updatedMembership));
      dispatch(updateMembership(updatedMembership));
    });
  };
}

/**
 `DELETE /v1/memberships/{membershipId}` removes user's membership from DB
 @method mergeMembership
 @param {Map} membership
 */
export function mergeMemberships(duplicatedMembership, registeredMembership) {
  return (dispatch, getState) => {
    const { app } = getState();
    const updatedParams = {
      lpId: duplicatedMembership.get('lpId'),
      credentials: duplicatedMembership.get('credentials'),
      id: registeredMembership.get('id')
    };

    // Delete duplicatedMembership and then update registeredMembership
    // with duplicatedMembership data
    dispatch(deleteMembership(duplicatedMembership))
      .then(Memberships.registerMembership(updatedParams)
        .then(updatedMembership => {
          dispatch(pollForMembershipDetails(updatedMembership));
          dispatch(updateMembership(updatedMembership));
        }));

    const message = app.get('formatter').formatMessage({
      id: 'membershipSelect.successfullyMerged'
    }, {
      lpName: duplicatedMembership.get('lpName')
    });

    dispatch(setPageMessage({ type: 'success', message }));
  };
}

/**
 `PUT /v1/memberships/{membershipId}` updates membership
 @method registerMembership
 @param {Map} unregisteredMembership
 */
export function registerMembership(unregisteredMembership) {
  return (dispatch, getState) => {
    dispatch(isUpdatingMemberships(true));
    dispatch(hasUpdatedMemberships(false));

    return Memberships.registerMembership(unregisteredMembership).then(registeredMembership => {
      dispatch(pollForMembershipDetails(registeredMembership));
      dispatch(updateMembership(registeredMembership));
      dispatch(isUpdatingMemberships(false));
    }, res => {
      // If there is an unregistered membership with an invalid account number
      // and there's already a valid membership of the same program in the wallet,
      // delete the unregistered membership and update valid membership.
      const { errorCodes } = constants;
      const shouldMergeAccounts = fromJS(res.errors).filter(item => item.get('code') === errorCodes.duplicateMemberId);

      if (shouldMergeAccounts && shouldMergeAccounts.size) {
        const duplicatedMembership = Map(unregisteredMembership);

        const registeredMembership = getState()
          .memberships
          .get('data')
          .find(membership => membership.get('isRegistered') === true && membership.get('lpId') === duplicatedMembership.get('lpId'));

        dispatch(mergeMemberships(duplicatedMembership, registeredMembership));
        dispatch(isUpdatingMemberships(false));
      }
    }).catch(() => {
      dispatch(isUpdatingMemberships(false));
    });
  };
}

