/**
 @class Auth Action Creator
 */
import Auth from '../api/auth';
import { fetchUser, setLinkAccountEmail } from './user';
import { setPageMessage } from './app';
import { setPartnerAccountLinked, setEarnMembershipSelectionLpId } from './earn';
import { getClientId } from '../utils/application';
import { replace, push } from 'react-router-redux';


/*-------------------------------
 *
 * Sync Actions
 *
 -------------------------------*/

/**
 UI boolean to trigger on/off spinner while creating and linking accounts
 @method setIsCreatingAccount
 @param {Boolean} isCreatingAccount true will show spinner, false will hide spinner
 */
export function setIsCreatingAccount(isCreatingAccount) {
  return {
    type: 'IS_CREATING_ACCOUNT',
    isCreatingAccount
  };
}

/**
 toggles isLinkingAccount boolean. If boolean true, then show the <LinkAccount />
 instead of <CreateAccount ?>
 @method setIsLinkingAccount
 @param {Boolean} value of isLinkingAccount
 */
export function setIsLinkingAccount(isLinkingAccount) {
  return {
    type: 'IS_LINKING_ACCOUNT',
    isLinkingAccount
  };
}

/**
 @method onLogin
 */
export function onLogin(isLoggedIn) {
  return {
    type: 'IS_LOGGED_IN',
    isLoggedIn
  };
}

/**
 @method clearUserData
 */
export function clearUserData() {
  return {
    type: 'CLEAR_USER_DATA'
  };
}

/**
 @method clearMemberships
 */
export function clearMemberships() {
  return {
    type: 'CLEAR_MEMBERSHIPS'
  };
}

export const logOut = () => dispatch => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) return;
  return Auth.invalidateAccessToken(accessToken).then(() => {
    dispatch(clearUserData());
    dispatch(clearMemberships());
    localStorage.clear();
  }).catch(() => {
    dispatch(clearUserData());
    dispatch(clearMemberships());
    localStorage.clear();
    dispatch(push('/error'));
  });
};

/**
 @method setLoginErrors
 */
export function setLoginErrors(loginErrors) {
  return {
    type: 'LOGIN_ERRORS',
    loginErrors
  };
}

export function hasCreatedAccount() {
  return {
    type: 'HAS_CREATED_ACCOUNT'
  };
}

/**
 will set error(s) to display to user on create account
 @method setCreateAccountError
 @param {List[String]} error(s) that will display to user on error of
 create account
 */
export function setCreateAccountError(error) {
  return {
    type: 'CREATE_ACCOUNT_ERROR',
    error
  };
}

/**
 will set error(s) to display to user on linking account
 @method setLinkAccountError
 @param {List[String]} error(s) pertaining to link account action
 */
export function setLinkAccountError(error) {
  return {
    type: 'LINK_ACCOUNT_ERROR',
    error
  };
}

export function setIsSingleSigningOn(isSingleSigningOn) {
  return {
    type: 'SET_IS_SINGLE_SIGNING_ON',
    isSingleSigningOn
  };
}

/*-------------------------------
 *
 * Async Actions
 *
 -------------------------------*/


/**
 uses mv to retrieve user from api
 @method postSSO
 @param {String} mv is hash that links to user in wallet api DB
 */
export function postSSO(params = {}) {
  const { arbitraryToMembership, earnLpId, mv, verifyEmailToken } = params;
  return (dispatch, getState) => {
    const clientId = getClientId();
    dispatch(setIsSingleSigningOn(true));
    return Auth.postSSO(clientId, mv, verifyEmailToken)
      .then(payload => {
        dispatch(onLogin(true));
        dispatch(setIsSingleSigningOn(false));
        dispatch(setPartnerAccountLinked(!!payload.json.partnerAccountLinked));
        const { app } = getState();
        if (verifyEmailToken && app.get('formatter')) {
          dispatch(setPageMessage({
            type: 'success',
            message: app.get('formatter').formatMessage({ id: 'createAccount.successfulAccountCreationMessage' })
          }));
        }
        if (earnLpId && (arbitraryToMembership === true || arbitraryToMembership === 'true')) { // arbitraryToMembership is obtained from query params, so it's likely to be a string
          dispatch(setEarnMembershipSelectionLpId(earnLpId));
        }
      }).catch(res => {
        dispatch(setLoginErrors(res.errors));
        dispatch(setIsSingleSigningOn(false));
      });
  };
}

/**
 `POST  /v1/blw/users` email associated with mv account
 @method linkAccount
 @param {string} email
 @param {string} [verificationCode] passcode that user recieves from email
 */
export function linkAccount(email, verificationCode) {
  return dispatch => {
    dispatch(setIsCreatingAccount(true));
    return Auth.createAccount(email, verificationCode).then(() => {
      dispatch(hasCreatedAccount());
      dispatch(setIsCreatingAccount(false));
      return dispatch(fetchUser(false, false));
    }).catch(e => {
      dispatch(setIsCreatingAccount(false));
      dispatch(setLinkAccountError(e));
    });
  };
}

/**
 `POST /v1/blw/send-code`
 @method sendCode
 @param {string} email email associated with mv account of which to send passcode
 */
export function sendCode(email) {
  return dispatch => Auth.sendCode(email)
    .then(res => {
      if (res.status > 299) dispatch(replace('/error'));
    })
    .catch(() => dispatch(replace('/error')));
}

/**
 `POST /v1/blw/users`
 @method createAccount
 @param {string} email email to create account with
 */
export function createAccount(email) {
  return dispatch => {
    dispatch(setIsCreatingAccount(true));
    return new Promise(resolve => {
      setTimeout(() => {
        Auth.createAccount(email).then(() => {
          dispatch(hasCreatedAccount());
          dispatch(setIsCreatingAccount(false));
          resolve(dispatch(fetchUser(false, false)));
        }).catch(e => {
          if (e.status === 409) {
            dispatch(setIsLinkingAccount(true));
            dispatch(setLinkAccountEmail(email));
            dispatch(sendCode(email));
          } else {
            dispatch(setCreateAccountError(e));
          }
          dispatch(setIsCreatingAccount(false));
          resolve();
        });
      }, 1000);
    });
  };
}
