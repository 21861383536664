import { Map } from 'immutable';

export function getMembershipFromState(state, direction) {
  const memberships = state.memberships.get('data');
  const membershipId = state.exchange.get(`${direction}MembershipId`);
  return memberships.find(membership => membership.get('id') === membershipId) || Map();
}

export function getLpFromState(state, direction) {
  const membership = getMembershipFromState(state, direction);
  return state.lps.get('allLps').find(lp => lp.get('id') === membership.get('lpId')) || Map();
}
