/**
 @class Earn Action Creator
 */
import Earn from '../api/earn';
import { push } from 'react-router-redux';
import constants from '../utils/constants';
import urlUtils from '../utils/url';
import httpUtils from '../api/utils/httpUtils';
import { setAppError, setPageMessage } from './app';
import { logOut } from './auth';

const {
  lyftRedirectUrls: {
    linkingSuccess: successUrl,
    linkingError: errorUrl
  },
  APP_ERROR_LINKING_EARN
} = constants;

export const setPromoData = promoData => ({
  type: 'SET_PROMO_DATA',
  promoData
});

export const setPromoRedirectUrl = promoRedirectUrl => ({
  type: 'SET_PROMO_REDIRECT_URL',
  promoRedirectUrl
});


export const setIsTriggeringPartnerIntegration = isTriggeringPartnerIntegration => ({
  type: 'SET_IS_TRIGGERING_PARTNER_INTEGRATION',
  isTriggeringPartnerIntegration
});

export const setShouldShowEarnInterstitial = shouldShowEarnInterstitial => ({
  type: 'SET_SHOULD_SHOW_EARN_INTERSTITIAL',
  shouldShowEarnInterstitial
});

export const setMembership = membership => ({
  type: 'SET_EARN_MEMBERSHIP',
  membership
});

export const setEarnIntegration = integration => ({
  type: 'SET_EARN_INTEGRATION',
  integration
});

export const setShouldExecutePartnerIntegration = shouldExecute => ({
  type: 'SET_EARN_SHOULD_EXECUTE_PARTNER_INTEGRATION',
  shouldExecute
});

export const setMembershipLp = lp => ({
  type: 'SET_EARN_MEMBERSHIP_LP',
  lp
});

export const setPartnerAccountLinked = (partnerAccountLinked = false) => ({
  type: 'SET_PARTNER_ACCOUNT_LINKED',
  partnerAccountLinked
});

export const setEarnMembershipSelectionLpId = earnMembershipSelectionLpId => ({
  type: 'SET_EARN_MEMBERSHIP_SELECTION_LP_ID',
  earnMembershipSelectionLpId
});

/**
 `GET /public/promos/:clientId`
 @method fetchClientPromos
 @param {String} clientId
 @param {Object} query
 */
export const fetchClientPromos = (clientId, query) => dispatch =>
  Earn.fetchClientPromos(clientId, query).then(res => {
    dispatch(setPromoData(res));
  });

export const fetchEarnIntegration = query => (dispatch, getState) => {
  const hasUser = !getState().user.get('data').isEmpty();
  const wpName = getState().app.get('clientData').get('wpName');

  dispatch(setIsTriggeringPartnerIntegration(true));
  return Earn.fetchIntegrationRedirect(query, hasUser)
    .then(res => {
      if (!Object.values(constants.earnIntegrationType).includes(res.integration)) {
        dispatch(setIsTriggeringPartnerIntegration(false));
        dispatch(setPageMessage({
          type: 'error',
          message: getState().app.get('formatter').formatMessage({
            id: 'earn.missingPartnerIntegration'
          }, { wpName })
        }));
      } else {
        httpUtils.setAuthToken(res.accessToken);
        dispatch(setEarnIntegration(res.integration));
        dispatch(setPartnerAccountLinked(res.partnerAccountLinked));
        dispatch(setPromoRedirectUrl(res.redirectUrl));
        dispatch(setShouldExecutePartnerIntegration(true));
      }
    }, () => {
      dispatch(setIsTriggeringPartnerIntegration(false));
      dispatch(setAppError(constants.APP_ERROR_CONTACT_POINTS_SUPPORT));
      dispatch(push('/error'));
    });
};

export const earnPerformanceHorizonsRedirect = url => dispatch => {
  dispatch(setShouldShowEarnInterstitial(true));
  setTimeout(() => {
    dispatch(setIsTriggeringPartnerIntegration(false));
    urlUtils.externalRedirect(url);
  }, constants.timeoutEarnRedirect);
};

export const earnLyftOpenPopup = url => (dispatch, getState) => {
  const popupWindow = window.open(url, 'Lyft Connection', 'height=600,width=800');
  const timer = setInterval(() => {
    if (popupWindow.closed) {
      clearInterval(timer);
      dispatch(setIsTriggeringPartnerIntegration(false));
      dispatch(setShouldExecutePartnerIntegration(false));
      dispatch(setEarnIntegration(''));
      dispatch(setPageMessage({
        type: 'error',
        message: getState().app.get('formatter').formatMessage({
          id: 'earn.partnerIntegrationCancelled'
        })
      }));
    }
  }, 500);

  if (window.focus) {
    popupWindow.focus();
  }
};

export const linkLyftAccount = (authorizationCode, lpId) => dispatch => {
  /* For linking errors, we are logging the user out and pushing them to /error temporarily until the BE fixes a bug on guest users.
  The ticket to watch for the BE is DLW-4035. The original flow would have been to keep the user authenticated and redirect them to /earn-flow/linking-error.
  */
  if (!authorizationCode) {
    dispatch(setAppError(APP_ERROR_LINKING_EARN));
    dispatch(logOut());
    dispatch(push(errorUrl));
    return;
  }
  return Earn.linkLyftAccount(authorizationCode, lpId)
    .then(() => dispatch(push(successUrl)))
    .catch(() => {
      dispatch(setAppError(APP_ERROR_LINKING_EARN));
      dispatch(logOut());
      dispatch(push(errorUrl));
    });
};

export const lyftRedirect = () => (dispatch, getState) => {
  const promoRedirectUrl = getState().earn.get('promoRedirectUrl');

  if (promoRedirectUrl !== '') {
    dispatch(setShouldShowEarnInterstitial(true));
    setTimeout(() => {
      urlUtils.externalRedirect(promoRedirectUrl);
    }, constants.timeoutEarnRedirect);
  } else {
    dispatch(push(`/${errorUrl}`));
  }
};
