import { fromJS } from 'immutable';

const initialState = fromJS({
  data: [],
  hasInitializedMemberships: false,
  hasUpdatedMemberships: false,
  hasFetchedFinalOfferSet: false,
  isUpdatingMemberships: false,
  isDeletingMembership: false,
  hasDeleteMembershipError: false
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'HAS_INITIALIZED_MEMBERSHIPS':
      if (action.hasInitializedMemberships === state.get('hasInitializedMemberships')) {
        return state;
      }
      return state.set('hasInitializedMemberships', action.hasInitializedMemberships);

    case 'HAS_UPDATED_MEMBERSHIPS':
      return state.set('hasUpdatedMemberships', action.hasUpdatedMemberships);

    case 'SET_MEMBERSHIPS':
      return state.set('data', fromJS(action.memberships));

    case 'CLEAR_MEMBERSHIPS':
      return state.set('data', fromJS({}));

    case 'ADD_MEMBERSHIP': {
      const memberships = state.get('data');
      return state.set('data', memberships.push(fromJS(action.membership)));
    }

    case 'REMOVE_MEMBERSHIP': {
      const memberships = state.get('data');
      const membershipsAfterRemoval = memberships.filter(membership => membership.get('id') !== action.membership.get('id'));
      return state.set('data', membershipsAfterRemoval);
    }

    case 'UPDATE_MEMBERSHIP': {
      const memberships = state.get('data');
      const index = memberships.findIndex(membership => membership.get('id') === action.membership.id);
      const updatedMemberships = memberships.update(index, () => fromJS(action.membership));
      return state.set('data', updatedMemberships);
    }

    case 'SET_HAS_FETCHED_FINAL_OFFER_SET':
      return state.set('hasFetchedFinalOfferSet', action.hasFetchedFinalOfferSet);

    case 'SET_IS_DELETING_MEMBERSHIP':
      return state.set('isDeletingMembership', action.isDeletingMembership);

    case 'SET_HAS_DELETE_MEMBERSHIP_ERROR':
      return state.set('hasDeleteMembershipError', action.hasDeleteMembershipError);

    case 'SET_IS_UPDATING_MEMBERSHIPS':
      return state.set('isUpdatingMemberships', action.isUpdatingMemberships);

    default:
      return state;
  }
}
